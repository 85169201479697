import React from "react";
import Header from "../../../components/Menu";
import EditarPermissao from "../../../components/Cadastros/Editar/EditarPermissao";

const EditarPermissaoPage = () => {
  return (
    <div className="flex">
      <Header />
      <EditarPermissao />
    </div>
  );
};

export default EditarPermissaoPage;
