import React from "react";
import { useAuth } from "../../providers/Auth";
import { Input } from "../Input";
import { QuantidadeBriefingStyled } from "./styles";
import { get } from "lodash";
import { getValue } from "@testing-library/user-event/dist/utils";

const QuantidadeBriefing = ({
  index,
  register,
  indexQuantidade,
  setIndexQuantidade,
  outerIndex,
  unregister,
}) => {
  return (
    <QuantidadeBriefingStyled>
      <div className="input-completo item">
        <Input
          label={`Quantidade ${outerIndex + 1}`}
          type="text"
          placeholder={`Quantidade ${outerIndex + 1}`}
          register={register(`quantidade${index}`)}
          className="input-name select"
        />
      </div>
      {outerIndex !== 0 && (
        <span
          className="close"
          onClick={() => {
            setIndexQuantidade(indexQuantidade.filter((i) => i !== index));
            unregister(`quantidade${index}`);
          }}
        >
          X
        </span>
      )}
    </QuantidadeBriefingStyled>
  );
};

export default QuantidadeBriefing;
