import { useNavigate } from "react-router-dom";
import { BoxFiltros, ItensContainer } from "./style";
import Table from "./Table";
import som from "../../assets/menu-concorrencia.svg";
import { useEffect, useState } from "react";
import { ReactSVG } from "react-svg";
import api from "../../services/api";
import { toast } from "react-toastify";
import Menu from "../../components/Menu";

const ConcorrenciaPage = () => {
  const navigate = useNavigate();

  const [perfilSelecionado, setPerfilSelecionado] = useState("");
  const [textoPesquisa, setTextoPesquisa] = useState("");
  const [clientes, setClientes] = useState(false);
  const [marcas, setMarcas] = useState([]);

  function NovaCampanha() {
    navigate("/campanha/nova");
  }

  function buscarMarcas() {
    api
      .get("/marcas")
      .then((response) => {
        setMarcas(response.data.data.marcas);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  function buscarClientes() {
    api
      .get("/clientes")
      .then((response) => {
        setClientes(response.data.data.clientes);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  useEffect(() => {
    buscarMarcas();
    buscarClientes();
  }, []);

  return (
    <div className="flex">
      <Menu />
      <ItensContainer>
        <div className="box">
          <div className="flex">
            <ReactSVG src={som} />
            <h1>Concorrência</h1>
          </div>
          <BoxFiltros>
            <div className="filtro">
              <input
                placeholder="Pesquisar por ID ou nome do item"
                value={textoPesquisa}
                onChange={(e) => setTextoPesquisa(e.target.value)}
              />
              <div>
                <p>Filtrar por Cliente</p>
                <select
                  value={perfilSelecionado}
                  onChange={(e) => setPerfilSelecionado(e.target.value)}
                >
                  <option value="">Ver todos</option>
                  {clientes &&
                    clientes.map((element) => (
                      <option value={element.id}>{element.razao_social}</option>
                    ))}
                </select>
              </div>
            </div>
          </BoxFiltros>
          <Table
            perfilSelecionado={perfilSelecionado}
            textoPesquisa={textoPesquisa}
            clientes={clientes}
          />
        </div>
      </ItensContainer>
    </div>
  );
};

export default ConcorrenciaPage;
