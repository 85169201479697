import { ReactSVG } from "react-svg";
import { HistoricoCampanhaStyled } from "./style";
import { useParams } from "react-router-dom";
import api from "../../services/api";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import Loading from "../Loading";

export const HistoricoCampanha = () => {
  const { id } = useParams();
  const [historico, setHistorico] = useState(false);
  const [abrir, setAbrir] = useState(false);

  function buscarHistorico() {
    api
      .get(`/historico-campanha/${id}`)
      .then((response) => {
        setHistorico(response.data.data.historico);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  useEffect(() => {
    buscarHistorico();
  }, []);

  const colunas = ["Ação", "Data", "Responsável"];

  return (
    <HistoricoCampanhaStyled>
      <div className="flex">
        <ReactSVG src="" />
        <h2 id={14}>Histórico</h2>
        {abrir ? (
          <span className="ver" onClick={() => setAbrir(false)}>
            ver menos
          </span>
        ) : (
          <span className="ver" onClick={() => setAbrir(true)}>
            ver maior
          </span>
        )}
      </div>
      {abrir ? (
        !historico ? (
          <Loading />
        ) : (
          <div>
            <table>
              <thead>
                <tr>
                  {colunas.map((coluna, index) =>
                    index === 0 ? (
                      <th className="left" key={coluna}>
                        {coluna}
                      </th>
                    ) : (
                      <th key={coluna}>{coluna}</th>
                    )
                  )}
                </tr>
              </thead>
              <tbody>
                {historico.map((lista) => (
                  <tr className="map" key={lista.id}>
                    <td className="left">{lista.texto}</td>
                    <td>{lista.data_criacao}</td>
                    <td>{lista.criador}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )
      ) : (
        <></>
      )}
      {}
    </HistoricoCampanhaStyled>
  );
};
