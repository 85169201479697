import { Link, useNavigate } from "react-router-dom";
import {
  Body,
  InputsCliente,
  InputsEndereco,
  InputsEndereco1,
  InputsEndereco2,
  InputsIncricao,
  InputsPagamento,
  InputsPagamento1,
  InputsPagamento2,
  NovoClienteContainer,
} from "./style";
import { toast } from "react-toastify";
import api from "../../../../services/api";
import { useForm } from "react-hook-form";
import { NovoClienteSchema } from "../../../../schemas/novoCadastro";
import { yupResolver } from "@hookform/resolvers/yup";
import { ReactSVG } from "react-svg";
import voltar from "../../../../assets/voltar.svg";
import { useEffect, useState } from "react";
import { useAuth } from "../../../../providers/Auth";

const NovoCliente = () => {
  const navigate = useNavigate();
  const { token } = useAuth();
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    setFocus,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(NovoClienteSchema),
  });

  const moedas = [
    { id: 1, nome: "Real Brasileiro", valor: "BRL" },
    { id: 2, nome: "Dólar Americano", valor: "USD" },
    { id: 3, nome: "Euro", valor: "EUR" },
    { id: 4, nome: "Libra Esterlina (Reino Unido)", valor: "GBP" },
    { id: 5, nome: "Iene Japonês", valor: "JPY" },
    { id: 6, nome: "Dólar Australiano", valor: "AUD" },
    { id: 7, nome: "Dólar Canadense", valor: "CAD" },
    { id: 8, nome: "Yuan Chinês", valor: "CNY" },
    { id: 9, nome: "Rúpia Indiana", valor: "INR" },
    { id: 10, nome: "Rublo Russo", valor: "RUB" },
  ];

  const [marcasSelecionadas, setMarcasSelecionadas] = useState([]);
  const [marcas, setMarcas] = useState([]);

  //CADASTRA CLIENTE
  const onSubmitFunction = () => {
    if (marcasSelecionadas.length === 0) {
      return toast.error("Selecionar Marcas");
    }
    const formValues = getValues();
    const dataToSend = {
      ...formValues,
      nome_registro: "",
      contato_secundario: "",
      telefone_secundario: "",
      telefone_principal: "",
      email_secundario: "",
      link: "",
      empresa_id: 1,
      categorias_cliente_id: 1,
      custo_financeiro_id: 1,
    };

    api
      .post("/clientes", { clientes: [dataToSend] })
      .then((response) => {
        // navigate("/clientes");
        toast.success("Cliente cadastrado com sucesso!");
        const arrayTransformado = marcasSelecionadas.map((item) => {
          return {
            marca_id: item,
            cliente_id: response.data.data.insertId,
          };
        });

        api
          .post(
            "/marcas-cliente",
            { vinculos: arrayTransformado },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((response) => {
            toast.success("Marcas Adicionadas! 🎉");
            setTimeout(() => {
              navigate(`/clientes`);
            }, 2000);
          })
          .catch((error) => {
            toast.error("Algo deu Errado. Tente novamente.");
          });
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  const checkCEP = (e) => {
    const codigo_postal = e.target.value.replace(/\D/g, "");

    fetch(`https://viacep.com.br/ws/${codigo_postal}/json/`)
      .then((res) => res.json())
      .then((data) => {
        setValue("logradouro", data.logradouro);
        setValue("bairro", data.bairro);
        setValue("cidade", data.localidade);
        setValue("estado", data.uf);
        setFocus("number");
      })
      .catch((error) => {
        toast.error("CEP inválido");
      });
  };

  function listarMarcas() {
    api
      .get("/marcas")
      .then((response) => {
        setMarcas(response.data.data.marcas);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  function adicionarMarca(element) {
    if (marcasSelecionadas.includes(Number(element)) === false && element > 0) {
      setMarcasSelecionadas((prevSelecionadas) => [
        ...prevSelecionadas,
        Number(element),
      ]);
    }
  }

  function removerMarca(id) {
    const filter = marcasSelecionadas.filter((element) => element != id);
    setMarcasSelecionadas(filter);
  }

  useEffect(() => {
    listarMarcas();
  }, []);

  return (
    <NovoClienteContainer onSubmit={handleSubmit(onSubmitFunction)}>
      <div className="box">
        <div className="flex">
          <ReactSVG
            onClick={() => navigate("/clientes")}
            className="voltar"
            src={voltar}
          />
          <div className="column">
            <h1>Cliente</h1>
            <h2 className="h2-header">Novo Cliente</h2>
          </div>
        </div>

        <Body>
          <div className="checkbox">
            <input
              type="checkbox"
              id="checkbox1"
              name="status"
              {...register("status")}
              defaultChecked={true}
            />
            <label htmlFor="checkbox1">Ativo</label>
          </div>

          <InputsCliente>
            <div className="cnpj">
              <label>CNPJ *</label>
              <input
                type="text"
                name="documento"
                {...register("documento")}
                placeholder="00.000.000/0000-00"
              />
              {errors.documento && (
                <span className="input-error-cadastro">
                  {errors.documento.message}
                </span>
              )}
            </div>
            <div>
              <label>Razão Social *</label>
              <input
                type="text"
                name="razao_social"
                {...register("razao_social")}
                placeholder="Razão Social do cliente"
              />
              {errors.razao_social && (
                <span className="input-error-cadastro">
                  {errors.razao_social.message}
                </span>
              )}
            </div>
            <div>
              <label>Nome Fantasia *</label>
              <input
                type="text"
                name="nome_fantasia"
                {...register("nome_fantasia")}
                placeholder="Nome Fantasia do cliente"
              />
              {errors.nome_fantasia && (
                <span className="input-error-cadastro">
                  {errors.nome_fantasia.message}
                </span>
              )}
            </div>
            <div>
              <label>Tipo</label>
              <select name="tipo" {...register("tipo")}>
                <option key={0} value={0}>
                  Selecionar tipo
                </option>
                <option value="Qualquer">Matrix</option>
                <option value="Qualquer">Filial</option>
              </select>

              {errors.tipo && (
                <span className="input-error-cadastro">
                  {errors.tipo.message}
                </span>
              )}
            </div>
          </InputsCliente>

          <InputsIncricao>
            <div>
              <label>Inscrição Municipal</label>
              <input
                type="text"
                name="inscricao_municipal"
                {...register("inscricao_municipal")}
                placeholder="0000000000000"
              />
              {errors.inscricao_municipal && (
                <span className="input-error-cadastro">
                  {errors.inscricao_municipal.message}
                </span>
              )}
            </div>
            <div>
              <label>Inscrição Estadual *</label>
              <input
                type="text"
                name="inscricao_estadual"
                {...register("inscricao_estadual")}
                placeholder="0000000000000"
              />
              {errors.documento && (
                <span className="input-error-cadastro">
                  {errors.inscricao_estadual.message}
                </span>
              )}
            </div>
            <div>
              <label>CNAE Principal</label>
              <input
                type="file"
                name="cnae"
                placeholder="tested"
                {...register("cnae")}
              />
              {errors.cnae && (
                <span className="input-error-cadastro">
                  {errors.cnae.message}
                </span>
              )}
            </div>
            <div>
              <label>Registro Fiscal</label>
              <input
                type="text"
                name="registro_fiscal"
                {...register("registro_fiscal")}
                placeholder="0000000000000"
              />
            </div>
            <div className="flex-input">
              <div className="div-select">
                <label>Marcas</label>
                <select
                  onClick={(e) => adicionarMarca(e.target.value)}
                  {...register("marcas_id")}
                >
                  <option value="0">Selecionar a marca</option>
                  {marcas &&
                    marcas.map((element) => (
                      <option value={element.id}>{element.nome}</option>
                    ))}
                </select>
              </div>
            </div>
          </InputsIncricao>
          <div className="flex">
            {marcasSelecionadas.length > 0 &&
              marcas &&
              marcasSelecionadas
                .map((elemen) =>
                  marcas.filter((element) => element.id === elemen)
                )
                .map((elemento) => (
                  <div className="relative">
                    <p className="nome-marca">{elemento[0].nome}</p>
                    <span onClick={() => removerMarca(elemento[0].id)}>X</span>
                  </div>
                ))}
          </div>
          <div className="dadosClientes">
            <h2> Dados do cliente</h2>
            <InputsEndereco>
              <div className="cep">
                <label>CEP *</label>
                <input
                  type="text"
                  name="codigo_postal"
                  {...register("codigo_postal")}
                  placeholder="00000-000"
                  onBlur={(e) => checkCEP(e)}
                />
                {errors.codigo_postal && (
                  <span className="input-error-cadastro">
                    {errors.codigo_postal.message}
                  </span>
                )}
              </div>
              <div>
                <label>Logradouro *</label>
                <input
                  type="text"
                  name="logradouro"
                  {...register("logradouro")}
                  placeholder="Avenida, rua, travessa"
                />
                {errors.logradouro && (
                  <span className="input-error-cadastro">
                    {errors.logradouro.message}
                  </span>
                )}
              </div>
              <div>
                <label>Número *</label>
                <input
                  type="text"
                  name="numero"
                  {...register("numero")}
                  placeholder="0000"
                />
                {errors.numero && (
                  <span className="input-error-cadastro">
                    {errors.numero.message}
                  </span>
                )}
              </div>
            </InputsEndereco>

            <InputsEndereco1>
              <div>
                <label>Complemento</label>
                <input
                  type="text"
                  name="complemento"
                  {...register("complemento")}
                  placeholder="Sala, andar"
                />
              </div>
              <div>
                <label>Bairro *</label>
                <input
                  type="text"
                  name="bairro"
                  {...register("bairro")}
                  placeholder="Bairro"
                />
                {errors.bairro && (
                  <span className="input-error-cadastro">
                    {errors.bairro.message}
                  </span>
                )}
              </div>
              <div>
                <label>Cidade *</label>
                <input
                  type="text"
                  name="cidade"
                  {...register("cidade")}
                  placeholder="Cidade"
                />
                {errors.cidade && (
                  <span className="input-error-cadastro">
                    {errors.cidade.message}
                  </span>
                )}
              </div>
              <div>
                <label>Estado *</label>
                <input
                  type="text"
                  name="estado"
                  {...register("estado")}
                  placeholder="UF"
                />
                {errors.estado && (
                  <span className="input-error-cadastro">
                    {errors.estado.message}
                  </span>
                )}
              </div>
            </InputsEndereco1>

            <InputsEndereco2>
              <div className="email">
                <label>E-mail contato *</label>
                <input
                  type="email"
                  name="email_principal"
                  {...register("email_principal")}
                  placeholder="email@email.com.br"
                />
                {errors.email_principal && (
                  <span className="input-error-cadastro">
                    {errors.email_principal.message}
                  </span>
                )}
              </div>
              <div>
                <label>Celular contato *</label>
                <input
                  type="text"
                  name="contato_principal"
                  {...register("contato_principal")}
                  placeholder="(00) 00000-0000"
                />
                {errors.contato_principal && (
                  <span className="input-error-cadastro">
                    {errors.contato_principal.message}
                  </span>
                )}
              </div>
              <div>
                <label>Site do cliente</label>
                <input
                  type="text"
                  name="link"
                  {...register("link")}
                  placeholder="www.cliente.com.br"
                />
                {errors.contato_principal && (
                  <span className="input-error-cadastro">
                    {errors.contato_principal.message}
                  </span>
                )}
              </div>
              <div>
                <label>Setor</label>
                <input
                  type="text"
                  name="setor_atuacao"
                  {...register("setor_atuacao")}
                  placeholder="Setor de atuação do cliente"
                />
              </div>
            </InputsEndereco2>
          </div>

          <div className="dadosPagamento">
            <h2> Dados de Pagamento</h2>

            <InputsPagamento>
              <div>
                <label>Nome do responsável financeiro *</label>
                <input
                  type="text"
                  name="contato_financeiro"
                  {...register("contato_financeiro")}
                  placeholder="Responsável financeiro"
                />
                {errors.contato_financeiro && (
                  <span className="input-error-cadastro">
                    {errors.contato_financeiro.message}
                  </span>
                )}
              </div>
              <div>
                <label>E-mail financeiro *</label>
                <input
                  type="email"
                  name="email_financeiro"
                  {...register("email_financeiro")}
                  placeholder="E-mail financeiro"
                />
                {errors.email_financeiro && (
                  <span className="input-error-cadastro">
                    {errors.email_financeiro.message}
                  </span>
                )}
              </div>
            </InputsPagamento>
            <InputsPagamento2>
              <div>
                <label>Banco</label>
                <select name="banco" {...register("banco")}>
                  <option>Selecione o banco</option>
                  <option value={1}>Banco do Brasil</option>
                  <option value={2}>Caixa</option>
                  <option value={3}>Citibank</option>
                  <option value={4}>Inter</option>
                  <option value={5}>Itaú</option>
                  <option value={6}>Original</option>
                  <option value={7}>Safra</option>
                  <option value={8}>Santander</option>
                  <option value={9}>ABC</option>
                  <option value={10}>Votorantim</option>
                  <option value={11}>Sicoob</option>
                  <option value={12}>Sicredi</option>
                  <option value={13}>C6</option>
                  <option value={14}>BTG Pactual</option>
                  <option value={15}>Banrisul</option>
                  <option value={16}>Banco do Nordeste</option>
                  <option value={17}>Outro</option>
                </select>
              </div>
              <div>
                <label>Agência</label>
                <input
                  type="text"
                  name="agencia"
                  {...register("agencia")}
                  placeholder="00000"
                />
              </div>
              <div>
                <label>Conta</label>

                <input
                  type="text"
                  name="Conta"
                  {...register("conta")}
                  placeholder="0000000"
                />
              </div>
            </InputsPagamento2>
            <InputsPagamento1>
              <div>
                <label>Prazo de Pagamento *</label>
                <input
                  type="number"
                  name="prazo_pagamento"
                  {...register("prazo_pagamento")}
                  placeholder="Em Dias"
                />
                {errors.prazo_pagamento && (
                  <span className="input-error-cadastro">
                    {errors.prazo_pagamento.message}
                  </span>
                )}
              </div>

              <div>
                <label>Moeda de transação *</label>
                <select name="moeda_transacao" {...register("moeda_transacao")}>
                  <option key={0} value={0}>
                    Selecione uma moeda
                  </option>
                  {moedas.map((item) => (
                    <option key={item.id} value={item.valor}>
                      {item.nome}
                    </option>
                  ))}
                </select>

                {errors.moeda_transacao && (
                  <span className="input-error-cadastro">
                    {errors.moeda_transacao.message}
                  </span>
                )}
              </div>
              <div>
                <label>Fee contratual %*</label>
                <input
                  type="number"
                  name="prazo_pagamento"
                  {...register("fee")}
                  placeholder="0"
                />
                {errors.fee && (
                  <span className="input-error-cadastro">
                    {errors.fee.message}
                  </span>
                )}
              </div>
            </InputsPagamento1>
          </div>
        </Body>
        <div className="buttons">
          <Link to="/clientes">{"< Voltar"}</Link>
          <button type="submit">Salvar</button>
        </div>
      </div>
    </NovoClienteContainer>
  );
};

export default NovoCliente;
