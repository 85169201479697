import * as yup from "yup";

export const loginSchema = yup.object().shape({
  email: yup
    .string()
    .email("Formato de e-mail inválido.")
    .required("Campo obrigatório (E-mail)."),
  senha: yup.string().required("Campo obrigatório."),
});

export const NovaSenhaSchema = yup.object().shape({
  email: yup
    .string()
    .email("Formato de e-mail inválido.")
    .required("Campo obrigatório (E-mail)."),
});
