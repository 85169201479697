import { toast } from "react-toastify";
import { useAuth } from "../../../providers/Auth";
import api from "../../../services/api";
import { DeletarMaterialBriefingStyle } from "./style";
import { useParams } from "react-router-dom";

export const DeletarMaterialBriefing = ({
  state,
  setAbrirModalDeletarMaterialBriefing,
  buscarMaterialBriefing,
}) => {
  const { token } = useAuth();
  const { id } = useParams();

  const adicionarHistorico = () => {
    const data = {
      tipo: "Briefing",
      texto: `Briefing Produto ${state.nome} Deletado [Id: ${state.id}]`,
      campanha_id: id,
    };

    api
      .post("/historico-campanha", [data])
      .then((response) => {})
      .catch((error) => {
        toast.error("Erro ao salvar no historico");
      });
  };

  function DeletarMaterialBriefing() {
    api
      .patch(
        `/campanha-material-briefing/${state.id}`,
        { status: 0 },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        toast.success("Material Briefing Deletado! 🎉");
        setAbrirModalDeletarMaterialBriefing(false);
        buscarMaterialBriefing();
        adicionarHistorico();
      })
      .catch((error) => {
        toast.error("Algo deu Errado. Tente novamente.");
      });
  }

  return (
    <DeletarMaterialBriefingStyle>
      <div>
        <span
          onClick={() => setAbrirModalDeletarMaterialBriefing(false)}
          className="close"
        >
          X
        </span>
        <h3>
          Tem certeza que deseja
          <h4>deletar {state.nome}?</h4>
        </h3>
        <button onClick={() => DeletarMaterialBriefing()}>Deletar</button>
      </div>
    </DeletarMaterialBriefingStyle>
  );
};
