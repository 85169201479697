import React from "react";
import Header from "../../components/Menu";
import Perfis from "../../components/Perfis";

const PerfisPage = () => {
  return (
    <div className="flex">
      <Header />
      <Perfis />
    </div>
  );
};

export default PerfisPage;
