import { useNavigate } from "react-router-dom";
import { BoxFiltros, FornecedorContainer } from "./styles";
import Table from "./Table";
import { useEffect, useState } from "react";
import { useAuth } from "../../providers/Auth";
import api from "../../services/api";
import { toast } from "react-toastify";
import { ReactSVG } from "react-svg";
import fornecedor from "../../assets/fornecedor.svg";

const Fornecedor = () => {
  const navigate = useNavigate();
  const [fornecedores, setFornecedores] = useState([]);
  const [perfilSelecionado, setPerfilSelecionado] = useState("");
  const [textoPesquisa, setTextoPesquisa] = useState("");
  const [categoriasFornecedores, setCategoriasFornecedores] = useState("");

  function buscarFornecedor() {
    api
      .get("/fornecedores")
      .then((response) => {
        setFornecedores(response.data.data.fornecedores);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  function listarRegimes() {
    api
      .get("/regimes-empresa")
      .then((response) => {
        setCategoriasFornecedores();
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  useEffect(() => {
    buscarFornecedor();
  }, []);

  function NovoFornecedor() {
    navigate("/Fornecedor/novo");
  }
  return (
    <FornecedorContainer>
      <div className="box">
        <div className="flex">
          <ReactSVG className="image" src={fornecedor} />
          <h1>Fornecedor</h1>
        </div>
        <BoxFiltros>
          <div className="filtro">
            <input
              placeholder="Pesquisar por ID ou nome do fornecedor"
              value={textoPesquisa}
              onChange={(e) => setTextoPesquisa(e.target.value)}
            />
            <div>
              <p>Filtrar por segmento</p>
              <select
                value={perfilSelecionado}
                onChange={(e) => setPerfilSelecionado(e.target.value)}
              >
                <option value="">Ver todos</option>
                {[
                  ...new Set(fornecedores.map((data) => data.segmento_atuacao)),
                ].map((segmento, index) => (
                  <option key={index}>{segmento}</option>
                ))}
              </select>
            </div>
          </div>
          <button onClick={NovoFornecedor}>Novo Fornecedor</button>
        </BoxFiltros>
        <Table
          perfilSelecionado={perfilSelecionado}
          textoPesquisa={textoPesquisa}
        />
      </div>
    </FornecedorContainer>
  );
};

export default Fornecedor;
