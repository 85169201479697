import styled from "styled-components";

export const ModalImagemAdministrarVitrineStyle = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .body {
    position: relative;
    display: flex;
    flex-direction: column;
    text-align: center;
    border-radius: 30px;
    background-color: var(--base);
    padding: 40px 50px;
  }

  button {
    padding: 15px 30px;
    border: none;
    background-color: var(--amarelo);
    color: var(--principal);
    border-radius: 24px;
    font-size: 16px;
    font-weight: 700;
    width: max-content;
    margin: 10px auto 0px;
  }
  h4 {
    font-weight: 600;
  }

  .close {
    position: absolute;
    top: -20px;
    right: -15px;
    padding: 15px 15px;
    background-color: #fa5252;
    border-radius: 300px;
    font-size: 32px;
    font-weight: 400;
    color: var(--base);
  }

  h3 {
    width: 600px;
  }

  .setas {
    display: flex;
    justify-content: space-between;

    span {
      padding: 15px 15px;
      border: none;
      background-color: var(--amarelo);
      color: var(--principal);
      border-radius: 24px;
      font-size: 36px;
      font-weight: 700;
      width: max-content;
      margin: 10px auto 0px;
      cursor: pointer;
    }

    .vazio {
      padding: 0px;
      width: 50px;
      background-color: white;
    }
  }

  img {
    width: 400px;
    height: auto;
    max-height: 500px;
  }
`;
