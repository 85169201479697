import { Link, useNavigate, useParams } from "react-router-dom";
import { Body, ContainerInputs, NovoUsuarioContainer } from "./styles";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import voltar from "../../../../assets/voltar.svg";
import { CampoObrigatorio } from "../../../../schemas/novoCadastro";
import { toast } from "react-toastify";
import api from "../../../../services/api";
import { ReactSVG } from "react-svg";

const EditarItem = () => {
  const { id } = useParams();
  const [categorias, setCategorias] = useState([]);
  const [ncms, setNcms] = useState([]);
  const [item, setItem] = useState();
  const navigate = useNavigate();

  const {
    register,
    getValues,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(CampoObrigatorio),
  });

  //ATUALIZA ITEM
  const onSubmitFunction = () => {
    const formValues = getValues();

    api
      .patch(`/itens/${id}`, formValues)
      .then((response) => {
        navigate("/itens");

        toast.success("Item atualizado com sucesso!");
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  //BUSCA TODAS AS CATEGORIA
  function buscarCategoriaItem() {
    api
      .get("/categoria-item")
      .then((response) => {
        setCategorias(response.data.data.categorias);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  //BUSCA ITEM PELO ID
  function buscaItemId() {
    api
      .get(`/itens/${id}`)
      .then((response) => {
        reset(response.data.data.item);
        setItem(response.data.data.item);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function listarNcm() {
    api
      .get("/ncm")
      .then((response) => {
        setNcms(response.data.data.ncms);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  useEffect(() => {
    buscaItemId();
    buscarCategoriaItem();
    listarNcm();
  }, [id]);

  return (
    <NovoUsuarioContainer onSubmit={handleSubmit(onSubmitFunction)}>
      <div className="box">
        <div className="flex">
          <ReactSVG
            onClick={() => navigate("/itens")}
            className="voltar"
            src={voltar}
          />
          <div>
            <h1>Item</h1>
            <span className="underline">Editar Item</span>
          </div>
        </div>

        <Body>
          <div className="checkbox">
            <input
              type="checkbox"
              id="checkbox1"
              name="status"
              defaultChecked={true}
              {...register("status")}
            />

            <label htmlFor="checkbox1">Ativo</label>
          </div>
          <ContainerInputs>
            <div>
              <label>Nome do item *</label>
              <input
                type="text"
                name="nome"
                {...register("nome")}
                placeholder="Nome do produto/serviço"
              />
              {errors.nome && (
                <span className="input-error-cadastro">
                  {errors.nome.message}
                </span>
              )}
            </div>
            <div>
              <label>Tipo de item *</label>
              <select name="tipo" {...register("tipo")}>
                <option>Produto</option>
                <option>Serviço</option>
              </select>
              {errors.nome && (
                <span className="input-error-cadastro">
                  {errors.nome.message}
                </span>
              )}
            </div>
            <div>
              <label>Categoria *</label>
              <select
                name="categoria_item_id"
                {...register("categoria_item_id")}
              >
                {categorias.map((data) => (
                  <option key={data.id} value={data.id}>
                    {data.nome}
                  </option>
                ))}
              </select>
              {errors.nome && (
                <span className="input-error-cadastro">
                  {errors.nome.message}
                </span>
              )}
            </div>
            {item && item.tipo === "Produto" ? (
              <div>
                <label>Ncm *</label>
                <select name="ncm_item_id" {...register("ncm_id")}>
                  <option value={""}>Selecione uma categoria</option>
                  {ncms.map((data) => (
                    <option key={data.id} value={data.id}>
                      {data.codigo}
                    </option>
                  ))}
                </select>
                {errors.nome && (
                  <span className="input-error-cadastro">
                    {errors.nome.message}
                  </span>
                )}
              </div>
            ) : (
              <div>
                <label>ISS *</label>
                <input
                  type="text"
                  name="nome"
                  {...register("iss")}
                  placeholder="iss"
                />
                {errors.nome && (
                  <span className="input-error-cadastro">
                    {errors.nome.message}
                  </span>
                )}
              </div>
            )}
          </ContainerInputs>
        </Body>
        <div className="buttons">
          <Link to="/itens">{"< Voltar"}</Link>
          <button type="submit">Salvar</button>
        </div>
      </div>
    </NovoUsuarioContainer>
  );
};

export default EditarItem;
