import { Link } from "react-router-dom";
import { TableContainer } from "./styles";
import { ReactSVG } from "react-svg";
import ativo from "../../../assets/ativo.svg";
import inativo from "../../../assets/inativo.svg";
import { useEffect, useState } from "react";
import api from "../../../services/api";
import { toast } from "react-toastify";

const Table = ({ perfilSelecionado, textoPesquisa }) => {
  const [imposto, setImposto] = useState([]);

  //BUSCAR TODOS OS imposto
  function buscarImposto() {
    api
      .get("/regimes-empresa")
      .then((response) => {
        setImposto(response.data.data.regimesEmpresa);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }
  useEffect(() => {
    buscarImposto();
  }, []);

  const colunas = ["Id", "Regime Empresa", "PIS", "COFINS", "Status"];
  const filtrarCategoria = (categoria) => {
    const perfilFiltrado =
      !perfilSelecionado || categoria.status === parseInt(perfilSelecionado);
    const pesquisaFiltrada =
      !textoPesquisa ||
      categoria.nome.toLowerCase().includes(textoPesquisa.toLowerCase());

    const idCategoria = parseInt(textoPesquisa);
    const idFiltrado = !isNaN(idCategoria) && categoria.id === idCategoria;

    return perfilFiltrado && (pesquisaFiltrada || idFiltrado);
  };

  const usuariosFiltrados = imposto.filter(filtrarCategoria);

  return (
    <TableContainer>
      <table>
        <thead>
          <tr>
            {colunas.map((coluna) => (
              <th key={coluna}>{coluna}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {usuariosFiltrados.map((lista) => (
            <tr className="map" key={lista.id}>
              <td>#{lista.id}</td>
              <td>{lista.regime_empresa}</td>
              <td>{lista.pis}</td>
              <td>{lista.cofins}</td>
              {lista.status === 1 ? (
                <td>
                  <div className="ativo">
                    <ReactSVG src={ativo} />
                    <p>ativo</p>
                  </div>
                </td>
              ) : (
                <td>
                  <div className="ativo">
                    <ReactSVG src={inativo} />
                    <p>inativo</p>
                  </div>
                </td>
              )}

              <td className="td-editar">
                <Link
                  to={`/imposto/editar/${lista.id}`}
                  className="editar"
                  htmlFor="editar"
                >
                  Abrir
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </TableContainer>
  );
};

export default Table;
