import styled from "styled-components";

export const ValorMaximoPropostaStyle = styled.div`
  display: flex;
  width: calc(100% - 260px);
  background: var(--base01);
  border-radius: 40px;
  padding: 40px;
  flex-direction: column;

  h1 {
    font-size: 30px;
    font-weight: 700;
    margin-left: 10px;
    margin-top: 12px;
    color: var(--principal);
  }

  button {
    border-radius: 100px;
    border: none;
    padding: 8px 16px;
    background: var(--amarelo);
    color: var(--principal);
    font-weight: 700;
  }

  input {
    border-radius: 12px;
    border: none;
    padding: 8px 30px;
    font-weight: 500;
    margin-right: 10px;
  }

  form {
    margin-top: 30px;

    div {
      position: relative;
    }

    span {
      position: absolute;
      top: 8px;
      left: 8px;
      font-weight: 700;
    }
  }
`;
