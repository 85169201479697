import Header from "../../components/Menu";
import Item from "../../components/Itens";

const ItensPage = () => {
  return (
    <div className="flex">
      <Header />
      <Item />
    </div>
  );
};

export default ItensPage;
