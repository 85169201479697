import styled from "styled-components";

export const ModalAcompanharMaterialDistribuicoesContainer = styled.div`
  display: flex;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  align-items: center;
  z-index: 1;

  .relative {
    position: relative;
  }

  .close {
    position: absolute;
    right: 3px;
    top: -14px;
    color: #e20613;
    font-weight: 600;
    cursor: pointer;
  }

  .remover {
    font-size: 14px;
    font-weight: 600;
    color: var(--principal);
    text-align: center;
    background-color: var(--amarelo);
    border-radius: 16px;
    padding: 4px;
  }
`;

export const ContentContainer = styled.div`
  background: #f6f6fc;
  min-width: 15%;
  border-radius: 18px;
  opacity: 1;
  padding: 30px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  position: relative;
  z-index: 10;

  > .fechar {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    border: none;
    background-color: #fa5252;
    width: 50px;
    height: 50px;
    position: absolute;
    top: -25px;
    right: -25px;
    > div {
      height: 25px;
    }
  }

  > h1 {
    font-size: 0.9em;
    color: #7d0000;
  }

  > .header {
    display: flex;
    column-gap: 50px;

    > p {
      font-size: 0.9em;
      > b {
        font-size: 1em;
        font-weight: 600;
      }

      > span {
        color: #a5a5a5;
        font-size: 0.9em;
      }
    }
  }

  > .adicionar-container {
    display: flex;
    justify-content: space-between;
    > .adicionar-linha {
      border: 1px solid #7d0000;
      border-radius: 20px;
      background: none;
      color: #7d0000;
      font-size: 0.8em;
      font-weight: 600;
      padding: 5px 10px;
    }

    > p {
      width: 55%;
      font-size: 0.8em;
      line-height: 1.2em;
      font-weight: 500;
      text-align: center;
    }
  }

  > .salvar {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 4px;
    width: 130px;
    height: 35px;
    background: #ffbe00;
    border-radius: 20px;
    font-size: 0.9em;
    font-weight: 600;
    border: none;
    color: #7d0000;
    margin: 0 auto;

    &:disabled {
      color: #fff;
      background: #ccc;
      cursor: not-allowed;
    }

    > div {
      height: 18px;
      svg {
        height: 18px;
        path,
        polygon,
        rect,
        circle,
        ellipse {
          fill: #7d0000;
        }
      }
    }
  }
`;

export const TableContainer = styled.div`
  > table {
    > thead > tr th:nth-child(3),
    > thead > tr th:nth-child(4),
    > thead > tr th:nth-child(5) {
      text-align: center;
    }
  }
`;

export const DistribuicaoTr = styled.tr`
  > td {
    height: 60px;
    > .quantidade {
      display: flex;
      align-items: center;
      justify-content: center;

      > button {
        width: 20px;
        height: 20px;
        background: #ffbe00;
        color: #ffffff;
        border: none;
        border-radius: 100%;

        &:disabled {
          background: #a5a5a5;
        }
      }

      > input {
        width: 50px;
        height: 40px;
        text-align: center;
        font-size: 0.9em;
        background: #ffffff;
        border: none;
        border-radius: 6px;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        -moz-appearance: textfield;
      }
    }

    > .destino {
      margin-left: 4px;
      > input {
        padding-left: 10px;
        font-size: 0.9em;
        height: 40px;
        background: #ffffff;
        border: none;
        border-radius: 6px;
      }
    }

    > .arquivo {
      text-align: center;
      a {
        color: #609ae5;
        font-size: 0.7em;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100px;
      }

      > .input-container {
        > input {
          display: none;
        }

        > label {
          display: flex;
          align-items: center;
          flex-direction: column;
          font-size: 0.7em;
          color: #7d0000;
          text-decoration: underline;
          > div {
            height: 15px;
            svg {
              height: 15px;
            }
          }
        }
      }
    }
  }
`;
