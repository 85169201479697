import styled from "styled-components";

export const FormBrindeStyle = styled.form`
  .flex {
    margin-bottom: 18px;
  }

  .tres {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: start;
    gap: 0px;

    .input-completo {
      width: 94%;
    }
  }

  .width-max {
    width: 100%;
    input {
      border: none;
      border-radius: 8px;
      padding: 10px;
    }
  }

  textarea {
    width: 100%;
    height: 100px;
    padding: 10px;
    margin-top: 10px;
  }

  .preco {
    input {
      padding-left: 30px;
    }
  }

  .quatro {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 0px;

    .input-completo {
      width: 94%;
    }
  }

  label {
    font-size: 14px;
    font-weight: 500;
  }

  .item {
    margin-bottom: 18px;
  }
  .column {
    display: flex;
    flex-direction: column;
  }

  .width-90 {
    width: 90%;

    div {
      max-width: 92%;
    }
  }

  .true-false {
    width: 300px;
  }

  .cifrao {
    position: relative;
    top: -28.5px;
  }

  .relative {
    position: relative;
    margin-right: 20px;
    width: max-content;
    span {
      position: absolute;
      top: -10px;
      right: -8px;
      font-size: 12px;
      cursor: pointer;
      color: red;
      font-weight: 600;
    }
  }

  .files {
    display: flex;
    margin-top: 20px;
  }

  h5 {
    font-size: 16px;
    color: black;
    font-weight: 500;
  }
`;
