import { useEffect, useState } from "react";
import { ConfirmarAddBrieifingVitrineStyle } from "./style";

export const ConfirmarAddBrieifingVitrine = ({
  orcamento,
  state,
  adicionarItemNaVitrine,
  itensVitrine,
}) => {
  const [itemSelecionado, setItemSelecionado] = useState("");

  useEffect(() => {
    orcamento.idItemVitrine = itemSelecionado;
    const item = itensVitrine.find((item) => item.id == itemSelecionado);
    orcamento.nome = item?.nome;
  }, [itemSelecionado]);

  return (
    <ConfirmarAddBrieifingVitrineStyle>
      <div className="div-body">
        <span className="close" onClick={() => state(false)}>
          X
        </span>
        <h2>Tem certeza que deseja adicionar esse item na vitrine?</h2>
        <div className="select">
          <h3>Selecione o item que deseja adicionar esse briefing:</h3>
          <select onChange={(e) => setItemSelecionado(e.target.value)}>
            <option value="">Selecione um item</option>
            {itensVitrine.map((item) => (
              <option key={item.id} value={item.id}>
                {item.nome}
              </option>
            ))}
          </select>
        </div>
        <div className="flex">
          <button className="cancelar" onClick={() => state(false)}>
            Cancelar
          </button>
          <button
            className="confirmar"
            onClick={() => adicionarItemNaVitrine(orcamento)}
          >
            Adicionar
          </button>
        </div>
      </div>
    </ConfirmarAddBrieifingVitrineStyle>
  );
};
