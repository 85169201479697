import { Link, useNavigate } from "react-router-dom";
import { Body, Inputs, NovaCategoriaContainer } from "./styles";
import { toast } from "react-toastify";
import api from "../../../../services/api";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAuth } from "../../../../providers/Auth/index";
import { CampoObrigatorio } from "../../../../schemas/novoCadastro";
import { ReactSVG } from "react-svg";
import voltar from "../../../../assets/voltar.svg";

const NovoItem = () => {
  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm({
    resolver: yupResolver(CampoObrigatorio),
  });

  const [ncms, setNcms] = useState([]);
  const [categorias, setCategorias] = useState([]);
  const [tipo, setTipo] = useState();
  const navigate = useNavigate();
  const { user } = useAuth();

  const onSubmitFunction = (data) => {
    data.status = 1;
    data.iss = Number(data.iss);

    const itens = [data];

    if (data.tipo === "Serviço") {
      delete data.ncm_id;
    }

    if (data.tipo === "Produto") {
      delete data.iss;
    }

    if (data.tipo === "") {
      return toast.error("Selecione um tipo de item");
    }
    api
      .post("/itens", { itens })
      .then((response) => {
        navigate("/itens");
        toast.success("Item cadastrado com sucesso!");
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  //BUSCA TODAS AS CATEGORIA
  function buscarCategoriaItem() {
    api
      .get("/categoria-item")
      .then((response) => {
        setCategorias(response.data.data.categorias);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  function listarNcm() {
    api
      .get("/ncm")
      .then((response) => {
        setNcms(response.data.data.ncms);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  useEffect(() => {
    buscarCategoriaItem();
    listarNcm();
  }, []);

  return (
    <NovaCategoriaContainer onSubmit={handleSubmit(onSubmitFunction)}>
      <div className="box">
        <div className="flex">
          <ReactSVG
            onClick={() => navigate("/itens")}
            className="voltar"
            src={voltar}
          />
          <div>
            <h1>Item / Serviço</h1>
            <span className="underline">Novo Item / Serviço</span>
          </div>
        </div>

        <Body>
          <div className="checkbox">
            <input
              type="checkbox"
              id="checkbox1"
              name="status"
              {...register("status")}
              defaultChecked={true}
            />

            <label htmlFor="checkbox1">Ativo</label>
          </div>
          <Inputs>
            <div>
              <label>Item *</label>
              <input
                type="text"
                name="nome"
                {...register("nome")}
                placeholder="Nome do produto/serviço"
              />
              {errors.nome && (
                <span className="input-error-cadastro">
                  {errors.nome.message}
                </span>
              )}
            </div>
            <div>
              <label>Tipo do item *</label>
              <select
                onClick={(e) => setTipo(e.target.value)}
                name="tipo"
                {...register("tipo")}
              >
                <option value={""}>Selecione uma opção</option>
                <option>Produto</option>
                <option>Serviço</option>
              </select>
              {errors.nome && (
                <span className="input-error-cadastro">
                  {errors.nome.message}
                </span>
              )}
            </div>
            <div>
              <label>Categoria *</label>
              <select
                name="categoria_item_id"
                {...register("categoria_item_id")}
              >
                <option value={""}>Selecione uma categoria</option>
                {categorias.map((data) => (
                  <option key={data.id} value={data.id}>
                    {data.nome}
                  </option>
                ))}
              </select>
              {errors.nome && (
                <span className="input-error-cadastro">
                  {errors.nome.message}
                </span>
              )}
            </div>
            {tipo === "Produto" ? (
              <div>
                <label>Ncm *</label>
                <select name="ncm_item_id" {...register("ncm_id")}>
                  <option value={""}>Selecione uma categoria</option>
                  {ncms.map((data) => (
                    <option key={data.id} value={data.id}>
                      {data.codigo}
                    </option>
                  ))}
                </select>
                {errors.nome && (
                  <span className="input-error-cadastro">
                    {errors.nome.message}
                  </span>
                )}
              </div>
            ) : tipo === "Serviço" ? (
              <div>
                <label>ISS *</label>
                <input
                  type="text"
                  name="nome"
                  {...register("iss")}
                  placeholder="iss"
                  value={5}
                />
                {errors.nome && (
                  <span className="input-error-cadastro">
                    {errors.nome.message}
                  </span>
                )}
              </div>
            ) : null}
          </Inputs>
        </Body>
        <div className="buttons">
          {user && user.permissoes.includes("itens_criar") && (
            <button type="submit">Salvar</button>
          )}
        </div>
      </div>
    </NovaCategoriaContainer>
  );
};

export default NovoItem;
