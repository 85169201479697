import NovaCategoriaFornecedor from "../../../components/Cadastros/Novo/NovaCategoriaFornecedor";
import Header from "../../../components/Menu";

const CadastrarNovaCategoriaPage = () => {
  return (
    <div>
      <Header />
      <NovaCategoriaFornecedor />
    </div>
  );
};

export default CadastrarNovaCategoriaPage;
