import { Link, useNavigate, useParams } from "react-router-dom";
import { Body, Inputs, NovoUsuarioContainer } from "./styles";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import api from "../../../../services/api";
import { CampoObrigatorio } from "../../../../schemas/novoCadastro";
import { toast } from "react-toastify";
import { useAuth } from "../../../../providers/Auth";
import { ReactSVG } from "react-svg";
import voltar from "../../../../assets/voltar.svg";

const EditarPerfil = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [permissoes, setPermissoes] = useState([]);

  function buscarPermissoes() {
    api
      .get("/permissoes")
      .then((response) => {
        setPermissoes(response.data.data.permissoes);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  useEffect(() => {
    buscarPermissoes();
  }, []);

  const {
    register,
    getValues,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(CampoObrigatorio),
  });

  //ATUALIZAÇÃO PERFIL
  const onSubmitFunction = () => {
    const formValues = getValues();
    formValues.permissao = Number(formValues.permissao);
    api
      .patch(`/perfis/${id}`, formValues)
      .then((response) => {
        navigate("/perfis");
        toast.success("Perfil atualizado com sucesso!");
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  //BUSCA PERFIL PELO ID SEMRPE QUE ALTERA O ID
  useEffect(() => {
    api
      .get(`/perfis/${id}`)
      .then((response) => {
        reset(response.data.data.perfil);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [id]);

  return (
    <NovoUsuarioContainer onSubmit={handleSubmit(onSubmitFunction)}>
      <div className="box">
        <div className="flex">
          <ReactSVG
            onClick={() => navigate("/perfis")}
            className="voltar"
            src={voltar}
          />
          <div className="column">
            <h1>Perfil</h1>
            <h2>Editar perfil</h2>
          </div>
        </div>

        <Body>
          <div className="checkbox">
            <input
              type="checkbox"
              id="checkbox1"
              name="status"
              defaultChecked={true}
              {...register("status")}
            />

            <label htmlFor="checkbox1">Ativo</label>
          </div>
          <div className="flex div-inputs">
            <Inputs>
              <div>
                <label>Nome do perfil</label>
                <input
                  type="text"
                  name="nome"
                  {...register("nome")}
                  placeholder="Nome do perfil"
                />
              </div>
            </Inputs>
            <Inputs>
              <div>
                <label>Permissão</label>
                <select name="permissao" {...register("permissao")}>
                  <option>Selecione uma permissão</option>
                  {permissoes.map((data) => (
                    <option key={data.id} value={data.id}>
                      {data.nome}
                    </option>
                  ))}
                </select>
                {errors.nome && (
                  <span className="input-error-cadastro">
                    {errors.novo.message}
                  </span>
                )}
              </div>
            </Inputs>
          </div>
        </Body>
        <div className="buttons">
          <button type="submit">Salvar</button>
        </div>
      </div>
    </NovoUsuarioContainer>
  );
};

export default EditarPerfil;
