import { useForm } from "react-hook-form";
import { PedirRevisaoStyle } from "./style";
import api from "../../../services/api";
import { toast } from "react-toastify";
import { useAuth } from "../../../providers/Auth";

export const DevolutivaCompras = ({ state, materialBriefing, quantidade }) => {
  const { register, handleSubmit, getValues } = useForm();
  const { user } = useAuth();

  const enviarRevisao = () => {
    //Colocando os dados que já tenho que são necessário para o Email
    const body = {
      produtor_nome: user.nome,
      material: materialBriefing.nome + " " + materialBriefing.subnome,
      resposta: getValues("revisao"),
      quantidade: quantidade,
      campanha_nome: materialBriefing.campanha_nome,
      produtor_id: materialBriefing.produtor_id,
      campanha_material_briefing_id: materialBriefing.id,
    };

    api
      .post(`/email/devolutiva-compras`, body)
      .then((response) => {
        state(false);
        toast.success("Revisão Enviada 🎉");
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  };

  return (
    <PedirRevisaoStyle>
      <form className="background" onSubmit={handleSubmit(enviarRevisao)}>
        <h2>
          Escreva para o produtor resposável por esse material, pedindo revisão:
        </h2>
        <textarea placeholder="Justificativa..." {...register("revisao")} />
        <h3>* O produtor receberá essa mensagem no email</h3>
        <button>Enviar</button>
        <span className="close" onClick={() => state(false)}>
          X
        </span>
      </form>
    </PedirRevisaoStyle>
  );
};
