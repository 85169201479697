import { useNavigate, useParams } from "react-router-dom";
import { Input } from "../../Input";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { useAuth } from "../../../providers/Auth";
import api from "../../../services/api";
import { toast } from "react-toastify";
import { FormBrindeStyle, FormPontoDeVendaStyle } from "./style";
import QuantidadeBriefing from "../../QuantidadeBriefing";
import { QuantidadeBriefingStyled } from "../../QuantidadeBriefing/styles";

export const FormBrinde = ({
  itens,
  categoriaSelecionada,
  clienteId,
  dataBriefing,
}) => {
  const navigate = useNavigate();
  const { user, token } = useAuth();
  const { id } = useParams();
  const [files, setFiles] = useState([]);
  const [indexQuantidade, setIndexQuantidade] = useState([1]);
  const [travarBotao, setTravarBotao] = useState(false);

  const {
    register,
    handleSubmit,
    getValues,
    reset,
    unregister,
    formState: { errors },
  } = useForm();

  const adicionarHistorico = (dados) => {
    const data = {
      tipo: "Campanha",
      texto: `Adicionado Novo Briefing: Produto de Comunicação Visual ${dados.nome} [Id: ${dados.id}]`,
      campanha_id: id,
    };

    api
      .post("/historico-campanha", [data])
      .then((response) => {})
      .catch((error) => {
        toast.error("Erro ao salvar no historico");
      });
  };

  const adicionarHistoricoAtualizar = () => {
    api
      .get(`/campanha-material-briefing/briefing/${id}`)
      .then((response) => {
        const dados = response.data.data.materiais;
        const data = {
          tipo: "Campanha",
          texto: `Briefing Atualizado: Produto Brinde ${dados.nome} [Id: ${dados.id}]`,
          campanha_id: dados.campanhas_id,
        };

        api
          .post("/historico-campanha", [data])
          .then((response) => {})
          .catch((error) => {
            toast.error("Erro ao salvar no historico");
          });
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  };

  async function aprovarDetalhes() {
    api
      .patch(
        `/campanha-material-briefing/${id}`,
        { aprovacao_produtor: 1 },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        toast.success("Briefing Atualizado! 🎉");
        adicionarHistoricoAtualizar();
        setTimeout(() => {
          window.history.back();
        }, 2000);
      })
      .catch((error) => {
        toast.error("Algo deu Errado. Tente novamente.");
      });
  }

  async function enviarData(data) {
    if (getValues("nome") === "Selecionar Material") {
      return toast.error("Selecione um item");
    }

    setTravarBotao(true);

    const parte = data.prazo_maximo.split("-");
    data.prazo_maximo = `${parte[2]}/${parte[1]}/${parte[0]}`;

    const quantidadeA = String(await getValues("quantidadeA"));
    const quantidadeB = String(await getValues("quantidadeB"));
    const quantidadeC = String(await getValues("quantidadeC"));

    let converterQuantidadeA = quantidadeA.replace(/\D/g, "");
    let converterQuantidadeB = quantidadeB.replace(/\D/g, "");
    let converterQuantidadeC = quantidadeC.replace(/\D/g, "");

    const objetoExemplo = {
      categoria: categoriaSelecionada,
      nome: data.nome,
      descricao: data.descricao,
      observacao: data.observacao,
      preco_maximo_unidade: data.preco_maximo_unidade,
      quantidade_a: converterQuantidadeA,
      quantidade_b: converterQuantidadeB,
      quantidade_c: converterQuantidadeC,
      prazo_maximo: data.prazo_maximo,
      endereco: data.endereco,
      item_id: itens.filter((element) => element.nome == data.nome)[0].id,
      campanhas_id: dataBriefing ? dataBriefing.campanhas_id : id,
      status: dataBriefing ? dataBriefing.status : "enviado pra orçamento",
      criador_id: clienteId,
      publico_alvo: data.publico_alvo,
      item_de_marca: data.item_de_marca ? data.item_de_marca : 0,
      subnome: data.subnome,
    };

    if (dataBriefing) {
      api
        .patch(`/campanha-material-briefing/${id}`, objetoExemplo, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          toast.success("Briefing Atualizado! 🎉");
          adicionarHistoricoAtualizar();
          setTimeout(() => {
            window.history.back();
          }, 2000);
        })
        .catch((error) => {
          toast.error("Algo deu Errado. Tente novamente.");
        });
    } else {
      api
        .post(
          "/campanha-material-briefing",
          { materiaisBriefing: [objetoExemplo] },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          toast.success("Briefing Criado! 🎉");
          adicionarHistorico(response.data.data.campanha);

          const arrayObjetoQuantidade = [];

          for (let i = 0; i < indexQuantidade.length; i++) {
            const quantidade = getValues(`quantidade${indexQuantidade[i]}`);
            if (quantidade) {
              arrayObjetoQuantidade.push({
                quantidade: Number(quantidade),
                campanha_materiais_briefing_id: response.data.data.campanha.id,
              });
            }
          }

          api
            .post("/briefing-quantidade", { materiais: arrayObjetoQuantidade })
            .then((responseBriefing) => {})
            .catch((error) => {
              toast.error("Erro ao salvar no historico");
            });

          const formData = new FormData();

          files.forEach((foto, index) => {
            formData.append(`fotos[${index}]`, foto);
          });

          if (formData.length > 0) {
            api
              .post(
                `/campanha-material-briefing/${response.data.data.campanha.id}/anexos`,
                formData,
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                }
              )
              .then((response) => {})
              .catch((error) => {});
          }
          setTimeout(() => {
            window.history.back();
          }, 1000);
        })
        .catch((error) => {
          toast.error("Algo deu Errado. Tente novamente.");
          setTravarBotao(false);
        });
    }
  }

  function removerArquivo(index) {
    // Crie uma cópia do array files
    const novaListaArquivos = [...files];
    // Remova o item do índice específico
    novaListaArquivos.splice(index, 1);
    // Atualize o estado files com a nova lista
    setFiles(novaListaArquivos);
  }

  useEffect(() => {
    if (dataBriefing) {
      dataBriefing.prazo_maximo = dataBriefing.prazo_maximo
        ? dataBriefing.prazo_maximo.split("/").reverse().join("-")
        : "";

      reset(dataBriefing);
    }
  }, []);

  return (
    <FormBrindeStyle onSubmit={handleSubmit(enviarData)}>
      <div className="div-category-name">
        <div className="div-category"></div>
        <div className="input-completo item">
          <label>Nome do Material</label>
          <select className="input-name select" {...register("nome")}>
            <option value={undefined}>Selecionar Material</option>
            {itens &&
              !dataBriefing &&
              itens
                .filter(
                  (elemen) =>
                    elemen.categoria_item_id == categoriaSelecionada &&
                    elemen.tipo !== "Serviço"
                )
                .map((element) => <option>{element.nome}</option>)}
            {dataBriefing && <option>{dataBriefing.nome}</option>}
          </select>
        </div>
        <div className="input-completo item">
          <Input
            label="Descrição do Material"
            type="text"
            placeholder="Edição Especial 1997"
            register={register("subnome")}
            className="input-name select"
          />
          {errors.subnome && (
            <span className="input-error">{errors.subnome.message}</span>
          )}
        </div>
      </div>

      <div className="grid">
        <div className="flex row tres">
          <div className="input-completo">
            <Input
              label="Público alvo"
              type="text"
              placeholder="Faixa"
              register={register("publico_alvo")}
              className="input-grid"
            />
            {errors.publico_alvo && (
              <span className="input-error">{errors.publico_alvo.message}</span>
            )}
          </div>
          <div className="input-completo">
            <Input
              label="Descreva a intenção do brinde"
              type="text"
              placeholder="Descrição"
              register={register("descricao")}
              className="input-grid"
            />
            {errors.descricao && (
              <span className="input-error">{errors.descricao.message}</span>
            )}
          </div>
          <div className="column true-false">
            <label>Necessidade ser um item de marca</label>
            <div className="flex">
              <div>
                <input
                  type="radio"
                  id="ifollow"
                  name="empresa_id"
                  value={true}
                  checked={
                    dataBriefing && dataBriefing.item_de_marca !== 0
                      ? true
                      : false
                  }
                  {...register("item_de_marca")}
                />
                <label htmlFor="ifollow">Sim</label>
              </div>
              <div>
                <input
                  type="radio"
                  id="sittrade"
                  name=""
                  value={false}
                  checked={
                    dataBriefing && dataBriefing.item_de_marca === 0
                      ? true
                      : false
                  }
                  {...register("item_de_marca")}
                />
                <label>Não</label>
              </div>
            </div>
            {errors.item_de_marca && (
              <span className="input-error">
                {errors.item_de_marca.message}
              </span>
            )}
          </div>
        </div>
      </div>
      <div className="quatro">
        {!dataBriefing
          ? indexQuantidade.map((index, outerIndex) => (
              <QuantidadeBriefing
                key={index}
                index={index}
                outerIndex={outerIndex}
                register={register}
                errors={errors}
                indexQuantidade={indexQuantidade}
                setIndexQuantidade={setIndexQuantidade}
                getValues={getValues}
                unregister={unregister}
              />
            ))
          : dataBriefing.quantidades.map((quantidade, outerIndex) => (
              <QuantidadeBriefingStyled>
                <div className="input-completo item">
                  <input
                    className="input-name select"
                    value={quantidade.quantidade}
                  />
                </div>
              </QuantidadeBriefingStyled>
            ))}
        <span
          onClick={() => {
            setIndexQuantidade([
              ...indexQuantidade,
              indexQuantidade[indexQuantidade.length - 1] + 1,
            ]);
          }}
          className="adicionar-quantidade"
          hidden={dataBriefing ? true : false}
        >
          Adicionar Quantidade
        </span>
      </div>
      <div className="div-prazo-endereco ">
        <div className="input-completo data">
          <Input
            label="Estimativa de Data de entrega"
            type="date"
            placeholder="00/00/0000"
            register={register("prazo_maximo")}
            className="input-data"
          />
          {errors.prazo_maximo && (
            <span className="input-error">{errors.prazo_maximo.message}</span>
          )}
        </div>
        <div className="input-completo">
          <Input
            label="Local da entrega"
            type="text"
            placeholder="Endereço completo"
            register={register("endereco")}
            className="input-name"
          />
          {errors.endereco && (
            <span className="input-error">{errors.endereco.message}</span>
          )}
        </div>
      </div>
      <div className="input-completo">
        <Input
          label="Observações"
          type="text"
          placeholder="Observações"
          register={register("observacao")}
          className="input-description"
        />
        {errors.observacao && (
          <span className="input-error">{errors.observacao.message}</span>
        )}
      </div>
      <div>
        <label className="label-div-file">Upload do arquivo</label>
        <input
          {...register("file")}
          type="file"
          id="file"
          accept="image/*"
          multiple={true}
          onChange={(e) => {
            setFiles([...e.target.files, ...files]);
          }}
        />
        <div className="div-file">
          <label className="label-file" for="file">
            Arraste e solte arquivos aqui ou{" "}
            <span className="red">pesquise em seu computador</span>
          </label>
        </div>
      </div>
      <div className="files">
        {files.length > 0 &&
          files.map((elem, outerIndex) => (
            <div className="relative" key={outerIndex}>
              <div>
                <h5>{elem.name}</h5>
                <span onClick={() => removerArquivo(outerIndex)}>X</span>
              </div>
            </div>
          ))}
      </div>
      {dataBriefing ? (
        <button className="button">Atualizar</button>
      ) : (
        <button className="button" hidden={travarBotao}>
          Adicionar
        </button>
      )}
      {(dataBriefing &&
        user.permissoes.includes("atendimento") &&
        dataBriefing.aprovacao_produtor === 0) ||
      (dataBriefing &&
        dataBriefing.aprovacao_produtor === 0 &&
        user.permissoes.includes("admin")) ? (
        <span className="button" onClick={() => aprovarDetalhes()}>
          Aprovar
        </span>
      ) : (
        ""
      )}
    </FormBrindeStyle>
  );
};
