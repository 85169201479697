import { useEffect, useState } from "react";
import Loading from "../../Loading";
import { ContentContainer, GerarPedidoComprasModalContainer } from "./styles";
import LogoVestaPng from "../../../assets/logo-vesta.png";
import { getBase64FromUrl } from "../../../utils/files";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { moneyMask } from "../../../utils/masks";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const GerarPedidoComprasModal = ({
  setModalOpened,
  campanha,
  material,
  fornecedor,
}) => {
  const [loading, setLoading] = useState(true);
  const [logo, setLogo] = useState("");

  useEffect(() => {
    getBase64FromUrl(LogoVestaPng).then((response) => {
      setLogo(response);
    });
  }, []);

  const generatePDF = async () => {
    const borders = [false, false, false, true];
    let subtotal = fornecedor
      ? Number(material.valor_total)
      : material.origem === "Vitrine"
      ? Number(material.valor_total)
      : Number(material.valor_final);

    let valorUnidade = fornecedor
      ? material.valor_unitario
      : material.origem === "Vitrine"
      ? material.valor_unitario
      : Number(material.valor_final) / Number(material.quantidade);

    let valorSemIPI =
      material.ipi && material.ipi > 0
        ? parseFloat(
            (material.valor_total / (1 + material.ipi / 100)).toFixed(2)
          )
        : material.valor_total;

    let icmsPagoFornecedor = parseFloat(
      ((valorSemIPI / 100) * material.icms).toFixed(2)
    );

    const dadosMaterial = [
      { text: 1, border: borders, style: "materialTexto", alignment: "center" },
      {
        text: [
          {
            text: material.subnome
              ? material.nome + " " + material.subnome + "\n"
              : material.nome + "\n",
          },
          { text: material.descricao, fontSize: 8, margin: [0, 0, 0, 10] },
        ],
        border: borders,
        style: "materialTexto",
      },
      {
        text: `${material.quantidade} un`,
        border: borders,
        style: "materialTexto",
      },
      {
        text: `${
          material.icms
            ? `R$${moneyMask(String(Number(icmsPagoFornecedor).toFixed(2)))}`
            : "---"
        }`,
        border: borders,
        style: "materialTexto",
        alignment: "center",
      },
      {
        text: `${
          Number(material.iss) !== 0
            ? `ISS`
            : `${material.porcentagemImpostos}%`
        }`,
        border: borders,
        style: "materialTexto",
        alignment: "center",
      },
      {
        text: `R$${moneyMask(
          String(
            material.valor_unitario.toLocaleString("pt-BR", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          )
        )}`,
        border: borders,
        style: "materialTexto",
        alignment: "center",
      },
      {
        text: `R$${
          fornecedor
            ? moneyMask(
                String(
                  material.valor_total.toLocaleString("pt-BR", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                )
              )
            : material.origem === "Vitrine"
            ? moneyMask(
                String(
                  subtotal.toLocaleString("pt-BR", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                )
              )
            : moneyMask(String(Number(material.valor_total).toFixed(2)))
        }`,
        border: borders,
        style: "materialTexto",
        alignment: "center",
      },
    ];

    var docDefinition = {
      pageMargins: [30, 40, 30, 40],
      content: [
        {
          columns: [
            {
              image: logo,
              width: 135,
              height: 40,
            },
            {
              stack: [
                {
                  text: [
                    {
                      text: "VESTA ASSESSORIA E MARKETING LTDA\n",
                      style: "header",
                    },
                    {
                      text: "CNPJ: 46.467.854/0001-83\n",
                      style: "endereco",
                    },
                    {
                      text: "Inscrição Estadual: IE 136.113.678.116\n",
                      style: "endereco",
                    },
                    {
                      text: "Inscrição Municipal : CCM 7.322.570-3\n",
                      style: "endereco",
                    },
                    {
                      text: "R. Jandiatuba, 630 - Conj 430 Bloco B1 - Vila Andrade\n",
                      style: "endereco",
                    },
                    {
                      text: "São Paulo - SP - CEP: 05716-150\n",
                      style: "endereco",
                    },
                    {
                      text: "Telefone: (11) 2737-4989\n",
                      style: "endereco",
                    },
                  ],
                },
              ],
              width: 190,
            },
          ],
          columnGap: 210,
        },
        { text: `Pedido de compras nº ${material.id}`, style: "subheader" },
        { text: `${campanha.nome}`, style: "subheader" },

        { text: "Informações do fornecedor", style: "sectionHeader" },
        {
          text: `${material.fornecedor_nome}`,
          style: "clientName",
          margin: [0, 0, 0, 10],
        },
        {
          columns: [
            [
              {
                text: `CNPJ: ${material.fornecedor_documento}`,
                style: "dadosCliente",
              },
              {
                text: `Inscrição Estadual: ${material.fornecedor_inscricao_estadual}`,
                style: "dadosCliente",
              },
            ],
            [
              {
                text: `${material.fornecedor_logradouro}`,
                style: "dadosCliente",
              },
              {
                text: `${material.fornecedor_cidade} - ${material.fornecedor_estado}- CEP: ${material.fornecedor_codigo_postal}`,
                style: "dadosCliente",
              },
            ],
            [
              {
                text: `E-mail: ${material.fornecedor_email_principal}`,
                style: "dadosCliente",
              },
              {
                text: `Telefone: ${
                  material.fornecedor_telefone_principal
                    ? material.fornecedor_telefone_principal
                    : material.fornecedor_telefone_secundario
                    ? material.fornecedor_telefone_secundario
                    : "-"
                }`,
                style: "dadosCliente",
              },
            ],
          ],
        },

        { text: "Itens do Pedido", style: "sectionHeader" },
        {
          table: {
            widths: ["5%", "25%", "15%", "10%", "15%", "15%", "15%"],
            headerRows: 1,
            heights: function (row) {
              if (row === 0) {
                return 20;
              }
              return 40;
            },
            border: "noBorders",
            body: [
              [
                {
                  text: "Item",
                  style: "tableHeader",
                  border: borders,
                  alignment: "center",
                },
                { text: "Descrição", style: "tableHeader", border: borders },
                { text: "Quant.", style: "tableHeader", border: borders },
                {
                  text: "Valor ICMS",
                  style: "tableHeader",
                  border: borders,
                  alignment: "center",
                },
                {
                  text: "Imposto",
                  style: "tableHeader",
                  border: borders,
                  alignment: "center",
                },
                {
                  text: "Valor uni.",
                  style: "tableHeader",
                  border: borders,
                  alignment: "center",
                },
                {
                  text: "Valor total",
                  style: "tableHeader",
                  border: borders,
                  alignment: "center",
                },
              ],
              dadosMaterial,
            ],
          },
        },
        {
          canvas: [
            {
              type: "rect",
              x: 0,
              y: 0,
              w: 150,
              h: 40,
              r: 10,
              color: "#7D0000",
            },
          ],
          margin: [385, 20, 0, 0],
        },
        {
          text: `Vencimento: `,
          style: "negrito",
        },
        {
          text: "90 dias",
          style: "dias",
        },
        {
          text: `Atenção, o grupo realiza pagamentos apenas nos dias 7, 17 e 27.
          Se a data de pagamento cair entre estes dias, considerar a próxima data de corte.`,
          style: "red",
        },
        {
          text: `SUBTOTAL: R$ ${moneyMask(
            String(
              material.valor_total.toLocaleString("pt-BR", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
            )
          )}`,
          style: "subtotal",
        },
        {
          canvas: [
            {
              type: "rect",
              x: 0,
              y: 0,
              w: 535,
              h: 120,
              r: 10,
              color: "#000",
            },
          ],
          margin: [0, 40, 0, 0],
        },
        {
          text: [
            {
              text: `Ao emitir sua nota fiscal eletronica (NF-e) garante que:\n`,
            },
            { text: "- O endereço \t" },
            { text: "nf@vesta.com.br \t", bold: true },
            { text: "esteja na relação de destinatários;\n" },
            { text: `- A Chave PIX ou boleto seja enviado também;\n` },
            {
              text: `- As informações do n° de job e n° do pedido de compra estejam na NF;\n`,
            },
            {
              text: `- O prazo de pagamento informado no pedido de compra esteja igual na NF e Boleto.\n`,
            },
            {
              text: `É imprescindível a emissão da NF no prazo máximo de 72hrs após a entrega dos produtos/serviços ou recebimento deste pedido de compra;`,
              bold: true,
            },
          ],
          columnGap: 2,
          style: {
            color: "#fff",
            fontSize: 10,
            lineHeight: 1.2,
          },
          margin: [10, -110, 0, 0],
        },
        {
          text: `
                        1. O FORNECEDOR, seus representantes, prepostos, empregados e terceiros eventualmente subcontratados devem considerar como confidenciais e assim tratar todos os fatos e informações que vierem a ter conhecimento no desenvolvimento da prestação do objeto deste Instrumento, e não poderão em hipótese alguma ser divulgadas sem a expressa autorização por escrito da CONTRATANTE, sob pena de reparação material e moral. 
                    `,
          style: {
            fontSize: 10,
          },
          margin: [0, 20, 0, 0],
        },
        {
          text: `
                        2. É expressamente vedado ao FORNECEDOR a utilização do objeto do pedido contratado pela CONTRATANTE, seu produto ou resultado, em outros negócios que não sejam com a CONTRATANTE, mesmo que a título gratuito. 
                    `,
          style: {
            fontSize: 10,
          },
        },
        {
          text: `
                        3. No preço estipulado estão incluídos todos os tributos e encargos de responsabilidade do FORNECEDOR. 
                    `,
          style: {
            fontSize: 10,
          },
        },
        {
          text: `
                        4. O FORNECEDOR, seus empregados, prepostos e representantes somente poderão utilizar a denominação, o título, a marca, logotipo ou expressões da CONTRATANTE com a prévia anuência por escrito da mesma. 
                    `,
          style: {
            fontSize: 10,
          },
        },
      ],
      styles: {
        header: {
          fontSize: 12,
          bold: true,
          margin: [200, 0, 0, 0],
          alignment: "right",
        },
        endereco: {
          fontSize: 8,
          margin: [200, 0, 0, 0],
          alignment: "right",
          lineHeight: 1.1,
        },
        dadosCliente: {
          fontSize: 10,
        },
        subheader: {
          fontSize: 10,
          bold: true,
          margin: [0, 0, 0, 5],
        },
        sectionHeader: {
          fontSize: 10,
          bold: true,
          margin: [0, 20, 0, 10],
        },
        clientName: {
          fontSize: 10,
          bold: true,
        },
        materialTexto: {
          fontSize: 10,
          height: 50,
          bold: false,
          margin: [0, 10, 0, 0],
        },
        subtotal: {
          fontSize: 10,
          bold: true,
          color: "#ffffff",
          margin: [410, -33, 0, 0],
        },
        negrito: {
          margin: [0, -40, 0, 0],
          bold: true,
        },
        dias: {
          fontSize: 10,
          margin: [69, -12, 0, 0],
        },
        red: {
          color: "#ff0000",
          fontSize: 10,
          margin: [0, 0, 0, 10],
        },
        tableHeader: {
          fontSize: 10,
          bold: true,
        },
      },
    };

    pdfMake.createPdf(docDefinition).getBlob((blob) => {
      const fileURL = URL.createObjectURL(blob);
      const newWindow = window.open(fileURL, "_blank", "noopener,noreferrer");
      if (newWindow) newWindow.opener = null;
      setModalOpened(false);
    });
  };

  useEffect(() => {
    if (logo) {
      generatePDF();
    }
  }, [logo]);

  return (
    <GerarPedidoComprasModalContainer>
      <ContentContainer>{loading && <Loading />}</ContentContainer>
    </GerarPedidoComprasModalContainer>
  );
};

export default GerarPedidoComprasModal;
