import { styled } from "styled-components";

export const NovaCategoriaContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 60px;

  .flex {
    display: flex;
    align-items: center;
  }

  .perfil {
    svg {
      width: 45px;
      height: 45px;
      margin-right: 10px;
    }
  }

  h1 {
    font-size: 38px;
    font-weight: 600;
    color: var(--principal);
  }

  .box {
    margin-top: 40px;
    width: 90%;
    height: 700px;
    background-color: var(--base);
    border-radius: 18px;
  }
  .filtro {
    display: flex;
    align-items: center;
    gap: 20px;
    width: 60%;

    input {
      border: none;
      padding: 10px;
      border-radius: 50px;
      width: 70%;
      height: 40px;
      font-size: 14px;
    }

    div {
      align-items: center;
      justify-content: center;
      display: flex;
      flex-direction: column;
      margin-bottom: 25px;
      width: 35%;

      > p {
        font-size: 0.8rem;
        margin-bottom: 8px;
      }
    }

    select,
    option {
      width: 161px;
      padding: 5px;
      border-radius: 6px;
      border: none;
      outline: none;
      background: var(--amarelo);
      text-align: center;
      font-size: 0.8rem;
      color: var(--base);
      font-weight: 200;
    }
  }
`;

export const BoxFiltros = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    background-color: var(--principal);
    color: var(--base);
    width: 20%;
    font-size: 0.8rem;
    font-weight: 600;
    padding: 8px;
    border: none;
    border-radius: 20px;
  }
`;
