import { Form, InputContainer, LoginButton, VoltarButton } from "./styles";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import api from "../../../services/api";
import LoginEmailSvg from "../../../assets/email.svg";
import { ReactSVG } from "react-svg";
import LogoVesta from "../../../assets/logo-vesta.svg";
import { useNavigate } from "react-router-dom";
import { NovaSenhaSchema } from "../../../schemas/login";

const NovaSenhaForm = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(NovaSenhaSchema),
  });

  const onSubmitFunction = (data) => {
    api
      .post("/recuperar-senha", data)
      .then((response) => {
        toast.success("Email de redefinição de senha enviado com sucesso.");
      })
      .catch((error) => {
        toast.error("Email inválido. Tente novamente.");
      });
  };

  function navigateToLogin() {
    navigate("/login");
  }

  return (
    <Form onSubmit={handleSubmit(onSubmitFunction)}>
      <img src={LogoVesta} alt="Logo Vesta" className="logo" />
      <p>
        Informe seu e-mail e enviaremos uma senha provisória para o e-mail
        cadastrado.
      </p>
      <InputContainer>
        <label htmlFor="email">
          <ReactSVG src={LoginEmailSvg} />
        </label>
        <input
          id="email"
          placeholder="E-mail"
          type="text"
          autoComplete="username"
          {...register("email")}
        />
        {errors.email && (
          <span className="input-error">{errors.email.message}</span>
        )}
      </InputContainer>

      <LoginButton type="submit">Enviar</LoginButton>
      <VoltarButton type="button" onClick={navigateToLogin}>
        Voltar
      </VoltarButton>
    </Form>
  );
};

export default NovaSenhaForm;
