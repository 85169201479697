import { ReactSVG } from "react-svg";
import {
  AprovarPropostaButton,
  GerarPropostaButton,
  PropostaCampanhaContainer,
} from "./styles";
import PropostaSvg from "../../assets/proposta.svg";
import CustomTable from "../CustomTable";
import PropostaCampanhaTr from "../trs/campanhas/PropostaCampanhaTr";
import { useState } from "react";
import GerarPropostaModal from "../Modal/GerarPropostaModal";
import AprovarPropostaModal from "../Modal/AprovarPropostaModal";
import PdfPropostaTr from "../trs/campanhas/PdfPropostaTr";
import { useAuth } from "../../providers/Auth";

const PropostaCampanha = ({
  campanha,
  materiaisBriefing,
  materiaisVitrine,
  propostas,
  buscarDadosCampanha,
  ncms,
  itens,
}) => {
  materiaisBriefing = materiaisBriefing.filter(
    (elem) => elem.status !== "pendente" && Number(elem.status) !== 0
  );
  const { user } = useAuth();

  const verificarExistenciaBriefing = materiaisBriefing.filter(
    (elem) => elem.status === "aguardando análise"
  );
  const verificarExistenciaVitrine = materiaisVitrine.filter(
    (elem) => elem.status === "proposta"
  );

  const [materiaisProposta, setMateriaisProposta] = useState([]);
  const [gerarPropostaModalOpened, setGerarPropostaModalOpened] =
    useState(false);
  const [aprovarPropostaModalOpened, setAprovarPropostaModalOpened] =
    useState(false);

  return materiaisBriefing.length < 0 && materiaisVitrine.length < 0 ? (
    <></>
  ) : (
    <PropostaCampanhaContainer>
      <h2>
        <ReactSVG src={PropostaSvg} /> Proposta
      </h2>
      <>
        <CustomTable
          header={[
            "Origem",
            "Item",
            "Quantidade",
            "Unitário",
            "Orçamento",
            "Status",
            "Selecione",
          ]}
          message={"Nenhum item adicionado."}
        >
          {materiaisBriefing.map((material) => {
            const output = [];
            material.quantidades.map((quantidade) =>
              output.push(
                <PropostaCampanhaTr
                  key={material.id + quantidade + "_briefing"}
                  origem="Briefing"
                  material={material}
                  quantidade={quantidade}
                  setMateriaisProposta={setMateriaisProposta}
                  ultimo={!quantidade + 1 && !quantidade + 1}
                  ncms={ncms}
                  itens={itens}
                  materiaisProposta={materiaisProposta}
                />
              )
            );
            if (material.quantidade_a) {
              output.push(
                <PropostaCampanhaTr
                  key={material.id + material.quantidade_a + "_briefing"}
                  origem="Briefing"
                  material={material}
                  quantidade={"a"}
                  setMateriaisProposta={setMateriaisProposta}
                  ultimo={!material.quantidade_b && !material.quantidade_c}
                  ncms={ncms}
                  itens={itens}
                  materiaisProposta={materiaisProposta}
                />
              );
            }
            if (material.quantidade_b) {
              output.push(
                <PropostaCampanhaTr
                  key={material.id + material.quantidade_b + "_briefing"}
                  origem="Briefing"
                  material={material}
                  quantidade={"b"}
                  setMateriaisProposta={setMateriaisProposta}
                  ultimo={!material.quantidade_c}
                  ncms={ncms}
                  itens={itens}
                  materiaisProposta={materiaisProposta}
                />
              );
            }
            if (material.quantidade_c) {
              output.push(
                <PropostaCampanhaTr
                  key={material.id + material.quantidade_c + "_briefing"}
                  origem="Briefing"
                  material={material}
                  quantidade={"c"}
                  setMateriaisProposta={setMateriaisProposta}
                  ultimo={true}
                  ncms={ncms}
                  itens={itens}
                  materiaisProposta={materiaisProposta}
                />
              );
            }
            return output;
          })}
          {materiaisVitrine.map((material) => (
            <PropostaCampanhaTr
              key={material.id + "_vitrine"}
              origem="Vitrine"
              material={material}
              setMateriaisProposta={setMateriaisProposta}
              ultimo={true}
              ncms={ncms}
              itens={itens}
              materiaisProposta={materiaisProposta}
            />
          ))}
        </CustomTable>
        <div className="buttons-container">
          {verificarExistenciaBriefing.length > 0 ||
          verificarExistenciaVitrine.length > 0 ? (
            <>
              <GerarPropostaButton
                onClick={() => setGerarPropostaModalOpened(true)}
              >
                <ReactSVG /> Ver pdf
              </GerarPropostaButton>

              {user.permissoes.includes("admin") ||
              user.permissoes.includes("atendimento") ? (
                <AprovarPropostaButton
                  onClick={() => setAprovarPropostaModalOpened(true)}
                >
                  <ReactSVG /> Aprovar
                </AprovarPropostaButton>
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}
        </div>
      </>
      {propostas.length > 0 && (
        <CustomTable
          header={["PDF PEDIDO DE VENDA"]}
          message={"Nenhuma proposta encontrada."}
        >
          {propostas.map((proposta) => (
            <PdfPropostaTr key={proposta.id} proposta={proposta} />
          ))}
        </CustomTable>
      )}
      {user && user.permissoes.includes("admin") && propostas.length > 0 && (
        <CustomTable
          header={["PDF PEDIDO DE COMPRA"]}
          message={"Nenhuma proposta encontrada."}
        >
          {propostas.map((proposta) => (
            <PdfPropostaTr
              key={proposta.id}
              proposta={proposta}
              tipo="compra"
            />
          ))}
        </CustomTable>
      )}

      {gerarPropostaModalOpened && (
        <GerarPropostaModal
          setModalOpened={setGerarPropostaModalOpened}
          campanha={campanha}
          materiais={materiaisProposta}
        />
      )}
      {aprovarPropostaModalOpened && (
        <AprovarPropostaModal
          setModalOpened={setAprovarPropostaModalOpened}
          campanha={campanha}
          materiais={materiaisProposta}
          buscarDadosCampanha={buscarDadosCampanha}
        />
      )}
    </PropostaCampanhaContainer>
  );
};

export default PropostaCampanha;
