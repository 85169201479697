import { useNavigate } from "react-router-dom";
import {
  Body,
  InputsTipoDeUsuario,
  InputsUsuarios,
  NovoUsuarioContainer,
} from "./styles";
import { toast } from "react-toastify";
import api from "../../../../services/api";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { NovoCadastroSchema } from "../../../../schemas/novoCadastro";
import { ReactSVG } from "react-svg";
import voltar from "../../../../assets/voltar.svg";
import Select from "react-select";

const NovoUsuario = () => {
  const [perfis, setPerfis] = useState();
  const [forncedores, setFornecedores] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [adicionarFornecedor, setAdicionarFornecedor] = useState(false);
  const [adicionarCliente, setAdicionarCliente] = useState(false);
  const [adicionarAtendimento, setAdicionarAtendimento] = useState(false);
  const [adicionarProdutor, setAdicionarProdutor] = useState(false);
  const [atendimento, setAtendimento] = useState(null);
  const [produtores, setProdutores] = useState(null);
  const [clientesResponsaveis, setClientesResponsaveis] = useState([]);
  const [produtoresSelecionados, setProdutoresSelecionados] = useState([]);
  const [atendimentoSelecionados, setAtendimentoSelecionados] = useState([]);

  const navigate = useNavigate();
  const {
    formState: { errors },
    handleSubmit,
    register,
    getValues,
  } = useForm({
    resolver: yupResolver(NovoCadastroSchema),
  });

  function buscarPerfis() {
    api
      .get("/perfis")
      .then((response) => {
        setPerfis(response.data.data.perfis);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  function buscarFornecedores() {
    api
      .get("/fornecedores")
      .then((response) => {
        setFornecedores(response.data.data.fornecedores);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  function buscarClientes() {
    api
      .get("/clientes")
      .then((response) => {
        for (const cliente of response.data.data.clientes) {
          cliente.value = cliente.id;
          cliente.label = cliente.razao_social;
        }
        setClientes(response.data.data.clientes);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  function listarUsuarios() {
    api
      .get("/usuarios")
      .then((response) => {
        for (const usuario of response.data.data.usuarios) {
          usuario.value = usuario.id;
          usuario.label = usuario.nome;
        }
        const filtroProdutores = response.data.data.usuarios.filter(
          (usuario) => usuario.perfil_nome === "produtor"
        );
        const filtroAtendimento = response.data.data.usuarios.filter(
          (usuario) => usuario.perfil_nome === "atendimento"
        );
        setProdutores(filtroProdutores);
        setAtendimento(filtroAtendimento);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  useEffect(() => {
    buscarPerfis();
    buscarFornecedores();
    buscarClientes();
    listarUsuarios();
  }, []);

  const onSubmitFunction = (data) => {
    const formValues = getValues();
    formValues.status = 1;
    api
      .post("/usuarios", { usuarios: [formValues] })
      .then((response) => {
        toast.success("Usuário cadastrado com sucesso!");
        const usuario_fornecedor = {
          fornecedor_id: Number(getValues("fornecedor_id")),
          usuario_id: response.data.data.insertId,
          status: 1,
        };

        const usuario_cliente = {
          cliente_id: Number(getValues("cliente_id")),
          usuario_id: response.data.data.insertId,
          status: 1,
        };

        if (
          adicionarFornecedor &&
          getValues("fornecedor_id") !== "Selecionar Fornecedor" &&
          getValues("fornecedor_id") !== ""
        ) {
          api
            .post("/usuario-fornecedor", [usuario_fornecedor])
            .then((response) => {
              toast.success("Cliente cadastrado com sucesso!");
              navigate("/usuarios");
            })
            .catch((error) => {
              toast.error(error.response.data.message);
            });
        }

        if (
          adicionarCliente &&
          getValues("cliente_id") !== "Selecionar Cliente" &&
          getValues("cliente_id") !== ""
        ) {
          api
            .post("/usuario-cliente", [usuario_cliente])
            .then((response) => {
              if (produtoresSelecionados.length > 0) {
                for (const produtor of produtoresSelecionados) {
                  const usuario_cliente = {
                    cliente_id: Number(getValues("cliente_id")),
                    usuario_id: produtor.id,
                    status: 1,
                  };
                  api
                    .post("/usuario-cliente", [usuario_cliente])
                    .then((response) => {})
                    .catch((error) => {
                      return toast.error(error.response.data.message);
                    });
                }
              }
              if (atendimentoSelecionados.length > 0) {
                for (const atendimento of atendimentoSelecionados) {
                  const usuario_cliente = {
                    cliente_id: Number(getValues("cliente_id")),
                    usuario_id: atendimento.id,
                    status: 1,
                  };
                  api
                    .post("/usuario-cliente", [usuario_cliente])
                    .then((response) => {})
                    .catch((error) => {
                      return toast.error(error.response.data.message);
                    });
                }
              }
            })
            .catch((error) => {
              toast.error(error.response.data.message);
            });
          navigate("/usuarios");
          toast.success("Clientes cadastrado com sucesso!");
        }

        if (adicionarAtendimento && clientesResponsaveis.length > 0) {
          for (const cliente of clientesResponsaveis) {
            const usuario_cliente = {
              cliente_id: cliente.id,
              usuario_id: response.data.data.insertId,
              status: 1,
            };
            api
              .post("/usuario-cliente", [usuario_cliente])
              .then((response) => {})
              .catch((error) => {
                return toast.error(error.response.data.message);
              });
          }
          navigate("/usuarios");
          toast.success("Clientes cadastrado com sucesso!");
        }

        if (adicionarProdutor && clientesResponsaveis.length > 0) {
          for (const cliente of clientesResponsaveis) {
            const usuario_cliente = {
              cliente_id: cliente.id,
              usuario_id: response.data.data.insertId,
              status: 1,
            };
            api
              .post("/usuario-cliente", [usuario_cliente])
              .then((response) => {
                toast.success("Cliente cadastrado com sucesso!");
                navigate("/usuarios");
              })
              .catch((error) => {
                toast.error(error.response.data.message);
              });
          }
        }
        navigate("/usuarios");
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  function verificarPerfil() {
    const idPerfil = getValues("perfil_id");

    if (idPerfil === "Selecione o perfil") {
      return;
    }

    const qualPerfilSelecionado = perfis.find(
      (element) => element.id == idPerfil
    ).nome;

    if (qualPerfilSelecionado === "fornecedor") {
      setAdicionarFornecedor(true);
    } else {
      setAdicionarFornecedor(false);
    }

    if (qualPerfilSelecionado === "cliente") {
      setAdicionarCliente(true);
    } else {
      setAdicionarCliente(false);
    }

    if (qualPerfilSelecionado === "atendimento") {
      setAdicionarAtendimento(true);
    } else {
      setAdicionarAtendimento(false);
    }

    if (qualPerfilSelecionado === "produtor") {
      setAdicionarProdutor(true);
    } else {
      setAdicionarProdutor(false);
    }
  }

  return (
    <NovoUsuarioContainer onSubmit={handleSubmit(onSubmitFunction)}>
      <div className="box">
        <div className="flex">
          <ReactSVG
            onClick={() => navigate("/usuarios")}
            className="voltar"
            src={voltar}
          />
          <div className="column">
            <h1>Usuário</h1>
            <h2>Novo usuário</h2>
          </div>
        </div>
        <Body>
          <InputsUsuarios>
            <div>
              <label>Nome *</label>
              <input
                type="text"
                name="nome"
                {...register("nome")}
                placeholder="Nome"
              />
              {errors.nome && (
                <span className="input-error-cadastro">
                  {errors.nome.message}
                </span>
              )}
            </div>
            <div>
              <label>Sobrenome *</label>
              <input
                type="text"
                name="sobrenome"
                {...register("sobrenome")}
                placeholder="Sobrenome"
              />
              {errors.sobrenome && (
                <span className="input-error-cadastro">
                  {errors.sobrenome.message}
                </span>
              )}
            </div>
          </InputsUsuarios>
          <InputsTipoDeUsuario>
            <div>
              <label>E-mail *</label>
              <input
                type="email"
                name="email"
                {...register("email")}
                placeholder="email@email.com.br"
              />
              {errors.email && (
                <span className="input-error-cadastro">
                  {errors.email.message}
                </span>
              )}
            </div>
            <div>
              <label>Senha *</label>
              <input
                type="password"
                name="senha"
                {...register("senha")}
                placeholder="********"
              />
              {errors.senha && (
                <span className="input-error-cadastro">
                  {errors.senha.message}
                </span>
              )}
            </div>
          </InputsTipoDeUsuario>
          <InputsTipoDeUsuario>
            <div>
              <label>Departamento</label>
              <input
                type="text"
                name="departamento"
                {...register("departamento")}
                placeholder="Departamento"
              />
              {errors.departamento && (
                <span className="input-error-cadastro">
                  {errors.departamento.message}
                </span>
              )}
            </div>
            <div>
              <label>Perfil de acesso *</label>
              <select
                name="perfil_id"
                {...register("perfil_id")}
                onClick={() => verificarPerfil()}
              >
                <option>Selecione o perfil</option>
                {perfis &&
                  perfis.map((data) => (
                    <option key={data.id} value={data.id}>
                      {data.nome}
                    </option>
                  ))}
              </select>
              {errors.perfil_id && (
                <span className="input-error-cadastro">
                  {errors.perfil_id.message}
                </span>
              )}
            </div>
          </InputsTipoDeUsuario>
          {adicionarFornecedor && (
            <>
              <div className="titulo-check">
                <label>Adicionar Fornecedor</label>
              </div>
              <div className="div-fornecedor">
                <select {...register("fornecedor_id")}>
                  <option>Selecionar Fornecedor</option>
                  {forncedores.length > 0 &&
                    forncedores.map((element) => (
                      <option value={element.id}>{element.nome}</option>
                    ))}
                </select>
              </div>
            </>
          )}
          {adicionarCliente && (
            <>
              <div className="titulo-check">
                <label>Adicionar Cliente</label>
              </div>
              <div className="div-fornecedor">
                <select {...register("cliente_id")}>
                  <option>Selecionar Cliente</option>
                  {clientes.length > 0 &&
                    clientes.map((element) => (
                      <option value={element.id}>{element.razao_social}</option>
                    ))}
                </select>
              </div>
              <div className="select">
                <label className="label">Produtores Responsáveis: </label>
                <Select
                  onChange={setProdutoresSelecionados}
                  options={produtores && produtores}
                  isMulti={true}
                  placeholder="Produtores"
                />
              </div>
              <div className="select">
                <label className="label">Atendimentos Responsáveis: </label>
                <Select
                  onChange={setAtendimentoSelecionados}
                  options={atendimento && atendimento}
                  isMulti={true}
                  placeholder="Atendimento"
                />
              </div>
            </>
          )}
          {adicionarAtendimento && (
            <div className="select">
              <Select
                value={clientesResponsaveis}
                onChange={setClientesResponsaveis}
                options={clientes && clientes}
                isMulti={true}
              />
            </div>
          )}
          {adicionarProdutor && (
            <div className="select">
              <Select
                value={clientesResponsaveis}
                onChange={setClientesResponsaveis}
                options={clientes && clientes}
                isMulti={true}
              />
            </div>
          )}
        </Body>
        <div className="buttons">
          <button className="button-save" type="submit">
            Salvar
          </button>
        </div>
      </div>
    </NovoUsuarioContainer>
  );
};

export default NovoUsuario;
