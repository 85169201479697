import NotFoundPage from '../pages/NotFoundPage';
import { useAuth } from '../providers/Auth'
import { Navigate } from 'react-router-dom';

const CustomRoute = ({ element: Element, permissions = [] }) => {
    const { user , userLoaded } = useAuth();

    if (userLoaded) {
        if (user) {
            if (permissions.length) {
                const permissoes = permissions.every(permission => user.permissoes.includes(permission));
                if (permissoes) {
                    return <Element />
                } else {
                    return <NotFoundPage />
                }
            } else {
                return <Element />
            }
        } else {
            return <Navigate to='/' />
        }
    };
};

export default CustomRoute;