import { styled } from "styled-components";

export const BodyConcorrencia = styled.div`
  h2 {
    color: var(--principal);
    font-size: 18px;
    font-weight: 600;
  }

  .titulo {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
  }

  .cliente {
    font-size: 12px;
    font-weight: 600;
  }

  a {
    margin-left: 8px;
    color: var(--azul);
    font-size: 0.8rem;
  }

  .status {
    width: 200px;
    max-width: 200px;
  }
`;

export const TableContainer = styled.div`
  height: 70%;

  h2 {
    color: var(--principal);
    font-size: 18px;
    font-weight: 600;
  }

  .titulo {
    height: 55px;
    padding-bottom: 16px;
    margin-top: 40px;
    justify-content: space-between;
  }

  a {
    margin-left: 10px;
  }

  .card {
    width: 350px;
    padding: 25px 20px;
    background-color: var(--base);
    border-radius: 8px;
    margin: 0px 25px 25px 0px;
  }

  .top-card {
    display: flex;
    margin-bottom: 15px;

    h3 {
      font-weight: 600;
      margin-right: 4px;
    }
  }

  .nome {
  }

  .lista {
    width: 100%;
    flex-wrap: wrap;
  }

  .deletado {
    font-size: 0.8rem;
    font-weight: 600;
    background-color: red;
    padding: 4px 0px;
    border-radius: 16px;
    color: var(--base);
  }

  .pendente {
    font-size: 0.8rem;
    font-weight: 600;
    background-color: blue;
    padding: 4px 0px;
    border-radius: 16px;
    color: var(--base);
  }

  .aguardando {
    font-size: 0.8rem;
    font-weight: 600;
    background-color: orange;
    padding: 4px 0px;
    border-radius: 16px;
    color: var(--base);
  }

  .enviado {
    font-size: 0.8rem;
    font-weight: 600;
    background-color: green;
    padding: 4px 0px;
    border-radius: 16px;
    color: var(--base);
  }

  .concluido {
    font-size: 0.8rem;
    font-weight: 600;
    background-color: #000000;
    padding: 4px 0px;
    border-radius: 16px;
    color: var(--base);
  }

  .analise {
    font-size: 0.8rem;
    font-weight: 600;
    background-color: purple;
    padding: 4px 0px;
    border-radius: 16px;
    color: var(--base);
  }

  .producao {
    font-size: 0.8rem;
    font-weight: 600;
    background-color: #609ae5;
    padding: 4px 0px;
    border-radius: 16px;
    color: var(--base);
  }

  &::-webkit-scrollbar {
    width: 8px;
    border-radius: 8px;
    background-color: var(--base01);
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--principal);
    border-radius: 5px;
  }

  > table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    padding: 10px;

    > thead > tr > th {
      font-weight: 600;
      padding: 20px;
      padding: 10px;
      font-size: 0.8rem;
    }
    > tbody > tr > td {
      border-bottom: 1px solid var(--grayDA);
      border-top: 1px solid var(--grayA5);
      padding: 15px;
      font-size: 0.8rem;
      text-align: center;
    }
  }

  .Material {
    text-align: justify;
  }

  .ativo {
    display: flex;
    align-items: center;
    justify-content: center;

    > p {
      font-size: 0.8rem;
      width: 60px;
    }
  }

  a {
    color: var(--azul);
    font-size: 0.8rem;
  }

  .ancora-desativada {
    font-size: 0.8rem;
    color: var(--principal);
    text-decoration: underline;
  }

  .editar {
    font-size: 0.8rem;
    color: var(--principal);
    text-decoration: underline;
  }

  th {
    width: 18%;
  }
`;
