import styled from "styled-components";

export const ListaVitrineStyle = styled.div`
  width: calc(100% - 260px);
  display: flex;
  flex-direction: column;
  padding: 55px 45px;
  flex-direction: column;
  background-color: var(--base01);
  border-radius: 60px 0px 0px 60px;

  .flex {
    display: flex;
    align-items: center;

    svg {
      width: 45px;
      height: 45px;
    }

    h1 {
      font-size: 47px;
      font-weight: 600;
      color: var(--principal);
      margin-left: 6px;
    }
  }

  .cards {
    display: flex;
    align-items: center;
  }

  .column {
    display: flex;
    flex-direction: column;
  }

  .div-flex {
    display: flex;
    align-items: baseline;
    margin-top: 45px;

    span {
      font-size: 12px;
      margin-left: 10px;
      text-decoration: underline;
      color: #609ae5;
      cursor: pointer;
    }
  }

  .title {
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: 600;
    color: var(--principal);
  }

  .seta {
    background-color: var(--amarelo);
    color: var(--principal);
    height: max-content;
    padding: 0px 14px;
    border-radius: 60px;
    margin-bottom: 40px;
    font-size: 40px;
  }

  .div-seta {
    width: 50px;
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
  }

  .h3-aviso {
    width: max-content;
    display: flex;
    margin: 0 auto;
    border-radius: 6px;
    color: var(--base);
    background: var(--principal);
    padding: 12px;
  }
`;
