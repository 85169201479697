import {
  AdicionarMaterialCampanhaModalContainer,
  ContentContainer,
} from "./styles";
import { ReactComponent as FecharSvg } from "../../../../assets/fechar.svg";
import SalvarSvg from "../../../../assets/salvar.svg";
import { Link, useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { useEffect, useState } from "react";
import api from "../../../../services/api";
import { toast } from "react-toastify";
import Loading from "../../../Loading";
import { useAuth } from "../../../../providers/Auth";

const AdicionarMaterialCampanhaModal = ({
  setModalOpened,
  valorCalculado,
  materialVitrine,
}) => {
  const { user } = useAuth();
  const [campanhas, setCampanhas] = useState([]);
  const [campanhaSelecionada, setCampanhaSelecionada] = useState("");

  const [campanhasLoaded, setCampanhasLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cliente, setCliente] = useState();

  const navigate = useNavigate();

  useEffect(() => {
    if (campanhasLoaded) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [campanhasLoaded]);

  const buscarCampanhas = () => {
    setCampanhasLoaded(false);
    api
      .get("/campanhas")
      .then((response) => {
        setCampanhas(response.data.data.campanhas);
        setCampanhasLoaded(true);
      })
      .catch((error) => {
        toast.error("Erro ao buscar campanhas");
      });
  };

  function buscarCliente() {
    api
      .get(`/usuario-cliente/${user.id}`)
      .then((response) => {
        setCliente(response.data.data.usuario.cliente_id);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  useEffect(() => {
    buscarCampanhas();
    buscarCliente();
  }, []);

  const handleSalvaMaterialCampanha = (status) => {
    const dados = {
      material_vitrine_valor_id: valorCalculado.material_vitrine_valor_id,
      campanha_id: campanhaSelecionada,
      quantidade: valorCalculado.quantidade,
      imposto: valorCalculado.imposto,
      fornecedor_id: valorCalculado.fornecedor_id,
      valor_total: valorCalculado.preco_total,
      preco_unitario: valorCalculado.preco_unitario,
      criador_id: cliente,
    };

    api
      .post("/campanha-material-vitrine", { materiais: [dados] })
      .then((response) => {
        toast.success("Material adicionado com sucesso");
        if (status) {
          navigate(`/campanha/detalhes/${campanhaSelecionada}`);
        } else {
          navigate("/vitrine");
        }
      })
      .catch((error) => {
        toast.error("Erro ao adicionar material");
      });
  };

  return (
    <AdicionarMaterialCampanhaModalContainer
      onClick={() => setModalOpened(false)}
    >
      <ContentContainer onClick={(e) => e.stopPropagation()}>
        <button className="fechar" onClick={() => setModalOpened(false)}>
          <FecharSvg />
        </button>
        {loading ? (
          <Loading />
        ) : (
          <div className="content">
            <p>
              Adicionar {valorCalculado && valorCalculado.quantidade} unidades
              de {materialVitrine.nome} à campanha:
            </p>
            <div className="select-container">
              <select onChange={(e) => setCampanhaSelecionada(e.target.value)}>
                <option value="">Seleciona uma campanha</option>
                {campanhas.map((campanha) => (
                  <option key={campanha.id} value={campanha.id}>
                    {campanha.nome}
                  </option>
                ))}
              </select>
              <span>
                <Link to="/campanha/nova">criar nova campanha</Link>
              </span>
            </div>
            <div className="flex">
              <button
                className="salvar-button"
                onClick={() => handleSalvaMaterialCampanha(false)}
                disabled={campanhaSelecionada === ""}
              >
                <ReactSVG src={SalvarSvg} />
                <span className="span-button">Salvar na Campanha</span>
              </button>
              <button
                className="salvar-button"
                onClick={() => handleSalvaMaterialCampanha(true)}
                disabled={campanhaSelecionada === ""}
              >
                <ReactSVG src={SalvarSvg} />
                <span className="span-button">Salvar e Ir Pra Campanha</span>
              </button>
            </div>
          </div>
        )}
      </ContentContainer>
    </AdicionarMaterialCampanhaModalContainer>
  );
};

export default AdicionarMaterialCampanhaModal;
