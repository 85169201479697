import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Body,
  InputsCliente,
  InputsEndereco,
  InputsEndereco1,
  InputsEndereco2,
  InputsIncricao,
  InputsPagamento,
  InputsPagamento1,
  EditarForncedorContainer,
} from "./styles";
import { toast } from "react-toastify";
import api from "../../../../services/api";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { CampoObrigatorio } from "../../../../schemas/novoCadastro";
import { yupResolver } from "@hookform/resolvers/yup";
import { NovoClienteContainer } from "../../Novo/NovoFornecedor/styles";
import voltar from "../../../../assets/voltar.svg";
import { ReactSVG } from "react-svg";

const EditarForncedor = () => {
  const { id } = useParams();
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    setFocus,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(CampoObrigatorio),
  });
  const [usuarios, setUsuarios] = useState([]);
  const [categorias, setCategorias] = useState();
  const [adicionarIcms, setAdicionarIcms] = useState(false);
  const [regimesEmpresa, setRegimesEmpresa] = useState();
  const arrayEstados = [
    "AC",
    "AL",
    "AP",
    "AM",
    "BA",
    "CE",
    "DF",
    "ES",
    "GO",
    "MA",
    "MT",
    "MS",
    "MG",
    "PA",
    "PB",
    "PR",
    "PE",
    "PI",
    "RJ",
    "RN",
    "RS",
    "RO",
    "RR",
    "SC",
    "SP",
    "SE",
    "TO",
  ];
  const navigate = useNavigate();
  const onSubmitFunction = () => {
    const formValues = getValues();

    const dataToSend = {
      ...formValues,
      icms: adicionarIcms ? getValues("icms") : "",
      nome_registro: "",
      cnae_principal: "",
      registro_fiscal: "",
      cartao_cnpj: "",
      certidao_negativa: "",
      comprovante_bancario: "",
      contrato_social: "",
      contato_secundario: "",
      telefone_secundario: "",
      email_secundario: "",
      contato_financeiro: "",
      link: "",
      moeda_transacao: "",
      credita_icms: adicionarIcms,
      empresa_id: 1,
    };

    api
      .patch(`/fornecedores/${id}`, dataToSend)
      .then((response) => {
        navigate("/fornecedores");
        toast.success("Fornecedor atualizado com sucesso!");
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  useEffect(() => {
    if (regimesEmpresa && categorias) {
      api
        .get(`/fornecedores/${id}`)
        .then((response) => {
          reset(response.data.data.fornecedor);
          setAdicionarIcms(
            response.data.data.fornecedor.credita_icms == 1 ? true : false
          );
        })
        .catch((error) => {
          console.error("Erro ao buscar dados da API:", error);
          toast.error("Erro ao buscar dados");
        });
    }
  }, [regimesEmpresa, categorias]);

  function buscarCategoria() {
    api
      .get("/categoria-fornecedor")
      .then((response) => {
        setCategorias(response.data.data.categorias);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  function buscarRegimeEmpresa() {
    api
      .get("/regimes-empresa")
      .then((response) => {
        setRegimesEmpresa(response.data.data.regimesEmpresa);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }
  function buscarUsuario() {
    api
      .get("/usuarios")
      .then((response) => {
        setUsuarios(response.data.data.usuarios);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  const checkCEP = (e) => {
    const codigo_postal = e.target.value.replace(/\D/g, "");

    fetch(`https://viacep.com.br/ws/${codigo_postal}/json/`)
      .then((res) => res.json())
      .then((data) => {
        setValue("logradouro", data.logradouro);
        setValue("bairro", data.bairro);
        setValue("cidade", data.localidade);
        setValue("estado", data.uf);
        setFocus("number");
      })
      .catch((error) => {
        toast.error("CEP inválido");
      });
  };

  useEffect(() => {
    buscarUsuario();
    buscarCategoria();
    buscarRegimeEmpresa();
  }, []);

  return (
    <NovoClienteContainer onSubmit={handleSubmit(onSubmitFunction)}>
      <div className="box">
        <div className="flex">
          <ReactSVG
            onClick={() => navigate("/fornecedores")}
            className="voltar"
            src={voltar}
          />
          <div className="column">
            <h1>Fornecedor</h1>
            <h2 className="h2-header">Editar Fornecedor</h2>
          </div>
        </div>

        <Body>
          <div className="checkbox">
            <input
              type="checkbox"
              id="checkbox1"
              name="status"
              {...register("status")}
              defaultChecked={true}
            />
            <label htmlFor="checkbox1">Ativo</label>
          </div>

          <InputsCliente>
            <div className="cnpj">
              <label>CNPJ</label>
              <input
                type="text"
                name="documento"
                {...register("documento")}
                placeholder="00.000.000/0000-00"
              />
            </div>
            <div>
              <label>Razão Social</label>
              <input
                type="text"
                name="razao_social"
                {...register("razao_social")}
                placeholder="Razão Social do cliente"
              />
            </div>

            <div>
              <label>Nome Fantasia</label>
              <input
                type="text"
                name="nome_fantasia"
                {...register("nome")}
                placeholder="Nome Fantasia do cliente"
              />
            </div>
            <div>
              <label>Inscrição Municipal</label>
              <input
                type="text"
                name="inscricao_municipal"
                {...register("inscricao_municipal")}
                placeholder="0000000000000"
              />
            </div>
          </InputsCliente>

          <InputsIncricao>
            <div>
              <label>Inscrição Estadual *</label>
              <input
                type="text"
                name="inscricao_estadual"
                {...register("inscricao_estadual")}
                placeholder="0000000000000"
              />
            </div>
            <div>
              <label>Regime Empresa</label>
              <select {...register("regime_empresa")}>
                <option>Selecionar Regime</option>
                {regimesEmpresa &&
                  regimesEmpresa.map((element) => (
                    <option value={element.regime_empresa}>
                      {element.regime_empresa}
                    </option>
                  ))}
              </select>
            </div>
            <div>
              <label>Categoria</label>
              <select {...register("categoria")}>
                <option>Selecionar Categoria</option>
                <option value="Fabricante">Fabricante</option>
                <option value="Revendedor">Revendedor</option>
                <option value="Importador">Importador</option>
              </select>
            </div>
            <div>
              <label>Segmento</label>
              <select {...register("segmento_fornecedor_id")}>
                <option>Selecionar Segmento</option>
                {categorias &&
                  categorias.map((element) => (
                    <option value={element.id}>{element.nome}</option>
                  ))}
              </select>
            </div>
          </InputsIncricao>
          <div className="credita-icms">
            <div className="div-label-icms">
              <div className="column">
                <label className="nome-fornecedor">Credita ICMS?</label>
                <div className="flex checkboxs">
                  <div className="right" onClick={() => setAdicionarIcms(true)}>
                    <input
                      className="right-5"
                      type="checkbox"
                      checked={adicionarIcms}
                    />
                    <label>Sim?</label>
                  </div>
                  <div onClick={() => setAdicionarIcms(false)}>
                    <input
                      className="right-5"
                      checked={adicionarIcms ? false : true}
                      type="checkbox"
                    />
                    <label>Não?</label>
                  </div>
                  {adicionarIcms && (
                    <div className="div-icms">
                      <label>ICMS:</label>
                      <input
                        type="number"
                        name="icms"
                        placeholder="0%"
                        min={0}
                        step={0.0001}
                        {...register("icms")}
                        className="input-icms"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="dadosClientes">
            <h2> Dados do fornecedor</h2>
            <InputsEndereco>
              <div className="cep">
                <label>CEP</label>
                <input
                  type="text"
                  name="codigo_postal"
                  {...register("codigo_postal")}
                  placeholder="00000-000"
                  onBlur={(e) => checkCEP(e)}
                />
              </div>
              <div>
                <label>Logradouro</label>
                <input
                  type="text"
                  name="logradouro"
                  {...register("logradouro")}
                  placeholder="Avenida, rua, travessa"
                />
              </div>
              <div>
                <label>Número</label>
                <input
                  type="text"
                  name="numero_logradouro"
                  {...register("numero_logradouro")}
                  placeholder="0000"
                />
              </div>
            </InputsEndereco>

            <InputsEndereco1>
              <div>
                <label>Complemento</label>
                <input
                  type="text"
                  name="complemento"
                  {...register("complemento")}
                  placeholder="Sala, andar"
                />
              </div>
              <div>
                <label>Bairro</label>
                <input
                  type="text"
                  name="bairro"
                  {...register("bairro")}
                  placeholder="Bairro"
                />
              </div>
              <div>
                <label>Cidade</label>
                <input
                  type="text"
                  name="cidade"
                  {...register("cidade")}
                  placeholder="Cidade"
                />
              </div>
              <div>
                <label>Estado</label>
                <select
                  type="text"
                  name="estado"
                  {...register("estado")}
                  placeholder="UF"
                >
                  <option>Selecionar Estado</option>
                  {arrayEstados.map((element) => (
                    <option value={element}>{element}</option>
                  ))}
                </select>
              </div>
            </InputsEndereco1>

            <InputsEndereco2>
              <div>
                <label>Nome contato</label>
                <input
                  type="text"
                  name="contato_principal"
                  {...register("contato_principal")}
                  placeholder="Nome do contato fornecedor"
                />
              </div>
              <div className="email">
                <label>E-mail contato </label>
                <input
                  type="email"
                  name="email_principal"
                  {...register("email_principal")}
                  placeholder="email@email.com.br"
                />
              </div>
              <div>
                <label>Celular</label>
                <input
                  type="text"
                  name="telefone_principal"
                  {...register("telefone_principal")}
                  placeholder="(00) 00000-0000"
                />
              </div>
            </InputsEndereco2>
          </div>

          <div className="dadosPagamento">
            <h2> Dados de Pagamento</h2>

            <InputsPagamento>
              <div>
                <label>Nome do responsável financeiro</label>
                <input
                  type="text"
                  name="contato_financeiro"
                  {...register("contato_financeiro")}
                  placeholder="Responsável financeiro"
                />
              </div>
              <div>
                <label>E-mail financeiro</label>
                <input
                  type="email"
                  name="email_financeiro"
                  {...register("email_financeiro")}
                  placeholder="E-mail financeiro"
                />
              </div>
              <div>
                <label>Prazo de Pagamento</label>
                <input
                  type="number"
                  name="prazo_pagamento"
                  {...register("prazo_pagamento")}
                  placeholder="000"
                />
              </div>
            </InputsPagamento>

            <InputsPagamento1>
              <div>
                <label>Banco</label>
                <select name="banco" {...register("banco")}>
                  <option value={1}>Banco do Brasil</option>
                  <option value={2}>Caixa</option>
                  <option value={3}>Citibank</option>
                  <option value={4}>Inter</option>
                  <option value={5}>Itaú</option>
                  <option value={6}>Original</option>
                  <option value={7}>Safra</option>
                  <option value={8}>Santander</option>
                  <option value={9}>ABC</option>
                  <option value={10}>Votorantim</option>
                  <option value={11}>Sicoob</option>
                  <option value={12}>Sicredi</option>
                  <option value={13}>C6</option>
                  <option value={14}>BTG Pactual</option>
                  <option value={15}>Banrisul</option>
                  <option value={16}>Banco do Nordeste</option>
                  <option value={17}>Outro</option>
                </select>
              </div>
              <div>
                <label>Agência</label>
                <input
                  type="text"
                  name="agencia"
                  {...register("agencia")}
                  placeholder="00000"
                />
              </div>
              <div>
                <label>Conta</label>

                <input
                  type="text"
                  name="Conta"
                  {...register("conta")}
                  placeholder="0000000"
                />
              </div>

              <div>
                <label>Pix</label>
                <input
                  type="text"
                  name="pix"
                  {...register("pix")}
                  placeholder="Chave pix"
                />
              </div>
            </InputsPagamento1>
          </div>
        </Body>
        <div className="buttons">
          <Link to="/fornecedores">{"< Voltar"}</Link>
          <button type="submit">Salvar</button>
        </div>
      </div>
    </NovoClienteContainer>
  );
};

export default EditarForncedor;
