import { styled } from "styled-components";

export const NovoUsuarioContainer = styled.form`
  width: calc(100% - 260px);
  display: flex;
  justify-content: center;
  border-radius: 40px;

  height: 100vh;

  .box {
    width: 100%;
    min-height: 300px;
    border-radius: 30px 0px 0px 30px;
    padding: 40px;
    background-color: var(--base01);

    .voltar {
      svg {
        width: 45px;
        height: 45px;
        cursor: pointer;
      }
    }

    h1 {
      font-size: 24px;
      font-weight: 700;
      margin-left: 10px;
      color: var(--principal);
      margin-bottom: 8px;
    }

    .underline {
      font-size: 14px;
      margin-left: 10px;
      text-decoration: underline;
      color: var(--principal);
    }

    .buttons {
      margin-top: 50px;
      display: flex;
      align-items: center;
      gap: 20px;
      a {
        font-size: 0.9rem;
      }
      button {
        background-color: var(--principal);
        color: var(--base01);
        border: none;
        padding: 5px;
        border-radius: 20px;
        width: 10%;
        font-size: 0.8rem;
      }
    }
  }
`;

export const Body = styled.form`
  display: flex;
  flex-direction: column;
  margin-top: 30px;

  .checkbox input {
    display: none;
  }

  .checkbox input + label:before {
    content: "";
    width: 15px;
    height: 15px;
    border-radius: 2px;
    border: 1px solid #979696;
    vertical-align: middle;
    display: inline-block;
    margin-left: 5px;
    margin-right: 5px;
    cursor: pointer;
  }

  .checkbox input:checked + label:before {
    background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='15' viewBox='0 0 10 10'%3E%3Cg class='nc-icon-wrapper' stroke-width='1' fill='%23555555'%3E%3Cpath fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' data-cap='butt' d='M2.83 4.72l1.58 1.58 2.83-2.83'/%3E%3C/g%3E%3C/svg%3E");
    background-color: var(--amarelo);
  }

  .checkbox label {
    font-size: 0.9rem;
  }

  h2 {
    margin: 20px 0px 30px 0px;
  }

  > label {
    margin-bottom: 10px;
    font-size: 0.8rem;
  }

  > input {
    border: none;
    background-color: var(--grayF3);
    padding: 10px;
    border-radius: 6px;
    font-size: 0.8rem;
    outline: none;
  }
`;

export const ContainerInputs = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin: 20px 0 20px 0;
  gap: 50px;

  > div {
    display: flex;
    flex-direction: column;
    width: 100%;

    > label {
      margin-bottom: 10px;
      font-size: 0.8rem;
    }

    > input,
    select {
      border: none;
      background-color: var(--grayF3);
      padding: 10px;
      border-radius: 6px;
      font-size: 0.8rem;
      outline: none;
    }
  }
`;
