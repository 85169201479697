import { ReactSVG } from "react-svg";
import { NotFoundPageContainer } from "./styles";
import logo from "../../assets/logo-vesta.svg";
import notFound from "../../assets/404.svg";
import { useNavigate } from "react-router-dom";

const NotFoundPage = () => {
  const navigate = useNavigate();
  return (
    <NotFoundPageContainer>
      <ReactSVG className="logo" src={logo} />
      <ReactSVG className="notFound" src={notFound} />
      <h3>Página não encontrada 😢</h3>
      <span>
        A página que você tentou acessar está indisponível ou não existe.
      </span>
      <div className="flex">
        <button onClick={() => window.history.back()}>
          Retornar á página anterior
        </button>
        <button onClick={() => navigate("/campanhas")}>
          Ir para a página inicial
        </button>
      </div>
    </NotFoundPageContainer>
  );
};

export default NotFoundPage;
