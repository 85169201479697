import { styled } from "styled-components";

export const ListaCampanhaStyle = styled.div`
  width: 100%;
  margin-bottom: 30px;
  .title {
    font-size: 20px;
    font-weight: 600;
    color: var(--principal);
  }

  a {
    font-size: 14px;
    margin-left: 8px;
    color: #609ae5;
    cursor: pointer;
  }

  .filter {
    display: flex;
    width: 100%;
    overflow-x: auto;
  }
`;
