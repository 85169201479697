import { Link, useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../../../providers/Auth";
import {
  Body,
  InputsTipoDeUsuario,
  InputsUsuarios,
  NovoUsuarioContainer,
} from "./style";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import api from "../../../../services/api";

const EditarUsuario = () => {
  const { id } = useParams();
  const [perfils, setPerfils] = useState([]);
  const { user } = useAuth();

  const {
    reset,
    // formState: { errors },
  } = useForm({
    // resolver: yupResolver(NovoCadastroSchema),
  });

  const navigate = useNavigate();
  const [usuario, setUsuario] = useState({
    sobrenome: "",
    departamento: "",
    nome: "",
    senha: "",
    email: "",
    status: true,
    perfil_id: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUsuario({ ...usuario, [name]: value });
  };

  const onSubmitFunction = (e) => {
    e.preventDefault();
    api
      .patch(`/usuarios/${id}`, { ...usuario })
      .then((response) => {
        navigate("/usuarios");
        toast.success("Usuário atualizado com sucesso!");
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  //BUSCAR PERFILS
  function buscarPerfis() {
    api
      .get("/perfis")
      .then((response) => {
        setPerfils(response.data.data.perfis);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  function buscarUsuarioId() {
    api
      .get(`/usuarios/${id}`)
      .then((response) => {
        setUsuario(response.data.data.usuario);
        reset();
      })
      .catch((error) => {
        console.error(error);
      });
  }

  useEffect(() => {
    buscarPerfis();
    buscarUsuarioId();
  }, []);

  return (
    <NovoUsuarioContainer onSubmit={onSubmitFunction}>
      <div className="box">
        <div>
          <h1> Editar usuário</h1>
        </div>

        <Body>
          <div className="checkbox">
            <input
              type="checkbox"
              id="checkbox1"
              name="status"
              checked={usuario.status}
              onChange={(e) =>
                setUsuario({ ...usuario, status: e.target.checked })
              }
            />

            <label htmlFor="checkbox1">Ativo</label>
          </div>
          <InputsUsuarios>
            <div>
              <label>Nome </label>
              <input
                type="text"
                name="nome"
                value={usuario.nome}
                onChange={handleInputChange}
                placeholder="Nome "
              />
            </div>
          </InputsUsuarios>

          <InputsTipoDeUsuario>
            <div>
              <label>E-mail</label>
              <input
                type="email"
                name="email"
                value={usuario.email}
                onChange={handleInputChange}
                placeholder="email@email.com.br"
              />
            </div>
            <div>
              <label>Senha</label>
              <input
                type="password"
                name="senha"
                value={usuario.senha}
                onChange={handleInputChange}
                placeholder="********"
              />
            </div>
            <div>
              <label>Perfil de acesso</label>
              <select
                name="perfil_id"
                value={usuario.perfil_id}
                onChange={handleInputChange}
              >
                <option>Selecione o perfil</option>
                {perfils.map((data) => (
                  <option key={data.id} value={data.id}>
                    {data.nome}
                  </option>
                ))}
              </select>
            </div>
          </InputsTipoDeUsuario>
        </Body>
        {user && user.permissoes.includes("usuario_atualizar") && (
          <div className="buttons">
            <button type="submit">Salvar</button>
          </div>
        )}
      </div>
    </NovoUsuarioContainer>
  );
};

export default EditarUsuario;
