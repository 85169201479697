import { Link, useNavigate, useParams } from "react-router-dom";
import { Body, ContainerInputs, NovoUsuarioContainer } from "./styles";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import voltar from "../../../../assets/voltar.svg";
import { CampoObrigatorio } from "../../../../schemas/novoCadastro";
import { toast } from "react-toastify";
import api from "../../../../services/api";
import { ReactSVG } from "react-svg";
import { useAuth } from "../../../../providers/Auth";
import { Inputs } from "../EditarCategoria/styles";

const EditarImposto = () => {
  const { id } = useParams();
  const [categorias, setCategorias] = useState([]);
  const navigate = useNavigate();
  const { user } = useAuth();

  const { register, getValues, handleSubmit, reset } = useForm();

  //ATUALIZA ITEM
  const onSubmitFunction = () => {
    const formValues = getValues();

    api
      .patch(`/regimes-empresa/${id}`, formValues)
      .then((response) => {
        navigate("/imposto");

        toast.success("Item atualizado com sucesso!");
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  //BUSCA TODAS AS CATEGORIA
  function buscarCategoriaItem() {
    api
      .get("/categoria-item")
      .then((response) => {
        setCategorias(response.data.data.categorias);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  //BUSCA ITEM PELO ID
  function buscaItemId() {
    api
      .get(`/regimes-empresa/${id}`)
      .then((response) => {
        reset(response.data.data.regimeEmpresa);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  useEffect(() => {
    buscaItemId();
    buscarCategoriaItem();
  }, [id]);

  return (
    <NovoUsuarioContainer onSubmit={handleSubmit(onSubmitFunction)}>
      <div className="box">
        <div className="flex">
          <ReactSVG
            onClick={() => navigate("/imposto")}
            className="voltar"
            src={voltar}
          />
          <div>
            <h1>Imposto</h1>
            <span className="underline">Novo Imposto</span>
          </div>
        </div>
        <Body>
          <div className="checkbox">
            <input
              type="checkbox"
              id="checkbox1"
              name="status"
              {...register("status")}
              defaultChecked={true}
            />

            <label htmlFor="checkbox1">Ativo</label>
          </div>
          <Inputs>
            <div>
              <label>Regime da Empresa *</label>
              <input
                type="text"
                name="nome"
                {...register("regime_empresa")}
                placeholder="Simples, pressumido ou real..."
              />
            </div>
            <div>
              <label>PIS *</label>
              <input
                {...register("pis")}
                type="number"
                min={0}
                placeholder="0%"
              />
            </div>
            <div>
              <label>COFINS *</label>
              <input
                {...register("cofins")}
                type="number"
                min={0}
                placeholder="0%"
              />
            </div>
          </Inputs>
        </Body>
        <div className="buttons">
          {user && user.permissoes.includes("itens_criar") && (
            <button type="submit">Salvar</button>
          )}
        </div>
      </div>
    </NovoUsuarioContainer>
  );
};

export default EditarImposto;
