import { styled } from "styled-components";

export const TableContainer = styled.div`
  height: 70%;
  overflow: auto;

  h2 {
    color: var(--principal);
    font-size: 18px;
    font-weight: 600;
  }

  .titulo {
    height: 55px;
    padding-bottom: 16px;
  }

  a {
    margin-left: 10px;
  }

  .card {
    width: 510px;
    padding: 25px 20px;
    background-color: var(--base);
    border-radius: 8px;
    margin: 0px 25px 25px 0px;
    cursor: pointer;
  }

  .top-card {
    display: flex;
    margin-bottom: 25px;

    h3 {
      font-weight: 600;
      font-size: 14px;
      margin-right: 4px;
    }
  }

  .nome {
    font-size: 14px;
  }

  .lista {
    width: 100%;
    flex-wrap: wrap;
  }

  &::-webkit-scrollbar {
    width: 8px;
    border-radius: 8px;
    background-color: var(--base01);
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--principal);
    border-radius: 5px;
  }

  > table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    padding: 10px;

    > thead > tr > th {
      font-weight: 600;
      padding: 20px;
      padding: 10px;
      font-size: 0.8rem;
    }
    > tbody > tr > td {
      border-bottom: 1px solid var(--grayDA);
      border-top: 1px solid var(--grayA5);
      padding: 15px;
      font-size: 0.8rem;
    }
  }

  .ativo {
    display: flex;
    align-items: center;
    justify-content: center;

    > p {
      font-size: 0.8rem;
      width: 60px;
    }
  }

  a {
    color: var(--azul);
    font-size: 0.8rem;
  }

  .justify {
    justify-content: space-between;
  }

  .status {
    font-size: 14px;
    padding: 8px 12px;
    border-radius: 12px;
  }

  .aguardando {
    background-color: #ef5713;
    font-size: 13px;
    font-weight: 600;
    padding: 6px 12px;
    border-radius: 18px;
    color: white;
  }

  .produzindo {
    background-color: #609ae5;
    font-size: 13px;
    font-weight: 600;
    padding: 6px 12px;
    border-radius: 18px;
    color: white;
  }

  .concluido {
    background-color: black;
    font-size: 13px;
    font-weight: 600;
    padding: 6px 12px;
    border-radius: 18px;
    color: white;
  }

  .aviso {
    font-size: 26px;
    font-weight: 800;
    display: flex;
    justify-content: center;
    margin-top: 36px;
  }

  .flex-space {
    display: flex;
    justify-content: space-between;
  }
`;
