import styled from "styled-components";

export const DisputaPageStyle = styled.div`
  display: flex;
  background-color: var(--base01);
  border-radius: 60px 0px 0px 60px;

  .body {
    width: 84%;
    padding: 35px;
    border-radius: 60px 0px 0px 60px;
  }

  .editar {
    padding: 4px 8px !important;
    font-size: 14px !important;
  }

  .div-prazo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 85%;
    margin: 3px auto 0px;

    h3 {
      font-size: 16px !important;
      font-weight: 600;
    }
  }

  .select {
    width: 100%;
    border-radius: 6px;
    border: none;
    padding: 6px;
    margin-top: 10px;
  }
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

  .margin-right {
    margin-right: 15px;
  }

  .div-detalhes {
    display: flex;
    justify-content: space-between;
  }

  .back {
    cursor: pointer;
    svg {
      width: 35px;
      height: 35px;
    }
  }

  .div-detalhes {
    display: flex;
    width: 80%;
  }

  .detalhes {
    margin-top: 6px;
    margin-left: 12px;
  }

  h1 {
    font-size: 30px;
    font-weight: 600;
    color: #640000;
  }

  .nome-campanha {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 24px;
    margin-top: 6px;
    color: #640000;
  }

  .column {
    display: flex;
    flex-direction: column;
    width: 70%;
    width: max-content;

    .prazo_proposta {
      display: flex;
      justify-content: center;
      align-items: baseline;
      h2 {
        padding-top: 30px;
        font-size: 14px;
        font-weight: 600;
      }

      h3 {
        font-size: 13px;
        margin-left: 5px;
      }

      span {
        color: #609ae5;
        font-size: 10px;
        text-decoration: underline;
        margin-left: 5px;
      }
    }
    .prazo {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      h2 {
        padding-top: 30px;
        font-size: 14px;
        font-weight: 600;
      }

      h3 {
        font-size: 13px;
        margin-left: 5px;
      }

      span {
        color: #609ae5;
        font-size: 10px;
        text-decoration: underline;
        margin-left: 5px;
      }
    }

    input {
      border-radius: 6px;
      border: none;
      padding: 6px;
      margin-right: 13px;
    }

    button {
      border: none;
      border-radius: 8px;
      background-color: var(--amarelo);
      color: var(--principal);
      font-size: 12px;
      font-weight: 600;
      padding: 8px;
    }
  }

  .prazo_proposta {
    display: flex;
    justify-content: center;
    align-items: baseline;
    h2 {
      font-size: 14px;
      font-weight: 600;
    }

    h3 {
      font-size: 13px;
      margin-left: 5px;
    }

    span {
      color: #609ae5;
      font-size: 10px;
      text-decoration: underline;
      margin-left: 5px;
    }
  }
  .prazo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h2 {
      padding-top: 30px;
      font-size: 14px;
      font-weight: 600;
    }

    h3 {
      font-size: 13px;
      margin-left: 5px;
    }

    span {
      color: #609ae5;
      font-size: 10px;
      text-decoration: underline;
      margin-left: 5px;
    }
  }

  input {
    border-radius: 6px;
    border: none;
    padding: 6px;
    margin-right: 13px;
  }

  button {
    border: none;
    border-radius: 8px;
    background-color: var(--amarelo);
    color: var(--principal);
    font-size: 12px;
    font-weight: 600;
    padding: 8px;
  }

  .percentage-input {
    position: relative;
  }

  .percentage-input input {
    padding-right: 20px;
  }

  .percentage-input::after {
    content: "%";
    position: absolute;
    right: 90px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    font-size: 14px;
    color: #640000;
  }

  .div-dados {
    display: flex;
    flex-direction: column;

    h2 {
      display: flex;
      font-size: 16px;
      justify-content: center;
    }

    h3 {
      font-size: 14px;
      font-weight: 600;
    }

    h4 {
      font-size: 14px;
    }

    .solicitante {
      display: flex;
      margin-top: 10px;
      h3 {
        margin-right: 5px;
      }
      h2 {
        font-size: 14px;
      }
    }
  }

  .faixa {
    display: flex;
    width: 100%;
    padding: 12px 10px;
    background-color: var(--principal);
    align-items: center;
    border-radius: 5px;
    margin-top: 30px;
    justify-content: space-between;

    span {
      color: var(--amarelo);
      font-size: 20px;
      margin-right: 6px;
    }

    h2 {
      font-size: 16px;
      color: var(--base);
      margin-left: 10px;
    }

    h4 {
      font-size: 16px;
      color: var(--amarelo);
    }
  }

  .div-especificao {
    width: 300px;
    margin-top: 20px;

    h3 {
      font-weight: 600;
      margin-bottom: 6px;
      font-size: 14px;
    }

    h4 {
      font-size: 12px;
    }
  }

  .div-tipo {
    margin-top: 20px;
    width: max-content;

    h3 {
      font-weight: 600;
      margin-bottom: 6px;
      font-size: 14px;
    }

    h4 {
      font-size: 12px;
    }
  }

  .div-ncm {
    margin-top: 20px;
    width: max-content;

    h3 {
      font-weight: 600;
      margin-bottom: 6px;
      font-size: 14px;
    }

    h4 {
      font-size: 12px;
    }
  }

  .div-quantidade {
    display: flex;
    flex-direction: column;
    margin-top: 20px;

    h3 {
      font-size: 14px;
      font-weight: 600;
    }

    label {
      margin-left: 6px;
      font-size: 14px;
    }
  }

  .div-detalhes-proposta {
    width: 90%;
    justify-content: space-around;
  }

  .marcas {
    font-size: 14px;
    margin: 0px 3px;
  }

  .aling-itens {
    display: flex;
    align-items: flex-end;
  }

  .div-fee {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    h5 {
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 10px;
    }

    input {
      border-radius: 4px;
      border: none;
      padding: 0px 20px 0px 6px;
      height: 30px;
      width: 100px;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
        rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    }

    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Remove as setas no Firefox */
    input[type="number"] {
      -moz-appearance: textfield;
    }

    button {
      border: none;
      background-color: var(--amarelo);
      color: var(--principal);
      border-radius: 8px;
      padding: 4px;
      font-size: 12px;
      font-weight: 600;
      margin-left: 10px;
    }
  }

  input[type="radio"] {
    cursor: pointer;
  }

  /* input[type="radio"] + label {
    cursor: pointer;
  } */
`;
