import { ListaVitrine } from "../../components/ListaVitrine";
import Menu from "../../components/Menu";
import { VitrinePageStyle } from "./style";

export const VitrinePage = () => {
  return (
    <VitrinePageStyle>
      <Menu />
      <ListaVitrine />
    </VitrinePageStyle>
  );
};
