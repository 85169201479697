import { toast } from "react-toastify";
import api from "../../../services/api";
import { FormPropostaFornecedorPage } from "../../Forms/FormPropostaFornecedor";
import { ModalCriarOrcamentoStyle } from "./style";
import { useEffect, useState } from "react";

export const ModalCriarOrcamento = ({
  state,
  dados,
  buscarMaterialBriefing,
  quantidade,
}) => {
  const [campanha, setCampanha] = useState();
  const [dadosFornecedor, setDadosFornecedor] = useState();
  const [categoriaFornecedor, setCategoriaFornecedor] = useState();
  const [orcamentos, setOrcamentos] = useState();
  const [materialBriefing, setMaterialBriefing] = useState();

  // dados.fornecedor.fornecedor_id = dados.fornecedor.id;
  // dados.fornecedor.fornecedor_nome = dados.fornecedor.nome;

  function buscarCampanhas() {
    api
      .get(`/campanhas/${dados.materialBriefing.campanhas_id}`)
      .then((response) => {
        setCampanha(response.data.data.campanha[0]);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  function buscarCampanhasFornecedor(idFornecedor) {
    api
      .get(
        `/campanha-material-briefing/fornecedores/fornecedor/${idFornecedor}`
      )
      .then((response) => {
        const filtrarPorCampanha = response.data.data.fornecedores.filter(
          (briefing) =>
            Number(briefing.campanhas_id) ==
            Number(dados.materialBriefing.campanhas_id)
        );
        setMaterialBriefing(
          filtrarPorCampanha.filter(
            (briefing) => briefing.id == dados.materialBriefing.id
          )
        );
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  function buscarDadosFornecedor(id) {
    api
      .get(`/fornecedores/${id}`)
      .then((response) => {
        setDadosFornecedor(response.data.data.fornecedor);
        buscarCategoriaFornecedor(
          response.data.data.fornecedor.segmento_fornecedor_id
        );
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  function buscarCategoriaFornecedor(id) {
    api
      .get(`/categoria-fornecedor/${id}`)
      .then((response) => {
        setCategoriaFornecedor(response.data.data.categoria.nome);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  function buscarOrcamentoBriefing(id) {
    api
      .get(`/orcamento-materiais-briefing/fornecedor/${id}`)
      .then((response) => {
        const data = response.data.data.orcamentos;
        setOrcamentos(data);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  function buscarFornecedor() {
    console.log(dados.fornecedor.id);
  }

  useEffect(() => {
    buscarCampanhas();
  }, []);

  useEffect(() => {
    buscarDadosFornecedor(dados.fornecedor.id);
    buscarOrcamentoBriefing(dados.fornecedor.id);
    buscarCampanhasFornecedor(dados.fornecedor.id);
  }, []);

  return (
    <ModalCriarOrcamentoStyle>
      <div className="background">
        <span className="close" onClick={() => state(false)}>
          X
        </span>
        <div className="overflow">
          {materialBriefing &&
            materialBriefing.map((element, index) => {
              console.log(
                (element.quantidades = element.quantidades.filter(
                  (elementQuantidade) =>
                    elementQuantidade.quantidade == quantidade
                ))
              );

              return (
                <FormPropostaFornecedorPage
                  element={element}
                  fornecedor={dados.fornecedor}
                  index={index}
                  length={materialBriefing.length}
                  campanha={campanha}
                  orcamentos={orcamentos}
                  dadosFornecedor={dadosFornecedor}
                  categoriaFornecedor={categoriaFornecedor}
                  buscarFornecedor={buscarFornecedor}
                  admin={true}
                  buscarMaterialBriefing={buscarMaterialBriefing}
                  quantidade={quantidade}
                />
              );
            })}
        </div>
      </div>
    </ModalCriarOrcamentoStyle>
  );
};
