import { useForm } from "react-hook-form";
import { PedirRevisaoStyle } from "./style";
import api from "../../../services/api";
import { toast } from "react-toastify";
import { useAuth } from "../../../providers/Auth";

export const PedirAprovacaoCompras = ({
  state,
  orcamento,
  materialBriefing,
  quantidade,
}) => {
  const { register, handleSubmit, getValues } = useForm();
  const { user } = useAuth();

  const enviarRevisao = () => {
    //Colocando os dados que já tenho que são necessário para o Email
    orcamento.justificativa = getValues("revisao");
    orcamento.produtor_nome = user.nome;
    orcamento.material = materialBriefing.nome + " " + materialBriefing.subnome;
    orcamento.campanha_nome = materialBriefing.campanha_nome;
    orcamento.quantidade = quantidade;
    orcamento.produtor_id = materialBriefing.produtor_id;

    api
      .post(`/email/pedir-aprovacao-compras`, orcamento)
      .then((response) => {
        state(false);
        toast.success("Revisão Enviada 🎉");
      })
      .catch((error) => {
        console.error("Email Revisao de briefing Erro:", error);
        toast.error("Erro ao enviar revisão. Tente novamente.");
      });

    api.patch(`/orcamento-materiais-briefing/${orcamento.id}`, {
      status: "Revisão Compras",
    });
  };

  return (
    <PedirRevisaoStyle>
      <form className="background" onSubmit={handleSubmit(enviarRevisao)}>
        <h2>
          O orçamento selecinado {orcamento.fornecedor_nome} não tem o melhor
          preço, escreva uma justificativa para compras explicando sua escolha
        </h2>
        <textarea placeholder="Justificativa..." {...register("revisao")} />
        <h3>* Compras irá analisar sua justificativa e fará um veretido</h3>
        <button>Enviar</button>
        <span className="close" onClick={() => state(false)}>
          X
        </span>
      </form>
    </PedirRevisaoStyle>
  );
};
