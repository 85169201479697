import styled from "styled-components";

export const ModalAdicionarFornecedoresBriefingStyle = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  z-index: 2;

  .flex {
    justify-content: none !important;
  }

  .div-body {
    width: 90%;
    max-width: 700px;
    padding: 30px;
    background-color: var(--base01);
    margin: 0 auto;
    border-radius: 8px;
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 607px;
  }

  .close {
    position: absolute;
    top: 0px;
    right: 0px;
    color: var(--base01);
    background-color: #fa5252;
    padding: 11px 13px;
    font-size: 30px;
    font-weight: 300;
    border-radius: 30px;
  }

  .width-90 {
    width: 100%;
    display: flex;
    justify-content: space-between !important;
  }

  .header-tabela {
    border-bottom: 1px solid var(--gray70);
    padding: 10px 15px;
  }

  .check {
    width: 115px;
    height: 14px;
  }

  .id-fornecedor {
    font-weight: 500;
  }

  .nome-fornecedor {
    font-weight: 500;
    font-size: 14px;
    width: 150px;
  }

  .fornecedor {
    width: 150px;
  }

  .selecionar-todos {
    font-size: 11px;
    color: #609ae5;
    text-decoration: underline;
    cursor: pointer;
  }

  .enviar {
    position: unset;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px auto 0px;
  }

  .aviso {
    display: flex;
    justify-content: center;
    padding: 20px 10px;
    font-size: 18px;
    font-weight: 600;
    color: var(--principal);
  }

  .input-text {
    margin-top: 10px;
    border: none;
    border-radius: 8px;
    background-color: white;
    padding: 8px 16px;
    width: 40%;
    min-width: 400px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
      rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  }

  .div-column {
    span {
      font-size: 14px;
    }

    select {
      border: none;
      border-radius: 6px;
      padding: 4px 4px;
      color: var(--base);
      background-color: var(--amarelo);
      font-size: 14px;
      margin-top: 3px;
    }
  }

  .h3-top {
    color: var(--principal);
  }
`;
