import { Link } from "react-router-dom";
import { useAuth } from "../../../providers/Auth";
import { TableContainer } from "./styles";
import { ReactSVG } from "react-svg";
import ativo from "../../../assets/ativo.svg";
import inativo from "../../../assets/inativo.svg";
import { useEffect, useState } from "react";
import api from "../../../services/api";
import { toast } from "react-toastify";

const Table = ({ perfilSelecionado, textoPesquisa }) => {
  const [clientes, setClientes] = useState([]);

  function buscarClientes() {
    api
      .get("/clientes")
      .then((response) => {
        setClientes(response.data.data.clientes);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  useEffect(() => {
    buscarClientes();
  }, []);

  const colunas = [
    "Id",
    "Nome do cliente",
    "Nome Fantasia",
    "CNPJ",
    "Pagamento",
    "Status",
  ];
  const filtrarClientes = (usuario) => {
    const perfilFiltrado =
      !perfilSelecionado || usuario.status === parseInt(perfilSelecionado);
    const pesquisaFiltrada =
      !textoPesquisa ||
      usuario.razao_social.toLowerCase().includes(textoPesquisa.toLowerCase());
    const idUsuario = parseInt(textoPesquisa);
    const idFiltrado = !isNaN(idUsuario) && usuario.id === idUsuario;

    return perfilFiltrado && (pesquisaFiltrada || idFiltrado);
  };

  const clientesFiltrados = clientes.filter(filtrarClientes);

  return (
    <TableContainer>
      <table>
        <thead>
          <tr>
            {colunas.map((coluna) => (
              <th key={coluna}>{coluna}</th>
            ))}
          </tr>
        </thead>
        {clientesFiltrados !== "" ? (
          <tbody>
            {clientesFiltrados.map((lista) => (
              <tr className="map" key={lista.id}>
                <td>#{lista.id}</td>
                <td>{lista.razao_social}</td>
                <td>{lista.nome_fantasia}</td>

                <td>{lista.documento}</td>

                {lista.status === 1 && (
                  <td>
                    <div className="ativo">
                      <ReactSVG src={ativo} />
                      <p>ativo</p>
                    </div>
                  </td>
                )}
                {lista.status === 0 && (
                  <td>
                    <div className="ativo">
                      <ReactSVG src={inativo} />
                      <p>inativo</p>
                    </div>
                  </td>
                )}
                <td className="td-editar">
                  <Link
                    to={`/cliente/editar/${lista.id}`}
                    className="editar"
                    htmlFor="editar"
                  >
                    Abrir
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        ) : (
          <p>nenhum cliente encontrado</p>
        )}
      </table>
      {/* {perfilSelecionado === "" && <p>nenhum cliente encontrado</p>} */}
    </TableContainer>
  );
};

export default Table;
