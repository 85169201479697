import Marcas from "../../components/Marcas";
import Header from "../../components/Menu";
import Ncm from "../../components/Ncm";
const NcmPage = () => {
  return (
    <div className="flex">
      <Header />
      <Ncm />
    </div>
  );
};

export default NcmPage;
