import styled from "styled-components";

export const InputRadioStyled = styled.div`
  margin-top: 10px;
  width: max-content;

  div {
    margin-right: 10px;
  }
`;
