import NovoItemVitrine from "../../../components/Cadastros/Novo/NovoItemVitrine";
import Menu from "../../../components/Menu";

export const NovoItemVitrinePage = () => {
  return (
    <div className="flex">
      <Menu />
      <NovoItemVitrine />
    </div>
  );
};
