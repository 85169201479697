import styled from "styled-components";

export const HistoricoCampanhaStyled = styled.div`
  width: 90%;
  margin: 40px auto 80px;
  position: relative;
  background-color: var(--base);
  padding: 30px 30px;
  border-radius: 20px;

  h2 {
    color: var(--principal);
    font-weight: 600;
    margin-right: 10px;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    text-align: center;
    padding: 10px;

    > thead > tr > th {
      font-weight: 600;
      padding: 20px;
      padding: 10px;
      font-size: 0.8rem;
    }
    > tbody > tr > td {
      border-bottom: 1px solid var(--grayDA);
      border-top: 1px solid var(--grayA5);
      padding: 15px;
      font-size: 0.8rem;
    }
  }

  .left {
    text-align: left;
  }

  .ver {
    font-size: 0.9em;
    text-decoration: underline;
    color: #609ae5;
    cursor: pointer;
  }
`;
