import styled from "styled-components";

export const VitrinePageStyle = styled.div`
  display: flex;
  width: 100%;
  background-color: var(--base0);
  border-radius: 30px;

  .sc-dIvsgl {
    max-width: none;
  }
`;
