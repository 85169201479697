import EditarUsuario from "../../../components/Cadastros/Editar/EditarUsuario";
import Header from "../../../components/Menu";

const EditarUsuarioPage = () => {
  return (
    <div className="flex">
      <Header />
      <EditarUsuario />
    </div>
  );
};

export default EditarUsuarioPage;
