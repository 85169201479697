import { styled } from "styled-components";

export const FormPropostaFornecedorPageStyle = styled.div`
  .div-top {
    width: 100%;
    background-color: var(--principal);
    padding: 10px 20px;
    border-radius: 8px;

    h2 {
      color: var(--amarelo);
      font-weight: 600;
    }
  }

  .editar {
    display: flex;
    flex-direction: unset;
    background-color: var(--amarelo);
    color: white;
    padding: 5px 16px;
    border-radius: 32px;
    cursor: pointer;
    font-size: 18px;
    font-weight: 600;
    width: max-content;
  }

  .observacao {
    width: 100%;
  }

  input {
    font-size: 14px;
  }

  select {
    font-size: 12px;
  }

  .nome {
    margin-left: 10px;
    color: var(--base);
  }

  .body {
    width: 100%;
    padding: 20px 30px;
  }

  .titulo-especificacao {
    font-weight: 600;
  }

  .especificacao {
    font-size: 12px;
    width: 550px;
  }

  .font-size {
    font-size: 12px;
  }

  .weight {
    font-weight: 700;
    margin-top: 20px;
  }

  .justify {
    justify-content: space-between;
  }

  .right {
    display: flex;
    justify-content: space-between;
    width: 450px;

    h5 {
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 10px;
    }

    span {
      font-size: 13px;
    }
  }

  .prazo {
    color: var(--base);
  }

  .dias {
    font-size: 12px;
    margin-right: 80px;
  }

  .justify {
    display: flex;
  }

  .titulo-prazo {
    color: var(--base);
    margin-right: 10px;
    font-weight: 600;
  }

  h6 {
    background: var(--base);
    padding: 7px;
    border-radius: 8px;
    text-align: -webkit-center;
    font-size: 14px;
  }

  .margin-button {
    margin-bottom: 8px;
    font-size: 14px;
  }

  .flex-end {
    text-align: end;
    margin-top: 20px;
  }
  .arquivo-impressao {
    color: #609ae5;
    font-size: 14px;
    margin-bottom: 10px;
    cursor: pointer;
  }
`;

export const FormStyle = styled.form`
  width: 90%;
  margin: 20px auto;

  h4 {
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  select {
    border: none;
    border-radius: 8px;
    padding: 6px 10px;
    width: 100%;
  }

  input {
    width: 100%;
    border: none;
    border-radius: 8px;
    padding: 6px 10px;
  }

  .flex {
    justify-content: space-between;
  }

  .div-categoria {
    width: 21%;
  }

  .div-estado {
    width: 14%;
  }

  .div-icms {
    width: 13.6%;
  }

  .div-ipi {
    width: 13.6%;
  }

  .div-icms-st {
    width: 13.6%;
  }

  .width {
    width: 100%;
    text-align: left;

    margin-bottom: 20px;
  }

  .div-observacao {
    margin-top: 20px;
    width: 100%;

    input {
      font-size: 15px;
    }

    h4 {
      font-size: 14px;
    }
  }

  .enviar {
    border: none;
    border-radius: 30px;
    background-color: var(--amarelo);
    color: var(--principal);
    padding: 4px 40px;
    font-weight: 600;
    height: max-content;
    cursor: pointer;
  }

  .div-validacao {
    h4 {
      width: 320px;
      font-size: 14px;
    }
    width: 280px;
  }

  .h4-diminuir {
    width: 223px !important;
  }

  .twogrid {
    grid-template-columns: repeat(2, 1fr);

    .enviar {
      width: max-content !important;
      display: flex;
      align-items: center;
      margin: 0 75%;
    }

    .editar {
      width: max-content !important;
      display: flex;
      align-items: center;
      margin: 0 75%;
    }
  }

  .div-final {
    align-items: center;
    margin-top: 30px;
  }

  .h3-total {
    background: var(--base);
    padding: 7px;
    border-radius: 12px;
    text-align: -webkit-center;
    font-size: 10px;
  }

  .label-observacoes {
    display: flex;
    margin-left: 26px;
  }

  .font-weight {
    font-weight: 600;
    margin-right: 30px;
  }

  .input-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 450px;
    border-radius: 6px;
    cursor: pointer;

    > input {
      display: none;
    }

    label {
      display: flex;
      align-items: center;
      color: #979696;
      font-size: 0.9em;
      width: 100%;
      height: 100%;
      cursor: pointer;
      padding: 10px 8px;
      margin-right: 8px;
      background: rgba(165, 165, 165, 0.1);
    }

    .adicionar_nf {
      background-color: var(--amarelo);
      width: max-content;
      padding: 12px 20px;
      border-radius: 8px;
      color: var(--principal);
      font-weight: 600;
    }

    .pesquisar {
      padding: 10px;
      width: 100px;
      height: 100%;
      font-size: 0.9em;
      background: rgba(128, 131, 132, 0.39);
      border-radius: 6px;
      border: none;
      color: #707070;
      font-weight: 600;
    }
  }

  .div-nf {
    h4 {
      font-size: 14px;
    }
    div {
      display: flex;
    }
  }

  .div-nota {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
  }

  .pdf {
    border: none;
    text-decoration: underline;
    color: #609ae5;
    margin-left: 4px;
    cursor: pointer;
  }

  .column {
    div {
      margin-bottom: 16px;
      position: relative;
      width: max-content;
    }

    a {
      color: #609ae5;
      font-size: 16px;
      margin-bottom: 10px;
      cursor: pointer;
    }
  }
  .close {
    position: absolute;
    right: -5px;
    top: -14px;
    /* color: #e20613; */
    font-weight: 600;
  }

  .vertical {
    display: flex;
    flex-direction: column;
  }

  .input-prazo {
    width: 175px;
  }
`;

export const TabelaStyle = styled.div`
  margin-top: 30px;

  .flex {
    align-items: center;
    justify-content: space-between;
  }

  h4 {
    text-align: center;
  }

  .h5 {
    font-size: 14px;
  }

  input {
    width: 100%;
    border: none;
    text-align: center;
  }

  .linha {
    padding: 10px 0px 10px 0px;
    border-bottom: solid 1px var(--grayDA);
  }

  .check {
    border-radius: 4px;
  }

  .participar {
    width: 16%;
  }

  .quantidade {
    width: 26%;
    align-items: flex-start;
  }

  .valor-unitario {
    width: 16%;
  }

  .valor-total {
    width: 16%;
  }

  .valor {
    /* margin-left: 20px; */
  }

  .input-prazo {
    width: 155px;
  }

  .border-bottom {
    border-bottom: 1px solid var(--gray66);
  }

  .flex-start {
    text-align: start;
  }
`;

export const TableContainer = styled.div`
  height: 70%;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 8px;
    border-radius: 8px;
    background-color: var(--base01);
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--principal);
    border-radius: 5px;
  }

  > table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    text-align: center;
    padding: 10px;

    > thead > tr > th {
      font-weight: 600;
      padding: 20px;
      padding: 10px;
      font-size: 0.8rem;
    }
    > tbody > tr > td {
      border-bottom: 1px solid var(--grayDA);
      border-top: 1px solid var(--grayA5);
      padding: 15px;
      font-size: 0.8rem;
    }
  }

  .ativo {
    display: flex;
    align-items: center;
    justify-content: center;

    > p {
      font-size: 0.8rem;
      width: 60px;
    }
  }

  a {
    color: #609ae5;
    font-size: 0.8rem;
    cursor: pointer;
  }
`;
