import Menu from "../../../Menu";
import Botao from "../../../../assets/voltar.svg";
import { BriefingStyle } from "./style";
import { ReactSVG } from "react-svg";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import api from "../../../../services/api";
import { toast } from "react-toastify";
import { FormBrinde } from "../../../Forms/FormBrindeBriefing";
import { useAuth } from "../../../../providers/Auth";
import { FormPontoDeVenda } from "../../../Forms/FormPontoDeVenda";
import { FormMaterialImpresso } from "../../../Forms/FormMaterialImpresso";
import { FormComunicacaoVisual } from "../../../Forms/FormComunicacaoVisualBriefing";
import { FormServico } from "../../../Forms/FormServico";
import { PedirRevisaoModal } from "../../../Modal/PedirRevisaoModal";

const BriefingPageEditar = () => {
  const { user } = useAuth();
  const { id, categoria } = useParams();
  const [itens, setItens] = useState([]);
  const [clienteId, setClienteId] = useState();
  const [categorias, setCategorias] = useState([]);
  const [dataBriefing, setDataBriefing] = useState();
  const [campanha, setCampanha] = useState();
  const [item, setItem] = useState();
  const [abrirModalPedirRevisao, setAbrirModalPedirRevisao] = useState(false);

  function buscarItens() {
    api
      .get(`/itens`)
      .then((response) => {
        setItens(response.data.data.itens);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  function buscarBriefing() {
    api
      .get(`/campanha-material-briefing/briefing/${id}`)
      .then((response) => {
        const data = response.data.data.materiais;
        setDataBriefing(data);
        buscarItem(data.item_id);
        buscarCampanhas(data.campanhas_id);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  function buscarCampanhas(campanhaId) {
    api
      .get(`/campanhas/${campanhaId}`)
      .then((response) => {
        setCampanha(response.data.data.campanha[0]);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  function buscarCliente() {
    api
      .get(`/usuario-cliente/${user.id}`)
      .then((response) => {
        setClienteId(response.data.data.usuario.cliente_id);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  function buscarCategoriaItem() {
    api
      .get(`/categoria-item`)
      .then((response) => {
        setCategorias(response.data.data.categorias);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  function buscarItem(briefingId) {
    api
      .get(`/itens/${briefingId}`)
      .then((response) => {
        setItem(response.data.data.item);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  useEffect(() => {
    setDataBriefing();
    buscarItens();
    buscarCategoriaItem();
    buscarCliente();
    buscarBriefing();
  }, []);

  return (
    <BriefingStyle>
      <Menu />
      <div className="div-right">
        <div className="title">
          <ReactSVG
            onClick={() => window.history.back()}
            src={Botao}
            className="back"
          />
          <h1>Briefing</h1>
        </div>
        <label>Tipo de Material</label>
        <select className="input_categoria">
          {categoria == "Material Impresso" && (
            <option value="Material Impresso">Material Impresso</option>
          )}
          {categoria == "Comunicação Visual" && (
            <option value="Comunicação Visual">Comunicação Visual</option>
          )}
          {categoria == "Ponto de Venda" && (
            <option value="Materiais de Ponto de Venda">
              Materiais de Ponto de Venda
            </option>
          )}
          {categoria == "Brindes" && <option value="Brindes">Brindes</option>}
        </select>
        {categoria == "Comunicação Visual" &&
          categorias.length > 0 &&
          dataBriefing && (
            <FormComunicacaoVisual
              itens={itens}
              categoriaSelecionada={
                categorias.find((element) => element.nome == categoria).id
              }
              clienteId={clienteId}
              dataBriefing={dataBriefing}
              setAbrirModalPedirRevisao={setAbrirModalPedirRevisao}
            />
          )}
        {categoria == "Ponto de Venda" &&
          categorias.length > 0 &&
          dataBriefing && (
            <FormPontoDeVenda
              itens={itens}
              categoriaSelecionada={
                categorias.find((element) => element.nome == categoria).id
              }
              clienteId={clienteId}
              dataBriefing={dataBriefing}
              setAbrirModalPedirRevisao={setAbrirModalPedirRevisao}
            />
          )}
        {categoria == "Brindes" && categorias.length > 0 && dataBriefing && (
          <FormBrinde
            itens={itens}
            categoriaSelecionada={
              categorias.find((element) => element.nome == categoria).id
            }
            clienteId={clienteId}
            dataBriefing={dataBriefing}
            setAbrirModalPedirRevisao={setAbrirModalPedirRevisao}
          />
        )}
        {categoria == "Material Impresso" &&
          categorias.length > 0 &&
          dataBriefing && (
            <FormMaterialImpresso
              itens={itens}
              categoriaSelecionada={
                categorias.find((element) => element.nome == categoria).id
              }
              clienteId={clienteId}
              dataBriefing={dataBriefing}
              setAbrirModalPedirRevisao={setAbrirModalPedirRevisao}
            />
          )}
        |
        {categoria == "Serviço" && categorias.length > 0 && dataBriefing && (
          <FormServico
            itens={itens}
            categoriaSelecionada={
              categorias.find((element) => element.nome == categoria).id
            }
            clienteId={clienteId}
            dataBriefing={dataBriefing}
            setAbrirModalPedirRevisao={setAbrirModalPedirRevisao}
          />
        )}
      </div>
      {abrirModalPedirRevisao && (
        <PedirRevisaoModal
          state={setAbrirModalPedirRevisao}
          campanha={campanha}
          item={item}
          perfil={user.perfil}
          briefing={dataBriefing}
        />
      )}
    </BriefingStyle>
  );
};

export default BriefingPageEditar;
