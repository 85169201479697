import { styled } from "styled-components";

export const MateriaisViaBriefingStyle = styled.div`
  width: 90%;
  margin: 0 auto;
  position: relative;
  background-color: var(--base);
  padding: 30px 30px;
  border-radius: 20px;

  .header {
    display: flex;
    align-items: center;

    svg {
      margin-right: 10px;
    }

    h2 {
      color: var(--principal);
      font-weight: 600;
      margin-right: 10px;
    }

    span {
      font-size: 0.9em;
      text-decoration: underline;
      color: #609ae5;
      cursor: pointer;
    }
  }

  .button-proposta {
    padding: 6px 20px;
    color: var(--principal);
    background-color: var(--amarelo);
    border: none;
    border-radius: 20px;
    font-size: 12px;
    font-weight: 600;
    position: absolute;
    right: 10px;
    bottom: 5px;
  }

  .escala {
    display: flex;
    padding: 30px 0px 10px 20px;
    .categoria {
      font-weight: 600;
      width: 18%;
      font-size: 13px;
    }

    .material {
      font-weight: 600;
      width: 18%;
      font-size: 13px;
    }

    .especificacoes {
      font-weight: 600;
      width: 18%;
      font-size: 13px;
    }

    .id {
      font-weight: 600;
      width: 6%;
      font-size: 13px;
    }

    .quantidade {
      font-weight: 600;
      width: 18%;
      font-size: 13px;
      text-align: center;
    }

    .acoes {
      width: 28%;
      text-align: center;
    }
  }

  .vazio {
    color: var(--gray70);
    padding: 20px 20px 20px 20px;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    text-align: center;
    padding: 10px;

    > thead > tr > th {
      font-weight: 600;
      padding: 20px;
      padding: 10px;
      font-size: 0.8rem;
    }
    > tbody > tr > td {
      border-top: 1px solid var(--grayA5);
      padding: 15px;
      font-size: 0.8rem;
    }
  }
`;

export const TableContainer = styled.div`
  height: 70%;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 8px;
    border-radius: 8px;
    background-color: var(--base01);
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--principal);
    border-radius: 5px;
  }

  > table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    text-align: center;
    padding: 10px;

    > thead > tr > th {
      font-weight: 600;
      padding: 20px;
      padding: 10px;
      font-size: 0.8rem;
    }
    > tbody > tr > td {
      border-bottom: 1px solid var(--grayDA);
      border-top: 1px solid var(--grayA5);
      padding: 15px;
      font-size: 0.8rem;
    }
  }

  .ativo {
    display: flex;
    align-items: center;
    justify-content: center;

    > p {
      font-size: 0.8rem;
      width: 60px;
    }
  }

  a {
    color: var(--azul);
    font-size: 0.8rem;
  }
`;
