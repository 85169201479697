import styled from "styled-components";

export const TabelaDisputaStyled = styled.div`
  width: 100%;
  padding: 45px 25px;
  margin-top: 30px;
  position: relative;
  background: var(--base);
  border-radius: 16px;

  .adicionar-fornecedor {
    font-size: 12px;
    color: var(--principal);
    text-decoration: underline;
    margin-left: 8px;
    cursor: pointer;
  }

  .selecionar {
    margin-top: 20px;
    margin: auto;
  }

  .melhorPreco {
    font-weight: 700;
  }

  h3 {
    width: 100px;
    font-weight: 600;
    text-align: center;
  }

  .titulo {
    padding: 0px 0px 20px;
    font-weight: 700;
    color: var(--principal);
  }

  .titulos {
    display: flex;
    padding-bottom: 10px;
    border-bottom: 1px solid var(--gray70);
    font-weight: 600;
    padding: 0px 20px 10px;
  }

  .space {
    display: flex;
    justify-content: space-between;
    width: 80%;

    h3 {
      font-size: 12px;
    }

    .recusado {
      svg {
        margin-top: 10px;
      }

      font-size: 12px;
    }

    .icms {
      font-size: 12px;
    }

    .ipi {
      font-size: 12px;
    }

    .orcamentos {
      font-size: 12px;
    }

    .entrega {
      font-size: 12px;
    }

    h3 {
      font-size: 12px;
    }
  }

  .ganhador {
    margin-top: 15px;

    svg {
      width: 28px;
      height: 28px;
    }
  }

  .melhorPreco {
    align-items: center;
    font-size: 0.8rem;
  }

  .h3-compras {
    border: none;
    border-radius: 30px;
    color: var(--amarelo);
    font-size: 12px;
    font-weight: 700;
    position: absolute;
    right: 20px;
    top: 40px;
    width: max-content;
    font-size: 1rem;
  }

  .email-fornecedor {
    background-color: var(--principal);
    color: var(--amarelo);
    right: 190px;
    width: max-content;
  }

  .valorVenda {
    font-size: 0.8rem;
  }

  .fornecedores {
    width: 20%;
    font-size: 12px;
    h3 {
      font-size: 12px;
      width: max-content;
      margin-right: 10px;
    }
  }

  button {
    border: none;
    border-radius: 30px;
    padding: 6px 10px;
    background-color: var(--amarelo);
    color: var(--principal);
    font-size: 12px;
    font-weight: 700;
    position: absolute;
    right: 21px;
    bottom: 3px;
  }

  .editar {
    width: 30px;
    height: 29px;
  }

  .entrega {
    display: flex;
    justify-content: center;
  }

  /* .button-disabled {
    background-color: #d9d9d9;
    color: #a5a5a5;
  } */

  .h2-aviso {
    padding: 10px 0px 0px 20px;
    color: #979696;
  }

  .titulo-justificativa {
    width: max-content;
    margin-top: 30px;
  }

  .justificativa {
    width: 70%;
    max-width: 600px;
    text-align: start;
    font-weight: 500;
  }

  .calculos {
    background-color: #7d0000;
    padding: 5px 6px 1px 7px;
    border-radius: 36px;
    cursor: pointer;
    margin-right: 10px;
  }

  .flex {
    justify-content: center;
  }

  .nota {
    background-color: var(--amarelo);
    margin-right: 10px;
    padding: 5px 5px 1px 6px;
    border-radius: 36px;
    cursor: pointer;
  }
`;

export const TableContainer = styled.div`
  height: 70%;
  overflow: auto;
  .recusado {
    svg {
      margin-top: 10px;
    }

    font-size: 12px;
  }

  &::-webkit-scrollbar {
    width: 8px;
    border-radius: 8px;
    background-color: var(--base01);
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--principal);
    border-radius: 5px;
  }

  > table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    text-align: center;
    padding: 10px;

    > thead > tr > th {
      font-weight: 600;
      padding: 20px;
      padding: 10px;
      font-size: 0.8rem;
    }
    > tbody > tr > td {
      padding: 15px;
      font-size: 0.8rem;
    }

    tr {
      border-bottom: 1px solid var(--grayDA);
    }
  }

  .space-between {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    align-items: center;

    .icone {
      padding: 7px 6px 2px 8px;
      background-color: var(--principal);
      border-radius: 100%;
      margin-left: 20px;
      cursor: pointer;
    }

    .botao-disabled {
      background-color: #987575 !important;
      cursor: not-allowed !important;
    }

    svg {
      width: 22px;
      height: 22px;

      path,
      polygon,
      rect,
      circle,
      ellipse {
        fill: var(--amarelo);
      }
    }
  }

  .position-icone {
    position: relative;
    right: 120px;
  }

  .label-icone {
    position: absolute;
    width: 200px;
    color: white;
    font-size: 0.8rem;
    font-weight: 600;
  }

  .position-icone:hover {
    color: var(--principal);
    z-index: 2;
  }

  .position-icone:hover .label-icone {
    cursor: pointer;
    color: var(--principal);
    z-index: 2;
  }

  .label-fornecedor {
    left: -16px;
  }

  .label-liberar {
    left: -30px;
  }

  .label-prazo {
    left: 10px;
  }

  .ativo {
    display: flex;
    align-items: center;
    justify-content: center;

    > p {
      font-size: 0.8rem;
      width: 60px;
    }
  }

  a {
    color: var(--azul);
    font-size: 0.8rem;
  }

  .excluir {
    background: var(--principal);
    padding: 4px 4px 3px 2px;
    border-radius: 50px;
    width: 34px;
    display: flex;
    margin: 0 auto;
    svg {
      width: 30px;
    }
  }
`;
