import styled from "styled-components";

export const FormAdministrarItemStyle = styled.div`
  width: 100%;
  padding: 50px 40px;
  border-radius: 40px 0px 0px 40px;
  background-color: var(--base01);

  .voltar {
    svg {
      width: 45px;
      height: 45px;
    }
  }

  .label-check {
    margin-right: 20px;
    text-align: center;
  }

  h1 {
    font-size: 20px;
    font-weight: 700;
    margin-left: 10px;
    color: var(--principal);
    margin-bottom: 8px;
    width: 350px;
  }

  .underline {
    font-size: 14px;
    margin-left: 10px;
    text-decoration: underline;
    color: var(--principal);
  }

  .input-completo {
    display: flex;
    flex-direction: column;

    max-width: 350px;
  }

  label {
    font-size: 15px;
    font-weight: 500;
  }

  select {
    margin-top: 6px;
    padding: 10px 10px;
    border: none;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    font-size: 12px;
    width: 100%;
    max-width: none;
  }
`;

export const FormStyle = styled.form`
  width: 100%;
  margin-top: 40px;

  .input-grid {
    width: 100%;
    max-width: none;
  }

  .margin-top {
    margin-top: 30px;
  }

  input {
    margin-top: 6px;
    padding: 10px 10px;
    border: none;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    font-size: 12px;
    width: 100%;
    max-width: none;
  }

  .inputs {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }

  .div-images {
    h2 {
      margin-top: 30px;
      margin-bottom: 10px;
    }

    img {
      width: 170px;
      border-radius: 8px;
      margin-right: 12px;
      cursor: pointer;
    }
  }

  .adicionar-foto {
    padding: 40px 20px;
    display: flex;
    flex-direction: column;
    border: 4px dashed #8f8d8d;
    align-items: center;
    width: 140px;

    cursor: pointer;
  }

  .font {
    font-size: 12px;
  }

  .h3-red {
    font-weight: 700;
    margin-top: 30px;
    color: var(--principal);
  }

  button {
    padding: 8px 24px;
    border-radius: 16px;
    border: none;
    background-color: var(--amarelo);
    color: var(--principal);
    font-weight: 700;
    margin-top: 20px;
  }

  .checkbox {
    margin-bottom: 20px;
  }

  .checkbox input {
    display: none;
  }

  .checkbox input + label:before {
    content: "";
    width: 15px;
    height: 15px;
    border-radius: 2px;
    border: 1px solid #979696;
    vertical-align: middle;
    display: inline-block;
    margin-left: 5px;
    margin-right: 5px;
    cursor: pointer;
  }

  .checkbox input:checked + label:before {
    background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='15' viewBox='0 0 10 10'%3E%3Cg class='nc-icon-wrapper' stroke-width='1' fill='%23555555'%3E%3Cpath fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' data-cap='butt' d='M2.83 4.72l1.58 1.58 2.83-2.83'/%3E%3C/g%3E%3C/svg%3E");
    background-color: var(--amarelo);
  }

  .checkbox label {
    font-size: 0.9rem;
  }
`;

export const TableContainer = styled.div`
  height: 70%;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 8px;
    border-radius: 8px;
    background-color: var(--base01);
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--principal);
    border-radius: 5px;
  }

  > table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    text-align: center;
    padding: 10px;

    > thead > tr > th {
      font-weight: 600;
      padding: 20px;
      padding: 10px;
      font-size: 0.8rem;
    }
    > tbody > tr > td {
      border-bottom: 1px solid var(--grayDA);
      border-top: 1px solid var(--grayA5);
      padding: 15px;
      font-size: 0.8rem;
    }
  }

  .ativo {
    display: flex;
    align-items: center;
    justify-content: center;

    > p {
      font-size: 0.8rem;
      width: 60px;
    }
  }

  a {
    color: var(--azul);
    font-size: 0.8rem;
  }

  input {
    text-align: center;
  }
`;
