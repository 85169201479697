import { useForm } from "react-hook-form";
import { DivStyled } from "./style";
import { Input } from "../../../components/Input";
import Menu from "../../../components/Menu";
import Som from "../../../assets/campanha.svg";
import { useNavigate, useParams } from "react-router-dom";
import { ReactSVG } from "react-svg";
import Botao from "../../../assets/voltar.svg";
import { yupResolver } from "@hookform/resolvers/yup";
import { campanhaSchema } from "../../../schemas/campanha";
import { useAuth } from "../../../providers/Auth";
import api from "../../../services/api";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";

export const CriarCamapanha = () => {
  const { token, user } = useAuth();
  const [clienteId, setClienteId] = useState();
  const [marcas, setMarcas] = useState([]);
  const [marcasClientes, setMarcasClientes] = useState([]);
  const [clienteSelecionado, setClienteSelecionado] = useState();
  const [marcasSelecionadas, setMarcasSelecionadas] = useState([]);
  const [clientesResponsaveis, setClientesResponsaveis] = useState([]);
  const navigate = useNavigate();

  const adicionarHistorico = (dados) => {
    const data = {
      tipo: "Campanha",
      texto: `Campanha ${dados.nome} Criada`,
      campanha_id: dados.id,
    };

    api
      .post("/historico-campanha", [data])
      .then((response) => {})
      .catch((error) => {
        toast.error("Erro ao salvar no historico");
      });
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(campanhaSchema),
  });

  function buscarMarcas() {
    api
      .get("/marcas")
      .then((response) => {
        setMarcas(response.data.data.marcas);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  function listarMarcas(id) {
    api
      .get(`/marcas-cliente/${id}`)
      .then((response) => {
        setMarcasClientes(response.data.data.vinculos);
        setMarcasSelecionadas([]);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  function buscarCliente() {
    api
      .get(`/usuario-cliente/${user.id}`)
      .then((response) => {
        setClienteId(response.data.data.usuario.cliente_id);
        listarMarcas(response.data.data.usuario.cliente_id);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  function buscarClientesResponsaveis() {
    api
      .get(`/clientes/cliente/responsaveis`)
      .then((response) => {
        setClientesResponsaveis(response.data.data.clientes);
      })
      .catch((error) => {
        console.error(
          "Buscar Clientes Responsaveis na criação de campanha Erro:",
          error
        );
        toast.error("Erro ao buscar dados");
      });
  }

  useEffect(() => {
    if (!user) {
      return;
    }

    buscarMarcas();
    if (
      user.permissoes.includes("admin") ||
      user.permissoes.includes("atendimento")
    ) {
      buscarClientesResponsaveis();
    } else {
      buscarCliente();
    }
  }, [user]);

  useEffect(() => {
    listarMarcas(clienteSelecionado);
  }, [clienteSelecionado]);

  function adicionarMarca(element) {
    if (marcasSelecionadas.includes(Number(element)) === false && element > 0) {
      setMarcasSelecionadas((prevSelecionadas) => [
        ...prevSelecionadas,
        Number(element),
      ]);
    }
  }

  function removerMarca(id) {
    const filter = marcasSelecionadas.filter((element) => element != id);
    setMarcasSelecionadas(filter);
  }

  const acao = (data) => {
    const partes = data.prazo_final.split("-");
    data.prazo_final = `${partes[2]}/${partes[1]}/${partes[0]}`;

    data.cliente_id = data.cliente_id ? data.cliente_id : clienteId;
    data.status = 1;

    if (marcasSelecionadas.length === 0) {
      toast.warning("Selecione ao menos uma marca.");
      return;
    }
    api
      .post(
        "/campanhas",
        { campanhas: [data] },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        adicionarHistorico(response.data.data.campanha);
        const arrayTransformado = marcasSelecionadas.map((item) => {
          return {
            marca_id: item,
            campanha_id: response.data.data.insertId,
          };
        });

        api
          .post(
            "/campanha-marca",
            { vinculos: arrayTransformado },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((response) => {
            toast.success("Campanha Criada! 🎉");
            setTimeout(() => {
              navigate(
                `/campanha/detalhes/${arrayTransformado[0].campanha_id}`
              );
            }, 2000);
          })
          .catch((error) => {
            toast.error("Algo deu Errado. Tente novamente.");
          });
      })
      .catch((error) => {
        toast.error("Algo deu Errado. Tente novamente.");
      });
  };

  return (
    <DivStyled>
      <Menu />
      {user.permissoes.includes("admin") ||
      user.permissoes.includes("atendimento") ? (
        <div className="box-right">
          <div className="flex">
            <ReactSVG onClick={() => navigate("/campanhas")} src={Botao} />
            <ReactSVG src={Som} alt="" className="som" />
            <h1>Nova Campanha</h1>
          </div>
          <form onSubmit={handleSubmit(acao)}>
            <div className="div-select">
              <label>Cliente</label>
              <select
                {...register("cliente_id")}
                onChange={(e) => {
                  setClienteSelecionado(e.target.value);
                }}
              >
                <option value="">Selecionar Cliente</option>
                {clientesResponsaveis &&
                  clientesResponsaveis.map((cliente) => {
                    return (
                      <option value={cliente.id}>
                        {cliente.nome_fantasia}
                      </option>
                    );
                  })}
              </select>
            </div>
            <Input
              label="Nome da campanha"
              type="text"
              placeholder="Nome da campanha"
              register={register("nome")}
              className="nome"
            />
            {errors.nome && (
              <span className="input-error">{errors.nome.message}</span>
            )}
            <div className="flex-input">
              <div className="div-select">
                <label>Marca</label>
                <select
                  onClick={(e) => adicionarMarca(e.target.value)}
                  {...register("marcas_id")}
                >
                  <option value="0">Selecionar a marca</option>
                  {marcas.length > 0 &&
                  marcasClientes.length > 0 &&
                  user.permissoes.includes("admin")
                    ? marcas.map((element) => (
                        <option value={element.id}>{element.nome}</option>
                      ))
                    : marcas.length > 0 &&
                      marcasClientes.length > 0 &&
                      marcasClientes.map((element) => (
                        <option
                          value={
                            marcas.find((elem) => elem.id == element.marca_id)
                              .id
                          }
                        >
                          {
                            marcas.find((elem) => elem.id == element.marca_id)
                              .nome
                          }
                        </option>
                      ))}
                </select>
                <div className="flex">
                  {marcasSelecionadas.length > 0 &&
                    marcas &&
                    marcasSelecionadas
                      .map((elemen) =>
                        marcas.filter((element) => element.id === elemen)
                      )
                      .map((elemento) => (
                        <div className="relative">
                          <p className="nome-marca">{elemento[0].nome}</p>
                          <span onClick={() => removerMarca(elemento[0].id)}>
                            X
                          </span>
                        </div>
                      ))}
                </div>
              </div>
              <div className="div-error">
                <Input
                  className="input"
                  label="Prazo final (data máxima da entrega da campanha)"
                  type="date"
                  placeholder="DD/MM/AAAA"
                  register={register("prazo_final")}
                />
                {errors.prazo_final && (
                  <span className="input-error">
                    {errors.prazo_final.message}
                  </span>
                )}
              </div>
            </div>
            <button>Confirmar</button>
          </form>
        </div>
      ) : (
        <div className="box-right">
          <div className="flex">
            <ReactSVG onClick={() => navigate("/campanhas")} src={Botao} />
            <ReactSVG src={Som} alt="" className="som" />
            <h1>Nova Campanha</h1>
          </div>
          <form onSubmit={handleSubmit(acao)}>
            <Input
              label="Nome da campanha"
              type="text"
              placeholder="Nome da campanha"
              register={register("nome")}
              className="nome"
            />
            {errors.nome && (
              <span className="input-error">{errors.nome.message}</span>
            )}
            <div className="flex-input">
              <div className="div-select">
                <label>Marca</label>
                <select
                  onClick={(e) => adicionarMarca(e.target.value)}
                  {...register("marcas_id")}
                >
                  <option value="0">Selecionar a marca</option>
                  {marcas.length > 0 &&
                  marcasClientes.length > 0 &&
                  user.permissoes.includes("admin")
                    ? marcas.map((element) => (
                        <option value={element.id}>{element.nome}</option>
                      ))
                    : marcas.length > 0 &&
                      marcasClientes &&
                      marcasClientes.map((element) => (
                        <option
                          value={
                            marcas.find((elem) => elem.id == element.marca_id)
                              .id
                          }
                        >
                          {
                            marcas.find((elem) => elem.id == element.marca_id)
                              .nome
                          }
                        </option>
                      ))}
                </select>
                <div className="flex">
                  {marcasSelecionadas.length > 0 &&
                    marcas &&
                    marcasSelecionadas
                      .map((elemen) =>
                        marcas.filter((element) => element.id === elemen)
                      )
                      .map((elemento) => (
                        <div className="relative">
                          <p className="nome-marca">{elemento[0].nome}</p>
                          <span onClick={() => removerMarca(elemento[0].id)}>
                            X
                          </span>
                        </div>
                      ))}
                </div>
              </div>
              <div className="div-error">
                <Input
                  className="input"
                  label="Prazo final (data máxima da entrega da campanha)"
                  type="date"
                  placeholder="DD/MM/AAAA"
                  register={register("prazo_final")}
                />
                {errors.prazo_final && (
                  <span className="input-error">
                    {errors.prazo_final.message}
                  </span>
                )}
              </div>
            </div>
            <button>Confirmar</button>
          </form>
        </div>
      )}
    </DivStyled>
  );
};
