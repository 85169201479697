import { CepInputDiv, ContentContainer, InputContainer, ModalPreencherEnderecoContainer, SelectContainer } from './styles';
import { ReactSVG } from 'react-svg';
import FecharSvg from '../../../assets/fechar.svg';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import axios from 'axios';
import * as yup from 'yup';
import api from '../../../services/api';
import { toast } from 'react-toastify';
import Loading from '../../Loading';

const enderecoSchema = yup.object().shape({
    cep: yup.string().test(
        'cep-valido',
        'CEP inválido',
        value => /^\d{5}-\d{3}$/.test(value)
    ).required('Campo obrigatório'),
    logradouro: yup.string().required('Campo obrigatório'),
    numero: yup.string().required('Campo obrigatório'),
    complemento: yup.string(),
    bairro: yup.string().required('Campo obrigatório'),
    cidade: yup.string().required('Campo obrigatório'),
    uf: yup.string().required('Campo obrigatório'),
});

const UFs = [
    {
        estado: 'Acre',
        uf: 'AC'
    },
    {
        estado: 'Alagoas',
        uf: 'AL'
    },
    {
        estado: 'Amapá',
        uf: 'AP'
    },
    {
        estado: 'Amazonas',
        uf: 'AM'
    },
    {
        estado: 'Bahia',
        uf: 'BA'
    },
    {
        estado: 'Ceará',
        uf: 'CE'
    },
    {
        estado: 'Distrito Federal',
        uf: 'DF'
    },
    {
        estado: 'Espírito Santo',
        uf: 'ES'
    },
    {
        estado: 'Goiás',
        uf: 'GO'
    },
    {
        estado: 'Maranhão',
        uf: 'MA'
    },
    {
        estado: 'Mato Grosso',
        uf: 'MT'
    },
    {
        estado: 'Mato Grosso do Sul',
        uf: 'MS'
    },
    {
        estado: 'Minas Gerais',
        uf: 'MG'
    },
    {
        estado: 'Pará',
        uf: 'PA'
    },
    {
        estado: 'Paraíba',
        uf: 'PB'
    },
    {
        estado: 'Paraná',
        uf: 'PR'
    },
    {
        estado: 'Pernambuco',
        uf: 'PE'
    },
    {
        estado: 'Piauí',
        uf: 'PI'
    },
    {
        estado: 'Rio de Janeiro',
        uf: 'RJ'
    },
    {
        estado: 'Rio Grande do Norte',
        uf: 'RN'
    },
    {
        estado: 'Rio Grande do Sul',
        uf: 'RS'
    },
    {
        estado: 'Rondônia',
        uf: 'RO'
    },
    {
        estado: 'Roraima',
        uf: 'RR'
    },
    {
        estado: 'Santa Catarina',
        uf: 'SC'
    },
    {
        estado: 'São Paulo',
        uf: 'SP'
    },
    {
        estado: 'Sergipe',
        uf: 'SE'
    },
    {
        estado: 'Tocantins',
        uf: 'TO'
    }
];

const ModalPreencherEndereco = ({setModalOpened, material, buscarMateriaisVitrine}) => {
    const { register, handleSubmit, formState: { errors }, setValue, getValues, reset } = useForm({
        resolver: yupResolver(enderecoSchema)
    });

    const [loading, setLoading] = useState(false);

    const [cepInvalido, setCepInvalido] = useState('');
    
    const cepSearch = (validate = false) => {
        const cep = getValues('cep');
        if (cep && cep.length === 9) {
            axios.request({
                url: `https://viacep.com.br/ws/${cep.replace('-', '')}/json`,
                method: 'GET'
            }).then((response) => {
                if ('erro' in response.data && validate) {
                    setCepInvalido('CEP inválido.');
                    return false;
                } else if (response.data.uf !== material.uf) {
                    setCepInvalido('CEP não pertence ao estado selecionado.');
                    return false;
                } else {
                    const address = response.data;
                    address.logradouro && setValue('logradouro', address.logradouro || '');
                    address.bairro && setValue('bairro', address.bairro || '');
                    address.localidade && setValue('cidade', address.localidade || '');
                    address.uf && setValue('uf', address.uf || '');
                    setCepInvalido('');

                    return true;
                }
            })
        }
    };

    useEffect(() => {
        setValue('uf', material.uf);
    }, [material])

    const onSubmitFunction = (data) => {
        setLoading(true);
        api.patch(`/campanha-material-vitrine/${material.id}`, data)
            .then((response) => {
                buscarMateriaisVitrine();
                toast.success('Material da vitrine atualizado com sucesso.');
                setModalOpened(false);
            }).catch(error => {
                toast.error('Erro ao atualizar o material da vitrine.');
                setLoading(false);
            })
    }

    return (
        <ModalPreencherEnderecoContainer onClick={() => setModalOpened(false)}>
            <ContentContainer onClick={(e) => e.stopPropagation()}  >
                <button className="fechar" onClick={() => setModalOpened(false)}>
                    <ReactSVG src={FecharSvg} />
                </button>
                {loading ? (
                    <Loading />
                ) : (
                    <>
                    <h1>Onde você deseja receber o seu pedido?</h1>
                    <form onSubmit={handleSubmit(onSubmitFunction)} className='endereco-container'>
                        <InputContainer className='cep'>
                            <label htmlFor='cep'>CEP *</label>
                            <CepInputDiv>
                                <label onClick={() => cepSearch(true)}>
                                    Pesquisar
                                </label>
                                <input
                                    {...register('cep', {
                                        onChange:(e) => {
                                            setValue('logradouro', '');
                                            setValue('bairro', '');
                                            setValue('cidade', '');
                                            setValue('uf', '');
                                            setValue('cep', e.target.value.replace(/\D/g, '')
                                                .replace(/(\d{5})(\d)/, '$1-$2')
                                                .replace(/(-\d{3})\d+?$/, '$1'))
                                        },
                                    })}
                                    placeholder='00000-000'
                                    maxLength='9'
                                />
                            </CepInputDiv>
                            {cepInvalido && <span className='error-span'>{cepInvalido}</span>}
                            {errors.cep && <span className='error-span'>{errors.cep.message}</span>}
                        </InputContainer>
                        <InputContainer className='logradouro'>
                            <label htmlFor='logradouro'>Logradouro</label>
                            <input 
                                id='logradouro' 
                                placeholder='Avenida, Rua, Travessa'
                                disabled
                                {...register('logradouro')}
                            />
                            {errors.logradouro && <span className='error-span'>{errors.logradouro.message}</span>}
                        </InputContainer>
                        <InputContainer className='numero'>
                            <label htmlFor='numero'>Número *</label>
                            <input 
                                id='numero' 
                                placeholder='00000'
                                {...register('numero')}
                            />
                            {errors.numero && <span className='error-span'>{errors.numero.message}</span>}
                        </InputContainer>
                        <InputContainer className='complemento'>
                            <label htmlFor='complemento'>Complemento</label>
                            <input 
                                id='complemento' 
                                placeholder='Sala, apartamento'
                                {...register('complemento')}
                            />
                            {errors.complemento && <span className='error-span'>{errors.complemento.message}</span>}
                        </InputContainer>
                        <InputContainer className='bairro'>
                            <label htmlFor='bairro'>Bairro</label>
                            <input 
                                id='bairro' 
                                placeholder='Bairro'
                                {...register('bairro')}
                            />
                            {errors.bairro && <span className='error-span'>{errors.bairro.message}</span>}
                        </InputContainer>
                        <InputContainer className='cidade'>
                            <label htmlFor='cidade'>Cidade</label>
                            <input 
                                id='cidade' 
                                placeholder='Cidade'
                                {...register('cidade')}
                            />
                            {errors.cidade && <span className='error-span'>{errors.cidade.message}</span>}
                        </InputContainer>
                        <SelectContainer className='estado'>
                            <label htmlFor='uf'>Estado</label>
                            <select
                                disabled
                                {...register('uf')}
                            >
                                <option value=''>Estado</option>
                                {UFs.map(item => (
                                    <option key={item.uf} value={item.uf}>{item.estado}</option>
                                ))}
                            </select>
                            {errors.uf && <span className='error-span'>{errors.uf.message}</span>}
                        </SelectContainer>
                        <div className='button-container'>
                            <button
                                disabled={cepInvalido}
                            >
                                salvar
                            </button>
                        </div>
                    </form>
                    </>
                )}
            </ContentContainer>
        </ModalPreencherEnderecoContainer>
    )
};

export default ModalPreencherEndereco;