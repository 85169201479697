import { toast } from "react-toastify";
import api from "../../../services/api";
import { ModalImagemAdministrarVitrineStyle } from "./style";
import { useParams } from "react-router-dom";

export const ModalImagemAdministrarVitrine = ({
  state,
  materialVitrineImagens,
  index,
  setImagemIndex,
  buscarMaterialVitrine,
  buscarMaterialVitrineImagens,
  buscarMaterialVitrineValores,
}) => {
  const { material_id } = useParams();
  const materialVitrine = materialVitrineImagens[index];

  const deletarImagemAdministrar = () => {
    api
      .delete(`/material-vitrine/${material_id}/anexos/${materialVitrine.id}`)
      .then((response) => {
        state(false);
        toast.success("Imagem Deletada");
        buscarMaterialVitrine();
        buscarMaterialVitrineImagens();
        buscarMaterialVitrineValores();
      })
      .catch((error) => {
        toast.error("Erro ao buscar dados");
      });
  };

  return (
    <ModalImagemAdministrarVitrineStyle>
      <div className="body">
        <span onClick={() => state(false)} className="close">
          X
        </span>
        <img src={materialVitrine.url} />
        <div className="setas">
          {index > 0 ? (
            <span onClick={() => setImagemIndex(index - 1)}>&lt;</span>
          ) : (
            <span className="vazio"></span>
          )}
          <button onClick={() => deletarImagemAdministrar()}>Deletar</button>
          {materialVitrineImagens.length - 1 > index ? (
            <span onClick={() => setImagemIndex(index + 1)}>&gt;</span>
          ) : (
            <span className="vazio"></span>
          )}
        </div>
      </div>
    </ModalImagemAdministrarVitrineStyle>
  );
};
