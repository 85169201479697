import Header from "../../components/Menu";
import ItemCategoria from "../../components/ItensCategoria";


const ItensCategoriaPage = () => {
  return (
    <div>
      <Header />
     <ItemCategoria/>   
    </div>
  );
};

export default ItensCategoriaPage;
