import { InputStyled } from "./style";

export const Input = ({
  label,
  type,
  placeholder,
  register,
  className,
  min,
  max,
  onInput,
  onKeyUp,
  id,
}) => {
  return (
    <InputStyled className={className}>
      <label>{label}</label>
      <input
        type={type}
        placeholder={placeholder}
        min={min}
        max={max}
        {...register}
        onInput={onInput}
        onKeyUp={onKeyUp}
        id={id}
      />
    </InputStyled>
  );
};
