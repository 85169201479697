import Menu from "../../components/Menu";
import { VerTodosVitrineStyle } from "./style";
import { CardVitrine } from "../../components/CardVitrine";
import Vitrine from "../../assets/materiais-via-vitrine.svg";
import { ReactSVG } from "react-svg";
import { useParams } from 'react-router-dom';
import api from '../../services/api';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import Loading from '../../components/Loading';

export const VerTodosVitrinePage = () => {
  const [materiais, setMateriais] = useState([]);
  const { categoria } = useParams();

  const [materiaisLoaded, setMateriaisLoaded] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (materiaisLoaded) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  },[materiaisLoaded]);

  const buscarMateriaisVitrine = () => {
    setMateriaisLoaded(false);
    api.get(`/material-vitrine?categoria=${encodeURIComponent(categoria)}`)
      .then(response => {
        setMateriais(response.data.data.materiais);
        setMateriaisLoaded(true);
      }).catch(error => {
        toast.error('Erro ao buscar dados');
      })
  };

  useEffect(() => {
    buscarMateriaisVitrine();
  }, []);

  return (
    <VerTodosVitrineStyle>
      <Menu />
      {loading ? (
        <Loading />
      ) : (
        <div className="column">
          <div className="flex header">
            <ReactSVG src={Vitrine} />
            <h1>Vitrine</h1>
          </div>
          <h2>{categoria}:</h2>
          <div className="materiais-container">
            {materiais.map((element) => (
              <CardVitrine key={element.id} material={element} />
            ))}
          </div>
        </div>
      )}
    </VerTodosVitrineStyle>
  );
};
