import styled from "styled-components";

export const VerTodosVitrineStyle = styled.div`
  display: flex;
  background-color: var(--base);
  border-radius: 300px 0px 0px 300px;

  .materiais-container {
    display: flex;
    flex-wrap: wrap;
    height: max-content;
  }

  h2 {
    font-size: 28px;
    font-weight: 600;
    margin-top: 25px;
  }

  .column {
    padding: 40px 45px;
    display: flex;
    flex-direction: column;
    border-radius: 58px 0 0 58px;
    background-color: var(--base01);
    width: 100%;
  }

  .flex {
    display: flex;
    align-items: center;
  }

  .header {
    h1 {
      font-size: 38px;
      font-weight: 600;
      color: var(--principal);
    }

    svg {
      width: 50px;
      height: 50px;
    }
  }
`;
