import { styled } from "styled-components";

export const DivStyled = styled.div`
  display: flex;
  background-color: var(--base);
  border-radius: 40px;

  .box-right {
    width: calc(100% - 260px);
    height: 100vh;
    padding: 36px;
    background-color: var(--base01);
    border-radius: 40px;
  }

  .input-error {
    position: relative;
  }

  .flex {
    display: flex;
    align-items: center;
    margin-bottom: 32px;
  }
  .return {
    font-size: 40px;
    font-weight: 400;
    color: var(--principal);
    background-color: var(--amarelo);
    padding: 8px 7px;
    border-radius: 100px;
    margin-right: 10px;
  }

  .flex-input {
    display: flex;
    justify-content: space-between;
    width: 95%;
    align-items: baseline;
    margin-bottom: 10px;
  }

  .nome {
    width: 100%;
    max-width: 95%;
    margin-bottom: 20px;
  }

  .div-error {
    width: 45%;
  }

  input {
    height: 36px;
    border-radius: 6px 6px 6px 6px;
    border: none;
    padding: 18px 12px;
  }

  select {
    width: 100%;
    height: 36px;
    padding: 0px 12px;
    border-radius: 6px 6px;
    border: none;
  }

  .div-select {
    display: flex;
    flex-direction: column;
    width: 50%;
    margin-bottom: 20px;
  }

  .input {
    width: 100%;
    max-width: none;
  }

  .nome-marca {
    margin: 10px 10px 0px;
  }

  .relative {
    position: relative;

    span {
      position: absolute;
      top: 0;
      right: 0;
      font-size: 12px;
      cursor: pointer;
    }
  }

  button {
    border: none;
    border-radius: 20px;
    padding: 6px 14px;
    font-size: 14px;
    font-weight: 700;
    margin-top: 8px;
    color: var(--principal);
    background-color: var(--amarelo);
  }

  label {
    font-size: 0.9rem;
    font-weight: 600;
    margin-bottom: 5px;
  }

  h1 {
    font-size: 42px;
    font-weight: 600;
    margin-left: 10px;
    color: var(--principal);
  }

  .som {
    margin-left: 10px;
  }
`;
