import { Link, useNavigate } from "react-router-dom";
import {
  Body,
  Inputs,
  NovaCategoriaContainer,
} from "./styles";
import { toast } from "react-toastify";
import api from "../../../../services/api";
import { useForm } from "react-hook-form";
import { useAuth } from "../../../../providers/Auth/index";
import { useState,useEffect } from "react";


const NovaCategoriaItem = () => {
  const {
    // formState: { errors },
    handleSubmit,
    register,
  } = useForm({
    // resolver: yupResolver(NovoCadastroSchema),
  });

  const { user } = useAuth();
  const navigate = useNavigate();
  const [empresas,setEmpresas] = useState([])

  useEffect(()=>{
    buscarEmpresa()
  },[])

  function buscarEmpresa() {
    api.get("/empresas")
      .then((response) => {
        setEmpresas(response.data.data.empresas);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  //CRIAR CATEGORIA ITEM
  const onSubmitFunction = (data) => {
    const categorias = [data];
    api
      .post("/categoria-item", { categorias })
      .then((response) => {
        navigate("/itens/categoria");
        toast.success("Categoria cadastrada com sucesso!");
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  return (
    <NovaCategoriaContainer onSubmit={handleSubmit(onSubmitFunction)}>
      <div className="box">
        <div>
          <h1> Cadastrar nova categoria </h1>
        </div>

        <Body>
          <div className="checkbox">
            <input
              type="checkbox"
              id="checkbox1"
              name="status"
              {...register("status")}
              defaultChecked={true}
            />

            <label htmlFor="checkbox1">Ativo</label>
          </div>
          <Inputs>
            <div>
              <label>Nome da categoria</label>
              <input
                type="text"
                name="nome"
                {...register("nome")}
                placeholder="Nome da categoria"
              />
            </div>
            <div>
              <label>Empresa</label>
              <select name="empresa_id" {...register("empresa_id")}>
                <option>Selecione a empresa</option>
                {empresas.map((data) => (
                  <option key={data.id} value={data.id}>
                    {data.nome}
                  </option>
                ))}
              </select>
              {/* {errors.perfil_id && (
                <span className="input-error-cadastro">{errors.perfil_id.message}</span>
              )} */}
            </div>
          </Inputs>
        </Body>
        <div className="buttons">
          <Link to="/itens/categoria">{"< Voltar"}</Link>
          <button type="submit">Salvar</button>
        </div>
      </div>
    </NovaCategoriaContainer>
  );
};

export default NovaCategoriaItem;
