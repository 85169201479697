import { Form, InputContainer, LoginButton } from "./styles";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import api from "../../../services/api";
import LoginEmailSvg from "../../../assets/email.svg";
import LoginSenhaSvg from "../../../assets/senha.svg";
import LoginVerSenhaSvg from "../../../assets/ver-senha.svg";
import LogoVesta from "../../../assets/logo-vesta.svg"
import { ReactSVG } from "react-svg";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { loginSchema } from "../../../schemas/login";
import { useAuth } from '../../../providers/Auth';


const LoginForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(loginSchema),
  });

  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const { setToken } = useAuth();
  const navigate = useNavigate();

  const onSubmitFunction = (data) => {
    setLoading(true);
    api.post("/login", data)
      .then((response) => {
        const token = response.headers["x-access-token"];
        localStorage.setItem("@erp:token", token);
        setToken(token);
      })
      .catch((error) => {
        toast.error("Email ou senha inválidos. Tente novamente.");
        setLoading(false);
      });
  };

  return (
    <Form onSubmit={handleSubmit(onSubmitFunction)}>
      <div className="logo">
      <img src={LogoVesta} alt="imagem do Login" className="logo-vesta" />
       
      </div>
      <p>Preencha os campos e efetue o login</p>
      <InputContainer>
   
        <label htmlFor="email">
          <ReactSVG src={LoginEmailSvg} />
        </label>
        <input
          id="email"
          placeholder="E-mail"
          type="text"
          autoComplete="username"
          {...register("email")}
          />
        {errors.email && (
          <span className="input-error">{errors.email.message}</span>
          )}
      </InputContainer>
      <InputContainer $showPassword={showPassword}>
        <label htmlFor="password">
          <ReactSVG src={LoginSenhaSvg} />
        </label>
        <input
          id="password"
          placeholder="Senha"
          autoComplete="current-password"
          type={showPassword ? "text" : "password"}
          {...register("senha")}
          />
        {errors.senha && (
          <span className="input-error">{errors.senha.message}</span>
          )}
        <ReactSVG
          className="show-password"
          src={LoginVerSenhaSvg}
          onClick={() => setShowPassword(!showPassword)}
          />
      </InputContainer>
      <LoginButton>
        {loading ? (
          <div className="loading-container">
            <span className="circle"></span>
            <span className="circle"></span>
            <span className="circle"></span>
            <span className="circle"></span>
          </div>
        ) : (
          "Entrar"
        )}

      </LoginButton>
      <Link to='/senha'>Esqueci minha senha</Link>
    </Form>
  );
};

export default LoginForm;
