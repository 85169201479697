import { createContext, useContext, useState, useEffect } from "react";
import api from "../../services/api";
import { toast } from "react-toastify";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState("");
  const [user, setUser] = useState("");
  const [tokenLoaded, setTokenLoaded] = useState(false);
  const [userLoaded, setUserLoaded] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("@erp:token");
    if (token) {
      setToken(token);
    }
    setTokenLoaded(true);
  }, []);

  //BUSCAR DADOS DO USUARIO
  useEffect(() => {
    if (tokenLoaded) {
      if (token) {
        api
          .get("/profile")
          .then((response) => {
            setUser(response.data.data.usuario);
            setUserLoaded(true);
          })
          .catch((error) => {
            toast.error(
              "Ocorreu um erro ao carregar o usuário. Tente novamente mais tarde."
            );
            setUserLoaded(true);
          });
      } else {
        setUserLoaded(true);
      }
    }
  }, [token, tokenLoaded]);

  return (
    <AuthContext.Provider
      value={{
        setToken,
        token,
        user,
        userLoaded,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
