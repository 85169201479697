import { Link, useNavigate, useParams } from "react-router-dom";
import { Body, Inputs, NovoUsuarioContainer } from "./styles";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import api from "../../../../services/api";
import { CampoObrigatorio } from "../../../../schemas/novoCadastro";
import { toast } from "react-toastify";
import { useAuth } from "../../../../providers/Auth";

const EditarCategoriaItem = () => {
  const { id } = useParams();

  const {
    register,
    getValues,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(CampoObrigatorio),
  });

  const navigate = useNavigate();

  const onSubmitFunction = () => {
    const formValues = getValues();

    api.patch(`/categoria-item/${id}`, formValues)
      .then((response) => {
        navigate("/itens/categoria");
        toast.success("Categoria atualizado com sucesso!");
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  function buscaCategoriaItem(){
    api.get(`/categoria-item/${id}`)
      .then((response) => {
        reset(response.data.data.categoria);
      })
      .catch((error) => {
        console.error(error);
    });
  }

  useEffect(() => {
    buscaCategoriaItem()
  }, [id]);

  return (
    <NovoUsuarioContainer onSubmit={handleSubmit(onSubmitFunction)}>
      <div className="box">
        <div>
          <h1> Editar Categoria</h1>
        </div>

        <Body>
          <div className="checkbox">
            <input
              type="checkbox"
              id="checkbox1"
              name="status"
              defaultChecked={true}
              {...register("status")}
            />
            <label htmlFor="checkbox1">Ativo</label>
          </div>
          <Inputs>
            <div>
              <label>Nome da categoria</label>
              <input
                type="text"
                name="nome"
                {...register("nome")}
                placeholder="Nome da categoria"
              />
              {errors.nome && (
                <span className="input-error-cadastro">
                  {errors.nome.message}
                </span>
              )}
            </div>
          </Inputs>
        </Body>
        <div className="buttons">
          <Link to="/itens/categoria">{"< Voltar"}</Link>
          <button type="submit">Salvar</button>
        </div>
      </div>
    </NovoUsuarioContainer>
  );
};

export default EditarCategoriaItem;
