import { ListaCategoriaStyle } from "./style";
import { CardVitrine } from "../../CardVitrine";

export const ListaCategoria = ({
  categoria,
  materiais,
  materiaisPendentes,
}) => {
  return (
    <ListaCategoriaStyle>
      <div className="div-flex">
        <h3 className="title">{categoria}</h3>
      </div>
      <div className="cards">
        {materiais.map((element) => (
          <CardVitrine
            key={element.id}
            material={element}
            materiaisPendentes={materiaisPendentes}
          />
        ))}
      </div>
    </ListaCategoriaStyle>
  );
};
