
import NovaSenha from '../../components/NovaSenha';
import { NovaSenhaPageContainer } from './styles';

const NovaSenhaPage = () => {

    return (
        
        <NovaSenhaPageContainer>
            <NovaSenha />
        </NovaSenhaPageContainer>
    );
};

export default NovaSenhaPage;