import styled from "styled-components";

export const PedirRevisaoStyle = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;

  .flex {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }

  .background {
    position: relative;
    display: flex;
    flex-direction: column;
    text-align: center;
    border-radius: 30px;
    background-color: var(--base);
    padding: 25px 20px;
    width: 70%;
    max-width: 800px;
  }

  h2 {
    font-size: 16px;
    font-weight: 700;
  }

  h3 {
    font-size: 14px;
    font-weight: 500;
  }

  textarea {
    height: 100px;
    max-width: 100%;
    min-width: 100px;
    border-radius: 6px;
    padding: 5px;
  }

  .chat {
    max-height: 300px;
    overflow-y: auto;
    margin-bottom: 20px;
  }

  .mensagem {
    display: flex;

    .esquerda {
      display: flex;
      justify-content: flex-start;
    }

    div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      background-color: #f0f0f0;
      padding: 10px;
      border-radius: 10px;
      margin-bottom: 10px;
      width: 100%;
      width: 45%;
    }

    h3 {
      font-weight: 700;
    }

    p {
      text-align: start;
      word-break: break-all;
    }
  }

  .direita {
    display: flex;
    flex-direction: row-reverse !important;
  }

  button {
    padding: 10px 20px;
    border: none;
    background-color: var(--amarelo);
    color: var(--principal);
    border-radius: 24px;
    font-size: 16px;
    font-weight: 700;
    width: max-content;
    margin: 10px auto 0px;
  }

  .column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-top: 20px;
  }

  .min {
    min-width: 136px;
  }

  .close {
    position: absolute;
    top: -20px;
    right: -15px;
    padding: 15px 15px;
    background-color: #fa5252;
    border-radius: 300px;
    font-size: 32px;
    font-weight: 400;
    color: var(--base);
    text-decoration: none;
  }
`;
