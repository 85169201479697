import { useEffect, useState } from "react";
import Menu from "../../components/Menu";
import { PropostaFornecedorPageStyle } from "./style";
import api from "../../services/api";
import { toast } from "react-toastify";
import { ReactSVG } from "react-svg";
import Botao from "../../assets/voltar.svg";
import { FormPropostaFornecedorPage } from "../../components/Forms/FormPropostaFornecedor";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../providers/Auth";
import { FormPropostaFornecedorVitrine } from "../../components/Forms/FormPropostaVitrineFornecedor";
import Loading from "../../components/Loading";

export const PropostaFornecedorPage = () => {
  const { user } = useAuth();
  const [campanha, setCampanha] = useState(false);
  const [materialBriefing, setMaterialBriefing] = useState([]);
  const [materialVitrine, setMaterialVitrine] = useState([]);
  const [marcas, setMarcas] = useState([]);
  const [itens, setItens] = useState([]);
  const [ncm, setNcm] = useState([]);
  const [orcamentos, setOrcamentos] = useState([]);
  const [fornecedor, setFornecedor] = useState([]);
  const [categoriaFornecedor, setCategoriaFornecedor] = useState();
  const [dadosFornecedor, setDadosFornecedor] = useState();
  const [categorias, setCategorias] = useState([]);

  const navigate = useNavigate();

  const urlAtual = window.location.href;
  const urlObj = new URL(urlAtual);
  const id = urlObj.pathname.split("/").pop();

  function buscarCampanhas() {
    api
      .get(`/campanhas/${id}`)
      .then((response) => {
        setCampanha(response.data.data.campanha[0]);
        buscarMarcas(response.data.data.campanha[0].id);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  function buscarMarcas(id) {
    api
      .get(`/campanha-marca/${id}`)
      .then((response) => {
        setMarcas(response.data.data.vinculos);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  // TODO: Só verificar se está sendo usado no Material Vitrine e remover
  function buscarItens() {
    api
      .get(`/itens`)
      .then((response) => {
        setItens(response.data.data.itens);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  function buscarNCM() {
    api
      .get(`/ncm`)
      .then((response) => {
        setNcm(response.data.data.ncms);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  function buscarOrcamentoBriefing(id) {
    api
      .get(`/orcamento-materiais-briefing/fornecedor/${id}`)
      .then((response) => {
        const data = response.data.data.orcamentos;
        setOrcamentos(data);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  function buscarCampanhasFornecedor(idFornecedor) {
    api
      .get(
        `/campanha-material-briefing/fornecedores/fornecedor/${idFornecedor}`
      )
      .then((response) => {
        const filtrarPorCampanha = response.data.data.fornecedores.filter(
          (briefing) => Number(briefing.campanhas_id) == Number(id)
        );
        setMaterialBriefing(filtrarPorCampanha);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  function buscarFornecedor() {
    api
      .get(`/usuario-fornecedor/${user.id}`)
      .then((response) => {
        setFornecedor(response.data.data.usuario);
        buscarOrcamentoBriefing(response.data.data.usuario.fornecedor_id);
        buscarDadosFornecedor(response.data.data.usuario.fornecedor_id);
        buscarCampanhasFornecedor(response.data.data.usuario.fornecedor_id);
        buscarMaterialVitrine(response.data.data.usuario.fornecedor_id);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  function buscarMaterialVitrine(fornecedor_id) {
    api
      .get(`/campanha-material-vitrine/${id}`)
      .then((response) => {
        const arrayVitrine = response.data.data.materiais;

        setMaterialVitrine(arrayVitrine);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  function buscarCategoriaFornecedor(id) {
    api
      .get(`/categoria-fornecedor/${id}`)
      .then((response) => {
        setCategoriaFornecedor(response.data.data.categoria.nome);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  function buscarDadosFornecedor(id) {
    api
      .get(`/fornecedores/${id}`)
      .then((response) => {
        setDadosFornecedor(response.data.data.fornecedor);
        buscarCategoriaFornecedor(
          response.data.data.fornecedor.segmento_fornecedor_id
        );
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  useEffect(() => {
    buscarCampanhas();
    buscarItens();
    buscarNCM();
  }, []);

  useEffect(() => {
    if (user.id !== undefined) {
      buscarFornecedor();
    }
  }, [user]);

  return (
    <PropostaFornecedorPageStyle>
      <Menu />
      {campanha ? (
        <div className="width">
          <div className="div-detalhes">
            <div className="flex header">
              <ReactSVG
                className="back"
                onClick={() => navigate("/fornecedor/campanhas")}
                src={Botao}
              />
              <h1># {campanha && `${campanha.id} ${campanha.nome}`}</h1>
              <h2 className="nome-campanha">{}</h2>
            </div>
            <div className="column">
              <div className="div-dados">
                <div className="flex">
                  <h3 className="font-weight">Cliente:</h3>
                  <h4 className="nome">{campanha.cliente_nome}</h4>
                </div>
                <div className="flex">
                  <h3 className="font-weight">Marca:</h3>
                  {marcas &&
                    marcas.map((element, index) => (
                      <h4 className={`h4-${index}`}>{element.nome}</h4>
                    ))}
                </div>
              </div>
            </div>
          </div>
          {materialBriefing ? (
            materialBriefing.map((element, index) => (
              <FormPropostaFornecedorPage
                element={element}
                fornecedor={fornecedor}
                index={index}
                length={[...materialBriefing, ...materialVitrine].length}
                campanha={campanha}
                orcamentos={orcamentos}
                dadosFornecedor={dadosFornecedor}
                categoriaFornecedor={categoriaFornecedor}
                buscarFornecedor={buscarFornecedor}
              />
            ))
          ) : (
            <Loading />
          )}
          {materialVitrine &&
            materialVitrine.map((element, index) => (
              <FormPropostaFornecedorVitrine
                element={element}
                fornecedor={fornecedor}
                index={index + materialBriefing.length}
                length={[...materialBriefing, ...materialVitrine].length}
                itens={itens}
                campanha={campanha}
                ncm={ncm}
                orcamentos={orcamentos}
                dadosFornecedor={dadosFornecedor}
                categoriaFornecedor={categoriaFornecedor}
                buscarFornecedor={buscarFornecedor}
                categorias={categorias}
              />
            ))}
        </div>
      ) : (
        <Loading />
      )}
    </PropostaFornecedorPageStyle>
  );
};
