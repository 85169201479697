import * as yup from "yup";

export const NovoCadastroSchema = yup.object().shape({
  email: yup
    .string()
    .email("Formato de e-mail inválido.")
    .required("Campo obrigatório"),
  nome: yup.string().required("Campo obrigatório"),
  senha: yup.string().required("Campo obrigatório "),
});

export const CampoObrigatorio = yup.object().shape({
  nome: yup.string().required("Campo obrigatório"),
});

export const FornecedorSchema = yup.object().shape({
  documento: yup.string().required("Campo obrigatório"),
  razao_social: yup.string().required("Campo obrigatório"),
  nome: yup.string().required("Campo obrigatório"),
  regime_empresa: yup.string().required("Campo obrigatório"),
  categoria: yup.string().required("Campo obrigatório"),
  segmento_atuacao: yup.string().required("Campo obrigatório"),
  email_principal: yup.string().required("Campo obrigatório"),
  email_financeiro: yup.string().required("Campo obrigatório"),
});

export const NovoClienteSchema = yup.object().shape({
  documento: yup.string().required("Campo obrigatório"),
  razao_social: yup.string().required("Campo obrigatório"),
  nome_fantasia: yup.string().required("Campo obrigatório "),
  inscricao_estadual: yup.string().required("Campo obrigatório "),
  codigo_postal: yup.string().required("Campo obrigatório"),
  logradouro: yup.string().required("Campo obrigatório"),
  numero: yup.string().required("Campo obrigatório "),
  bairro: yup.string().required("Campo obrigatório "),
  cidade: yup.string().required("selecione uma das opções"),
  estado: yup.string().required("Campo obrigatório "),
  email_principal: yup.string().required("selecione uma das opções"),
  contato_principal: yup.string().required("Campo obrigatório "),
  contato_financeiro: yup.string().required("Campo obrigatório "),
  email_financeiro: yup.string().required("selecione uma das opções"),
  prazo_pagamento: yup.string().required("Campo obrigatório "),
  moeda_transacao: yup.string().required("selecione uma das opções"),
  cnae: yup.string().required("Campo obrigatório"),
  tipo: yup.string().required("Campo obrigatório"),
});
