import api from "../../../services/api";
import { ModalAdicionarProdutorStyled } from "./style";
import { useEffect, useState } from "react";
import Loading from "../../Loading";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useAuth } from "../../../providers/Auth";

export const ModalAdicionarProdutor = ({
  campanha,
  briefing,
  state,
  buscarMaterialBriefing,
}) => {
  const { token } = useAuth();
  const { register, getValues } = useForm();
  const [produtores, setProdutores] = useState(false);
  const [briefingProdutores, setBriefingProdutores] = useState([]);

  function listarProdutores() {
    api
      .get(`/clientes/${campanha.cliente_id}/produtores`)
      .then((response) => {
        setBriefingProdutores(response.data.data.produtores.briefing);
        setProdutores(response.data.data.produtores.produtores);
      })
      .catch((error) => {
        console.error("Listar Produtores Erro:", error);
        toast.error("Erro ao listar produtores. Tente novamente.");
      });
  }

  function adicionarProdutor() {
    api
      .patch(
        `/campanha-material-briefing/${briefing.id}`,
        { produtor_id: getValues("produtor") },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        enviarEmailParaProdutor();
        buscarMaterialBriefing();
        toast.success("Produtor adicionado! 🎉");
        state(false);
      })
      .catch((error) => {
        console.error("Adicionar Produtor Erro:", error);
        toast.error("Erro ao adicionar produtor. Tente novamente.");
      });
  }

  function enviarEmailParaProdutor() {
    api
      .post(
        `/email/produtor-selecionado`,
        {
          produtor_id: getValues("produtor"),
          campanha: campanha,
          briefing: briefing,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        toast.success("Email enviado com sucesso! 🎉");
        state(false);
      })
      .catch((error) => {
        console.error("Enviar Email Para Produtor Erro:", error);
        toast.error("Erro ao enviar email para produtor. Tente novamente.");
      });
  }

  useEffect(() => {
    listarProdutores();
  }, []);

  return (
    <ModalAdicionarProdutorStyled>
      {produtores ? (
        <div>
          <span className="close" onClick={() => state(false)}>
            X
          </span>
          <div>
            <h1>Adicionar Produtor</h1>
            <div>
              <select {...register("produtor")}>
                {produtores.map((produtor) => (
                  <option value={produtor.id}>{produtor.nome}</option>
                ))}
              </select>
              <button className="adicionar" onClick={() => adicionarProdutor()}>
                {briefing.produtor_nome === "Pendente" ? "Adicionar" : "Editar"}
              </button>
            </div>
          </div>
          <div className="position">
            <h3 className="bold">Produtor Responsável: </h3>
            <h3 className={briefing.produtor_nome === "Pendente" ? "red" : ""}>
              {briefing.produtor_nome}
            </h3>
          </div>
          <div className="table">
            <div className="margin">
              {produtores.map((produtor) => (
                <div className="coluna">
                  <h2>{produtor.nome}</h2>
                  {briefingProdutores
                    .filter((element) => element.produtor_id == produtor.id)
                    .map((briefingProdutor) => {
                      return (
                        <div className="card">
                          <div className="flex">
                            <h4 className="bold">#{briefingProdutor.id}</h4>{" "}
                            <h4>{briefingProdutor.nome}</h4>
                          </div>
                          <h5
                            className={`${
                              briefingProdutor.status ===
                                "enviado pra orçamento" ||
                              briefingProdutor.status === "pendente"
                                ? "aguardando-orcamento"
                                : briefingProdutor.status ===
                                  "enviado para os fornecedores"
                                ? "aguardando-disputa"
                                : briefingProdutor.status === "em produção"
                                ? "producao"
                                : briefingProdutor.status === "concluido"
                                ? "concluido"
                                : briefingProdutor.status ===
                                  "aguardando análise"
                                ? "aguardando-disputa"
                                : ""
                            }`}
                          >
                            {briefingProdutor.status}
                          </h5>
                        </div>
                      );
                    })}
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <Loading className="loading" />
      )}
    </ModalAdicionarProdutorStyled>
  );
};
