import EditarNcm from "../../../components/Cadastros/Editar/EditarNcm";
import NovoNcm from "../../../components/Cadastros/Novo/NovoNcm";
import Header from "../../../components/Menu";

const EditarNcmPage = () => {
  return (
    <div className="flex">
      <Header />
      <EditarNcm />
    </div>
  );
};

export default EditarNcmPage;
