import { Link, useNavigate } from "react-router-dom";
import { Body, Inputs, NovaCategoriaContainer } from "./styles";
import { toast } from "react-toastify";
import api from "../../../../services/api";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAuth } from "../../../../providers/Auth/index";
import voltar from "../../../../assets/voltar.svg";

import { useEffect, useState } from "react";
import { CampoObrigatorio } from "../../../../schemas/novoCadastro";
import { ReactSVG } from "react-svg";

const NovoPerfil = () => {
  const {
    formState: { errors },
    handleSubmit,
    register,
    getValues,
  } = useForm({
    resolver: yupResolver(CampoObrigatorio),
  });

  const [permissoesSelecionadas, setPermissoesSelecionadas] = useState([]);
  const [permissoes, setPermissoes] = useState([]);
  const navigate = useNavigate();

  function adicionarPermissao(element) {
    if (
      permissoesSelecionadas.includes(Number(element)) === false &&
      element > 0
    ) {
      setPermissoesSelecionadas((prevSelecionadas) => [
        ...prevSelecionadas,
        Number(element),
      ]);
    }
  }

  function removerPermissao(id) {
    const filter = permissoesSelecionadas.filter((element) => element != id);
    setPermissoesSelecionadas(filter);
  }

  function buscarPermissoes() {
    api
      .get("/permissoes")
      .then((response) => {
        setPermissoes(response.data.data.permissoes);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  useEffect(() => {
    buscarPermissoes();
  }, []);
  const onSubmitFunction = (data) => {
    api
      .post("/perfis", { perfis: [data] })
      .then((response) => {
        toast.success("Perfil cadastrado com sucesso!");
        const formValues = getValues();
        const vinculos = {
          ...formValues,
          perfil_id: response.data.data.insertId,
          permissoes: permissoesSelecionadas,
        };
        api
          .post("/perfil-permissao/perfil", vinculos)
          .then((response) => {
            navigate("/perfis");
            toast.success("permissão criada com sucesso!");
          })
          .catch((error) => {
            toast.error(error.response.data.message);
          });
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };
  return (
    <NovaCategoriaContainer onSubmit={handleSubmit(onSubmitFunction)}>
      <div className="box">
        <div className="flex">
          <ReactSVG
            onClick={() => navigate("/perfis")}
            className="voltar"
            src={voltar}
          />
          <div className="column">
            <h1>Perfil</h1>
            <h2>Novo perfil</h2>
          </div>
        </div>
        <Body>
          <div className="checkbox">
            <input
              type="checkbox"
              id="checkbox1"
              name="status"
              {...register("status")}
            />
            <label htmlFor="checkbox1">Ativo</label>
          </div>
          <Inputs>
            <div>
              <label>Nome do perfil</label>
              <input
                type="text"
                name="nome"
                {...register("nome")}
                placeholder="Nome do perfil"
              />
              {errors.nome && (
                <span className="input-error-cadastro">
                  {errors.nome.message}
                </span>
              )}
            </div>

            <div>
              <label>Permissão</label>
              <select
                onClick={(e) => adicionarPermissao(e.target.value)}
                {...register("marcas_id")}
                name="permissao"
                {...register("permissao")}
              >
                <option>Selecione uma permissão</option>
                {permissoes &&
                  permissoes.map((data) => (
                    <option key={data.id} value={data.id}>
                      {data.nome}
                    </option>
                  ))}
              </select>
            </div>
          </Inputs>
          <div className="flex wrap">
            {permissoesSelecionadas.length > 0 &&
              permissoesSelecionadas
                .map((elemen) =>
                  permissoes.filter((element) => element.id === elemen)
                )
                .map((elemento) => (
                  <div className="relative">
                    <p className="nome-marca">{elemento[0].nome}</p>
                    <span onClick={() => removerPermissao(elemento[0].id)}>
                      X
                    </span>
                  </div>
                ))}
          </div>
        </Body>
        <div className="buttons">
          <Link to="/perfis">{"< Voltar"}</Link>
          <button type="submit">Salvar</button>
        </div>
      </div>
    </NovaCategoriaContainer>
  );
};

export default NovoPerfil;
