import Marcas from "../../components/Marcas";
import Header from "../../components/Menu";
const MarcaPage = () => {
  return (
    <div className="flex">
      <Header />
      <Marcas />
    </div>
  );
};

export default MarcaPage;
