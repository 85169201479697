import { CalculosOrcamentoStyle, TableContainer } from "./style";

export const CalculosOrcamento = ({ calculos, state }) => {
  if (calculos.tipo === "Serviço") {
    return (
      <CalculosOrcamentoStyle>
        <div className="body">
          <span onClick={() => state(false)} className="close">
            X
          </span>
          <TableContainer>
            <table>
              <thead className="border-bottom">
                <tr>
                  <th className="vazio"></th>
                  <th className="vazio"></th>
                  <th>Valor de Compra</th>
                  <th>Fee</th>
                  <th>ISS</th>
                  <th>Base de PIS/COFINS</th>
                  <th>PIS</th>
                  <th>COFINS</th>
                  <th>TOTAL</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="background-yellow">
                    <h3>Fornecedor</h3>
                  </td>
                  <td className="yellow margin-bottom border-bottom">%</td>
                  <td>100%</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>{calculos.impostosFornecedor.pis}%</td>
                  <td>{calculos.impostosFornecedor.cofins}%</td>
                  <td>-</td>
                </tr>
                <tr className="margin-bottom">
                  <td className="background-yellow relative border-bottom">
                    <h4 className="red">IN</h4>
                  </td>
                  <td className="yellow border-bottom">R$</td>
                  <td>R$ {calculos.orcamento.valor_total}</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>R$ {calculos.pisPagoFornecedor}</td>
                  <td>R${calculos.confinsPagoFornecedor}</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td className="background-yellow">
                    <h3>Vesta</h3>
                  </td>
                  <td className="yellow margin-bottom border-bottom">%</td>
                  <td>-</td>
                  <td>
                    {" "}
                    {calculos.feeEspecifico
                      ? calculos.feeEspecifico
                      : calculos.fee}
                    %
                  </td>
                  <td>{calculos.iss}%</td>
                  <td>
                    {(Number(calculos.valorTotalComIss) /
                      Number(calculos.orcamento.valor_total)) *
                      100}
                    %
                  </td>
                  <td>{calculos.regimeLucroPresumido.pis}%</td>
                  <td>{calculos.regimeLucroPresumido.cofins}%</td>
                  <td>-</td>
                </tr>
                <tr className="margin-bottom">
                  <td className="background-yellow relative border-bottom">
                    <h4 className="red">OUT</h4>
                  </td>
                  <td className="yellow border-bottom">R$</td>
                  <td>-</td>
                  <td>-</td>
                  <td>R$ {calculos.valorComIss}</td>
                  <td>R$ {Number(calculos.valorTotalComIss)}</td>
                  <td>R$ {Number(calculos.valorPisSemReducaoVesta)}</td>
                  <td>R$ {Number(calculos.valorCofinsSemReducaoVesta)}</td>
                  <td>
                    R${" "}
                    {parseFloat(
                      Number(calculos.orcamento.valor_total) +
                        Number(calculos.valorComIss) +
                        Number(calculos.valorPisSemReducaoVesta) +
                        Number(calculos.valorCofinsSemReducaoVesta)
                    ).toFixed(4)}
                  </td>
                </tr>
                <tr className="margin-bottom">
                  <td className="background-yellow relative border-bottom">
                    <h4 className="h4-red">Dedução impostos (in-out)</h4>
                  </td>
                  <td className="yellow border-bottom">%</td>
                  <td>-</td>
                  <td>-</td>
                  <td>R$ {calculos.valorComIss}</td>
                  <td></td>
                  <td>R$ {calculos.valorPisComReducaoVesta}</td>
                  <td>R$ {calculos.valorCofinsComReducaoVesta}</td>
                  <td>
                    R${" "}
                    {parseFloat(
                      Number(calculos.orcamento.valor_total) +
                        Number(calculos.valorComIss) +
                        Number(calculos.valorPisComReducaoVesta) +
                        Number(calculos.valorCofinsComReducaoVesta)
                    ).toFixed(4)}
                  </td>
                </tr>
                <tr>
                  <td className="background-yellow relative">
                    <h4>Fee</h4>
                  </td>
                  <td className="yellow">R$</td>
                  <td>-</td>
                  <td>R$ {calculos.valorTotalComDeducaoFee}</td>
                  <td>R$ {calculos.valorFee}</td>
                  <td>R$ {calculos.valorTotalComReducaoMaisFee}</td>
                  <td>R$ {calculos.valorPisFinal}</td>
                  <td>R$ {calculos.valorCofinsFinal}</td>
                  <td>
                    R${" "}
                    {parseFloat(
                      Number(calculos.valorTotalComDeducaoFee) +
                        Number(calculos.valorFee) +
                        Number(calculos.valorCofinsFinal) +
                        Number(calculos.valorPisFinal)
                    ).toFixed(4)}
                  </td>
                </tr>
              </tbody>
            </table>
          </TableContainer>
          <div className="div-valor-venda">
            <h2>Valor de Venda: R$ {calculos.valorFinal}</h2>
          </div>
        </div>
      </CalculosOrcamentoStyle>
    );
  } else {
    return (
      <CalculosOrcamentoStyle>
        <div className="body">
          <span onClick={() => state(false)} className="close">
            X
          </span>
          <TableContainer>
            <table>
              <thead className="border-bottom">
                <tr>
                  <th className="vazio"></th>
                  <th className="vazio"></th>
                  <th>Valor de Compra</th>
                  <th>IPI</th>
                  <th>Fee</th>
                  <th>ISS</th>
                  <th>MVA ICMS ST</th>
                  <th>Base de cálculo ICMS</th>
                  <th>ICMS</th>
                  <th>Base de PIS/COFINS</th>
                  <th>PIS</th>
                  <th>COFINS</th>
                  <th>TOTAL</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="background-yellow">
                    <h3>Fornecedor</h3>
                  </td>
                  <td className="yellow margin-bottom border-bottom">%</td>
                  <td>100%</td>
                  <td>
                    {calculos.ipi && calculos.ipi > 0
                      ? calculos.ipi + "%"
                      : "0%"}
                  </td>
                  <td>-</td>
                  <td>-</td>
                  <td>{calculos.icms_st ? calculos.icms_st : 0}%</td>
                  <td>-</td>
                  <td>{calculos.icms}%</td>
                  <td>-</td>
                  <td>{calculos.pis}%</td>
                  <td>{calculos.cofins}%</td>
                  <td>-</td>
                </tr>
                <tr className="margin-bottom">
                  <td className="background-yellow relative border-bottom">
                    <h4 className="red">IN</h4>
                  </td>
                  <td className="yellow border-bottom">R$</td>
                  <td>R$ {calculos.valorDeCompra}</td>
                  <td>
                    R${" "}
                    {calculos.ipi && calculos.ipi > 0
                      ? calculos.valorSemIPI
                      : 0}
                  </td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>R$ {calculos.icmsPagoFornecedor}</td>
                  <td>-</td>
                  <td>R$ {calculos.pisPagoFornecedor}</td>
                  <td>R$ {calculos.confinsPagoFornecedor}</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td className="background-yellow">
                    <h3>Vesta</h3>
                  </td>
                  <td className="yellow margin-bottom border-bottom">%</td>
                  <td>-</td>
                  <td>-</td>
                  <td>
                    {calculos.feeEspecifico
                      ? calculos.feeEspecifico
                      : calculos.fee}
                    %
                  </td>
                  <td>0%</td>
                  <td>{Number(calculos.fator).toFixed(4) * 100}%</td>
                  <td>
                    {(
                      (calculos.baseDeCalculoIcms / calculos.valorDeCompra) *
                      100
                    ).toFixed(4)}
                    %
                  </td>
                  <td>{calculos.icmsVesta}%</td>
                  <td>
                    {(
                      (
                        calculos.baseDeCalculoPisCofins / calculos.valorDeCompra
                      ).toFixed(4) * 100
                    ).toFixed(4)}
                    %
                  </td>
                  <td>{calculos.pisVesta.toFixed(4)}%</td>
                  <td>{calculos.cofinsVesta.toFixed(4)}%</td>
                  <td>-</td>
                </tr>
                <tr className="margin-bottom">
                  <td className="background-yellow relative border-bottom">
                    <h4 className="red">OUT</h4>
                  </td>
                  <td className="yellow border-bottom">R$</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>R$ {calculos.baseDeCalculoIcms.toFixed(4)}</td>
                  <td>R$ {calculos.icmsSemReducaoVesta.toFixed(4)}</td>
                  <td>R$ {calculos.baseDeCalculoPisCofins}</td>
                  <td>R$ {calculos.pisSemReducaoVesta}</td>
                  <td>R$ {calculos.cofinsSemReducaoVesta}</td>
                  <td>
                    R${" "}
                    {(
                      Number(calculos.valorDeCompra) +
                      Number(calculos.icmsSemReducaoVesta.toFixed(4)) +
                      Number(calculos.pisSemReducaoVesta) +
                      Number(calculos.cofinsSemReducaoVesta)
                    ).toFixed(4)}
                  </td>
                </tr>
                <tr className="margin-bottom">
                  <td className="background-yellow relative border-bottom">
                    <h4 className="h4-red">Dedução impostos (in-out)</h4>
                  </td>
                  <td className="yellow border-bottom">%</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>{calculos.icmsFinalVesta.toFixed(4)}</td>
                  <td>-</td>
                  <td>{calculos.pisFinalVesta}</td>
                  <td>{calculos.cofinsFinalVesta}</td>
                  <td>
                    R${" "}
                    {Number(calculos.icmsFinalVesta) +
                      Number(calculos.pisFinalVesta) +
                      Number(calculos.cofinsFinalVesta) +
                      Number(calculos.valorDeCompra)}
                  </td>
                </tr>
                <tr>
                  <td className="background-yellow relative">
                    <h4>Fee</h4>
                  </td>
                  <td className="yellow">R$</td>
                  <td>-</td>
                  <td>-</td>
                  <td>R$ {calculos.valorTotalComDeducaoFee}</td>
                  <td>-</td>
                  <td>-</td>
                  <td>R$ {calculos.baseDeCalculoIcmsComFee}</td>
                  <td>R$ {calculos.icmsFee}</td>
                  <td>R$ {calculos.baseDeCalculoPisCofinsComFee}</td>
                  <td>R$ {calculos.pisFee}</td>
                  <td>R$ {calculos.cofinsFee}</td>
                  <td>
                    R${" "}
                    {(
                      Number(calculos.valorTotalComDeducaoFee) +
                      Number(calculos.icmsFee) +
                      Number(calculos.pisFee) +
                      Number(calculos.cofinsFee)
                    ).toFixed(4)}
                  </td>
                </tr>
              </tbody>
            </table>
          </TableContainer>
          <div className="div-valor-venda">
            <h2>Valor de Venda: R$ {calculos.valorDeVenda.toFixed(4)}</h2>
          </div>
        </div>
      </CalculosOrcamentoStyle>
    );
  }
};
