import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import api from "../../services/api";
import Loading from "../Loading";

export const FaixaDePreco = ({
  elem,
  fornecedor,
  register,
  reset,
  elemPosterior,
}) => {
  const [dadosOrcamento, setDadosOrcamento] = useState();

  const buscarOrcamento = () =>
    api
      .get(`orcamento-materiais-briefing/orcamento/${elem.orcamento_id}`)
      .then((response) => {
        response.data.data.orcamentos[0].validade_orcamento =
          response.data.data.orcamentos[0].validade_orcamento
            .split("-")
            .reverse()
            .join("/");
        setDadosOrcamento(response.data.data.orcamentos[0]);
        const obj = {};
        obj[`quantidade_minima${elem.id}`] = elem.quantidade_minima;
        obj[`preco_unitario${elem.id}`] = elem.preco_unitario;
        obj[`prazo_entrega${elem.id}`] = elem.prazo_entrega;
        obj[`validade_orçamento`] = elem.validade_orcamento;
        reset(obj);
      })
      .catch((error) => {
        console.error(error);
      });

  useEffect(() => {
    buscarOrcamento();
  }, []);

  if (!dadosOrcamento) {
    return <Loading />;
  }

  return (
    <tr>
      <td>
        <input
          type="number"
          {...register(`quantidade_minima${elem.id}`)}
          min={1}
        />
      </td>
      <td>
        <input
          value={elemPosterior ? elemPosterior.quantidade_minima - 1 : "..."}
        />
      </td>
      <td>
        <input {...register(`preco_unitario${elem.id}`)} />
      </td>
      <td>
        <input value={fornecedor && fornecedor.nome} />
      </td>
      <td>
        <input {...register(`prazo_entrega${elem.id}`)} />
      </td>
      <td>
        <input value={elem.orcamento_id} />
      </td>
      <td>
        <input
          {...register(`validade_orcamento${elem.id}`)}
          value={dadosOrcamento && dadosOrcamento.validade_orcamento}
        />
      </td>
    </tr>
  );
};
