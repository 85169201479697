import { styled } from "styled-components";

export const HeaderContainer = styled.div`
  &::-webkit-scrollbar {
    width: 3px;
    border-radius: 8px;
    background-color: var(--base);
    height: 30px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--principal);
    border-radius: 5px;
    height: 30px;
  }

  background-color: var(--base);
  min-width: 260px;
  height: 100vh;
  display: flex;
  padding: 35px 0px;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  position: sticky;
  top: 0;
  overflow-y: auto;
  overflow-x: hidden;

  .logo {
    width: 190px;
  }

  .itemVitrine {
    svg {
      width: 20px;
      height: 20px;
    }
  }

  .category {
    width: 100%;
    padding: 6px 10px 2px 10px;
    display: flex;
    align-items: center;
    border: none;
    border-radius: 7px;
    margin-bottom: 20px;
    background-color: var(--base);

    p {
      margin-left: 7px;
    }
  }

  .categories {
    margin-top: 40px;
    width: 100%;
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .cadastros {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 130px;
      background-color: transparent;
      border: none;
      flex-direction: column;
      position: relative;

      svg {
        width: 35px;
      }
    }

    .nav {
      background-color: var(--base);
      border: 1px solid var(--grayD2);
      box-shadow: 0px 3px 6px #00000029;
      position: absolute;
      min-width: 130px;
      padding: 10px;
      border-radius: 7px;
      z-index: 1;

      nav {
        display: flex;
        flex-direction: column;

        .item {
          border: none;
        }

        > div {
          display: flex;
          gap: 10px;
          padding: 2px;
          align-items: center;
          border-bottom: 1px solid var(--grayD2);

          a {
            font-size: 0.7rem;
            cursor: pointer;

            &:hover {
              color: var(--principal);
              font-weight: 600;
            }
          }

          svg {
            width: 15px;
            margin-top: 2px;
          }
        }
      }
    }
  }
  .profile {
    display: flex;
    align-items: center;
    flex-direction: row;

    position: relative;

    img {
      margin-right: 16px;
    }

    p {
      font-size: 0.8rem;
      font-weight: 500;
    }
  }

  .flex {
    display: flex;

    p {
      margin-left: 5px;
      text-decoration: underline;
      color: var(--principal);
      font-weight: 500;
      cursor: pointer;
    }
  }

  .profileLoggout {
    background-color: var(--base);
    border: 1px solid var(--grayD2);
    box-shadow: 0px 3px 6px #00000029;
    position: absolute;
    right: 0;
    top: 75px;
    min-width: 130px;
    padding: 10px;
    border-radius: 7px;

    .sair {
      border: none;
    }

    > div {
      display: flex;
      align-items: center;
      gap: 5px;
      margin-top: 5px;
      border-bottom: 1px solid var(--grayD2);

      p {
        font-weight: 400;
        cursor: pointer;
        margin-top: 5px;
      }
    }
  }

  .imagem-perfil {
    width: 75px;
    border-radius: 100px;
  }

  .extends {
    margin-left: 18px;
    display: flex;
    width: 95%;
    flex-direction: column;
    align-content: center;
    justify-content: center;
  }
`;
