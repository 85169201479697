import styled from "styled-components";

export const RelatorioContasPagarStyle = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  padding: 20px;
  border-radius: 18px;

  button {
    display: flex;
    margin: 30px auto;
    border-radius: 6px;
    color: white;
    background-color: #7d0000;
    padding: 6px 12px;
    font-size: 0.8rem;
    font-weight: 500;
    border: none;
  }

  input {
    border: none;
    padding: 6px 12px;
    margin: 12px 0px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  }

  h2 {
    font-size: 1.2rem;
    font-weight: 600;
  }
`;
