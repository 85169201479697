import { ReactSVG } from "react-svg";
import FornecedorSvg from "../../assets/fornecedor.svg";
import { PedidoComprasCampanhaContainer } from "./styles";
import api from "../../services/api";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import GerarPedidoComprasModal from "../Modal/GerarPedidoComprasModal";
import Loading from "../Loading";
import PedidoComprasMaterial from "./PedidoComprasMaterial";

const PedidoComprasCampanha = ({
  propostas,
  campanha,
  buscarMaterialBriefing,
  marcas,
}) => {
  const [propostaMateriais, setPropostaMateriais] = useState(false);

  const [propostaMateriaisLoaded, setPropostaMateriaisLoaded] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (propostaMateriaisLoaded) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [propostaMateriaisLoaded]);

  const buscarPropostaMateriais = async () => {
    setPropostaMateriaisLoaded(false);
    try {
      let materiais = [];
      for (const proposta of propostas) {
        const response = await api.get(`/proposta-materiais/${proposta.id}`);
        materiais = [...materiais, ...response.data.data.materiais];
      }
      setPropostaMateriais(materiais);
      setPropostaMateriaisLoaded(true);
    } catch (error) {
      toast.error("Erro ao buscar materiais da proposta.");
    }
  };

  useEffect(() => {
    buscarPropostaMateriais();
  }, []);

  return !propostaMateriais ? (
    <Loading />
  ) : propostaMateriais.length > 0 ? (
    <PedidoComprasCampanhaContainer>
      <h2>
        <ReactSVG src={FornecedorSvg} /> Produção
      </h2>
      {loading ? (
        <Loading />
      ) : (
        <>
          {propostaMateriais.map((material) => (
            <PedidoComprasMaterial
              key={material.id}
              campanha={campanha}
              material={material}
              buscarPropostaMateriais={buscarPropostaMateriais}
              setLoading={setLoading}
              buscarMaterialBriefing={buscarMaterialBriefing}
              marcas={marcas}
            />
          ))}
        </>
      )}
    </PedidoComprasCampanhaContainer>
  ) : (
    <></>
  );
};

export default PedidoComprasCampanha;
