import styled from "styled-components";

export const ModalObservacoesFornecedorStyle = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;

  .div-body {
    width: 90%;
    max-width: 520px;
    padding: 50px;
    background-color: var(--base01);
    margin: 0 auto;
    border-radius: 8px;
    position: relative;
  }

  .close {
    position: absolute;
    top: -15px;
    right: -15px;
    color: var(--base01);
    background-color: #fa5252;
    padding: 11px 13px;
    font-size: 30px;
    font-weight: 300;
    border-radius: 30px;
  }

  .width-90 {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  h5 {
    font-size: 14px;
    font-weight: 500;
  }

  .input-text {
    width: 100%;
    padding-bottom: 50px;
    margin-top: 20px;
    border: none;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
      rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    max-height: 250px;
  }

  .input-check {
    width: 17px;
    height: 17px;
    margin-right: 4px;
  }

  .margin-top {
    margin-top: 30px;
  }

  button {
    position: unset;
    display: flex;
    margin: 20px auto 0px;
    padding: 8px 15px;
  }

  h3 {
    width: max-content;
  }

  h4 {
    display: flex;
    margin-top: 8px;
  }

  .entrega {
    width: max-content;
    margin-top: 20px;
  }
`;
