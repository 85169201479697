import { ConfirmacaoDeOrcamentoStyle } from "./style";

export const ConfirmacaoDeOrcamento = ({
  state,
  verificarData,
  handleSubmit,
  criarOrcamento,
  setAbrirModal,
}) => {
  return (
    <ConfirmacaoDeOrcamentoStyle>
      <div>
        <span onClick={() => state(false)} className="close">
          X
        </span>
        <h3>
          Você está ciente que é de sua responsabilidade todas as informações
          fornecidas e assume e compromisso conforme contrato firmado entre as
          empresas para o que está sendo formalizado.
        </h3>
        <div className="flex">
          <button className="recusar" onClick={() => setAbrirModal(false)}>
            Recusar
          </button>
          <button
            onClick={() => {
              handleSubmit(criarOrcamento);
            }}
          >
            OK!
          </button>
        </div>
      </div>
    </ConfirmacaoDeOrcamentoStyle>
  );
};
