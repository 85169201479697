import styled from "styled-components";

export const PedirRevisaoStyle = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;

  .flex {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }

  .background {
    position: relative;
    display: flex;
    flex-direction: column;
    text-align: center;
    border-radius: 30px;
    background-color: var(--base);
    padding: 45px 30px 15px;
    width: 538px;
  }

  h2 {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
  }

  h3 {
    font-size: 14px;
    font-weight: 500;
    display: contents;
  }

  textarea {
    height: 180px;
    max-width: 100%;
    min-width: 100px;
    border-radius: 4px;
  }

  button {
    border: none;
    display: flex;

    font-size: 16px;
    font-weight: 700;

    width: max-content;

    padding: 10px 20px;
    border-radius: 24px;
    margin: 10px auto 0px;

    color: var(--principal);
    background-color: var(--amarelo);

    position: initial;
    justify-content: center;
  }

  .column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    width: 100%;

    display: grid;
    grid-template-columns: repeat(3, 1fr);

    margin-top: 20px;
  }

  .min {
    min-width: 136px;
  }

  .close {
    position: absolute;
    top: -20px;
    right: -15px;
    padding: 15px 15px;
    background-color: #fa5252;
    border-radius: 300px;
    font-size: 32px;
    font-weight: 400;
    color: var(--base);
    text-decoration: none;
  }
`;
