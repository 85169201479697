import styled from 'styled-components';

export const Tr = styled.tr`

    > td {
        padding-left: 10px;
        height: 50px;
        border-bottom: 1px solid rgba(112, 112, 112, 0.51);

        > label {
            display: none;
        }

        > .content {
            display: flex;
            justify-content: space-between;
            align-items: center;

            > a {
               color: #609AE5; 
               font-size: 0.8em;
            }

            > button {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 150px;
                height: 30px;
                background: #EF5713;
                border-radius: 20px;
                color: #fff;
                border: none;
                font-size: 0.8em;

                > div {
                    height: 16px;
                    svg {
                        height: 16px;
                    }
                }

            }
        }

        > .loading {
            @keyframes animation-loading {
                0% {
                    opacity: 0;
                }

                40% {
                    opacity: 1;
                }

                80% {
                    opacity: 0;
                }

                100% {
                    opacity: 0;
                }
            }

            display: flex;
            padding: 15px 20px;
            column-gap: 8px;

            > span {
                background-color: #292828;
                border-radius: 10px;
                display: inline-block;
                height: 10px;
                vertical-align: top;
                width: 10px;
            }

            > span:first-child {
                animation: animation-loading 1s ease-in-out 0ms infinite;
            }

            > span:nth-child(2) {
                animation: animation-loading 1s ease-in-out 160ms infinite;
            }

            > span:last-child {
                animation: animation-loading 1s ease-in-out 320ms infinite;
            }
        }
    }

    @media screen and (max-width: 768px) {
        display: flex;
        flex-direction: column;
        border: 1px solid #E9E9E9;
        border-radius: 15px;
        margin: 1rem 0;
        padding: 1rem;
        
        > td {
            display: flex;
            justify-content: space-between;
            align-items: center;
            
            > label {
                display: flex;
                color: #292828;
                font-weight: 600;
            }
        }

        > td:last-child {
            border-bottom: none;
        }
    }
`