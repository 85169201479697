import { styled } from "styled-components";

export const ItensCategoriaContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 60px;

  .box {
    margin-top: 40px;
    width: 90%;
    height: 700px;
    background-color: var(--base);
    border-radius: 18px;
    padding: 40px;
  }
  .filtro {
    display: flex;
    align-items: center;
    gap: 20px;
    width: 50%;

    input {
      border: none;
      border: 1px solid var(--grayA5);
      padding: 10px;
      border-radius: 50px;
      width: 70%;
      height: 40px;
    }

    div {
      align-items: center;
      justify-content: center;
      display: flex;
      flex-direction: column;
      margin-bottom: 25px;
      width: 35%;

      > p {
        font-size: 0.8rem;
        margin-bottom: 8px;
      }
    }

    select,
    option {
      width: 161px;
      padding: 5px;
      border-radius: 6px;
      border: none;
      outline: none;
      background: var(--principal);
      text-align: center;
      font-size: 0.8rem;
      color: var(--base);
      font-weight: 200;
      border-right: 10px solid var(--principal);
    }
  }
`;

export const BoxFiltros = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 40px;

  button {
    background-color: var(--principal);
    color: var(--base);
    width: 20%;
    font-size: 0.8rem;
    font-weight: 600;
    padding: 8px;
    border: none;
    border-radius: 20px;
  }
`;
