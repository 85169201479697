import { useForm } from "react-hook-form";
import { ConfirmacaoDeOrcamentoStyle } from "./style";

export const EditarPrazoConcorrencia = ({
  state,
  editarPrazo,
  materialBriefing,
}) => {
  const { register, getValues } = useForm();

  return (
    <ConfirmacaoDeOrcamentoStyle>
      <div>
        <span onClick={() => state(false)} className="close">
          X
        </span>
        <h3>
          {materialBriefing.prazo_propostas
            ? "Tem certeza que deseja editar prazo máximo de envio de orçamento?"
            : "Adiconar prazo máximo de envio de orçamento?"}
        </h3>
        <input type="datetime-local" {...register("prazo_propostas")} />
        <div className="div-buttons">
          <button
            className="cancelar"
            onClick={() => {
              state(false);
            }}
          >
            Cancelar
          </button>
          <button
            onClick={() => {
              editarPrazo(getValues("prazo_propostas"));
              state(false);
            }}
          >
            {materialBriefing.prazo_propostas ? "Editar" : "Adicionar"}
          </button>
        </div>
      </div>
    </ConfirmacaoDeOrcamentoStyle>
  );
};
