import { styled } from "styled-components";

export const MateriaisViaVitrineStyle = styled.div`
  width: 90%;
  margin: 40px auto 0;
  background-color: var(--base);
  padding: 30px 30px;
  border-radius: 20px;

  .header {
    display: flex;
    align-items: center;
    margin-left: 4px;

    svg {
      margin-right: 10px;
    }

    h2 {
      color: var(--principal);
      font-weight: 600;
      margin-right: 10px;
    }

    span {
      text-decoration: underline;
      font-size: 0.9em;
      cursor: pointer;
      color: #609ae5;
    }
  }

  .descricao {
    width: 85%;
    font-size: 10px;
    display: -webkit-box;

    text-overflow: ellipsis;

    overflow: hidden;

    -webkit-line-clamp: 2;

    -webkit-box-orient: vertical;
  }

  .total-container {
    display: flex;
    column-gap: 10px;
    justify-content: flex-end;
    > .total {
      display: flex;
      align-items: center;
      column-gap: 5px;
       
      > b {
        font-weight: 600;
        font-size: 0.9em;
      }

      > div {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100px;
        height: 30px;
        background: #7D0000;
        border-radius: 7px;
        color: #fff;
        font-weight: 500;
        font-size: 0.8em;
      }
    }

    > .enviar {
      background: #FFBE00 0% 0% no-repeat padding-box;
      border-radius: 20px;
      border: none;
      color: #7D0000;
      font-weight: 600;
      font-size: 0.8em;
      width: 200px;
      height: 30px;
    }
  }
`;
