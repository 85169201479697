import { yupResolver } from "@hookform/resolvers/yup";
import { EditarDataCampanhaStyle } from "./style";
import { useForm } from "react-hook-form";
import { Input } from "../../Input";
import { EditarCampanhaSchema } from "../../../schemas/campanha";
import api from "../../../services/api";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

export const EditarDataCampanha = ({ data, state, buscarCampanhas }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(EditarCampanhaSchema),
  });
  const { id } = useParams();
  const getDataUTC = (utc) => {
    const dataAtual = new Date();
    const dataNaoFormatada = new Date(dataAtual.valueOf() - utc * 60 * 60000);

    const { dia, mes, anoCompleto, hora, minuto } = {
      dia: dataNaoFormatada.getUTCDate().toString().padStart(2, "0"),
      mes: (dataNaoFormatada.getUTCMonth() + 1).toString().padStart(2, "0"),
      anoCompleto: dataNaoFormatada.getFullYear(),
      hora: dataNaoFormatada.getUTCHours().toString().padStart(2, "0"),
      minuto: dataNaoFormatada.getUTCMinutes().toString().padStart(2, "0"),
    };

    const dataFormatada = [dia, mes, anoCompleto].join("/");
    return dataFormatada;
  };

  const adicionarHistorico = (prazo) => {
    const data = {
      tipo: "Campanha",
      texto: `Prazo Máximo Campanha alterado para: ${prazo}`,
      campanha_id: id,
    };

    api
      .post("/historico-campanha", [data])
      .then((response) => {})
      .catch((error) => {
        toast.error("Erro ao salvar no historico");
      });
  };

  const acao = (value) => {
    const partes = value.prazo.split("-");
    data.prazo = `${partes[2]}/${partes[1]}/${partes[0]}`;

    function converterFormatoData(dataOriginal) {
      const partesData = dataOriginal.split("/");
      const dataObj = new Date(
        `${partesData[2]}-${partesData[1]}-${partesData[0]}`
      );
      const dataConvertida = dataObj.toISOString().split("T")[0];
      return dataConvertida;
    }

    const dataAtual = converterFormatoData(getDataUTC(3));

    if (dataAtual < value.prazo) {
      api
        .patch(`/campanhas/${id}`, { prazo_final: data.prazo })
        .then((response) => {
          state(false);
          toast.success("Prazo final atualizado com sucesso!");
          adicionarHistorico(data.prazo);
          buscarCampanhas();
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        });
    } else {
      toast.error("Prazo final Inválido");
    }
  };

  return (
    <EditarDataCampanhaStyle>
      <div>
        <form onSubmit={handleSubmit(acao)}>
          <span onClick={() => state(false)} className="close">
            X
          </span>
          <Input
            className="input"
            label="Prazo final (data máxima da entrega da campanha)"
            type="date"
            placeholder="DD/MM/AAAA"
            register={register("prazo")}
          />
          {/* {errors.prazo && (
            <span className="input-error">{errors.prazo.message}</span>
          )} */}
          <button>Editar</button>
        </form>
      </div>
    </EditarDataCampanhaStyle>
  );
};
