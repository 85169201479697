import { BoxLeft, BoxRight, NovaSenhaContainer } from "./styles";
import loginVesta from "../../assets/imagem-login.svg";
import NovaSenhaForm from "../Forms/NovaSenhaForm";
import { motion } from "framer-motion";

const NovaSenha = () => {
  return (
    <NovaSenhaContainer>
      <BoxLeft>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 1, ease: "easeInOut" }}
        >
          <NovaSenhaForm />
        </motion.div>
      </BoxLeft>
      <BoxRight>
        <img src={loginVesta} alt="gestao" />
      </BoxRight>
    </NovaSenhaContainer>
  );
};

export default NovaSenha;
