import { useEffect, useState } from "react";
import { ModalAdicionarFornecedoresBriefing } from "../Modal/AddFornecedoresCampanhaMaterialBriefing";
import { TabelaDisputaStyled, TableContainer } from "./style";
import api from "../../services/api";
import { toast } from "react-toastify";
import { ModalConfirmacaoDeFornecedor } from "../Modal/ConfirmacaoDeFornecedor";
import { ReactSVG } from "react-svg";
import { CalculosOrcamento } from "../Modal/CalculosOrcamento";
import Calculo from "../../assets/calculo.svg";
import Nota from "../../assets/nota.svg";
import Negado from "../../assets/negado.svg";
import { ModalObservacoesFornecedor } from "../Modal/ObservacoesFornecedor";
import Excluir from "../../assets/excluir-material.svg";
import check from "../../assets/ativo.svg";
import { useAuth } from "../../providers/Auth";
import { useParams } from "react-router-dom";
import Estrela from "../../assets/estrela.svg";
import Editar from "../../assets/editar.svg";
import Prazo from "../../assets/prazo-concorrencia.svg";
import AddFornecedor from "../../assets/add-fornecedor.svg";
import LiberarOrcamentos from "../../assets/liberar-orçamento.svg";
import { PedirAprovacaoCompras } from "../Modal/PedirAprovacaoCompras";
import { DevolutivaCompras } from "../Modal/DevolutivaCompras";
import { ModalCriarOrcamento } from "../Modal/CriarOrcamento";

export const TabelaDisputa = ({
  orcamentos,
  buscarMaterialBriefing,
  materialBriefing,
  ncm,
  fee,
  categoriasFornecedor,
  bloquear,
  item,
  quantidade,
  setAbrirModalEditarPrazo,
}) => {
  const { token, user } = useAuth();
  const { id } = useParams();
  const [fornecedores, setFornecedores] = useState([]);
  const [abrirModalAddFornecedores, setAbrirModalAddFornecedores] =
    useState(false);
  const [abrirModalConfirmacao, setModalConfirmacao] = useState(false);
  const [abrirModalCalculos, setAbrirModalCalculos] = useState(false);
  const [propostaSelecionada, setPropostaSelecionada] = useState();
  const [orcamentoAprovado, setOrcamentoAprovado] = useState();
  const [regimesEmpresas, setRegimesEmpresas] = useState();
  const [calculos, setCalculos] = useState();
  const [valorDeVendaState, setValorDeVendaState] = useState();
  const [abrirModalObservacoesFornecedor, setAbrirModalObservacoesFornecedor] =
    useState(false);
  const [observacoes, setObservacoes] = useState();
  const [fornecedoresCadastrados, setFornecedoresCadastrados] = useState([]);
  const [fornecedoresNaoCadastrados, setFornecedoresNaoCadastrados] = useState(
    []
  );
  const [abrirModalPedirRevisaoCompras, setAbrirModalPedirRevisaoCompras] =
    useState(false);
  const [menorValor, setMenorValor] = useState(0);
  const [todosFornecedoresCadastrados, setTodosFornecedoresCadastrados] =
    useState([]);
  const produtorNegociando = orcamentos.filter(
    (orcamento) => orcamento.status === "Revisão Compras"
  );
  const [abrirModalDevolutiva, setAbrirModalDevolutiva] = useState(false);
  const [abrirModalCriarOrcamento, setAbrirModalCriarOrcamento] =
    useState(false);

  const [dadosParaCriarOrcamento, setDadosParaCriarOrcamento] = useState(false);

  useEffect(() => {
    setMenorValor(0);
  }, [quantidade]);

  function buscarFornecedor() {
    api
      .get("/fornecedores")
      .then((response) => {
        setFornecedores(response.data.data.fornecedores);
        buscarFornecedorCampanhaMaterialBriefing(
          response.data.data.fornecedores
        );
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  function buscarFornecedorCampanhaMaterialBriefing(fornecedores) {
    api
      .get(`/campanha-material-briefing/fornecedores/${id}`)
      .then((response) => {
        const responseFornecedoresCadastrados = response.data.data.fornecedores;
        const idsFornecedoresCadastrados = responseFornecedoresCadastrados.map(
          (element) => Number(element.fornecedor_id)
        );

        setTodosFornecedoresCadastrados(responseFornecedoresCadastrados);
        const filterFornecedores = fornecedores.filter((element) =>
          idsFornecedoresCadastrados.includes(element.id)
        );
        const elementosUnicos = fornecedores.filter(
          (elemento) => !filterFornecedores.includes(elemento)
        );
        setFornecedoresNaoCadastrados(elementosUnicos);

        setFornecedoresCadastrados(filterFornecedores);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  function buscarMaterialProposta() {
    const filterOrcamentoAprovado = orcamentos.find(
      (elem) => elem.status === "aprovado"
    );

    if (filterOrcamentoAprovado) {
      api
        .get(`/proposta-materiais/${filterOrcamentoAprovado.id}`)
        .then((response) => {
          setOrcamentoAprovado(response.data.data.materiais[0]);
        })
        .catch((error) => {
          console.error("Erro ao buscar dados da API:", error);
          toast.error("Erro ao buscar dados");
        });
    } else {
      setOrcamentoAprovado();
    }
  }

  const verificarPrazoProposta = () => {
    if (materialBriefing.prazo_propostas) {
      setAbrirModalAddFornecedores(true);
    } else {
      toast.error("Favor adicionar um prazo máximo de propostas");
    }
  };

  function listarRegimes() {
    api
      .get("/regimes-empresa")
      .then((response) => {
        setRegimesEmpresas(response.data.data.regimesEmpresa);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  function liberarParaFornecedores() {
    let array = [];
    if (
      todosFornecedoresCadastrados.filter(
        (element) => element.status === "fornecedor selecionado"
      ).length === 0
    ) {
      return toast.error("Selecione ao menos um fornecedor!");
    }

    for (let i = 0; i < todosFornecedoresCadastrados.length; i++) {
      const obj = {
        campanha_material_briefing_id: id,
        fornecedor_id: todosFornecedoresCadastrados[i].fornecedor_id,
      };

      array.push(obj);
    }

    api
      .patch(
        `/campanha-material-briefing/fornecedores/${id}`,
        { fornecedores: array },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        toast.success("Fornecedores Adicionados no Briefing! 🎉");
        buscarFornecedor();
      })
      .catch((error) => {
        toast.error("Algo deu Errado. Tente novamente.");
      });
  }

  function deletarFornecedorSelecionado(fornecedor_id) {
    api
      .delete(
        `campanha-material-briefing/fornecedores/${id}/${fornecedor_id}`,
        {
          fornecedor_id: Number(fornecedor_id),
          campanha_material_briefing_id: id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        toast.success("Fornecedor Removidos no Briefing! 🎉");
        buscarFornecedor();
      })
      .catch((error) => {
        toast.error("Algo deu Errado. Tente novamente.");
      });
  }

  useEffect(() => {
    buscarFornecedor();
    listarRegimes();
  }, []);

  useEffect(() => {
    setPropostaSelecionada("");
  }, [quantidade]);

  useEffect(() => {
    buscarMaterialProposta();
  }, [orcamentos]);

  const colunas = [
    "Fornecedor",
    "ICMS",
    "IPI",
    "ICMS-ST MVA",
    "Valor un.",
    "Valor de Compra",
    "Notas",
    "Entrega",
    "Valor venda",
    "Regime Empresa",
    `${
      todosFornecedoresCadastrados.filter(
        (elem) => elem.status === "fornecedor adicionado"
      ).length > 0
        ? "Selecionar"
        : "Excluir"
    }`,
    ,
  ];

  const colunaServiço = [
    "Fornecedor",
    "Entrega",
    "Valor de Compra",
    "Valor venda",
    "Notas",
    `${
      todosFornecedoresCadastrados.filter(
        (elem) => elem.status === "fornecedor adicionado"
      ).length > 0
        ? "Selecionar"
        : "Excluir"
    }`,
    ,
  ];

  const getDataUTC = (utc) => {
    const dataAtual = new Date();
    const dataNaoFormatada = new Date(dataAtual.valueOf() - utc * 60 * 60000);

    const { dia, mes, anoCompleto, hora, minuto } = {
      dia: dataNaoFormatada.getUTCDate().toString().padStart(2, "0"),
      mes: (dataNaoFormatada.getUTCMonth() + 1).toString().padStart(2, "0"),
      anoCompleto: dataNaoFormatada.getFullYear(),
      hora: dataNaoFormatada.getUTCHours().toString().padStart(2, "0"),
      minuto: dataNaoFormatada.getUTCMinutes().toString().padStart(2, "0"),
    };

    const dataFormatada = [dia, mes, anoCompleto].join("/");
    return dataFormatada;
  };

  const partesData = getDataUTC(3).split("/");

  return (
    <>
      <TabelaDisputaStyled>
        <TableContainer>
          <div className="space-between">
            <h2 className="titulo">Fornecedores </h2>
            <div className="flex">
              <div className="position-icone">
                <ReactSVG
                  disabled={
                    user.permissoes.includes("admin") ||
                    user.permissoes.includes("produtor")
                      ? false
                      : true
                  }
                  className="icone"
                  src={Prazo}
                  onClick={() => setAbrirModalEditarPrazo(true)}
                />
                <h4
                  className="label-icone label-prazo"
                  onClick={() => setAbrirModalEditarPrazo(true)}
                >
                  {materialBriefing.prazo_propostas ? "Atualizar" : "Adicionar"}
                </h4>
              </div>
              <div className="position-icone">
                <ReactSVG
                  disabled={
                    user.permissoes.includes("admin") ||
                    user.permissoes.includes("produtor")
                      ? false
                      : true
                  }
                  className={
                    "icone" +
                    (!materialBriefing.prazo_propostas ? " botao-disabled" : "")
                  }
                  src={AddFornecedor}
                  onClick={() => verificarPrazoProposta()}
                />
                <h4
                  className="label-icone label-fornecedor"
                  onClick={() => verificarPrazoProposta()}
                >
                  Add. Fornecedores
                </h4>
              </div>
              <div className="position-icone">
                <ReactSVG
                  disabled={
                    user.permissoes.includes("admin") ||
                    user.permissoes.includes("produtor")
                      ? false
                      : true
                  }
                  className={
                    "icone" +
                    (todosFornecedoresCadastrados.length === 0
                      ? " botao-disabled"
                      : "")
                  }
                  src={LiberarOrcamentos}
                  onClick={() => liberarParaFornecedores()}
                />
                <h4
                  className="label-icone label-liberar"
                  onClick={() => liberarParaFornecedores()}
                >
                  Liberar Orçamentos
                </h4>
              </div>
            </div>
          </div>
          <table>
            <thead>
              <tr>
                {materialBriefing.tipo === "Serviço"
                  ? colunaServiço.map((coluna, index) => (
                      <th key={coluna}>
                        {index == 0 ? (
                          <>
                            {coluna}
                            {todosFornecedoresCadastrados.filter(
                              (elem) => elem.status === "fornecedor adicionado"
                            ).length > 0 &&
                            !user.permissoes.includes("admin") ? (
                              ""
                            ) : (
                              <div>
                                <span
                                  hidden={
                                    !user.perfil === "produtor" &&
                                    !user.perfil === "admin"
                                      ? true
                                      : false
                                  }
                                  className="adicionar-fornecedor"
                                  onClick={() => verificarPrazoProposta()}
                                >
                                  Adicionar
                                </span>
                              </div>
                            )}
                          </>
                        ) : (
                          coluna
                        )}
                      </th>
                    ))
                  : colunas.map((coluna, index) => (
                      <th key={coluna} className={index == 0 ? "flex" : ""}>
                        {coluna}
                      </th>
                    ))}
              </tr>
            </thead>
            <tbody>
              {todosFornecedoresCadastrados.filter(
                (elem) => elem.status === "fornecedor adicionado"
              ).length > 0
                ? fornecedores
                    .filter((element) =>
                      todosFornecedoresCadastrados
                        .filter((el) => el.status === "fornecedor adicionado")
                        .map((e) => Number(e.fornecedor_id))
                        .includes(Number(element.id))
                    )
                    .map((element) => {
                      const orcamento = orcamentos.find(
                        (eleme) => eleme.fornecedor_id == element.id
                      );

                      if (!orcamento && materialBriefing.tipo === "Serviço") {
                        return (
                          <tr>
                            <td className="fornecedores">{element.nome}</td>
                            <td className="iss">-</td>
                            <td className="quantidade">-</td>
                            <td className="entrega">-</td>
                            <td>
                              <ReactSVG
                                className="nota editar"
                                src={Editar}
                                onClick={() => {
                                  setAbrirModalCriarOrcamento(true);
                                  setDadosParaCriarOrcamento({
                                    fornecedor: element,
                                    materialBriefing: materialBriefing,
                                  });
                                }}
                                // hidden={bloquear}
                              />
                            </td>
                            <input
                              className="selecionar"
                              type="radio"
                              id="ifollow1"
                              name="empresa_id1"
                              disabled={true}
                            />
                          </tr>
                        );
                      } else if (!orcamento) {
                        return (
                          <tr>
                            <td className="fornecedores">{element.nome}</td>
                            <td className="icms">-</td>
                            <td className="ipi">-</td>
                            <td className="icms">-</td>
                            <td className="quantidade">-</td>
                            <td className="quantidade">-</td>
                            <td
                              className="entrega"
                              onClick={() => {
                                setAbrirModalCriarOrcamento(true);
                                setDadosParaCriarOrcamento({
                                  fornecedor: element,
                                  materialBriefing: materialBriefing,
                                });
                              }}
                            >
                              <ReactSVG className="nota editar" src={Editar} />
                            </td>
                            <ReactSVG
                              className="notas"
                              src="https://cdn.icon-icons.com/icons2/1875/PNG/512/note_120060.png"
                            />
                            <td>-</td>
                            <td>-</td>
                            <td>
                              <input
                                className="selecionar"
                                type="radio"
                                id="ifollow1"
                                name="empresa_id1"
                                disabled={true}
                              />
                            </td>
                          </tr>
                        );
                      } else if (orcamento.status == "Não Participa") {
                        return (
                          <tr>
                            <td className="fornecedores">{element.nome}</td>
                            <td className="icms">-</td>
                            <td className="ipi">-</td>
                            <td className="icms">-</td>
                            <td className="quantidade">-</td>
                            <ReactSVG className="recusado" src={Negado} />
                            <td className="quantidade">
                              {" "}
                              <ReactSVG
                                className="nota editar"
                                src={Editar}
                                onClick={() => {
                                  setAbrirModalCriarOrcamento(true);
                                  setDadosParaCriarOrcamento({
                                    fornecedor: element,
                                    materialBriefing: materialBriefing,
                                  });
                                }}
                                // hidden={bloquear}
                              />
                            </td>
                            <td className="entrega">-</td>
                            <td>-</td>
                            <td>
                              <input
                                className="selecionar"
                                type="radio"
                                id="ifollow1"
                                name="empresa_id1"
                                disabled={true}
                              />
                            </td>
                          </tr>
                        );
                      } else if (materialBriefing.tipo === "Serviço") {
                        const impostosFornecedor = regimesEmpresas.find(
                          (eleme) =>
                            eleme.regime_empresa === element.regime_empresa
                        );
                        const regimeLucroPresumido = regimesEmpresas.find(
                          (eleme) => eleme.regime_empresa === "Real"
                        );

                        const pisPagoFornecedor = parseFloat(
                          (
                            (orcamento.valor_total / 100) *
                            impostosFornecedor.pis
                          ).toFixed(4)
                        );

                        const confinsPagoFornecedor = parseFloat(
                          (
                            (orcamento.valor_total / 100) *
                            impostosFornecedor.cofins
                          ).toFixed(4)
                        );

                        const valorComIss = parseFloat(
                          (Number(orcamento.valor_total) * Number(item.iss)) /
                            100
                        ).toFixed(4);

                        const valorTotalComIss = parseFloat(
                          Number(valorComIss) + Number(orcamento.valor_total)
                        ).toFixed(4);

                        const valorPisSemReducaoVesta = parseFloat(
                          (valorTotalComIss * regimeLucroPresumido.pis) / 100
                        ).toFixed(4);

                        const valorCofinsSemReducaoVesta = parseFloat(
                          (valorTotalComIss * regimeLucroPresumido.cofins) / 100
                        ).toFixed(4);

                        const valorPisComReducaoVesta = parseFloat(
                          valorPisSemReducaoVesta - pisPagoFornecedor
                        ).toFixed(4);

                        const valorCofinsComReducaoVesta = parseFloat(
                          valorCofinsSemReducaoVesta - confinsPagoFornecedor
                        ).toFixed(4);

                        const valorTotalComReducaoVesta = parseFloat(
                          Number(valorTotalComIss) +
                            Number(valorCofinsComReducaoVesta) +
                            Number(valorPisComReducaoVesta)
                        ).toFixed(4);

                        const valorTotalComDeducaoFee = materialBriefing.fee
                          ? parseFloat(
                              (
                                (Number(materialBriefing.fee) *
                                  Number(valorTotalComReducaoVesta)) /
                                100
                              ).toFixed(4)
                            )
                          : parseFloat(
                              (
                                (Number(fee) *
                                  Number(valorTotalComReducaoVesta)) /
                                100
                              ).toFixed(4)
                            );

                        const valorFee = parseFloat(
                          (valorTotalComDeducaoFee * item.iss) / 100
                        ).toFixed(4);

                        const valorTotalComReducaoMaisFee = parseFloat(
                          Number(valorTotalComDeducaoFee) + Number(valorFee)
                        ).toFixed(4);

                        const valorPisFinal = parseFloat(
                          (Number(valorTotalComReducaoMaisFee) *
                            regimeLucroPresumido.pis) /
                            100
                        ).toFixed(4);

                        const valorCofinsFinal = parseFloat(
                          (Number(valorTotalComReducaoMaisFee) *
                            regimeLucroPresumido.cofins) /
                            100
                        ).toFixed(4);

                        const valorFinal = parseFloat(
                          Number(valorTotalComReducaoVesta) +
                            Number(valorTotalComReducaoMaisFee) +
                            Number(valorPisFinal) +
                            Number(valorCofinsFinal)
                        ).toFixed(4);

                        if (
                          Number(valorFinal) < menorValor ||
                          menorValor === 0
                        ) {
                          setMenorValor(Number(valorFinal));
                        }

                        orcamento.valor_final = valorFinal;

                        // console.log(orcamento);
                        // console.log(valorComIss);
                        // console.log(valorTotalComIss);
                        // console.log(valorPisSemReducaoVesta);
                        // console.log(valorCofinsSemReducaoVesta);
                        // console.log(valorPisComReducaoVesta);
                        // console.log(valorCofinsComReducaoVesta);
                        // console.log(valorTotalComReducaoVesta);
                        // console.log(valorTotalComDeducaoFee);
                        // console.log(valorFee);
                        // console.log(valorTotalComReducaoMaisFee);
                        // console.log(valorPisFinal);
                        // console.log(valorCofinsFinal);
                        // console.log(valorFinal);

                        // console.log(impostosFornecedor);
                        // console.log(regimeLucroPresumido);
                        // console.log(pisPagoFornecedor);
                        // console.log(confinsPagoFornecedor);
                        return (
                          <tr>
                            <td className="fornecedores">
                              {element.nome}{" "}
                              {Number(valorFinal) === menorValor ? "" : ""}
                            </td>
                            <td className="entrega">
                              {orcamentos
                                ? `${
                                    Number(partesData[0]) +
                                    Number(orcamento.prazo_producao)
                                  }/${partesData[1]}/${Number(partesData[2])}`
                                : "-"}
                            </td>
                            <td>R${orcamento.valor_total}</td>
                            <td>
                              <div
                                className={`flex valorVenda ${
                                  Number(valorFinal) === menorValor
                                    ? "melhorPreco"
                                    : ""
                                }`}
                              >
                                {Number(valorFinal) === menorValor ? (
                                  <ReactSVG src={Estrela} />
                                ) : (
                                  ""
                                )}{" "}
                                R${valorFinal}
                              </div>
                            </td>
                            <td>
                              <div className="flex">
                                <ReactSVG
                                  src={Nota}
                                  className="nota"
                                  onClick={() => {
                                    setAbrirModalObservacoesFornecedor(true);
                                    setObservacoes(orcamento);
                                  }}
                                />
                                <ReactSVG
                                  className="calculos"
                                  src={Calculo}
                                  onClick={() => {
                                    setCalculos({
                                      orcamento: orcamento,
                                      iss: item.iss,
                                      fee: fee,
                                      impostosFornecedor: impostosFornecedor,
                                      pisPagoFornecedor: pisPagoFornecedor,
                                      regimeLucroPresumido:
                                        regimeLucroPresumido,
                                      confinsPagoFornecedor:
                                        confinsPagoFornecedor,
                                      valorComIss: valorComIss,
                                      valorTotalComIss: valorTotalComIss,
                                      valorPisSemReducaoVesta:
                                        valorPisSemReducaoVesta,
                                      valorCofinsSemReducaoVesta:
                                        valorCofinsSemReducaoVesta,
                                      valorPisComReducaoVesta:
                                        valorPisComReducaoVesta,
                                      valorCofinsComReducaoVesta:
                                        valorCofinsComReducaoVesta,
                                      valorTotalComReducaoVesta:
                                        valorTotalComReducaoVesta,
                                      valorTotalComDeducaoFee:
                                        valorTotalComDeducaoFee,
                                      valorFee: valorFee,
                                      valorTotalComReducaoMaisFee:
                                        valorTotalComReducaoMaisFee,
                                      valorPisFinal: valorPisFinal,
                                      valorCofinsFinal: valorCofinsFinal,
                                      valorFinal: valorFinal,
                                      tipo: "Serviço",
                                      feeEspecifico: materialBriefing.fee,
                                      menorValor: menorValor,
                                    });
                                    setAbrirModalCalculos(true);
                                  }}
                                />
                                <ReactSVG
                                  className="nota editar"
                                  src={Editar}
                                  onClick={() => {
                                    setAbrirModalCriarOrcamento(true);
                                    setDadosParaCriarOrcamento({
                                      fornecedor: element,
                                      materialBriefing: materialBriefing,
                                    });
                                  }}
                                  // hidden={bloquear}
                                />
                              </div>
                            </td>
                            {(orcamentos && orcamento.status == "aprovado") ||
                            orcamento.status == "proposta aprovada" ||
                            orcamento.status == "em produção" ? (
                              <input
                                className="selecionar"
                                type="radio"
                                id="ifollow1"
                                name="empresa_id1"
                                onClick={() =>
                                  setPropostaSelecionada(
                                    orcamentos.find(
                                      (eleme) =>
                                        eleme.fornecedor_id == element.id
                                    )
                                  )
                                }
                                checked={true}
                                value={true}
                              />
                            ) : (orcamentos &&
                                orcamento.status == "recusado") ||
                              orcamentos.filter(
                                (elem) =>
                                  elem.status === "proposta aprovada" ||
                                  elem.status === "aprovado"
                              ).length > 0 ? (
                              <input
                                className="selecionar"
                                type="radio"
                                id="ifollow1"
                                name="empresa_id1"
                                disabled={true}
                              />
                            ) : (
                              <input
                                className="selecionar"
                                type="radio"
                                id="ifollow1"
                                name="empresa_id1"
                                onClick={() => {
                                  setPropostaSelecionada({
                                    orcamento,
                                    valorFinal,
                                  });
                                  setValorDeVendaState(valorFinal);
                                }}
                              />
                            )}
                          </tr>
                        );
                      } else {
                        const impostosFornecedor = regimesEmpresas.find(
                          (eleme) =>
                            eleme.regime_empresa === element.regime_empresa
                        );
                        const regimeLucroPresumido = regimesEmpresas.find(
                          (eleme) => eleme.regime_empresa === "Real"
                        );

                        const fator = orcamento.icms_st
                          ? ((1 + orcamento.icms_st / 100) *
                              (1 - orcamento.icms / 100)) /
                              (1 - ncm.icms_sp / 100) -
                            1
                          : 0;

                        //Retirando o IPI para fazer o calculo de ICMS sem erro
                        const valorSemIPI =
                          orcamento.ipi && orcamento.ipi > 0
                            ? parseFloat(
                                (
                                  orcamento.valor_total /
                                  (1 + orcamento.ipi / 100)
                                ).toFixed(4)
                              )
                            : orcamento.valor_total;

                        const pisPagoFornecedor = parseFloat(
                          (
                            (orcamento.valor_total / 100) *
                            impostosFornecedor.pis
                          ).toFixed(4)
                        );

                        const confinsPagoFornecedor = parseFloat(
                          (
                            (orcamento.valor_total / 100) *
                            impostosFornecedor.cofins
                          ).toFixed(4)
                        );

                        const icmsPagoFornecedor = parseFloat(
                          ((valorSemIPI / 100) * orcamento.icms).toFixed(4)
                        );

                        //Formula: Valor de Compra / 100% - (ICMS% + PIS% + COFINS%)
                        // 100% - (ICMS% + PIS% + COFINS%)
                        const porcentagensBaseDeCalculo =
                          100 -
                          (Number(ncm.icms_sp) +
                            Number(regimeLucroPresumido.pis) +
                            Number(regimeLucroPresumido.cofins));

                        //Valor de Compra / porcentagens(ICMS+PIS+COFINS)
                        const baseDeCalculoIcms = parseFloat(
                          (
                            (orcamento.valor_total /
                              porcentagensBaseDeCalculo) *
                            100
                          ).toFixed(4)
                        );

                        // console.log(porcentagensBaseDeCalculo);
                        // console.log(baseDeCalculoIcms);

                        const icmsSemReducaoVesta =
                          orcamento.icms_st > 0
                            ? (orcamento.valor_total *
                                (1 + fator) *
                                ncm.icms_sp) /
                              100
                            : parseFloat(
                                (
                                  (baseDeCalculoIcms * ncm.icms_sp) /
                                  100
                                ).toFixed(2)
                              );
                        // console.log(icmsSemReducaoVesta);

                        const baseDeCalculoPisCofins = parseFloat(
                          (baseDeCalculoIcms - icmsSemReducaoVesta).toFixed(4)
                        );
                        // console.log(
                        //   "---------------------------------------------------------------------------------------------------"
                        // );
                        // console.log("Icms Fornecedor: " + orcamento.icms);
                        // console.log(
                        //   "Icms Pago Pelo Fornecedor: " + icmsPagoFornecedor
                        // );

                        const pisSemReducaoVesta = parseFloat(
                          (
                            (baseDeCalculoPisCofins *
                              regimeLucroPresumido.pis) /
                            100
                          ).toFixed(4)
                        );

                        const cofinsSemReducaoVesta = parseFloat(
                          (
                            (baseDeCalculoPisCofins *
                              regimeLucroPresumido.cofins) /
                            100
                          ).toFixed(4)
                        );

                        const icmsFinalVesta = parseFloat(
                          (icmsSemReducaoVesta - icmsPagoFornecedor).toFixed(4)
                        );

                        const pisFinalVesta = parseFloat(
                          (pisSemReducaoVesta - pisPagoFornecedor).toFixed(4)
                        );

                        const cofinsFinalVesta = parseFloat(
                          (
                            cofinsSemReducaoVesta - confinsPagoFornecedor
                          ).toFixed(2)
                        );

                        const valorTotalComDecucao =
                          Number(orcamento.valor_total) +
                          icmsFinalVesta +
                          pisFinalVesta +
                          cofinsFinalVesta;

                        const valorTotalSemDeducao =
                          Number(orcamento.valor_total) +
                          icmsSemReducaoVesta +
                          pisSemReducaoVesta +
                          cofinsSemReducaoVesta;

                        const valorTotalComDeducaoFee = materialBriefing.fee
                          ? parseFloat(
                              (
                                (Number(materialBriefing.fee) *
                                  Number(valorTotalComDecucao)) /
                                100
                              ).toFixed(4)
                            )
                          : parseFloat(
                              (
                                (Number(fee) * Number(valorTotalComDecucao)) /
                                100
                              ).toFixed(4)
                            );

                        const baseDeCalculoIcmsComFee = parseFloat(
                          (
                            (valorTotalComDeducaoFee /
                              porcentagensBaseDeCalculo) *
                            100
                          ).toFixed(4)
                        );

                        const icmsFee = parseFloat(
                          (
                            (baseDeCalculoIcmsComFee * ncm.icms_sp) /
                            100
                          ).toFixed(2)
                        );

                        const baseDeCalculoPisCofinsComFee = parseFloat(
                          (baseDeCalculoIcmsComFee - icmsFee).toFixed(4)
                        );

                        const pisFee = parseFloat(
                          (
                            (baseDeCalculoPisCofinsComFee *
                              regimeLucroPresumido.pis) /
                            100
                          ).toFixed(4)
                        );

                        const cofinsFee = parseFloat(
                          (
                            (baseDeCalculoPisCofinsComFee *
                              regimeLucroPresumido.cofins) /
                            100
                          ).toFixed(4)
                        );

                        const impostoTotalFee = parseFloat(
                          (
                            Number(valorTotalComDeducaoFee) +
                            Number(pisFee) +
                            Number(cofinsFee) +
                            Number(icmsFee)
                          ).toFixed(4)
                        );

                        // console.log(valorTotalComDecucao);
                        // console.log(impostoTotalFee);
                        const valorDeVenda = orcamento.valor_de_venda
                          ? orcamento.valor_de_venda
                          : parseFloat(
                              valorTotalComDecucao + impostoTotalFee
                            ).toFixed(4);

                        if (
                          Number(valorDeVenda) < menorValor ||
                          menorValor === 0
                        ) {
                          setMenorValor(Number(valorDeVenda));
                        }

                        orcamento.valor_final = Number(valorDeVenda);

                        // console.log("Pis: " + pisPagoFornecedor);
                        // console.log("Cofins: " + confinsPagoFornecedor);
                        // console.log("ICMS: " + icmsPagoFornecedor);
                        // console.log(
                        //   "Porcetagem Base De Cálculo" + porcentagensBaseDeCalculo
                        // );
                        // console.log("Base De Calculo Icms" + baseDeCalculoIcms);
                        // console.log(
                        //   "Base De Calculo Pis Cofins" + baseDeCalculoPisCofins
                        // );

                        // console.log(
                        //   "Valor Total Sem dedução: " + valorTotalSemDeducao
                        // );
                        // console.log(
                        //   "Pis sem redução Vesta: " + pisSemReducaoVesta
                        // );
                        // console.log(
                        //   "Cofins Sem Redução Vesta: " + cofinsSemReducaoVesta
                        // );
                        // console.log(
                        //   "Icms Sem redução Vesta:" + icmsSemReducaoVesta
                        // );
                        // console.log("Icms Final Vesta: " + icmsFinalVesta);
                        // console.log("Pis Final Vesta: " + pisFinalVesta);
                        // console.log("Cofins Final Vesta: " + cofinsFinalVesta);
                        // console.log(
                        //   "Valor Total com Dedução Vesta: " + valorTotalComDecucao
                        // );
                        // console.log(
                        //   "Valor Total Com Dedução + Fee: " +
                        //     valorTotalComDeducaoFee
                        // );
                        // console.log(
                        //   "Base De Calculo Icms com Fee: " +
                        //     baseDeCalculoIcmsComFee
                        // );
                        // console.log("IcmsFee: " + icmsFee);
                        // console.log(
                        //   "Base De Calculo Cofins/Pis/Fee: " +
                        //     baseDeCalculoPisCofinsComFee
                        // );
                        // console.log("PisFee:" + pisFee);
                        // console.log("CofinsFee:" + cofinsFee);
                        // console.log("Imposto Total Fee: " + impostoTotalFee);
                        // console.log("Valor De Venda: " + valorDeVenda);
                        // console.log(
                        //   "---------------------------------------------------------------------------------------------------"
                        // );

                        return (
                          <tr>
                            <td className="fornecedores">{element.nome} </td>
                            <td className="icms">
                              {orcamentos ? orcamento.icms + "%" : "-"}
                            </td>
                            <td className="ipi">
                              {orcamentos
                                ? orcamento.ipi
                                  ? orcamento.ipi + "%"
                                  : 0 + "%"
                                : "-"}
                            </td>
                            <td className="icms">
                              {orcamentos
                                ? orcamento.icms_st
                                  ? orcamento.icms_st + "%"
                                  : 0 + "%"
                                : "-"}
                            </td>
                            <td className="quantidade">
                              {orcamentos
                                ? "R$" + orcamento.valor_unidade
                                : "-"}
                            </td>
                            <td className="quantidade">
                              {orcamentos ? "R$" + orcamento.valor_total : "-"}
                            </td>
                            <td>
                              <div className="flex">
                                <ReactSVG
                                  src={Nota}
                                  className="nota"
                                  onClick={() => {
                                    setAbrirModalObservacoesFornecedor(true);
                                    setObservacoes(orcamento);
                                  }}
                                />
                                <ReactSVG
                                  className="calculos"
                                  src={Calculo}
                                  onClick={() => {
                                    setCalculos({
                                      pis: impostosFornecedor.pis,
                                      cofins: impostosFornecedor.cofins,
                                      icms: orcamento.icms,
                                      pisPagoFornecedor: pisPagoFornecedor,
                                      confinsPagoFornecedor:
                                        confinsPagoFornecedor,
                                      icmsPagoFornecedor: icmsPagoFornecedor,
                                      baseDeCalculoIcms: baseDeCalculoIcms,
                                      valorDeCompra: orcamento.valor_total,
                                      ipi: orcamento.ipi,
                                      fee: fee,
                                      icms_st: orcamento.icms_st,
                                      fator: fator,
                                      icmsVesta: ncm.icms_sp,
                                      pisVesta: regimeLucroPresumido.pis,
                                      cofinsVesta: regimeLucroPresumido.cofins,
                                      icmsSemReducaoVesta: icmsSemReducaoVesta,
                                      baseDeCalculoPisCofins:
                                        baseDeCalculoPisCofins,
                                      pisSemReducaoVesta: pisSemReducaoVesta,
                                      cofinsSemReducaoVesta:
                                        cofinsSemReducaoVesta,
                                      icmsFinalVesta: icmsFinalVesta,
                                      cofinsFinalVesta: cofinsFinalVesta,
                                      pisFinalVesta: pisFinalVesta,
                                      icmsFee: icmsFee,
                                      pisFee: pisFee,
                                      cofinsFee: cofinsFee,
                                      baseDeCalculoPisCofinsComFee:
                                        baseDeCalculoPisCofinsComFee,
                                      baseDeCalculoIcmsComFee:
                                        baseDeCalculoIcmsComFee,
                                      valorTotalComDeducaoFee: Number(
                                        valorTotalComDeducaoFee
                                      ),
                                      valorDeVenda: Number(valorDeVenda),
                                      valorSemIPI: valorSemIPI,
                                      feeEspecifico: materialBriefing.fee,
                                      menorValor: menorValor,
                                    });
                                    setAbrirModalCalculos(true);
                                  }}
                                />
                                <ReactSVG
                                  className="nota editar"
                                  src={Editar}
                                  onClick={() => {
                                    setAbrirModalCriarOrcamento(true);
                                    setDadosParaCriarOrcamento({
                                      fornecedor: element,
                                      materialBriefing: materialBriefing,
                                    });
                                  }}
                                  // hidden={bloquear}
                                />
                              </div>
                            </td>

                            <td className="entrega">
                              {orcamentos
                                ? `${
                                    Number(partesData[0]) +
                                    Number(orcamento.prazo_producao)
                                  }/${partesData[1]}/${Number(partesData[2])}`
                                : "-"}
                            </td>
                            <td>
                              <div
                                className={`flex valorVenda ${
                                  Number(valorDeVenda) === menorValor
                                    ? "melhorPreco"
                                    : ""
                                }`}
                              >
                                {Number(valorDeVenda) === menorValor ? (
                                  <ReactSVG src={Estrela} />
                                ) : (
                                  ""
                                )}{" "}
                                R${valorDeVenda}
                              </div>
                            </td>
                            <td>{impostosFornecedor.regime_empresa}</td>
                            <td>
                              {(orcamentos && orcamento.status == "aprovado") ||
                              orcamento.status == "proposta aprovada" ||
                              orcamento.status == "em produção" ||
                              orcamento.status == "concluido" ? (
                                <ReactSVG src={check} className="ganhador" />
                              ) : (orcamentos &&
                                  orcamento.status == "recusado") ||
                                orcamentos.filter(
                                  (elem) =>
                                    elem.status === "proposta aprovada" ||
                                    elem.status === "aprovado"
                                ).length > 0 ? (
                                <input
                                  className="selecionar"
                                  type="radio"
                                  id="ifollow1"
                                  name="empresa_id1"
                                  disabled={true}
                                />
                              ) : (
                                <input
                                  className="selecionar"
                                  type="radio"
                                  id="ifollow1"
                                  name="empresa_id1"
                                  onClick={() => {
                                    setPropostaSelecionada(
                                      orcamentos.find(
                                        (eleme) =>
                                          eleme.fornecedor_id == element.id
                                      )
                                    );
                                    setValorDeVendaState(valorDeVenda);
                                  }}
                                />
                              )}
                            </td>
                          </tr>
                        );
                      }
                    })
                : fornecedoresCadastrados.map((element) => {
                    return (
                      <tr>
                        <td className="fornecedores">{element.nome}</td>
                        <td className="icms">-</td>
                        <td className="ipi">-</td>
                        <td className="icms">-</td>
                        <td className="quantidade">-</td>
                        <td className="quantidade">-</td>
                        <td>-</td>

                        <td className="entrega">-</td>
                        <td>-</td>
                        <td>-</td>
                        <td
                          onClick={() => {
                            deletarFornecedorSelecionado(element.id);
                          }}
                        >
                          <ReactSVG className="excluir" src={Excluir} />
                        </td>
                      </tr>
                    );
                  })}
              {todosFornecedoresCadastrados.filter(
                (elem) => elem.status === "fornecedor adicionado"
              ).length > 0 ? (
                <>
                  <tbody>
                    <button
                      hidden={
                        orcamentos.filter(
                          (elem) =>
                            elem.status === "proposta aprovada" ||
                            elem.status === "aprovado" ||
                            elem.status === "em produção" ||
                            elem.status === "concluido"
                        ).length > 0
                          ? true
                          : false
                      }
                      onClick={() =>
                        propostaSelecionada
                          ? propostaSelecionada.valor_final !==
                              Number(menorValor) &&
                            !user.permissoes.includes("compras")
                            ? setAbrirModalPedirRevisaoCompras(true)
                            : setModalConfirmacao(true)
                          : toast.error("Selecione uma das propostas")
                      }
                    >
                      Confirmar Orçamento
                    </button>
                  </tbody>
                </>
              ) : !bloquear ? (
                <>
                  <h2 className="h2-aviso"></h2>
                  <button
                    disabled={
                      user.permissoes.includes("admin") ||
                      user.permissoes.includes("produtor")
                        ? false
                        : true
                    }
                    hidden={
                      !user.permissoes.includes("admin") &&
                      !user.permissoes.includes("produtor")
                        ? true
                        : false
                    }
                    onClick={() => liberarParaFornecedores()}
                    className="button-disabled"
                  >
                    Liberar para os fornecedores
                  </button>{" "}
                </>
              ) : (
                <></>
              )}
              {produtorNegociando.length > 0 ? (
                <div>
                  <h3 className="h3-compras">Revisão Compras</h3>
                </div>
              ) : (
                <></>
              )}
              {produtorNegociando.length > 0 &&
              user.permissoes.includes("compras") ? (
                <button
                  className="email-fornecedor"
                  onClick={() => setAbrirModalDevolutiva(true)}
                >
                  Enviar Email para o produtor
                </button>
              ) : (
                <></>
              )}
              {abrirModalObservacoesFornecedor && (
                <ModalObservacoesFornecedor
                  state={setAbrirModalObservacoesFornecedor}
                  orcamento={observacoes}
                />
              )}
              {abrirModalAddFornecedores && (
                <ModalAdicionarFornecedoresBriefing
                  state={setAbrirModalAddFornecedores}
                  fornecedoresNaoCadastrados={fornecedoresNaoCadastrados}
                  fornecedores={fornecedores}
                  buscarFornecedor={buscarFornecedor}
                  categoriasFornecedor={categoriasFornecedor}
                  materialBriefing={materialBriefing}
                  existenciaDeFornecedoresAdicionados={
                    todosFornecedoresCadastrados.filter(
                      (elem) => elem.status === "fornecedor adicionado"
                    ).length > 0
                  }
                />
              )}
              {abrirModalConfirmacao && (
                <ModalConfirmacaoDeFornecedor
                  state={setModalConfirmacao}
                  orcamentos={orcamentos}
                  orcamento={propostaSelecionada}
                  fornecedores={fornecedoresCadastrados}
                  buscarMaterialBriefing={buscarMaterialBriefing}
                  buscarMaterialProposta={buscarMaterialProposta}
                  materialBriefing={materialBriefing}
                  valorDeVenda={valorDeVendaState}
                  ncm={ncm}
                  item={item}
                  menorValor={menorValor}
                />
              )}
              {abrirModalPedirRevisaoCompras && (
                <PedirAprovacaoCompras
                  state={setAbrirModalPedirRevisaoCompras}
                  orcamento={propostaSelecionada}
                  fornecedores={fornecedoresCadastrados}
                  buscarMaterialBriefing={buscarMaterialBriefing}
                  buscarMaterialProposta={buscarMaterialProposta}
                  materialBriefing={materialBriefing}
                  quantidade={quantidade}
                />
              )}
              {orcamentoAprovado && (
                <>
                  <div>
                    <h3 className="titulo-justificativa">
                      Justificativa da liberação:
                    </h3>
                    <h3 className="justificativa">
                      {orcamentoAprovado.justificar} | feita em{" "}
                      {orcamentoAprovado.data_criacao} por {}
                    </h3>
                  </div>
                </>
              )}
              {abrirModalCalculos && (
                <CalculosOrcamento
                  calculos={calculos}
                  state={setAbrirModalCalculos}
                />
              )}
              {abrirModalDevolutiva && (
                <DevolutivaCompras
                  state={setAbrirModalDevolutiva}
                  materialBriefing={materialBriefing}
                  quantidade={quantidade}
                />
              )}
            </tbody>
          </table>
        </TableContainer>
      </TabelaDisputaStyled>
      {todosFornecedoresCadastrados.filter(
        (elem) => elem.status === "fornecedor adicionado"
      ).length > 0 &&
      user.permissoes.includes("compras") &&
      todosFornecedoresCadastrados.filter(
        (elem) => elem.status === "fornecedor selecionado"
      ).length > 0 ? (
        <TabelaDisputaStyled>
          <TableContainer>
            <h2 className="titulo">Fornecedores Para Adicionar</h2>
            <table>
              <tbody>
                {todosFornecedoresCadastrados
                  .filter((elem) => elem.status === "fornecedor selecionado")
                  .map((element) => {
                    return (
                      <tr>
                        <td className="fornecedores">
                          {element.fornecedor_nome}
                        </td>
                        <td className="icms">-</td>
                        <td className="ipi">-</td>
                        <td className="icms">-</td>
                        <td className="quantidade">-</td>
                        <td className="quantidade">-</td>
                        <td>-</td>
                        <td className="entrega">-</td>
                        <td>-</td>
                        <td>-</td>
                        <td
                          onClick={() => {
                            deletarFornecedorSelecionado(element.fornecedor_id);
                          }}
                        >
                          <ReactSVG className="excluir" src={Excluir} />
                        </td>
                      </tr>
                    );
                  })}
                <button
                  disabled={
                    user.permissoes.includes("admin") ||
                    user.permissoes.includes("produtor")
                      ? false
                      : true
                  }
                  hidden={
                    !user.permissoes.includes("admin") &&
                    !user.permissoes.includes("produtor")
                      ? true
                      : false
                  }
                  onClick={() => liberarParaFornecedores()}
                  className="button-disabled"
                >
                  Liberar para os fornecedores
                </button>{" "}
              </tbody>
            </table>
          </TableContainer>
        </TabelaDisputaStyled>
      ) : (
        " "
      )}
      {abrirModalCriarOrcamento && (
        <ModalCriarOrcamento
          state={setAbrirModalCriarOrcamento}
          dados={dadosParaCriarOrcamento}
          buscarMaterialBriefing={buscarMaterialBriefing}
          quantidade={quantidade}
        />
      )}
    </>
  );
};
