import { useEffect, useState } from "react";
import Loading from "../../Loading";
import { ContentContainer, GerarPedidoComprasModalContainer } from "./styles";
import LogoVestaPng from "../../../assets/logo-vesta.png";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { getBase64FromUrl } from "../../../utils/files";
import { moneyMask } from "../../../utils/masks";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const GerarContasModal = ({ setModalOpened, relatorios, data, tipo }) => {
  const [loading, setLoading] = useState(true);
  const [logo, setLogo] = useState("");

  useEffect(() => {
    getBase64FromUrl(LogoVestaPng).then((response) => {
      setLogo(response);
    });
  }, []);

  const generatePDF = async () => {
    const borders = [false, false, false, true];

    const tableBody = relatorios.map((relatorio) => {
      return [
        {
          text:
            tipo === "pagar"
              ? relatorio.nome_fornecedor
              : relatorio.nome_cliente,
          border: borders,
          style: "materialTexto",
          alignment: "center",
        },
        {
          text: relatorio.campanha_nome,
          border: borders,
          style: "materialTexto",
          alignment: "center",
        },
        {
          text: relatorio.item_tipo,
          border: borders,
          style: "materialTexto",
          alignment: "center",
        },
        {
          text: relatorio.item_nome,
          border: borders,
          style: "materialTexto",
          alignment: "center",
        },
        {
          text: relatorio.subnome,
          border: borders,
          style: "materialTexto",
          alignment: "center",
        },
        {
          text: relatorio.quantidade,
          border: borders,
          style: "materialTexto",
          alignment: "center",
        },
        {
          text:
            tipo === "pagar"
              ? "R$" +
                Number(relatorio.valor_unitario).toFixed(2).replace(".", ",")
              : "R$ " +
                Number(relatorio.valor_de_venda / relatorio.quantidade)
                  .toFixed(2)
                  .replace(".", ","),
          border: borders,
          style: "materialTexto",
          alignment: "center",
        },
        {
          text:
            tipo === "pagar"
              ? "R$ " +
                Number(relatorio.valor_total).toFixed(2).replace(".", ",")
              : "R$ " +
                Number(relatorio.valor_de_venda).toFixed(2).replace(".", ","),
          border: borders,
          style: "materialTexto",
          alignment: "center",
        },
        {
          text: relatorio.ncm ? relatorio.ncm : "-",
          border: borders,
          style: "materialTexto",
          alignment: "center",
        },
        {
          text: `R$ ${Number(relatorio.valor_creditado)
            .toFixed(2)
            .replace(".", ",")}`,
          border: borders,
          style: "materialTexto",
          alignment: "center",
        },
        {
          text: `${relatorio.data_vencimento}`,
          border: borders,
          style: "materialTexto",
          alignment: "center",
        },
      ];
    });

    var docDefinition = {
      pageMargins: [30, 40, 30, 40],
      content: [
        {
          columns: [
            {
              text: `São Paulo ${data.split("-").reverse().join("/")}`,
            },
            {
              image: logo,
              width: 135,
              height: 40,
            },
          ],
          columnGap: 210,
        },
        {
          table: {
            style: "teste",
            widths: [
              "9%",
              "9%",
              "9%",
              "9%",
              "9%",
              "9%",
              "9%",
              "9%",
              "9%",
              "9%",
              "9%",
            ],
            headerRows: 1,
            heights: function (row) {
              if (row === 0) {
                return 20;
              }
              return 40;
            },
            border: "noBorders",
            body: [
              [
                {
                  text: tipo === "pagar" ? "Fornecedor" : "Cliente",
                  style: "tableHeader",
                  border: borders,
                  alignment: "center",
                },
                {
                  text: "Campanha",
                  style: "tableHeader",
                  border: borders,
                },
                {
                  text: "Tipo",
                  style: "tableHeader",
                  border: borders,
                },
                {
                  text: "Nome",
                  style: "tableHeader",
                  border: borders,
                  alignment: "center",
                },
                {
                  text: "Descrição",
                  style: "tableHeader",
                  border: borders,
                  alignment: "center",
                },
                {
                  text: "Quantidade Produzida",
                  style: "tableHeader",
                  border: borders,
                  alignment: "center",
                },
                {
                  text: "Valor unitário de compra",
                  style: "tableHeader",
                  border: borders,
                  alignment: "center",
                },
                {
                  text: "Valor total de compra",
                  style: "tableHeader",
                  border: borders,
                  alignment: "center",
                },
                {
                  text: "NCM",
                  style: "tableHeader",
                  border: borders,
                  alignment: "center",
                },
                {
                  text: "Valor de impostos a creditar",
                  style: "tableHeader",
                  border: borders,
                  alignment: "center",
                },
                {
                  text: "Data de Vencimento",
                  style: "tableHeader",
                  border: borders,
                  alignment: "center",
                },
              ],
              ...tableBody,
            ],
          },
        },
      ],
      styles: {
        teste: {
          background: "#7D0000",
        },
        teste2: {
          margin: [500, 500, 500, 500],
        },
        header: {
          fontSize: 12,
          bold: true,
          margin: [200, 0, 0, 0],
          alignment: "right",
        },
        endereco: {
          fontSize: 8,
          margin: [200, 0, 0, 0],
          alignment: "right",
          lineHeight: 1.1,
        },
        dadosCliente: {
          fontSize: 10,
        },
        subheader: {
          fontSize: 10,
          bold: true,
          margin: [0, 0, 0, 5],
        },
        sectionHeader: {
          fontSize: 10,
          bold: true,
          margin: [0, 20, 0, 10],
        },
        clientName: {
          fontSize: 10,
          bold: true,
        },
        materialTexto: {
          fontSize: 6,
          height: 50,
          bold: false,
          margin: [0, 10, 0, 0],
        },
        subtotal: {
          fontSize: 10,
          bold: true,
          color: "#fff",
          margin: [410, -25, 0, 0],
        },
        tableHeader: {
          fontSize: 7,
          bold: true,
          alignment: "center",
        },
      },
    };

    pdfMake.createPdf(docDefinition).getBlob((blob) => {
      const fileURL = URL.createObjectURL(blob);
      const newWindow = window.open(fileURL, "_blank", "noopener,noreferrer");
      if (newWindow) newWindow.opener = null;
      setModalOpened(false);
    });
  };

  useEffect(() => {
    if (logo) {
      generatePDF();
    }
  }, [logo]);

  return (
    <GerarPedidoComprasModalContainer>
      <ContentContainer>{loading && <Loading />}</ContentContainer>
    </GerarPedidoComprasModalContainer>
  );
};

export default GerarContasModal;
