import { styled } from "styled-components";

export const CardCampanhaContainer = styled.div`
  .verMais {
    margin-left: 5px;
    padding: 5px 8px;
    background: var(--amarelo);
    border-radius: 12px;
    color: white;
    font-weight: 600;
    font-size: 12px;
  }
`;
