import { CardCampanhaContainer } from "./style";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const CardCampanhaBriefing = ({ elem }) => {
  const navigate = useNavigate();
  const [verMais, setVerMais] = useState(false);

  return (
    <CardCampanhaContainer>
      <div
        className="card"
        onClick={() =>
          navigate(`/campanha/detalhes/${elem.campanhas_id}/${elem.id}`)
        }
      >
        <div className="flex-space">
          <div className="top-card">
            <h3>Item: </h3>
            <span className="nome">
              {elem.nome} {elem.subnome}
            </span>
          </div>
          <div className="top-card">
            <h3>Origem: </h3>
            <span className="nome">Briefing</span>
          </div>
        </div>
        <div className="flex justify">
          <h4>
            {elem.quantidades.map((quantidade, index) => {
              if (index > 3 && !verMais) {
                return;
              }
              return index != 0 ? " | " + quantidade : quantidade;
            })}
            {elem.quantidades.length > 3 && !verMais && (
              <span
                className="verMais"
                onClick={(e) => e.stopPropagation() || setVerMais(true)}
              >
                Ver mais
              </span>
            )}
            {elem.quantidades.length > 3 && verMais && (
              <span
                className="verMais"
                onClick={(e) => e.stopPropagation() || setVerMais(false)}
              >
                Ver menos
              </span>
            )}
          </h4>
          {elem.status === "enviado pra orçamento" && (
            <h4 className="aguardando">Aguardando análise</h4>
          )}
          {elem.status === "enviado para os fornecedores" && (
            <h4 className="aguardando">Aguardando análise</h4>
          )}
          {elem.status === "aguardando análise" && (
            <h4 className="aguardando">Aguardando análise</h4>
          )}
          {elem.status === "em produção" && (
            <h4 className="produzindo">Em Produção</h4>
          )}
          {elem.status === "concluido" && (
            <h4 className="concluido">Concluído</h4>
          )}
        </div>
      </div>
    </CardCampanhaContainer>
  );
};

export default CardCampanhaBriefing;
