//Importanto createContext e useState, já vem com o react
import { createContext, useState } from "react";

//Criando nosso contexto
export const DataContext = createContext({});

//Função que vai englobar nosso contexto
export const DataProvider = ({ children }) => {
  const [mainImage, setMainImage] = useState();

  return (
    <DataContext.Provider
      value={{
        mainImage,
        setMainImage,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};
