import { styled } from "styled-components";

export const ModelCampanhaContainer = styled.div`
  background-color: var(--base01);
  border-radius: 40px;

  .div-top {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 45px 20px 5px;
  }

  .body {
    width: 100%;
  }

  .div-detalhes {
    display: flex;
    margin-bottom: 20px;
  }

  .back {
    cursor: pointer;
    svg {
      margin-right: 20px;
      width: 45px;
      height: 45px;
    }
  }

  .back:hover {
    cursor: pointer;
  }

  h1 {
    margin-bottom: 7px;
    font-size: 30px;
    font-weight: 600;
    color: #640000;
  }

  .nome-campanha {
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 24px;
    margin-top: 10px;
    color: #640000;
  }

  .font-weight {
    font-size: 16px;
    font-weight: 600;
  }

  .flex {
    display: flex;

    a {
      margin-left: 10px;
    }
  }

  .editar {
    margin-left: 7px;
    text-decoration: underline;
    color: #609ae5;
    cursor: pointer;
  }

  .marcas {
    margin-bottom: 15px;
    display: flex;

    span {
      margin-right: 6px;
    }

    p {
      margin-right: 4px;
    }
  }

  .add-campaing {
    h3 {
      font-size: 18px;
      font-weight: 700;
      color: #640000;
    }

    .flex {
      align-items: center;
      margin-bottom: 20px;

      img {
        width: 15px;
        height: 15px;
        margin-right: 6px;
      }

      .icon {
        width: 60px;
        height: 50px;
        margin-bottom: 6px;

        svg {
          width: 40px;
          height: 40px;
        }
      }
    }
  }

  .highlight {
    animation: pulse 1.5s infinite; /* Adiciona animação pulsante */
  }

  @keyframes pulse {
    0% {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.7); /* Box shadow inicial */
    }
    50% {
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.7); /* Box shadow aumentando pela metade do tempo */
    }
    100% {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.7); /* Box shadow voltando ao tamanho inicial */
    }
  }

  .div-right {
    margin-right: 20px;
    padding: 20px 15px;
    border-radius: 12px;
    width: 270px;
    background-color: var(--base);

    h5 {
      font-size: 12px;
      font-weight: 500;
    }
  }

  .info-div {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h4 {
      margin-right: 4px;
      color: #640000;
      font-weight: 700;
    }

    span {
      font-size: 12px;
      color: #640000;
      text-decoration: underline;
    }
  }

  .div-right:hover {
    background-color: var(--principal);
    cursor: pointer;

    h4 {
      color: aliceblue;
    }

    h5 {
      color: var(--base);
    }

    span {
      color: var(--base);
    }
  }

  h5 {
    font-size: 13px;
  }
`;
