import {
  DetalhesDeCampanhaMaterialBriefingStyle,
  InputEspecificacoes,
  ButtonSalvar,
} from "./style";
import { useForm } from "react-hook-form";
import api from "../../../services/api";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { useAuth } from "../../../providers/Auth";

export const DetalhesDeCampanhaMaterialBriefing = ({
  dados,
  state,
  ncm,
  item,
  categoria,
}) => {
  const { register, getValues, setValue } = useForm({});
  const { user } = useAuth();

  useEffect(() => {
    setValue("especificacoes_tecnicas", dados.descricao_produtor);
  }, []);

  //ATUALIZA DADOS ITEM CAMPANHA BRIENFING
  function AtualizarItemBriefieng() {
    api
      .patch(`/campanha-material-briefing/${dados.id}`, {
        descricao_produtor: getValues("especificacoes_tecnicas"),
      })
      .then((result) => {
        toast.success(result.data.message);
        window.location.reload();
      })
      .catch((err) => {
        toast.error(err);
      });
  }

  return (
    <DetalhesDeCampanhaMaterialBriefingStyle>
      <div className="background">
        <div className="flex">
          <div>
            <h2>Tipo</h2>
            <h3>{categoria && categoria}</h3>
          </div>
          <div>
            <h2>Material</h2>
            <h3>{dados.nome}</h3>
          </div>{" "}
          <div>
            <h2>NCM</h2>
            <h3>{ncm && ncm.codigo}</h3>
          </div>
        </div>
        <div>
          <div className="flex_start">
            <h2>Especificações</h2>
            <h5>
              {categoria === "Brindes"
                ? ` Observação: ${dados.descricao} | Público Alvo: ${
                    dados.publico_alvo
                  } | Item de Marca: ${
                    dados.item_de_marca ? "Sim" : "Não"
                  } | Quantidades:  [${dados.quantidades.map(
                    (element) => element
                  )}]`
                : categoria === "Material Impresso"
                ? ` Observação: ${dados.descricao} | Quantidade de páginas: ${
                    dados.quantidade_de_paginas
                  } | Formato aberto: ${
                    dados.formato_aberto
                  }cm | Formato fechado: ${
                    dados.formato_fechado
                  }cm | Tipo de Papel: ${dados.substrato} | Gramatura: ${
                    dados.gramatura
                  } | Cores: ${dados.cores} | Acabamentos: ${
                    dados.acabamentos
                  } | Quantidades:  [${dados.quantidades.map(
                    (element) => element
                  )}]`
                : categoria == "Ponto de Venda"
                ? ` Observação: ${dados.descricao} | Substrato: ${
                    dados.substrato
                  } | Espessura: ${dados.espessura} | Cores: ${
                    dados.cores
                  } | Acabamentos: ${
                    dados.acabamentos
                  } | Necessário Positivação: ${
                    dados.positivacao ? "Sim" : "Não"
                  } | Quantidades:  [${dados.quantidades.map(
                    (element) => element
                  )}]`
                : categoria === "Comunicação Visual"
                ? ` Observação: ${dados.descricao} | Tamanho Final: ${
                    dados.tamanho
                  } | Formato aberto: ${dados.formato_aberto}cm | Substrato: ${
                    dados.substrato
                  } | Gramatura: ${dados.gramatura} | Cores: ${
                    dados.cores
                  } | Acabamentos: ${
                    dados.acabamentos
                  } | Necessário Positivação: ${
                    dados.positivacao ? "Sim" : "Não"
                  }`
                : `assa`}
            </h5>
          </div>
        </div>
        <div className="column">
          <div>
            <h2 className="min">Local de Entrega</h2>
            <h3>{dados.endereco}</h3>
          </div>
        </div>
        <span className="close" onClick={() => state(false)}>
          X
        </span>
      </div>
    </DetalhesDeCampanhaMaterialBriefingStyle>
  );
};
