import EditarCliente from "../../../components/Cadastros/Editar/EditarCliente";
import Header from "../../../components/Menu";

const EditarClientePage = () => {
  return (
    <div className="flex">
      <Header />
      <EditarCliente />
    </div>
  );
};

export default EditarClientePage;
