import styled from "styled-components";

export const CalculosOrcamentoStyle = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;

  .body {
    position: relative;
    display: flex;
    flex-direction: column;
    text-align: center;
    border-radius: 30px;
    background-color: var(--base);
    padding: 40px 30px;
    width: 80%;
    justify-content: center;
    margin: 0 auto;
  }

  button {
    padding: 15px 30px;
    border: none;
    background-color: var(--amarelo);
    color: var(--principal);
    border-radius: 24px;
    font-size: 16px;
    font-weight: 700;
    width: max-content;
    margin: 10px auto 0px;
  }
  h4 {
    font-weight: 600;
  }

  .close {
    position: absolute;
    top: -20px;
    right: -15px;
    padding: 15px 15px;
    background-color: #fa5252;
    border-radius: 300px;
    font-size: 32px;
    font-weight: 400;
    color: var(--base);
  }

  .div-valor-venda {
    display: flex;
    justify-content: flex-end;
  }

  h2 {
    margin-top: 15px;
    padding: 18px 12px;
    background-color: #ef5713;
    max-width: max-content;
    border-radius: 4px;
    color: white;
    font-size: 15px;
    font-weight: 600;
  }

  .border-bottom {
    border-bottom: 5px solid white;
  }
`;

export const TableContainer = styled.div`
  height: 100%;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 8px;
    border-radius: 8px;
    background-color: var(--base01);
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--principal);
    border-radius: 5px;
  }

  > table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    text-align: center;
    padding: 10px;

    > thead > tr > th {
      font-weight: 600;
      padding: 10px;
      margin: 0px 10px;
      font-size: 0.8rem;
      border-right: solid 8px white;
    }
    > tbody > tr > td {
      font-size: 0.8rem;
      border-right: 1px solid #c3c3c9;
    }
  }

  .ativo {
    display: flex;
    align-items: center;
    justify-content: center;

    > p {
      font-size: 0.8rem;
      width: 60px;
    }
  }

  a {
    color: var(--azul);
    font-size: 0.8rem;
  }

  th {
    background-color: var(--amarelo);
    margin-right: 10px;
  }

  .vazio {
    background-color: white;
  }

  .background-yellow {
    background-color: var(--amarelo);
    padding: 6px 8px;
    border-right: 6px solid white;
  }

  .red {
    color: red;
  }

  .relative {
    position: relative;
    padding: 6px 8px;
    top: -1px;
  }

  .preenchimento {
  }

  .yellow {
    background-color: var(--amarelo);
    font-weight: 700;
    padding: 6px;
  }

  .margin-bottom {
    padding-bottom: 10px;
    /* border-right: 5px solid white; */
  }

  .h4-red {
    flex-wrap: wrap;
    font-size: 7px;
  }

  td {
    font-size: 13px;
    font-weight: 500;
  }
`;
