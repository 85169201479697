import { useEffect, useState } from "react";
import { InputRadio } from "../../InputRadio";
import { ModalAdicionarFornecedoresBriefingStyle } from "./style";
import api from "../../../services/api";
import { toast } from "react-toastify";
import { useAuth } from "../../../providers/Auth";
import { Input } from "../../Input";
import Fornecedores from "../../../assets/fornecedores.svg";
import { ReactSVG } from "react-svg";
import { useParams } from "react-router-dom";
import Loading from "../../Loading";
import { number } from "yup";

export const ModalAdicionarFornecedoresBriefing = ({
  state,
  fornecedores,
  fornecedoresNaoCadastrados,
  buscarFornecedor,
  categoriasFornecedor,
  materialBriefing,
}) => {
  const categoriaSelecionada = categoriasFornecedor.find(
    (elem) => elem.id === Number(materialBriefing.categoria)
  );

  const { token } = useAuth();
  const [idsFornecedores, setIdsFornecedores] = useState(
    fornecedoresNaoCadastrados
      .filter(
        (elem) =>
          Number(elem.segmento_fornecedor_id) === categoriaSelecionada.id
      )
      .map((ele) => ele.id)
  );
  const [fornecedoresCadastrados, setFornecedoresCadastrados] = useState(false);
  const [textoPesquisa, setTextoPesquisa] = useState("");
  const [perfilSelecionado, setPerfilSelecionado] = useState();
  const { id } = useParams();

  const filtrarCategoria = (categoria) => {
    const perfilFiltrado =
      !perfilSelecionado ||
      Number(categoria.segmento_fornecedor_id) === parseInt(perfilSelecionado);
    const pesquisaFiltrada =
      !textoPesquisa ||
      categoria.nome.toLowerCase().includes(textoPesquisa.toLowerCase());

    const idCategoria = parseInt(textoPesquisa);
    const idFiltrado =
      !isNaN(idCategoria) && categoria.segmento_fornecedor_id === idCategoria;

    return perfilFiltrado && (pesquisaFiltrada || idFiltrado);
  };

  const fornecedoresFiltrados =
    fornecedoresNaoCadastrados.filter(filtrarCategoria);

  function adicionarERetirar(id) {
    const filter = idsFornecedores.find((element) => element == id);
    if (filter) {
      const retirarId = idsFornecedores.filter((element) => element != id);
      setIdsFornecedores(retirarId);
    } else if (!filter) {
      setIdsFornecedores([...idsFornecedores, id]);
    }
  }

  function adicionarFornecedores() {
    let array = [];

    if (idsFornecedores.length == 0) {
      return toast.error("Selecione ao menos um fornecedor!");
    }

    for (let i = 0; i < idsFornecedores.length; i++) {
      const obj = {
        campanha_material_briefing_id: id,
        fornecedor_id: idsFornecedores[i],
        status: "fornecedor selecionado",
      };

      array.push(obj);
    }

    api
      .post(
        "/campanha-material-briefing/fornecedores",
        { fornecedores: array },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        toast.success("Fornecedores Adicionados no Briefing! 🎉");
        state(false);
        buscarFornecedor();
      })
      .catch((error) => {
        toast.error("Algo deu Errado. Tente novamente.");
      });
  }

  function buscarFornecedorCampanhaMaterialBriefing() {
    api
      .get(`/campanha-material-briefing/fornecedores/${id}`)
      .then((response) => {
        setFornecedoresCadastrados(response.data.data.fornecedores);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  useEffect(() => {
    buscarFornecedorCampanhaMaterialBriefing();
  }, []);

  return (
    <ModalAdicionarFornecedoresBriefingStyle>
      <div className="div-body">
        <span onClick={() => state(false)} className="close">
          X
        </span>
        <div className="flex">
          <ReactSVG src={Fornecedores} />
          <h3 className="h3-top">Fornecedor</h3>
        </div>
        <div className="div-pesquisa flex">
          <input
            className="input-text"
            placeholder="Pesquisar por ID ou nome do fornecedor"
            value={textoPesquisa}
            onChange={(e) => setTextoPesquisa(e.target.value)}
          />
          <div className="div-column">
            <span>Filtrar por Tipo de Fornecedor</span>
            <select onChange={(e) => setPerfilSelecionado(e.target.value)}>
              <option value="">Selecionar Categoria</option>
              {categoriasFornecedor.map((elem) => (
                <option value={elem.id}>{elem.nome}</option>
              ))}
            </select>
          </div>
        </div>
        <div>
          <div className="flex header-tabela">
            <h3 className="id">ID</h3>
            <div className="flex width-90">
              <h3 className="fornecedor">Fornecedor</h3>
              <div>
                <h3 className="selecione">Selecione</h3>
                {idsFornecedores.length !=
                fornecedoresNaoCadastrados.map((element) => element.id)
                  .length ? (
                  <span
                    onClick={() =>
                      setIdsFornecedores(
                        fornecedoresNaoCadastrados.map((element) => element.id)
                      )
                    }
                    className="selecionar-todos"
                  >
                    Selecione Todos
                  </span>
                ) : (
                  <span
                    onClick={() => setIdsFornecedores([])}
                    className="selecionar-todos"
                  >
                    Retirar todos
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
        {fornecedoresNaoCadastrados && fornecedoresCadastrados ? (
          fornecedoresFiltrados.map((element) => (
            <>
              {fornecedoresCadastrados.find(
                (elem) => elem.fornecedor_id == element.id
              ) ? (
                <></>
              ) : (
                <div className="flex header-tabela">
                  <h3 className="id-fornecedor">{element.id}</h3>
                  <div className="flex width-90">
                    <h3 className="nome-fornecedor">{element.nome}</h3>
                    <input
                      onClick={() => adicionarERetirar(element.id)}
                      className="check"
                      type="checkbox"
                      checked={
                        idsFornecedores.find((elemen) => elemen == element.id)
                          ? true
                          : false
                      }
                    />
                  </div>
                </div>
              )}
            </>
          ))
        ) : (
          <Loading />
        )}
        {/* {fornecedores &&
          fornecedoresCadastrados &&
          fornecedoresNaoCadastrados &&
          fornecedores.length == fornecedoresNaoCadastrados.length && (
            <h2 className="aviso">Todos Fornecedores já Cadastrados!</h2>
          )} */}
        <button onClick={() => adicionarFornecedores()} className="enviar">
          Salvar
        </button>
      </div>
    </ModalAdicionarFornecedoresBriefingStyle>
  );
};
