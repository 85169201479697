import styled from "styled-components";

export const PropostaCampanhaContainer = styled.div`
  background: #ffffff;
  width: 90%;
  margin: 40px auto 0;
  padding: 30px;
  border-radius: 20px;

  > h1 {
    display: flex;
    column-gap: 10px;
    color: #640000;
    font-weight: 600;
    font-size: 1em;
  }

  h2 {
    display: flex;
    column-gap: 6px;
    color: #640000;
    font-weight: 600;
    font-size: 1em;

    div {
      height: 20px;
      svg {
        height: 20px;
        path,
        polygon,
        rect,
        circle,
        ellipse {
          fill: #640000;
        }
      }
    }
  }

  table > thead > tr > :nth-child(3),
  table > thead > tr > :nth-child(4),
  table > thead > tr > :nth-child(5),
  table > thead > tr > :nth-child(6),
  table > tbody > tr > :nth-child(3),
  table > tbody > tr > :nth-child(4),
  table > tbody > tr > :nth-child(5),
  table > tbody > tr > :nth-child(6) {
    text-align: center;
  }

  > .buttons-container {
    display: flex;
    justify-content: flex-end;
    column-gap: 20px;
  }

  th + th + th + th + th + th + th {
    text-align: center;
  }
`;

export const GerarPropostaButton = styled.button`
  display: flex;
  padding: 10px 20px;
  margin: 20px 0 20px;
  border-radius: 20px;
  border: none;
  background: #ffbe00;
  color: #640000;
  font-weight: 600;
`;

export const AprovarPropostaButton = styled.button`
  display: flex;
  padding: 10px 20px;
  margin: 20px 0 20px;
  border-radius: 20px;
  border: none;
  background: #40ad48;
  color: #fff;
  font-weight: 600;
`;
