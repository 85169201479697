import { Link, useNavigate } from "react-router-dom";
import { Body, Inputs, NovaCategoriaContainer } from "./styles";
import { toast } from "react-toastify";
import api from "../../../../services/api";
import { useForm } from "react-hook-form";
import { useAuth } from "../../../../providers/Auth/index";
import { useEffect, useState } from "react";

const NovaCategoriaFornecedor = () => {
  const {
    // formState: { errors },
    handleSubmit,
    register,
  } = useForm({
    // resolver: yupResolver(NovoCadastroSchema),
  });

  const { user } = useAuth();
  const navigate = useNavigate();

  //CRIAR CATEGORIA FORNECEDOR
  const onSubmitFunction = (data) => {
    const categorias = [data];
    api
      .post("/categoria-fornecedor", { categorias })
      .then((response) => {
        navigate("/fornecedor/categoria");
        toast.success("Categoria cadastrada com sucesso!");
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  return (
    <NovaCategoriaContainer onSubmit={handleSubmit(onSubmitFunction)}>
      <div className="box">
        <div>
          <h1> Cadastrar nova categoria </h1>
        </div>

        <Body>
          <div className="checkbox">
            <input
              type="checkbox"
              id="checkbox1"
              name="status"
              {...register("status")}
              checked={true}
            />

            <label htmlFor="checkbox1">Ativo</label>
          </div>
          <Inputs>
            <div>
              <label>Nome da categoria</label>
              <input
                type="text"
                name="nome"
                {...register("nome")}
                placeholder="Nome da categoria"
              />
            </div>
          </Inputs>
        </Body>
        <div className="buttons">
          <Link to="/fornecedor/categoria">{"< Voltar"}</Link>
          <button type="submit">Salvar</button>
        </div>
      </div>
    </NovaCategoriaContainer>
  );
};

export default NovaCategoriaFornecedor;
