import { Link } from "react-router-dom";
import { BodyConcorrencia, TableContainer } from "./styles";
import { ReactSVG } from "react-svg";
import ativo from "../../../assets/ativo.svg";
import inativo from "../../../assets/inativo.svg";
import { useEffect, useState } from "react";
import api from "../../../services/api";
import { toast } from "react-toastify";
import { useAuth } from "../../../providers/Auth";
import Loading from "../../../components/Loading";

const Table = ({ perfilSelecionado, textoPesquisa, clientes }) => {
  const [marcas, setMarcas] = useState([]);
  const [campanhas, setCampanhas] = useState([false]);
  const [briefings, setBriefings] = useState([]);
  const [categorias, setCategorias] = useState();
  const [itens, setItens] = useState();
  const [ncm, setNcm] = useState();
  const { user, token } = useAuth();
  const colunas = ["Material", "NCM", "Categoria", "Prazo", "Status"];

  function buscarMarcas() {
    api
      .get("/marcas")
      .then((response) => {
        setMarcas(response.data.data.marcas);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  function listarCategoriaItem() {
    api
      .get("/categoria-item")
      .then((response) => {
        setCategorias(response.data.data.categorias);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  function buscarItens() {
    api
      .get(`/itens`)
      .then((response) => {
        setItens(response.data.data.itens);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  function buscarCliente() {
    if (user.permissoes.includes("admin")) {
      return buscarCampanhas();
    }
    api
      .get(`/usuario-cliente/${user.id}`)
      .then((response) => {
        buscarCampanhas(response.data.data.usuario.cliente_id);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  function buscarCampanhas(cliente_id) {
    api
      .get("/campanhas")
      .then((response) => {
        const data = response.data.data.campanhas;
        if (cliente_id) {
          const filtro = data.filter(
            (element) => element.cliente_id == cliente_id
          );
          setCampanhas(data);
        } else {
          setCampanhas(data);
        }
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  function buscarBriefingCampanha() {
    api
      .get("/campanha-material-briefing", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setBriefings(response.data.data.materiais);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  const buscarNCM = () => {
    api
      .get(`/ncm`)
      .then((response) => {
        setNcm(response.data.data.ncms);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  };

  useEffect(() => {
    if (!user) {
      return;
    }
    buscarBriefingCampanha();
    buscarMarcas();
    buscarCliente();
    listarCategoriaItem();
    buscarItens();
    buscarNCM();
  }, [user]);

  const filtrarCampanha = (categoria) => {
    const perfilFiltrado =
      !perfilSelecionado ||
      categoria.criador_id === parseInt(perfilSelecionado);
    const pesquisaFiltrada =
      !textoPesquisa ||
      categoria.nome.toLowerCase().includes(textoPesquisa.toLowerCase());

    const idCategoria = parseInt(textoPesquisa);
    const idFiltrado = !isNaN(idCategoria) && categoria.id === idCategoria;

    return perfilFiltrado && (pesquisaFiltrada || idFiltrado);
  };

  const campanhasFiltradas = campanhas.filter(filtrarCampanha);

  return (
    <BodyConcorrencia>
      {campanhasFiltradas[0] !== false && clientes ? (
        campanhasFiltradas.map((lista) =>
          briefings.filter(
            (element) => Number(element.campanhas_id) == Number(lista.id)
          ).length > 0 ? (
            <>
              <div className="flex titulo">
                <div className="flex">
                  <h2 className="nome-campanha">
                    #{lista.id} - {lista.nome}
                  </h2>
                  <a href={"campanha/detalhes/" + lista.id}> ver tudo&gt; </a>
                </div>
                <h5 className="cliente">
                  {clientes &&
                    clientes.filter((ele) => ele.id == lista.criador_id)
                      .length > 0 &&
                    "Cliente: " +
                      clientes.find((ele) => ele.id == lista.criador_id)
                        .razao_social}
                </h5>
              </div>
              <TableContainer>
                <table>
                  <thead>
                    <tr>
                      {colunas.map((coluna) => (
                        <th className={coluna} key={coluna}>
                          {coluna}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  {briefings &&
                    briefings
                      .filter(
                        (element) =>
                          Number(element.campanhas_id) == Number(lista.id)
                      )
                      .map((elem) => (
                        <>
                          <tbody>
                            <tr>
                              <td className="Material">{elem.nome}</td>
                              <td>
                                {itens &&
                                ncm &&
                                ncm.find(
                                  (categoria) =>
                                    categoria.id ==
                                    itens.find((el) => el.id == elem.item_id)
                                      .ncm_id
                                )
                                  ? ncm.find(
                                      (categoria) =>
                                        categoria.id ==
                                        itens.find(
                                          (el) => el.id == elem.item_id
                                        ).ncm_id
                                    ).codigo
                                  : "---"}
                              </td>
                              <td>
                                {itens && categorias
                                  ? categorias.find(
                                      (categoria) =>
                                        categoria.id ==
                                        itens.find(
                                          (el) => el.id == elem.item_id
                                        ).categoria_item_id
                                    ).nome
                                  : "A"}
                              </td>
                              <td>{elem.prazo_maximo}</td>
                              <td className="status">
                                {elem.status == 0 ? (
                                  <h6 className="deletado">Deletado</h6>
                                ) : elem.status == "aguardando análise" ? (
                                  <h6 className="aguardando">
                                    Aguardando Confirmação Cliente
                                  </h6>
                                ) : elem.status == "pendente" ? (
                                  <h6 className="analise">Pendente</h6>
                                ) : elem.status == "enviado pra orçamento" ? (
                                  <h6 className="pendente">Pendente</h6>
                                ) : elem.status == "concluido" ? (
                                  <h6 className="concluido">Concluído</h6>
                                ) : elem.status == "em produção" ? (
                                  <h6 className="producao">Em Produção</h6>
                                ) : elem.status ==
                                  "enviado para os fornecedores" ? (
                                  <h6 className="enviado">
                                    Enviado pra disputa
                                  </h6>
                                ) : (
                                  elem.status
                                )}
                              </td>
                              <td>
                                {elem.descricao_produtor ? (
                                  elem.status == 0 ? (
                                    <span
                                      className="ancora-desativada"
                                      onClick={() =>
                                        toast.error("Briefing deletado!")
                                      }
                                    >
                                      editar &gt;
                                    </span>
                                  ) : elem.status == "aguardando" ? (
                                    <span
                                      className="ancora-desativada"
                                      onClick={() =>
                                        toast.error("Briefing deletado!")
                                      }
                                    >
                                      editar &gt;
                                    </span>
                                  ) : elem.status == "enviado pra orçamento" ? (
                                    <a
                                      href={`/disputa/${elem.id}`}
                                      className="editar"
                                    >
                                      editar &gt;
                                    </a>
                                  ) : elem.status ==
                                    "enviado para os fornecedore" ? (
                                    <a
                                      href={`/disputa/${elem.id}`}
                                      className="editar"
                                    >
                                      editar &gt;
                                    </a>
                                  ) : (
                                    <a
                                      href={`/disputa/${elem.id}`}
                                      className="editar"
                                    >
                                      editar &gt;
                                    </a>
                                  )
                                ) : (
                                  "Aguardando Descrição"
                                )}
                                {}
                              </td>
                            </tr>
                          </tbody>
                        </>
                      ))}
                </table>
              </TableContainer>
            </>
          ) : (
            <></>
          )
        )
      ) : (
        <Loading />
      )}
    </BodyConcorrencia>
  );
};

export default Table;
