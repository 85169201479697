import { useContext, useEffect, useState } from "react";
import {
  DescricaoContainer,
  ImagensContainer,
  ProdutoVitrineStyle,
} from "./style";
import Menu from "../../../components/Menu";
import { ReactSVG } from "react-svg";
import Voltar from "../../../assets/voltar.svg";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../../services/api";
import { toast } from "react-toastify";
import Loading from "../../../components/Loading";
import { moneyMask } from "../../../utils/masks";
import AdicionarMaterialCampanhaModal from "../../../components/Modal/Vitrine/AdicionarMaterialCampanhaModal";
import { useAuth } from "../../../providers/Auth";

export const ProdutoVitrinePage = () => {
  const { user } = useAuth();
  const { material_id } = useParams();
  const navigate = useNavigate();

  const [materialVitrine, setMaterialVitrine] = useState("");
  const [materialVitrineValores, setMaterialVitrineValores] = useState([]);
  const [materialVitrineImagens, setMaterialVitrineImagens] = useState([]);
  const [imagemPrincipal, setImagemPrincipal] = useState("");

  const [quantidade, setQuantidade] = useState(1);
  const [estadoSelecionado, setEstadoSelecionado] = useState("icms_sp");
  const [valorCalculado, setValorCalculado] = useState("");

  const [
    modalAdicionarMaterialCampanhaOpened,
    setModalAdicionarMaterialCampanhaOpened,
  ] = useState(false);

  const [materialVitrineLoaded, setMaterialVitrineLoaded] = useState(false);
  const [materialVitrineValoresLoaded, setMaterialVitrineValoresLoaded] =
    useState(false);
  const [materialVitrineImagensLoaded, setMaterialVitrineImagensLoaded] =
    useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (
      materialVitrineLoaded &&
      materialVitrineImagensLoaded &&
      materialVitrineValoresLoaded
    ) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [
    materialVitrineLoaded,
    materialVitrineImagensLoaded,
    materialVitrineValoresLoaded,
  ]);

  const buscarMaterialVitrine = () => {
    setMaterialVitrineLoaded(false);
    api
      .get(`/material-vitrine/${material_id}`)
      .then((response) => {
        setMaterialVitrine(response.data.data.material);
        setMaterialVitrineLoaded(true);
      })
      .catch((error) => {
        if (error.response.status === 404) {
          navigate("/vitrine");
          return;
        }
        toast.error("Erro ao buscar dados");
      });
  };

  const buscarMaterialVitrineImagens = () => {
    setMaterialVitrineImagensLoaded(false);
    api
      .get(`/material-vitrine/${material_id}/anexos`)
      .then((response) => {
        const anexos = response.data.data.anexos;
        setMaterialVitrineImagens(anexos);
        setImagemPrincipal(anexos[0]);
        setMaterialVitrineImagensLoaded(true);
      })
      .catch((error) => {
        toast.error("Erro ao buscar dados");
      });
  };

  const buscarMaterialVitrineValores = () => {
    setMaterialVitrineValoresLoaded(false);
    api
      .get(`/material-vitrine-valores/${material_id}`)
      .then((response) => {
        setMaterialVitrineValores(response.data.data.valores);
        setMaterialVitrineValoresLoaded(true);
      })
      .catch((error) => {
        toast.error("Erro ao buscar dados");
      });
  };

  useEffect(() => {
    buscarMaterialVitrine();
    buscarMaterialVitrineValores();
    buscarMaterialVitrineImagens();
  }, []);

  const handleConsultaValor = () => {
    const valor = materialVitrineValores
      .sort((a, b) => b.quantidade_minima - a.quantidade_minima)
      .find((valor) => quantidade >= valor.quantidade_minima);

    if (!valor) {
      return setValorCalculado(false);
    }

    const imposto = materialVitrine[estadoSelecionado]
      ? materialVitrine[estadoSelecionado]
      : materialVitrine["icms_sp"];
    const precoUnitario = valor.preco_unitario;

    const preco_unitario = precoUnitario / (1 - imposto / 100);

    const dadosValor = {
      material_vitrine_valor_id: valor.id,
      quantidade: quantidade,
      preco_unitario: preco_unitario,
      preco_total: Number(quantidade) * Number(preco_unitario),
      frete: estadoSelecionado === "icms_sp" ? false : true,
      imposto: estadoSelecionado,
      fornecedor_id: valor.fornecedor_id,
    };

    setValorCalculado(dadosValor);
  };

  return (
    <ProdutoVitrineStyle>
      <Menu />
      <div className="column">
        {loading ? (
          <Loading />
        ) : (
          <div className="body">
            <div className="justify">
              <div className="header">
                <ReactSVG
                  className="voltar"
                  onClick={() => navigate("/vitrine")}
                  src={Voltar}
                />
                <div>
                  <h2>{materialVitrine.nome}</h2>
                  <span>Vitrine &gt; Materiais</span>
                </div>
              </div>
              {user.permissoes.includes("admin") ||
              user.permissoes.includes("compras") ? (
                <h3
                  className="h3-administrar"
                  onClick={() => navigate("editar")}
                >
                  Administrar Item
                </h3>
              ) : (
                <></>
              )}
            </div>
            <div className="flex">
              <div className="material-container">
                <ImagensContainer>
                  <div className="miniaturas-container">
                    {materialVitrineImagens.map((imagem, index) => (
                      <figure key={imagem.id}>
                        <img
                          src={imagem.url}
                          onClick={() => setImagemPrincipal(imagem)}
                          alt={`imagem_${index}`}
                        />
                      </figure>
                    ))}
                  </div>
                  <div className="imagem-principal-container">
                    {imagemPrincipal && (
                      <img src={imagemPrincipal.url} className="main-image" />
                    )}
                  </div>
                </ImagensContainer>
                <DescricaoContainer>
                  <h2>{materialVitrine.nome}</h2>
                  <h3>{materialVitrine.observacao}</h3>
                  <p>
                    <b>NCM</b> {materialVitrine.ncm}
                  </p>
                  <div className="consultar-container">
                    <div className="input-container">
                      <label>Quantidade</label>
                      <input
                        type="number"
                        value={quantidade}
                        min="1"
                        onChange={(e) => setQuantidade(e.target.value)}
                      />
                    </div>
                    <div className="input-container">
                      <label>Estado</label>
                      <select
                        name="estado"
                        id="estado"
                        value={estadoSelecionado}
                        onChange={(e) => setEstadoSelecionado(e.target.value)}
                      >
                        <option value="icms_sp">São Paulo</option>
                        <option value="icms_ac">Acre</option>
                        <option value="icms_al">Alagoas</option>
                        <option value="icms_ap">Amapá</option>
                        <option value="icms_am">Amazonas</option>
                        <option value="icms_ba">Bahia</option>
                        <option value="icms_ce">Ceará</option>
                        <option value="icms_es">Espírito Santo</option>
                        <option value="icms_go">Goiás</option>
                        <option value="icms_ma">Maranhão</option>
                        <option value="icms_mt">Mato Grosso</option>
                        <option value="icms_ms">Mato Grosso do Sul</option>
                        <option value="icms_mg">Minas Gerais</option>
                        <option value="icms_pa">Pará</option>
                        <option value="icms_pb">Paraíba</option>
                        <option value="icms_pr">Paraná</option>
                        <option value="icms_pe">Pernambuco</option>
                        <option value="icms_pi">Piauí</option>
                        <option value="icms_rj">Rio de Janeiro</option>
                        <option value="icms_rn">Rio Grande do Norte</option>
                        <option value="icms_rs">Rio Grande do Sul</option>
                        <option value="icms_ro">Rondônia</option>
                        <option value="icms_rr">Roraima</option>
                        <option value="icms_sc">Santa Catarina</option>
                        <option value="icms_se">Sergipe</option>
                        <option value="icms_to">Tocantins</option>
                      </select>
                    </div>
                    <button
                      className="consultar-button"
                      onClick={handleConsultaValor}
                    >
                      Consultar
                    </button>
                  </div>
                  {valorCalculado ? (
                    <>
                      <div className="valor-container">
                        <div>{valorCalculado.quantidade} unidades</div>
                        <div>
                          R${" "}
                          {moneyMask(
                            String(valorCalculado.preco_unitario.toFixed(2))
                          )}
                          /un.
                        </div>
                        <div>
                          <p>
                            R${" "}
                            {moneyMask(
                              String(valorCalculado.preco_total.toFixed(2))
                            )}
                          </p>
                          {valorCalculado.frete && (
                            <span>+ frete a ser calculado</span>
                          )}
                        </div>
                      </div>
                      <button
                        className="adicionar-button"
                        onClick={() =>
                          setModalAdicionarMaterialCampanhaOpened(true)
                        }
                      >
                        <div>+</div>Adicionar
                      </button>
                    </>
                  ) : valorCalculado === false ? (
                    <span className="aviso">
                      {`Não Encontramos um valor para essa Quantidade :(`}
                    </span>
                  ) : (
                    <></>
                  )}
                </DescricaoContainer>
              </div>
            </div>
            {/* <Recomendacoes
              categoria={materialVitrine.categoria}
              id={material_id}
              state={imagemPrincipal.url}
            /> */}
          </div>
        )}
      </div>
      {modalAdicionarMaterialCampanhaOpened && (
        <AdicionarMaterialCampanhaModal
          setModalOpened={setModalAdicionarMaterialCampanhaOpened}
          valorCalculado={valorCalculado}
          materialVitrine={materialVitrine}
        />
      )}
    </ProdutoVitrineStyle>
  );
};
