import Fornecedor from "../../components/Fornecedor";
import Header from "../../components/Menu";

const FornecedoresPage = () => {
  return (
    <div className="flex">
      <Header />
      <Fornecedor />
    </div>
  );
};

export default FornecedoresPage;
