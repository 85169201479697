import styled from "styled-components";

export const DetalhesDeCampanhaMaterialBriefingStyle = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;

  .flex {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }

  .flex_start {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 18px;
    margin-top: 12px;
  }
  .flex_center {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 18px;
    margin-top: 12px;
  }

  .background {
    position: relative;
    display: flex;
    flex-direction: column;
    text-align: center;
    border-radius: 30px;
    background-color: var(--base);
    padding: 25px 20px;
    width: 538px;
  }

  h2 {
    font-size: 16px;
    font-weight: 700;
  }

  h3 {
    font-weight: 500;
  }

  .column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-top: 20px;
  }

  .min {
    min-width: 136px;
  }

  .close {
    position: absolute;
    top: -20px;
    right: -15px;
    padding: 15px 15px;
    background-color: #fa5252;
    border-radius: 300px;
    font-size: 32px;
    font-weight: 400;
    color: var(--base);
    text-decoration: none;
  }

  h5 {
    text-align: start;
  }
`;

export const InputEspecificacoes = styled.textarea`
  width: 60%;
  text-align: center;
  padding: 2px;
  margin: 5px;
  height: 120px;
`;

export const ButtonSalvar = styled.button`
  width: 60%;
  padding: 5px;
  border-radius: 8px;
  background-color: var(--amarelo);
  color: #fff;
  border: none;

  &:hover {
    background-color: #40ad48;
  }
`;
