import { styled } from "styled-components";

export const TableContainer = styled.div`
  height: 70%;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 8px;
    border-radius: 8px;
    background-color: var(--base01);
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--principal);
    border-radius: 5px;
  }

  > table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    text-align: center;
    padding: 10px;

    > thead > tr > th {
      font-weight: 600;
      padding: 20px;
      padding: 10px;
      font-size: 0.8rem;
    }
    > tbody > tr > td {
      border-bottom: 1px solid var(--grayDA);
      border-top: 1px solid var(--grayA5);
      padding: 15px;
      font-size: 0.8rem;
    }
  }

  .ativo {
    display: flex;
    align-items: center;
    justify-content: center;

    > p {
      font-size: 0.8rem;
      width: 60px;
    }
  }

  a {
    color: var(--azul);
    font-size: 0.8rem;
  }
`;
