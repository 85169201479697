import { styled } from "styled-components";

export const ProdutoVitrineStyle = styled.div`
  display: flex;

  .body {
    width: 100%;
    padding: 35px 35px;
  }

  .column {
    width: 100%;
    background: #f6f6fc;
    border-radius: 58px 0px 0px 58px;
    width: 100%;
  }

  .aviso {
    font-weight: 600;
    color: var(--principal);
    text-align: center;
    display: flex;

    width: 300px;
    justify-content: center;
    margin: 0 auto;
  }

  .header {
    display: flex;
    align-items: center;

    h2 {
      font-size: 28px;
      font-weight: 600;
      color: var(--principal);

      margin-bottom: 6px;
    }

    span {
      color: var(--principal);
      text-decoration: underline;
    }
  }

  svg {
    margin-right: 10px;
  }

  .flex {
    display: flex;
    margin-top: 30px;
  }

  .material-container {
    display: flex;
    width: 100%;
    height: 80%;
  }

  .justify {
    display: flex;
    justify-content: space-between;
  }

  .h3-administrar {
    height: max-content;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 8px 16px;
    border: solid 2px var(--principal);
    color: var(--principal);
    font-weight: 700;
    border-radius: 30px;
    cursor: pointer;
  }

  .voltar {
    cursor: pointer;
  }
`;

export const ImagensContainer = styled.div`
  display: flex;
  column-gap: 5px;
  width: 60%;
  height: min-content;

  > .miniaturas-container {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    width: 30%;
    aspect-ratio: 1/1;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 4px;
      border-radius: 8px;
      background-color: var(--base01);
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--principal);
      border-radius: 5px;
    }

    > figure {
      cursor: pointer;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.1);
        pointer-events: none;
      }

      > img {
        width: 100%;
      }
    }
  }

  > .imagem-principal-container {
    position: relative;
    width: 100%;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.1);
      pointer-events: none;
    }

    > img {
      width: 100%;
      aspect-ratio: 1/1;
      object-fit: cover;
    }
  }
`;

export const DescricaoContainer = styled.div`
  padding: 20px;
  width: 40%;

  > h2 {
    font-size: 24px;
    font-weight: 600;
    color: var(--principal);
    margin-bottom: 10px;
  }

  > h3 {
    font-size: 0.8em;
    line-height: 1.2;
    margin: 0;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  > p {
    font-size: 0.8em;

    > b {
      font-weight: 700;
      font-size: 1em;
    }
  }

  > .consultar-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    column-gap: 4%;
    row-gap: 20px;
    margin: 30px 0;

    > .input-container {
      display: flex;
      flex-direction: column;
      width: 48%;
      > label {
        font-size: 0.8em;
        margin-left: 10px;
      }

      > input,
      > select {
        height: 35px;
        border: none;
        padding: 5px 10px;
        border-radius: 6px;
      }
    }

    > .consultar-button {
      width: 150px;
      height: 38px;
      background: #ffbe00;
      border-radius: 20px;
      border: none;
      color: #640000;
      font-weight: bold;
      font-size: 0.9em;
    }
  }

  > .valor-container {
    display: flex;
    border-top: 1px solid rgba(112, 112, 112, 1);
    border-bottom: 1px solid rgba(112, 112, 112, 1);
    padding: 10px;
    min-height: 60px;

    > :first-child {
      width: 40%;
    }

    > :nth-child(2) {
      width: 20%;
      font-size: 0.8em;
    }

    > :last-child {
      padding-left: 5px;
      width: 40%;

      > p {
        font-weight: 600;
        text-align: right;
      }

      > span {
        display: flex;
        justify-content: flex-end;
        color: #9f0010;
        font-weight: bold;
        font-size: 0.8em;
        line-height: 1;
      }
    }
  }

  > .adicionar-button {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 4px;
    border: 1px solid #640000;
    border-radius: 20px;
    width: 177px;
    height: 42px;
    color: #640000;
    font-weight: bold;
    margin: 10px 0 10px auto;

    > div {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #640000;
      border-radius: 100%;
      color: #640000;
      width: 15px;
      height: 15px;
      font-size: 1em;
      padding-top: 2px;
    }
  }
`;
