import React from "react";
import Header from "../../../components/Menu";
import NovaPermissao from "../../../components/Cadastros/Novo/NovaPermissao";

const NovaPermissaoPage = () => {
  return (
    <div className="flex">
      <Header />
      <NovaPermissao />
    </div>
  );
};

export default NovaPermissaoPage;
