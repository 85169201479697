import { toast } from "react-toastify";
import api from "../../../../services/api";
import LogoVestaSvg from "../../../../assets/logo-vesta.png";
import ImprimirSvg from "../../../../assets/imprimir.svg";
import { Tr } from "./styles";
import { useEffect, useState } from "react";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { getBase64FromUrl } from "../../../../utils/files";
import { moneyMask } from "../../../../utils/masks";
import { ReactSVG } from "react-svg";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const PdfPropostaTr = ({ proposta, tipo }) => {
  const [propostaMateriais, setPropostaMateriais] = useState([]);
  const [pdfUrl, setPdfUrl] = useState("");

  const [loading, setLoading] = useState(true);
  const [logo, setLogo] = useState("");

  useEffect(() => {
    getBase64FromUrl(LogoVestaSvg).then((response) => {
      setLogo(response);
    });
  }, []);

  const buscarPropostaMateriais = () => {
    api
      .get(`/proposta-materiais/${proposta.id}`)
      .then((response) => {
        setPropostaMateriais(response.data.data.materiais);
      })
      .catch((error) => {
        toast.error("Erro ao buscar materiais da proposta.");
      });
  };

  useEffect(() => {
    buscarPropostaMateriais();
  }, []);

  useEffect(() => {
    const generatePdf = async () => {
      const borders = [false, false, false, true];
      let subtotal = 0;
      let itemIndex = 0;

      const tableBody = propostaMateriais.map((material) => {
        let valorUnidade =
          material.origem === "Vitrine" || tipo === "compra"
            ? Number(material.valor_unitario)
            : Number(material.valor_final) / Number(material.quantidade);

        if (material.origem === "Vitrine" || tipo === "compra") {
          subtotal += Number(material.valor_total);
        } else {
          subtotal += Number(material.valor_final);
        }
        itemIndex++;

        return [
          {
            text: itemIndex,
            border: borders,
            style: "materialTexto",
            alignment: "center",
          },
          {
            text: [
              {
                text: material.subnome
                  ? material.nome + " " + material.subnome + "\n"
                  : material.nome + "\n",
              },
              { text: material.descricao, fontSize: 8, margin: [0, 0, 0, 10] },
            ],
            border: borders,
            style: "materialTexto",
          },
          {
            text: `${material.quantidade} un`,
            border: borders,
            style: "materialTexto",
          },
          {
            text: `${
              material.icms
                ? `R$${String(
                    Number(
                      (Number(material.valor_total) * Number(material.icms)) /
                        100
                    ).toFixed(2)
                  )}`
                : "---"
            }`,
            border: borders,
            style: "materialTexto",
            alignment: "center",
          },
          {
            text: `${Number(material.iss) !== 0 ? `ISS` : "---"}`,
            border: borders,
            style: "materialTexto",
            alignment: "center",
          },
          {
            text: `R$${moneyMask(String(Number(valorUnidade).toFixed(2)))}`,
            border: borders,
            style: "materialTexto",
            alignment: "center",
          },
          {
            text: `R$${moneyMask(
              String(
                Number(
                  material.origem === "Vitrine" || tipo === "compra"
                    ? Number(material.valor_total)
                    : Number(material.valor_final)
                ).toFixed(2)
              )
            )}`,
            border: borders,
            style: "materialTexto",
            alignment: "center",
          },
        ];
      });
      var docDefinition = {
        content: [
          {
            columns: [
              {
                image: logo,
                width: 135,
                height: 40,
              },
              {
                stack: [
                  {
                    text: [
                      {
                        text: "VESTA ASSESSORIA E MARKETING LTDA\n",
                        style: "header",
                      },
                      {
                        text: "CNPJ: 46.467.854/0001-83\n",
                        style: "endereco",
                      },
                      {
                        text: "Inscrição Estadual: IE 136.113.678.116\n",
                        style: "endereco",
                      },
                      {
                        text: "Inscrição Municipal : CCM 7.322.570-3\n",
                        style: "endereco",
                      },
                      {
                        text: "R. Jandiatuba, 630 - Conj 430 Bloco B1 - Vila Andrade\n",
                        style: "endereco",
                      },
                      {
                        text: "São Paulo - SP - CEP: 05716-150\n",
                        style: "endereco",
                      },
                      {
                        text: "Telefone: (11) 2737-4989\n",
                        style: "endereco",
                      },
                    ],
                  },
                ],
                width: 190,
              },
            ],
            columnGap: 200,
          },
          {
            text: `Orçamento de Serviço nº ${proposta.campanha_id}`,
            style: "subheader",
          },
          { text: `${proposta.campanha_nome}`, style: "subheader" },

          {
            text: `Informações do ${
              tipo == "compra" ? "Fornecedor" : "Cliente"
            }`,
            style: "sectionHeader",
          },
          {
            text: `${
              tipo == "compra"
                ? propostaMateriais[0].fornecedor_nome
                : proposta.cliente_nome
            }`,
            style: "clientName",
            margin: [0, 0, 0, 10],
          },
          {
            columns: [
              [
                {
                  text: `CNPJ: ${
                    tipo == "compra"
                      ? propostaMateriais[0].fornecedor_documento
                      : proposta.cliente_documento
                  }`,
                  style: "dadosCliente",
                },
                {
                  text: `Inscrição Estadual: ${
                    tipo == "compra"
                      ? propostaMateriais[0].fornecedor_inscricao_estadual
                      : proposta.cliente_inscricao_estadual
                  }`,
                  style: "dadosCliente",
                },
              ],
              [
                {
                  text: `${
                    tipo === "compra"
                      ? propostaMateriais[0].fornecedor_logradouro
                      : proposta.cliente_logradouro
                  }`,
                  style: "dadosCliente",
                },
                {
                  text: `${
                    tipo === "compra"
                      ? propostaMateriais[0].fornecedor_cidade +
                        " - " +
                        propostaMateriais[0].fornecedor_estado +
                        " - " +
                        "CEP:" +
                        propostaMateriais[0].fornecedor_codigo_postal
                      : proposta.cliente_cidade +
                        " - " +
                        proposta.cliente_estado +
                        " - " +
                        "CEP: " +
                        proposta.cliente_codigo_postal
                  }`,
                  style: "dadosCliente",
                },
              ],
              [
                {
                  text: `E-mail: ${
                    tipo === "compra"
                      ? propostaMateriais[0].fornecedor_email_principal
                      : proposta.cliente_email_principal
                  }`,
                  style: "dadosCliente",
                },
                {
                  text: `Telefone: ${
                    tipo === "compra"
                      ? propostaMateriais[0].fornecedor_telefone_principal
                      : proposta.cliente_telefone_principal
                      ? proposta.cliente_telefone_principal
                      : proposta.cliente_telefone_secundario
                      ? proposta.cliente_telefone_secundario
                      : "-"
                  }`,
                  style: "dadosCliente",
                },
              ],
            ],
          },

          { text: "Itens do Pedido", style: "sectionHeader" },
          {
            table: {
              widths: ["5%", "25%", "15%", "10%", "15%", "15%", "15%"],
              headerRows: 1,
              heights: function (row) {
                if (row === 0) {
                  return 20;
                }
                return 40;
              },
              border: "noBorders",
              body: [
                [
                  {
                    text: "Item",
                    style: "tableHeader",
                    border: borders,
                    alignment: "center",
                  },
                  { text: "Descrição", style: "tableHeader", border: borders },
                  { text: "Quant.", style: "tableHeader", border: borders },
                  {
                    text: "Valor ICMS",
                    style: "tableHeader",
                    border: borders,
                    alignment: "center",
                  },
                  {
                    text: "Imposto",
                    style: "tableHeader",
                    border: borders,
                    alignment: "center",
                  },
                  {
                    text: "Valor uni.",
                    style: "tableHeader",
                    border: borders,
                    alignment: "center",
                  },
                  {
                    text: "Valor total",
                    style: "tableHeader",
                    border: borders,
                    alignment: "center",
                  },
                ],
                ...tableBody,
              ],
            },
          },
          {
            canvas: [
              {
                type: "rect",
                x: 0,
                y: 0,
                w: 150,
                h: 40,
                r: 10,
                color: "#FFBE00",
              },
            ],
            margin: [370, 20, 0, 0],
          },
          {
            text: `SUBTOTAL: R$ ${moneyMask(
              String(
                subtotal.toLocaleString("pt-BR", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              )
            )}`,
            style: "subtotal",
          },
        ],
        styles: {
          header: {
            fontSize: 12,
            bold: true,
            margin: [200, 0, 0, 0],
            alignment: "right",
          },
          endereco: {
            fontSize: 8,
            margin: [200, 0, 0, 0],
            alignment: "right",
            lineHeight: 1.1,
          },
          dadosCliente: {
            fontSize: 10,
          },
          subheader: {
            fontSize: 10,
            bold: true,
            margin: [0, 0, 0, 5],
          },
          sectionHeader: {
            fontSize: 10,
            bold: true,
            margin: [0, 20, 0, 10],
          },
          clientName: {
            fontSize: 10,
            bold: true,
          },
          materialTexto: {
            fontSize: 10,
            height: 50,
            bold: false,
            margin: [0, 10, 0, 0],
          },
          subtotal: {
            fontSize: 10,
            bold: true,
            color: "#000",
            margin: [390, -25, 0, 0],
          },
          tableHeader: {
            fontSize: 10,
            bold: true,
          },
        },
      };
      pdfMake.createPdf(docDefinition).getBlob((blob) => {
        const fileURL = URL.createObjectURL(blob);
        setPdfUrl(fileURL);
        setLoading(false);
      });
    };
    if (propostaMateriais.length && logo) {
      generatePdf();
    }
  }, [propostaMateriais, logo]);

  return (
    <Tr>
      <td>
        {loading ? (
          <div className="loading">
            <span></span>
            <span></span>
            <span></span>
          </div>
        ) : (
          <div className="content">
            <a href={pdfUrl} target="_blank" rel="noopener noreferrer">
              Proposta #{proposta.id}: {` `}
              {proposta.nome}.pdf
            </a>
            <button onClick={() => window.open(pdfUrl, "_blank")}>
              <ReactSVG src={ImprimirSvg} /> Imprimir
            </button>
          </div>
        )}
      </td>
    </Tr>
  );
};

export default PdfPropostaTr;
