import EditarItemVitrine from "../../../components/Cadastros/Editar/EditarItemVitrine";
import Menu from "../../../components/Menu";

export const EditarItemVitrinePage = () => {
  return (
    <div className="flex">
      <Menu />
      <EditarItemVitrine />
    </div>
  );
};
