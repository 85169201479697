import { useEffect, useState } from "react";
import Loading from "../../Loading";
import { ContentContainer, GerarPedidoComprasModalContainer } from "./styles";
import LogoVestaPng from "../../../assets/logo-vesta.png";
import { getBase64FromUrl } from "../../../utils/files";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { moneyMask } from "../../../utils/masks";
import api from "../../../services/api";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const GerarRelatorioDisputaModal = ({
  setModalOpened,
  campanha,
  fornecedor,
  material,
  marcas,
}) => {
  const [loading, setLoading] = useState(true);
  const [logo, setLogo] = useState("");
  const [orcamentos, setOrcamentos] = useState([]);

  function relatorioDisputa() {
    api
      .get(
        `/relatorios/disputa/${material.campanha_material_briefing_id}/${material.quantidade}`
      )
      .then((response) => {
        setOrcamentos(response.data.data.briefing);
      })
      .catch(() => {
        console.log("erro");
      });
  }

  useEffect(() => {
    getBase64FromUrl(LogoVestaPng).then((response) => {
      setLogo(response);
      relatorioDisputa();
    });
  }, []);

  const generatePDF = async () => {
    const borders = [false, false, false, true];

    const vencedor = orcamentos.find(
      (orcamento) =>
        orcamento.status === "em produção" || orcamento.status === "concluido"
    );

    const tableBody = orcamentos.map((material) => {
      return [
        {
          text: material.fornecedor_nome,
          border: borders,
          style: "materialTexto",
          alignment: "center",
        },
        {
          text: Number(material.posicao) + "º",
          border: borders,
          style: "materialTexto",
          alignment: "center",
        },
        {
          text: `R$ ${Number(material.valor_total).toFixed(2)}`,
          border: borders,
          style: "materialTexto",
          alignment: "center",
        },
        {
          text: `R$ ${Number(material.valor_de_venda).toFixed(2)}`,
          border: borders,
          style: "materialTexto",
          alignment: "center",
        },
        {
          text: material.diferenca,
          border: borders,
          style: "materialTexto",
          alignment: "center",
        },
        {
          text: `${material.prazo_producao} dias úteis`,
          border: borders,
          style: "materialTexto",
          alignment: "center",
        },
        {
          text: ``,
          border: borders,
          style: "materialTexto",
          alignment: "center",
        },
      ];
    });

    const dadosMaterial = [
      {
        text: vencedor.fornecedor_nome,
        border: borders,
        style: "materialTexto",
        alignment: "center",
      },
      {
        text: [
          {
            text: `${vencedor.quantidade} un`,
          },
        ],
        border: borders,
        style: "materialTexto",
      },
      {
        text: `R$ ${Number(vencedor.valor_total).toFixed(2)}`,
        border: borders,
        style: "materialTexto",
      },
      {
        text: `R$ ${Number(vencedor.valor_de_venda).toFixed(2)}`,
        border: borders,
        style: "materialTexto",
        alignment: "center",
      },
      {
        text: `${(
          (vencedor.valor_total / vencedor.valor_de_venda) *
          100
        ).toFixed(2)}%`,
        border: borders,
        style: "materialTexto",
        alignment: "center",
      },
      {
        text: `R$ ${Number(vencedor.impostos_pagos).toFixed(2)}`,
        border: borders,
        style: "materialTexto",
        alignment: "center",
      },
      {
        text: `R$ ${Number(vencedor.margem_liquida).toFixed(2)}`,
        border: borders,
        style: "materialTexto",
        alignment: "center",
      },
      {
        text: `${(
          (Number(vencedor.margem_liquida).toFixed(2) /
            Number(vencedor.valor_total)) *
          100
        ).toFixed(2)}%`,
        border: borders,
        style: "materialTexto",
        alignment: "center",
      },
      {
        text: `${vencedor.data_vencimento}`,
        border: borders,
        style: "materialTexto",
        alignment: "center",
      },
    ];

    var docDefinition = {
      pageMargins: [30, 40, 30, 40],
      content: [
        {
          columns: [
            {
              image: logo,
              width: 135,
              height: 40,
            },
            {
              stack: [
                {
                  text: [
                    {
                      text: "VESTA ASSESSORIA E MARKETING LTDA\n",
                      style: "header",
                    },
                    {
                      text: "CNPJ: 46.467.854/0001-83\n",
                      style: "endereco",
                    },
                    {
                      text: "Inscrição Estadual: IE 136.113.678.116\n",
                      style: "endereco",
                    },
                    {
                      text: "Inscrição Municipal : CCM 7.322.570-3\n",
                      style: "endereco",
                    },
                    {
                      text: "R. Jandiatuba, 630 - Conj 430 Bloco B1 - Vila Andrade\n",
                      style: "endereco",
                    },
                    {
                      text: "São Paulo - SP - CEP: 05716-150\n",
                      style: "endereco",
                    },
                    {
                      text: "Telefone: (11) 2737-4989\n",
                      style: "endereco",
                    },
                  ],
                },
              ],
              width: 190,
            },
          ],
          columnGap: 210,
        },
        {
          text: `Campanha / número: #${material.campanha_material_briefing_id}`,
          style: "subheader",
        },
        { text: `Nome: ${material.nome}`, style: "subheader" },
        {
          text: `Marcas: ${marcas.map((element) => element.nome).join(", ")}`,
          style: "subheader",
        },
        { text: `Tipo: ${orcamentos[0].item_tipo}`, style: "subheader" },
        {
          text: `Especificações: ${material.descricao_produtor}`,
          style: "subheader",
        },
        {
          text: `Orçamento: #${vencedor.id}`,
          style: "subheader",
        },
        {
          table: {
            style: "teste",
            widths: [
              "11.1%",
              "11.1%",
              "11.1%",
              "11.1%",
              "11.1%",
              "11.1%",
              "11.1%",
              "11.1%",
              "11.1%",
            ],
            headerRows: 1,
            heights: function (row) {
              if (row === 0) {
                return 20;
              }
              return 40;
            },
            border: "noBorders",
            body: [
              [
                {
                  text: "Fornecedor Vencedor",
                  style: "tableHeader",
                  border: borders,
                  alignment: "center",
                },
                {
                  text: "Quantidade a ser Produzida",
                  style: "tableHeader",
                  border: borders,
                },
                {
                  text: "Valor de Compra",
                  style: "tableHeader",
                  border: borders,
                },
                {
                  text: "Valor de Venda",
                  style: "tableHeader",
                  border: borders,
                  alignment: "center",
                },
                {
                  text: "% de custo de terceiro",
                  style: "tableHeader",
                  border: borders,
                  alignment: "center",
                },
                {
                  text: "Impostos Pagos",
                  style: "tableHeader",
                  border: borders,
                  alignment: "center",
                },
                {
                  text: "Margem líquida em $",
                  style: "tableHeader",
                  border: borders,
                  alignment: "center",
                },
                {
                  text: "Margem líquida em %",
                  style: "tableHeader",
                  border: borders,
                  alignment: "center",
                },
                {
                  text: "Data de Vencimento",
                  style: "tableHeader",
                  border: borders,
                  alignment: "center",
                },
              ],
              dadosMaterial,
            ],
          },
        },
        {
          table: {
            margin: [500, 500, 550, 550],
            style: "teste2",
            widths: [
              "14.2%",
              "14.2%",
              "14.2%",
              "14.2%",
              "14.2%",
              "14.2%",
              "14.2%",
            ],
            headerRows: 1,
            heights: function (row) {
              if (row === 0) {
                return 20;
              }
              return 40;
            },
            border: "noBorders",
            body: [
              [
                {
                  text: "Nome do fornecedor",
                  style: "tableHeader",
                  border: borders,
                  alignment: "center",
                },
                {
                  text: "Classiﬁcação concorrência",
                  style: "tableHeader",
                  border: borders,
                  alignment: "center",
                },
                {
                  text: "Valor de Compra",
                  style: "tableHeader",
                  border: borders,
                  alignment: "center",
                },
                {
                  text: "Valor de Venda",
                  style: "tableHeader",
                  border: borders,
                  alignment: "center",
                },
                {
                  text: "Diferença pro próximo colocado (%)",
                  style: "tableHeader",
                  border: borders,
                  alignment: "center",
                },
                {
                  text: "Prazo de Produção",
                  style: "tableHeader",
                  border: borders,
                  alignment: "center",
                },
                {
                  text: "",
                  style: "tableHeader",
                  border: borders,
                  alignment: "center",
                },
              ],
              ...tableBody,
            ],
          },
        },
        {
          text: `_______________________________________`,
          style: "linha",
        },
        {
          text: `Aprovação diretoria`,
          style: "textoLinha",
        },
      ],
      styles: {
        teste: {
          background: "#7D0000",
        },
        teste2: {
          margin: [500, 500, 500, 500],
        },
        header: {
          fontSize: 12,
          bold: true,
          margin: [200, 0, 0, 0],
          alignment: "right",
        },
        endereco: {
          fontSize: 8,
          margin: [200, 0, 0, 0],
          alignment: "right",
          lineHeight: 1.1,
        },
        dadosCliente: {
          fontSize: 10,
        },
        subheader: {
          fontSize: 10,
          bold: true,
          margin: [0, 0, 0, 5],
        },
        sectionHeader: {
          fontSize: 10,
          bold: true,
          margin: [0, 20, 0, 10],
        },
        clientName: {
          fontSize: 10,
          bold: true,
        },
        materialTexto: {
          fontSize: 8,
          height: 50,
          bold: false,
          margin: [0, 10, 0, 0],
        },
        subtotal: {
          fontSize: 10,
          bold: true,
          color: "#fff",
          margin: [410, -25, 0, 0],
        },
        tableHeader: {
          fontSize: 8,
          bold: true,
          alignment: "center",
          margin: [0, 30, 0, 0],
        },
        linha: {
          margin: [0, 80, 0, 0],
        },
        textoLinha: {
          margin: [50, 0, 0, 0],
        },
      },
    };

    pdfMake.createPdf(docDefinition).getBlob((blob) => {
      const fileURL = URL.createObjectURL(blob);
      const newWindow = window.open(fileURL, "_blank", "noopener,noreferrer");
      if (newWindow) newWindow.opener = null;
      setModalOpened(false);
    });
  };

  useEffect(() => {
    if (logo && orcamentos.length > 0) {
      generatePDF();
    }
  }, [logo, orcamentos]);

  return (
    <GerarPedidoComprasModalContainer>
      <ContentContainer>{loading && <Loading />}</ContentContainer>
    </GerarPedidoComprasModalContainer>
  );
};

export default GerarRelatorioDisputaModal;
