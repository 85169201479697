
import Dashboard from '../../components/Dashboard';
import Menu from '../../components/Menu';
import { DashBoardContainer } from './styles';

const DashboardPage = () => {

    return (
        <DashBoardContainer>
              <Menu />
            <Dashboard/>
        </DashBoardContainer>
    );
};

export default DashboardPage;