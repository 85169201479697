import { toast } from "react-toastify";
import Menu from "../../components/Menu";
import { AdministrarItemVritrinePageStyle } from "./style";
import api from "../../services/api";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../../components/Loading";
import { FormAdministrarItem } from "../../components/Forms/FormAdministrarItem";

export const AdministrarItemVritrinePage = () => {
  const { material_id } = useParams();
  const navigate = useNavigate();

  const [materialVitrine, setMaterialVitrine] = useState("");
  const [materialVitrineValores, setMaterialVitrineValores] = useState([]);
  const [materialVitrineImagens, setMaterialVitrineImagens] = useState([]);
  const [imagemPrincipal, setImagemPrincipal] = useState("");
  const [categorias, setCategorias] = useState();

  const [materialVitrineLoaded, setMaterialVitrineLoaded] = useState(false);
  const [materialVitrineValoresLoaded, setMaterialVitrineValoresLoaded] =
    useState(false);
  const [materialVitrineImagensLoaded, setMaterialVitrineImagensLoaded] =
    useState(false);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (
      materialVitrineLoaded &&
      materialVitrineImagensLoaded &&
      materialVitrineValoresLoaded
    ) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [
    materialVitrineLoaded,
    materialVitrineImagensLoaded,
    materialVitrineValoresLoaded,
  ]);

  const buscarMaterialVitrine = () => {
    setMaterialVitrineLoaded(false);
    api
      .get(`/material-vitrine/${material_id}`)
      .then((response) => {
        setMaterialVitrine(response.data.data.material);
        setMaterialVitrineLoaded(true);
      })
      .catch((error) => {
        if (error.response.status === 404) {
          navigate("/vitrine");
          return;
        }
        toast.error("Erro ao buscar dados");
      });
  };

  const buscarMaterialVitrineImagens = () => {
    setMaterialVitrineImagensLoaded(false);
    api
      .get(`/material-vitrine/${material_id}/anexos`)
      .then((response) => {
        const anexos = response.data.data.anexos;
        setMaterialVitrineImagens(anexos);
        setImagemPrincipal(anexos[0]);
        setMaterialVitrineImagensLoaded(true);
      })
      .catch((error) => {
        toast.error("Erro ao buscar dados");
      });
  };

  const buscarMaterialVitrineValores = () => {
    setMaterialVitrineValoresLoaded(false);
    api
      .get(`/material-vitrine-valores/${material_id}`)
      .then((response) => {
        setMaterialVitrineValores(response.data.data.valores);
        setMaterialVitrineValoresLoaded(true);
      })
      .catch((error) => {
        toast.error("Erro ao buscar dados");
      });
  };

  function buscarCategoriaItem() {
    api
      .get(`/categoria-item`)
      .then((response) => {
        setCategorias(response.data.data.categorias);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  useEffect(() => {
    buscarMaterialVitrine();
    buscarMaterialVitrineValores();
    buscarMaterialVitrineImagens();
    buscarCategoriaItem();
  }, []);
  return (
    <AdministrarItemVritrinePageStyle>
      <Menu />
      {loading ? (
        <Loading />
      ) : (
        <FormAdministrarItem
          materialVitrine={materialVitrine}
          materialVitrineValores={materialVitrineValores}
          materialVitrineImagens={materialVitrineImagens}
          material_id={material_id}
          buscarMaterialVitrine={buscarMaterialVitrine}
          buscarMaterialVitrineImagens={buscarMaterialVitrineImagens}
          buscarMaterialVitrineValores={buscarMaterialVitrineValores}
          categorias={categorias}
        />
      )}
    </AdministrarItemVritrinePageStyle>
  );
};
