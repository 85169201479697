import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Body,
  ContainerInputs,
  EditarMarcaStyle,
  NovoUsuarioContainer,
} from "./styles";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import voltar from "../../../../assets/voltar.svg";
import { CampoObrigatorio } from "../../../../schemas/novoCadastro";
import { toast } from "react-toastify";
import api from "../../../../services/api";
import { ReactSVG } from "react-svg";

const EditarMarca = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const {
    register,
    getValues,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(CampoObrigatorio),
  });

  //ATUALIZA ITEM
  const onSubmitFunction = () => {
    const formValues = getValues();

    api
      .patch(`/marcas/${id}`, formValues)
      .then((response) => {
        navigate("/marcas");

        toast.success("Item atualizado com sucesso!");
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  //BUSCA ITEM PELO ID
  function buscaItemId() {
    api
      .get(`/marcas/${id}`)
      .then((response) => {
        reset(response.data.data.marca);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  useEffect(() => {
    buscaItemId();
  }, [id]);

  return (
    <NovoUsuarioContainer onSubmit={handleSubmit(onSubmitFunction)}>
      <div className="box">
        <div className="flex">
          <ReactSVG
            onClick={() => navigate("/marcas")}
            className="voltar"
            src={voltar}
          />
          <div>
            <h1>Marca</h1>
            <span className="underline">Editar Marca</span>
          </div>
        </div>

        <Body>
          <div className="checkbox">
            <input
              type="checkbox"
              id="checkbox1"
              name="status"
              defaultChecked={true}
              {...register("status")}
            />
            <label htmlFor="checkbox1">Ativo</label>
          </div>
          <ContainerInputs>
            <div>
              <label>Nome do item *</label>
              <input
                type="text"
                name="nome"
                {...register("nome")}
                placeholder="Nome do produto/serviço"
              />
              {errors.nome && (
                <span className="input-error-cadastro">
                  {errors.nome.message}
                </span>
              )}
            </div>
          </ContainerInputs>
        </Body>
        <div className="buttons">
          <Link to="/itens">{"< Voltar"}</Link>
          <button type="submit">Salvar</button>
        </div>
      </div>
    </NovoUsuarioContainer>
  );
};

export default EditarMarca;
