import NovoFornecedor from "../../../components/Cadastros/Novo/NovoFornecedor";
import Header from "../../../components/Menu";

const NovoFornecedorPage = () => {
  return (
    <div className="flex">
      <Header />
      <NovoFornecedor />
    </div>
  );
};

export default NovoFornecedorPage;
