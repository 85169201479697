import { styled } from "styled-components";

export const FornecedorCampanhaPageStyle = styled.div`
  width: calc(100% - 260px);
  padding: 30px 30px;
  background: var(--base01);
  border-radius: 40px;
  .flex {
    display: flex;
    align-items: center;
  }

  h1 {
    font-size: 30px;
    font-weight: 600;
    color: var(--principal);
    margin-left: 8px;
  }

  input {
    width: 350px;
    border: none;
    border-radius: 8px;
    padding: 8px 8px;
    cursor: pointer;
    margin-right: 20px;
    margin-top: 30px;
    font-size: 12px;
  }

  form {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .header {
    display: flex;
    align-items: end;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 30px;

    button {
      border: none;
      border-radius: 16px;
      padding: 8px 50px;
      background-color: var(--principal);
      color: var(--base);
      font-weight: 600;
    }
  }

  .div-filter {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      margin-right: 5px;
    }

    span {
      font-weight: 500;
    }
  }

  select {
    width: 220px;
    padding: 3px 0px;
    text-align: center;
    border: none;
    border-radius: 6px;
    color: var(--base);
    background-color: var(--amarelo);
    margin-top: 15px;
    cursor: pointer;
  }
`;
