import { styled } from "styled-components";

export const ListaCategoriaStyle = styled.div`
  width: 100%;
  .cards {
    width: 100%;
    overflow-x: auto;
  }

  .cards::-webkit-scrollbar {
    width: 10px;
    height: 5px;
  }

  .cards::-webkit-scrollbar-thumb {
    background-color: var(--amarelo);
    border-radius: 6px;
  }

  .cards::-webkit-scrollbar-track {
    background-color: #f1f1f1; /* Cor da área de fundo da barra de rolagem */
  }
`;
