import { ReactSVG } from "react-svg";
import {
  ArteMaterialContainer,
  ArteMaterialLink,
  CardMaterialViaVitrineStyle,
} from "./style";
import Editar from "../../../assets/editar-material.svg";
import Excluir from "../../../assets/excluir-material.svg";
import { moneyMask } from "../../../utils/masks";
import { useEffect, useState } from "react";
import ModalPreencherEndereco from "../ModalPreencherEndereco";
import api from "../../../services/api";
import { toast } from "react-toastify";
import Loading from "../../Loading";
import { IoCloseSharp } from "react-icons/io5";

export const CardMaterialViaVitrine = ({
  material,
  buscarMateriaisVitrine,
  categorias,
}) => {
  const [artesMaterial, setArtesMaterial] = useState([]);
  const [preencherEndereco, setPreencherEndereco] = useState(false);

  const [artesMaterialLoaded, setArtesMaterialLoaded] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (artesMaterialLoaded) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [artesMaterialLoaded]);

  const buscarMaterialAnexos = () => {
    setArtesMaterialLoaded(false);
    api
      .get(`/campanha-material-vitrine/${material.id}/anexos`)
      .then((response) => {
        setArtesMaterial(response.data.data.anexos);
        setArtesMaterialLoaded(true);
      })
      .catch((error) => {
        toast.error("Erro ao buscar arte de personalização");
      });
  };

  useState(() => {
    buscarMaterialAnexos();
  }, []);

  const adicionaArtePersonalizacao = (file) => {
    setArtesMaterialLoaded(false);
    const formData = new FormData();
    formData.append("arquivo", file);

    api
      .post(`/campanha-material-vitrine/${material.id}/anexos`, formData)
      .then((response) => {
        toast.success("Arquivo adicionado com sucesso!");
        buscarMaterialAnexos();
      })
      .catch((error) => {
        toast.error("Erro ao adicionar arquivo");
        setLoading(false);
      });
  };

  const excluirMaterial = () => {
    setLoading(true);
    api
      .delete(`/campanha-material-vitrine/${material.id}`)
      .then((response) => {
        toast.success("Material excluído com sucesso!");
        buscarMateriaisVitrine();
      })
      .catch((error) => {
        toast.error("Erro ao excluir material");
      });
  };

  const excluirArtePersonalizacao = (arteId) => {
    setArtesMaterialLoaded(false);
    api
      .delete(`/campanha-material-vitrine/${material.id}/anexos/${arteId}`)
      .then((response) => {
        buscarMaterialAnexos();
      })
      .catch((error) => {
        toast.error("Erro ao excluir arte de personalização");
      });
  };

  return (
    <CardMaterialViaVitrineStyle>
      {loading ? (
        <Loading />
      ) : (
        <>
          <div className="escala">
            <h3 className="categoria">Categoria</h3>
            <h3 className="material">Material</h3>
            <h3 className="quantidade">Quantidade</h3>
            <h3 className="unidade">Valor .un</h3>
            <h3 className="total">Valor total</h3>
            <h3 className="acoes">Ações</h3>
          </div>
          <div className="flex">
            <h3 className="categoria">
              {categorias.length > 0 &&
                categorias.find(
                  (elemen) => Number(elemen.id) === Number(material.categoria)
                ).nome}
            </h3>
            <div className="material">
              <h4 className="nome">{material.material_vitrine}</h4>
              <p className="descricao">{material.observacao}</p>
            </div>
            <h4 className="quantidade">{material.quantidade} un.</h4>
            <h4 className="unidade">
              R$ {moneyMask(String(material.preco_unitario.toFixed(2)))}
            </h4>
            <h4 className="total">
              R${" "}
              {moneyMask(
                String(
                  (material.preco_unitario * material.quantidade).toFixed(2)
                )
              )}
            </h4>
            <div className="acoes">
              <ReactSVG
                className="excluir"
                onClick={() => excluirMaterial()}
                style={{ cursor: "pointer" }}
                src={Excluir}
              />
            </div>
          </div>
          <div className="flex infos-adicionais-container">
            <div className="personalizacao-container">
              <h3>
                <b>Arte de personalização</b>
              </h3>
              <div className="arquivo-container">
                <input
                  id={`${material.id}_arquivo`}
                  type="file"
                  accept="image/png, image/jpeg, image/jpg, image/webp, application/pdf"
                  onChange={(e) => {
                    adicionaArtePersonalizacao(e.target.files[0]);
                  }}
                />
                <label htmlFor={`${material.id}_arquivo`}>
                  Selecionar arquivo
                </label>
                <label htmlFor={`${material.id}_arquivo`} className="pesquisar">
                  pesquisar
                </label>
              </div>
              {artesMaterial.map((arte, index) => (
                <ArteMaterialContainer key={arte.id}>
                  <a href={arte.url} target="_blank" rel="noreferrer">
                    arte_personalizacao_{index + 1}.{arte.url.split(".").at(-1)}
                  </a>
                  <IoCloseSharp
                    style={{ cursor: "pointer" }}
                    fill="#ff0000"
                    onClick={() => excluirArtePersonalizacao(arte.id)}
                  />
                </ArteMaterialContainer>
              ))}
            </div>
            <div className="endereco-container">
              <h3>
                <b>Entrega</b>
                <button onClick={() => setPreencherEndereco(true)}>
                  preencher endereço {">"}
                </button>
              </h3>
              <div className="endereco">
                {material.cep ? (
                  <>
                    <p>
                      {material.logradouro}, {material.numero} -{" "}
                      {material.complemento ? `${material.complemento} - ` : ""}{" "}
                      {material.bairro}
                    </p>
                    <p>
                      {material.cidade} - {material.uf}, {material.cep}
                    </p>
                  </>
                ) : (
                  <p>Nenhum endereço preenchido</p>
                )}
              </div>
            </div>
          </div>
        </>
      )}
      {preencherEndereco && (
        <ModalPreencherEndereco
          setModalOpened={setPreencherEndereco}
          material={material}
          buscarMateriaisVitrine={buscarMateriaisVitrine}
        />
      )}
    </CardMaterialViaVitrineStyle>
  );
};
