import { ModalObservacoesFornecedorStyle } from "./style";

export const ModalObservacoesFornecedor = ({ state, orcamento }) => {
  return (
    <ModalObservacoesFornecedorStyle>
      <div className="div-body">
        <span onClick={() => state(false)} className="close">
          X
        </span>
        <h3>Observação:</h3>
        <h4>{orcamento.observacoes}</h4>
        <h3 className="entrega">Prazo de Orçamento:</h3>
        <h4>{orcamento.prazo_producao}</h4>
      </div>
    </ModalObservacoesFornecedorStyle>
  );
};
