import Clientes from "../../components/Clientes";
import Header from "../../components/Menu";

const ClientesPage = () => {
  return (
    <div className="flex">
      <Header />
      <Clientes />
    </div>
  );
};

export default ClientesPage;
