import styled from "styled-components";

export const QuantidadeBriefingStyled = styled.div`
  position: relative;
  .close {
    position: absolute;
    top: 10px;
    right: 16%;
    color: var(--base);
    background-color: #e74c3c;
    padding: 3px 8px;
    border-radius: 1000px;
    font-weight: 600;
    cursor: pointer;
  }
`;
