import EditarMarca from "../../../components/Cadastros/Editar/EditarMarca";
import Header from "../../../components/Menu";

const EditarMarcaPage = () => {
  return (
    <div className="flex">
      <Header />
      <EditarMarca />
    </div>
  );
};

export default EditarMarcaPage;
