import Login from '../../components/Login';
import { LoginPageContainer } from './styles';

const LoginPage = () => {

    return (
        <LoginPageContainer>
            <Login />
        </LoginPageContainer>
    );
};

export default LoginPage;