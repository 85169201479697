import { styled } from "styled-components";

export const CardMaterialViaBriefingStyle = styled.tr`
  span {
    text-decoration: underline;
    color: #609ae5;
  }

  .editar {
    padding: 10px 8px 6px 10px;
    border-radius: 80px;
    background-color: var(--amarelo);
    margin-right: 10px;
    width: 35px;
  }

  .margin25 {
    margin-right: 8px;
  }

  .aprovar {
    right: -25px;
  }

  .produtor {
    font-weight: 600;

    span {
      font-size: 12px;
      text-decoration: none;
      color: black;
    }

    .Pendente {
      color: red;
    }
  }

  .selecao {
    position: relative;
  }

  .selecao:hover {
    .esconder {
      color: var(--principal) !important;
      z-index: 10;
    }
  }

  .vitrine {
    padding: 6px 8px;
    background-color: var(--principal);
    border-radius: 32px;
    margin-left: 8px;
    cursor: pointer;

    svg {
      width: 23px;
      height: 23px;
    }
  }

  .lupa {
    border-radius: 32px;
    margin-left: 14px;
    cursor: pointer;
    width: 40px;
    height: 40px;
    background-color: var(--principal);
    margin-right: 8px;

    svg {
      width: 32px;
      height: 36px;
    }

    path {
      stroke: var(--amarelo);
    }
  }

  .botao-disabled {
    background-color: #987575 !important;
    cursor: not-allowed !important;
  }

  .especificacoes {
    display: flex;
  }

  .especificacoes-position {
    left: -24px;
    top: 40px;
    color: white;
    z-index: -1;
    cursor: pointer;
  }

  .esconder {
    color: white;
    position: absolute;
    bottom: -16px;
  }

  .excluir {
    padding: 6px 8px 6px 8px;
    border-radius: 800px;
    background-color: var(--principal);
    display: flex;
    align-items: center;
    margin-left: 8px;
    cursor: pointer;

    svg {
      width: 23px;
      height: 23px;
      path,
      polygon,
      rect,
      circle,
      ellipse {
        fill: var(--amarelo);
      }
    }
  }

  .cursor {
    cursor: pointer;
  }

  .checkbox {
    position: relative;
    left: 10px;
  }

  .botao-revisao {
    border: none;
    border-radius: 30px;
    font-size: 13px;
    font-weight: 500;
    width: max-content;
    padding: 8px 10px;
    background-color: var(--principal);
    color: white;
    margin-right: 15px;
  }

  .botao-concorrencia {
    border: none;
    border-radius: 30px;
    font-size: 13px;
    font-weight: 600;
    width: max-content;
    padding: 8px 10px;
    background-color: var(--amarelo);
    color: var(--principal);
    margin-left: 8px;
  }
  .center {
    justify-content: center;
  }

  .margin {
    margin-top: 4px;
    margin-right: 4px;
  }

  /* .quatro {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 0px;
  } */

  h6 {
    font-size: 14px;
    font-weight: 600;
    color: white;
  }

  .aguardando-orcamento {
    background-color: #ef5713;
    padding: 5px 0px 2px;
    border-radius: 30px;
  }

  .aguardando-disputa {
    background-color: #ffbe00;
    padding: 5px 0px 2px;
    border-radius: 30px;
  }

  .producao {
    background-color: #609ae5;
    padding: 5px 7px 5px;
    border-radius: 30px;
    width: 100%;
  }

  .concluido {
    background-color: black;
    padding: 5px 0px 5px;
    border-radius: 30px;
  }

  .botao-adicionar-produtor {
    border: none;
    border-radius: 30px;
    font-size: 13px;
    font-weight: 500;
    width: max-content;
    padding: 7px 8px 4px 8px;
    background-color: var(--amarelo);
    color: white;
    margin-left: 14px;
    cursor: pointer;

    svg {
      width: 25px;
      height: 25px;
      path,
      polygon,
      rect,
      circle,
      ellipse {
        fill: var(--principal);
      }
    }
  }

  .margin-right {
    margin-right: 14px;
  }

  .aprovacaoBriefing {
    padding: 10px 8px 6px 10px;
    border-radius: 80px;
    background-color: var(--amarelo);
    margin-right: 10px;
    width: 35px;
    border: none;
    border-radius: 30px;
    font-size: 13px;
    font-weight: 500;
    width: max-content;
    margin-right: 2px;
    margin-left: 2px;
    color: white;

    svg {
      width: 19px;
      height: 19px;
      path,
      polygon,
      rect,
      circle,
      ellipse {
        fill: var(--principal);
      }
    }
  }

  .editar {
    border: none;
    border-radius: 30px;
    font-size: 13px;
    font-weight: 500;
    width: max-content;
    padding: 8px -1px -1px 9px;
    background-color: var(--principal);
    margin-right: 2px;
    color: white;

    svg {
      width: 19px;
      height: 19px;
      path,
      polygon,
      rect,
      circle,
      ellipse {
        fill: var(--amarelo);
      }
    }
  }

  .botao-concorrencia {
    padding-top: 9px;

    svg {
      width: 19px;
      height: 19px;
      path,
      polygon,
      rect,
      circle,
      ellipse {
        fill: var(--principal);
      }
    }
  }

  .revisao {
    border: none;
    border-radius: 30px;
    font-size: 13px;
    font-weight: 500;
    width: max-content;
    padding: 7px 8px 4px 8px;
    background-color: var(--principal);
    color: white;
    cursor: pointer;
    margin-right: 5px;
    margin-left: 3px;

    svg {
      width: 22px;
      height: 22px;
      path,
      polygon,
      rect,
      circle,
      ellipse {
        fill: var(--amarelo);
      }
    }
  }

  .revisao-position {
    right: -10px;
    top: 40px;
    color: white;
    z-index: -1;
    cursor: pointer;
  }

  .concorrencia-position {
    right: -38px;
    top: 40px;
    color: white;
    z-index: -1;
    cursor: pointer;
  }

  .excluir-position {
    right: -6px;
    top: 40px;
    color: white;
    cursor: pointer;
  }

  .editar-position {
    right: -3px;
    top: 40px;
    color: white;
    cursor: pointer;
  }

  .produtor-position {
    right: -14px;
    top: 40px;
    color: white;
    cursor: pointer;
  }
`;
