import Menu from "../../components/Menu";
import CampanhasCriadas from "./CampanhasCriadasPage";
import { CampanhasPageContainer } from "./style";

const CampanhaPage = () => {
  return (
    <CampanhasPageContainer>
      <Menu />
      <CampanhasCriadas />
    </CampanhasPageContainer>
  );
};

export default CampanhaPage;
