import { Link } from "react-router-dom";
import { useAuth } from "../../../providers/Auth";
import { TableContainer } from "./styles";
import { ReactSVG } from "react-svg";
import ativo from "../../../assets/ativo.svg";
import inativo from "../../../assets/inativo.svg";
import { useEffect, useState } from "react";
import api from "../../../services/api";
import { toast } from "react-toastify";

const Table = ({ perfilSelecionado, textoPesquisa }) => {
  const [fornecedores, setFornecedores] = useState([]);
  const [categorias, setCategorias] = useState([]);

  function buscarFornecedor() {
    api
      .get("/fornecedores")
      .then((response) => {
        setFornecedores(response.data.data.fornecedores);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  function buscarCategoria() {
    api
      .get("/categoria-fornecedor")
      .then((response) => {
        setCategorias(response.data.data.categorias);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  useEffect(() => {
    buscarFornecedor();
    buscarCategoria();
  }, []);

  const colunas = ["Id", "Nome do fornecedor", "CNPJ", "Segmento", "Status"];
  const filtrarFornecedores = (fornecedor) => {
    const perfilFiltrado =
      !perfilSelecionado || fornecedor.segmento_atuacao === perfilSelecionado;
    const pesquisaFiltrada =
      !textoPesquisa ||
      fornecedor.nome.toLowerCase().includes(textoPesquisa.toLowerCase());
    const idFornecedor = parseInt(textoPesquisa);
    const idFiltrado = !isNaN(idFornecedor) && fornecedor.id === idFornecedor;

    return perfilFiltrado && (pesquisaFiltrada || idFiltrado);
  };

  const fornecedoresFiltrados = fornecedores.filter(filtrarFornecedores);

  return (
    <TableContainer>
      <table>
        <thead>
          <tr>
            {colunas.map((coluna) => (
              <th key={coluna}>{coluna}</th>
            ))}
          </tr>
        </thead>
        {fornecedores !== "" ? (
          <tbody>
            {fornecedoresFiltrados.length > 0 &&
              fornecedoresFiltrados.map((lista) => (
                <tr className="map" key={lista.id}>
                  <td>#{lista.id}</td>
                  <td>{lista.nome}</td>
                  <td>{lista.documento}</td>

                  <td>
                    {categorias.length > 0 &&
                      categorias.find(
                        (elem) =>
                          Number(elem.id) ===
                          Number(lista.segmento_fornecedor_id)
                      ).nome}
                  </td>

                  {lista.status === 1 ? (
                    <td>
                      <div className="ativo">
                        <ReactSVG src={ativo} />
                        <p>ativo</p>
                      </div>
                    </td>
                  ) : (
                    <td>
                      <div className="ativo">
                        <ReactSVG src={inativo} />
                        <p>inativo</p>
                      </div>
                    </td>
                  )}
                  <td className="td-editar">
                    <Link
                      to={`/fornecedor/editar/${lista.id}`}
                      className="editar"
                      htmlFor="editar"
                    >
                      Abrir
                    </Link>
                  </td>
                </tr>
              ))}
          </tbody>
        ) : (
          <p>nenhum cliente encontrado</p>
        )}
      </table>
      {/* {perfilSelecionado === "" &&(
        <p>nenhum cliente encontrado</p>
      )} */}
    </TableContainer>
  );
};

export default Table;
