import NovaCategoriaItem from "../../../components/Cadastros/Novo/NovaCategoriaItem"
import Header from "../../../components/Menu"


const NovaCategoriaItemPage= () => {
  return (
    <div>
      <Header/>
      <NovaCategoriaItem/>
    </div>
  )
}

export default NovaCategoriaItemPage
