import { useForm } from "react-hook-form";
import {
  FormPropostaFornecedorPageStyle,
  FormStyle,
  TabelaStyle,
  TableContainer,
} from "./style";
import api from "../../../services/api";
import { toast } from "react-toastify";
import { useAuth } from "../../../providers/Auth";
import { useEffect, useState } from "react";
import { ConfirmacaoDeOrcamento } from "../../Modal/ConfirmacaoDeOrcamento";
import { useParams } from "react-router-dom";
import GerarPedidoComprasModal from "../../Modal/GerarPedidoComprasModal";

export const FormPropostaFornecedorVitrine = ({
  element,
  index,
  length,
  campanha,
  itens,
  ncm,
  orcamentos,
  fornecedor,
  dadosFornecedor,
  categoriaFornecedor,
  buscarFornecedor,
  categorias,
}) => {
  const { token, user } = useAuth();
  const { id } = useParams();
  const { register, handleSubmit, reset, getValues, setValue } = useForm();
  const [orcamento, setOrcamento] = useState([]);

  const dadosItem = itens.find((elem) => elem.id == element.item_id);

  const [abrirModal, setAbrirModal] = useState(false);
  const filterOrcamentos = orcamentos.filter(
    (elemen) =>
      elemen.campanha_material_briefing_id == element.id &&
      elemen.fornecedor_id == fornecedor.fornecedor_id
  );

  const [propostas, setPropostas] = useState([]);
  const [propostaMateriais, setPropostaMateriais] = useState([]);
  const [propostaMateriaisLoaded, setPropostaMateriaisLoaded] = useState(false);
  const [materialPedidoCompras, setMaterialPedidoCompras] = useState(false);
  const [material, setMaterial] = useState();
  const [fotosSelecionadas, setFotosSelecionadas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [arrayMaterialDistribuicoes, setArrayMaterialDistribuicoes] = useState(
    []
  );
  const [categoria, setCategoria] = useState([]);
  const [arquivoImpressao, setArquivoImpressao] = useState();

  useEffect(() => {
    if (propostaMateriaisLoaded) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [propostaMateriaisLoaded]);

  const colunas = [
    "Quantidade para Produção",
    "Valor unitário",
    "Valor total",
    "Status",
  ];

  const buscarPropostas = () => {
    api
      .get(`/propostas/${id}`)
      .then((response) => {
        setPropostas(response.data.data.propostas);
      })
      .catch((error) => {
        toast.error("Erro ao buscar proposta");
      });
  };

  const buscarMaterialVitrineValor = () => {
    api
      .get(
        `/material-vitrine-valores/valores/${element.material_vitrine_valor_id}`
      )
      .then((response) => {
        setOrcamento(response.data.data.dados);
        setCategoria(
          categorias.find(
            (elem) => elem.id === Number(response.data.data.dados.categoria)
          ).nome
        );
      })
      .catch((error) => {
        toast.error("Erro ao buscar proposta");
      });
  };

  const adicionarImagem = (idDistribuicao, value) => {
    const formData = new FormData();
    value.forEach((foto, index) => {
      formData.append(`fotos[${index}]`, foto);
    });

    api
      .post(
        `/proposta-material-distribuicao/${idDistribuicao}/nf-fornecedor`,
        formData
      )
      .then((response) => {
        toast.success("Imagem Adicionada");
        buscarArquivosPropostas();
        buscarPropostaMateriais();
      })
      .catch((error) => {
        toast.error("Erro ao buscar dados");
      });
  };

  const removerImagem = (imagem) => {
    api
      .patch(`/proposta-material-distribuicao/${imagem.id}/nf-fornecedor`, {
        dados: "",
      })
      .then((response) => {
        toast.success("Imagem Removida");
        buscarArquivosPropostas();
        buscarPropostaMateriais();
      })
      .catch((error) => {
        toast.error("Erro ao buscar dados");
      });
  };

  useEffect(() => {
    if (fotosSelecionadas.length > 0) {
      adicionarImagem();
    }
  }, [fotosSelecionadas]);

  const buscarPropostaMateriais = async () => {
    setPropostaMateriaisLoaded(false);
    try {
      let materiais = [];
      const response = await api.get(
        `/proposta-materiais/vitrine/${element.id}`
      );
      materiais = [...materiais, ...response.data.data.materiais];

      setPropostaMateriais(materiais);
      setPropostaMateriaisLoaded(true);
    } catch (error) {
      toast.error("Erro ao buscar materiais da proposta.");
    }
  };

  const buscarArquivosPropostas = async () => {
    setPropostaMateriaisLoaded(false);
    try {
      let materiais = [];

      const response = await api.get(
        `/proposta-materiais/vitrine/${element.id}`
      );

      if (response.data.data.materiais.length > 0) {
        const arquivos = await api.get(
          `/proposta-material-distribuicao/${response.data.data.materiais[0].id}`
        );
        setArquivoImpressao(response.data.data.materiais[0].arquivo_impressao);
        materiais = [...materiais, ...arquivos.data.data.distribuicoes];

        setArrayMaterialDistribuicoes(materiais);
      }
    } catch (error) {
      toast.error("Erro ao buscar materiais da proposta.");
    }
  };
  useEffect(() => {
    buscarPropostas();
    buscarArquivosPropostas();
    buscarMaterialVitrineValor();
  }, []);

  useEffect(() => {
    buscarPropostaMateriais();
  }, [propostas]);

  return (
    <FormPropostaFornecedorPageStyle>
      <div className="div-top">
        <div className="justify">
          <div className="flex">
            <h2>
              Material {index + 1}/{length}:
            </h2>
            <h3 className="nome">
              {element.material_vitrine} (Orçamento: {element.id})
            </h3>
          </div>
          <div className="flex">
            <h4 className="titulo-prazo">
              Prazo final de envio das propostas:{" "}
            </h4>
          </div>
        </div>
      </div>
      <div className="body">
        <div className="flex justify">
          <div className="div-especificacao">
            <h3 className="titulo-especificacao">Especificação:</h3>
            <h4 className="especificacao">{element.observacao}</h4>
            <div className="flex">
              <h4 className="font-size weight">
                Arte:{" "}
                {arquivoImpressao ? (
                  <a className="arquivo-impressao" href={arquivoImpressao}>
                    Arquivo de Impressão
                  </a>
                ) : (
                  "Esperando Cliente Enviar"
                )}
              </h4>
            </div>
          </div>
          <div className="right">
            <div>
              <h5>NCM</h5>
              <span>
                {/* {itens &&
                  ncm &&
                  ncm.filter(
                    (elem) =>
                      elem.id ==
                      itens.filter((elemen) => elemen.id == element.item_id)[0]
                        .ncm_id
                  )[0].codigo} */}
              </span>
            </div>
            <div>
              <h5>Tipo de produto</h5>
              <span>
                {/* {itens &&
                  itens.filter((elemen) => elemen.id == element.item_id)[0]
                    .nome} */}
              </span>
            </div>
          </div>
        </div>
        <FormStyle>
          <div className="flex">
            <div className="div-categoria">
              <h4>Categoria</h4>
              <input
                {...register(`categoria`)}
                value={dadosFornecedor && dadosFornecedor.categoria}
              />
            </div>
            <div className="div-estado">
              <h4>Estado de origem</h4>
              <input value={dadosFornecedor && dadosFornecedor.estado}></input>
            </div>
            <div className="div-icms">
              <h4>ICMS</h4>
              <input value={element && element.imposto}></input>
            </div>
            <div className="div-ipi">
              <h4>IPI</h4>
              <input value={orcamento && orcamento.ipi}></input>
            </div>{" "}
            <div className="div-icms-st">
              <h4>ICMS-ST/MVA</h4>
              <input value={orcamento && orcamento.icms_st}></input>
            </div>
          </div>
          <TabelaStyle className="tabela">
            <TableContainer>
              <table>
                <thead>
                  <tr>
                    {colunas.map((elem) => {
                      if (
                        elem == "Não Participar" &&
                        filterOrcamentos.length > 0
                      ) {
                        return <></>;
                      }
                      return <th>{elem}</th>;
                    })}
                    {filterOrcamentos.length > 0 && <th>Status</th>}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{element.quantidade} unidades</td>
                    <td>{element.preco_unitario}</td>
                    <td>
                      R$
                      {(
                        Number(element.quantidade) *
                        Number(element.preco_unitario)
                      ).toFixed(2)}
                    </td>
                    <td>
                      {element.status === "pendente" ||
                      element.status === "proposta" ? (
                        "Aguardando Análise"
                      ) : element.status === "em produção" ? (
                        "Em Produção"
                      ) : element.status === "proposta aprovada" ? (
                        "Aguardando Análise"
                      ) : element.status === "concluido" ? (
                        "concluido"
                      ) : (
                        <></>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </TableContainer>
          </TabelaStyle>

          <div className="div-nf">
            <div className="flex">
              <h4 className="font-weight">
                Pedido de compras:{" "}
                <>
                  {propostaMateriais.map((elem) => (
                    <span
                      className="pdf"
                      onClick={() => {
                        setMaterial(elem);
                        setMaterialPedidoCompras(true);
                      }}
                    >
                      pedido-compras.pdf
                    </span>
                  ))}
                </>
              </h4>
              <div className=" flex div-nf-right">
                <h4 className="font-weight">
                  Prazo para envio da prova: <span></span>
                </h4>
                <h4 className="font-weight">
                  Tipo: <span> Prova digital</span>
                </h4>
              </div>
            </div>
            <div className="flex">
              <div className="div-nota">
                <h4 className="font-weight">Nota fiscal:</h4>
                <div className="input-container">
                  <div className="column">
                    {arrayMaterialDistribuicoes.length > 0 ? (
                      arrayMaterialDistribuicoes.map((elem) => {
                        if (elem.nf_fornecedor) {
                          return (
                            <div>
                              <span
                                className="close"
                                onClick={() => removerImagem(elem)}
                              >
                                X
                              </span>
                              <a href={elem.nf_fornecedor}>
                                {`Nota Fiscal de ${elem.quantidade} Unidades para ${elem.destino}`}
                              </a>
                            </div>
                          );
                        } else {
                          return (
                            <>
                              <h3>
                                {elem.quantidade} Unidades para {elem.destino}
                              </h3>
                              <div>
                                <input
                                  {...register("imagem")}
                                  type="file"
                                  id={`file${elem.id}`}
                                  hidden={true}
                                  accept="image/*"
                                  onChange={(e) => {
                                    adicionarImagem(elem.id, [
                                      ...e.target.files,
                                    ]);
                                  }}
                                />

                                <label htmlFor={`file${elem.id}`}>
                                  Selecionar arquivo
                                </label>
                                <label
                                  htmlFor={`file${elem.id}`}
                                  className="pesquisar"
                                >
                                  Pesquisar
                                </label>
                              </div>
                            </>
                          );
                        }
                      })
                    ) : (
                      <span>Esperando Distribuição</span>
                    )}
                  </div>
                </div>
              </div>
              <div className="column flex-end"></div>
            </div>
          </div>

          {abrirModal && (
            <ConfirmacaoDeOrcamento
              state={setAbrirModal}
              handleSubmit={handleSubmit}
              setAbrirModal={setAbrirModal}
            />
          )}
          {materialPedidoCompras && (
            <GerarPedidoComprasModal
              setModalOpened={setMaterialPedidoCompras}
              campanha={campanha}
              material={material}
              fornecedor={true}
            />
          )}
        </FormStyle>
      </div>
    </FormPropostaFornecedorPageStyle>
  );
};
