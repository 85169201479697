import styled from "styled-components";

export const Tr = styled.tr`
  > td {
    padding-left: 10px;
    height: 50px;
    border-bottom: ${({ $ultimo }) =>
      $ultimo
        ? "2px solid rgba(112, 112, 112, 0.51)"
        : "1px solid rgba(112, 112, 112, 0.51)"};

    > .loading {
      @keyframes animation-loading {
        0% {
          opacity: 0;
        }

        40% {
          opacity: 1;
        }

        80% {
          opacity: 0;
        }

        100% {
          opacity: 0;
        }
      }

      display: flex;
      justify-content: center;
      column-gap: 8px;

      > span {
        background-color: #292828;
        border-radius: 10px;
        display: inline-block;
        height: 10px;
        vertical-align: top;
        width: 10px;
      }

      > span:first-child {
        animation: animation-loading 1s ease-in-out 0ms infinite;
      }

      > span:nth-child(2) {
        animation: animation-loading 1s ease-in-out 160ms infinite;
      }

      > span:last-child {
        animation: animation-loading 1s ease-in-out 320ms infinite;
      }
    }

    > label {
      display: none;
    }

    > .quantidade {
      border-radius: 6px;
      height: 35px;
      min-width: 50px;
      text-align: center;
    }

    > .aguardando {
      text-align: right;
    }

    > .fornecedores {
      text-align: left;
    }

    > .aprovado {
      > p {
        font-size: 0.9em;
        line-height: 1.1;
        color: #40ad48;
      }

      > span {
        line-height: 1.1;
        font-size: 0.7em;
        color: #40ad48;
      }
    }
  }

  & > :last-child {
    text-align: center;
  }

  & > :last-child > img {
    margin-left: 5px;
  }

  p > b {
    font-weight: 500;
  }

  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    border: 1px solid #e9e9e9;
    border-radius: 15px;
    margin: 1rem 0;
    padding: 1rem;

    > td {
      display: flex;
      justify-content: space-between;
      align-items: center;

      > label {
        display: flex;
        color: #292828;
        font-weight: 600;
      }
    }

    > td:last-child {
      border-bottom: none;
    }
  }

  th {
    background-color: red;
    color: red;
    margin: 100px;
  }
`;
