import { ReactSVG } from "react-svg";
import { ItemMaterialBriefingStyle } from "./style";
import { useAuth } from "../../providers/Auth";
import statusAguardando from "../../assets/status-aguardando.svg";
import statusConcluido from "../../assets/status-concluido.svg";
import statusProducao from "../../assets/status-em-producao.svg";
import statusNaoParticipa from "../../assets/status-nao-participa.svg";
import statusReprovado from "../../assets/status-reprovado.svg";
import orcamentoEnviado from "../../assets/orçamento-enviado.svg";

export const ItemMaterialBriefing = (element, orcamentos, fornecedor) => {
  const { user } = useAuth();

  const filterOrcamentos = orcamentos.filter(
    (elemen) =>
      elemen.campanha_material_briefing_id == element.id &&
      elemen.fornecedor_id == fornecedor.fornecedor_id
  );

  const orcamentoA = filterOrcamentos.find(
    (elem) => elem.quantidade == element.quantidade_a
  );

  const orcamentoB = filterOrcamentos.find(
    (elem) => elem.quantidade == element.quantidade_b
  );

  const orcamentoC = filterOrcamentos.find(
    (elem) => elem.quantidade == element.quantidade_c
  );

  const getDataUTC = (utc) => {
    const dataAtual = new Date();
    const dataNaoFormatada = new Date(dataAtual.valueOf() - utc * 60 * 60000);

    const { dia, mes, anoCompleto, hora, minuto } = {
      dia: dataNaoFormatada.getUTCDate().toString().padStart(2, "0"),
      mes: (dataNaoFormatada.getUTCMonth() + 1).toString().padStart(2, "0"),
      anoCompleto: dataNaoFormatada.getFullYear(),
      hora: dataNaoFormatada.getUTCHours().toString().padStart(2, "0"),
      minuto: dataNaoFormatada.getUTCMinutes().toString().padStart(2, "0"),
    };

    const dataFormatada = [dia, mes, anoCompleto].join("/");
    const horaFormatada = [hora, minuto].join(":");
    const dataCompleta = dataFormatada + "|" + horaFormatada;
    return dataCompleta;
  };

  function converterFormatoData(dataOriginal) {
    const separarDataEHora = dataOriginal.split("|");
    const dataSplit = separarDataEHora[0];
    const horaSplit = separarDataEHora[1];
    const partesData = dataSplit.split("/");
    const dataObj = new Date(
      `${partesData[2]}/${partesData[1]}/${partesData[0]} ${horaSplit}`
    );

    return dataObj;
  }

  const dataAtual = converterFormatoData(getDataUTC(3));

  return (
    <ItemMaterialBriefingStyle>
      {element.material_vitrine ? (
        <>
          <div className="justify">
            <div className="flex margin-bottom">
              <h2 className="h2">Item: </h2>
              <h3>{element.material_vitrine}</h3>
            </div>

            <h2 className="h2">
              Origem: <span>Vitrine</span>
            </h2>
          </div>
          <div className="column">
            <div className="div-unidades">
              <h4>{element.quantidade} unid.</h4>
              <div className="flex">
                {element.status === "pendente" ||
                element.status === "proposta" ? (
                  <span className="div-esperando status tipo">
                    Aguardando Análise
                  </span>
                ) : element.status === "em produção" ? (
                  <span className="div-aprovado status tipo">Em Produção</span>
                ) : element.status === "proposta aprovada" ? (
                  <span className="div-esperando status tipo">
                    Aguardando Análise
                  </span>
                ) : element.status === "concluido" ? (
                  <span className="concluido status tipo">Concluido</span>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="justify">
            <div className="flex margin-bottom">
              <h2 className="h2">Item: </h2>
              <h3>
                {element.nome} {element.subnome ? element.subnome : ""}
              </h3>
            </div>
            <h2 className="h2">
              Origem: <span>Briefing</span>
            </h2>
          </div>
          <div className="column">
            {element.quantidades.map((dadosQuantidade) => {
              const orcamento = filterOrcamentos.find(
                (dadosOrcamento) =>
                  dadosOrcamento.quantidade == dadosQuantidade.quantidade
              );

              return (
                <>
                  <div className="div-unidades">
                    <h4>{dadosQuantidade.quantidade} unid.</h4>
                    <div className="flex">
                      <ReactSVG />
                      {filterOrcamentos.length > 0 &&
                      filterOrcamentos.filter(
                        (dadosOrcamentos) =>
                          dadosOrcamentos.quantidade ==
                          dadosQuantidade.quantidade
                      ).length > 0 ? (
                        <span className="tipo">
                          {orcamento.status == "pendente" ||
                          orcamento.status === "Revisão Compras" ? (
                            <div className="div-orcamento status">
                              <ReactSVG
                                src={orcamentoEnviado}
                                className="status-producao"
                              />
                              <span className="tipo">Orçamento Enviado</span>
                            </div>
                          ) : orcamento.status == "recusado" ? (
                            <div className="div-recusado status">
                              <ReactSVG
                                src={statusReprovado}
                                className="status-producao"
                              />
                              <span className="tipo ">Reprovado</span>
                            </div>
                          ) : orcamento.status == "Não Participa" ? (
                            <div className="div-prazo-expirado status">
                              <ReactSVG
                                src={statusNaoParticipa}
                                className="status-producao"
                              />
                              <span className="tipo prazo-expirado">
                                Não Participando
                              </span>
                            </div>
                          ) : orcamento.status === "aprovado" ||
                            orcamento.status === "proposta aprovada" ? (
                            <div className="div-esperando status">
                              <ReactSVG
                                className="status-producao"
                                src={statusProducao}
                              />
                              <span className="tipo ">Esperando Retorno</span>
                            </div>
                          ) : orcamento.status === "em produção" ? (
                            <div className="div-aprovado status">
                              <ReactSVG
                                className="status-producao"
                                src={statusProducao}
                              />
                              <span className="tipo ">Em Produção</span>
                            </div>
                          ) : orcamento.status === "concluido" ? (
                            <div className="div-aprovado status concluido">
                              <span className="tipo ">Concluído</span>
                            </div>
                          ) : element.prazo_propostas < dataAtual ? (
                            <div className="div-prazo-expirado status">
                              <ReactSVG
                                src={statusNaoParticipa}
                                className="status-producao"
                              />
                              <span className="tipo prazo-expirado">
                                Prazo Expirado
                              </span>
                            </div>
                          ) : (
                            <div className="div-analise status">
                              <ReactSVG src={statusAguardando} />
                              <span className="tipo">Aguardando Orçamento</span>
                            </div>
                          )}
                        </span>
                      ) : element.prazo_propostas < dataAtual ? (
                        <div className="div-prazo-expirado status">
                          <ReactSVG
                            src={statusNaoParticipa}
                            className="status-producao"
                          />
                          <span className="tipo prazo-expirado">
                            Prazo Expirado
                          </span>
                        </div>
                      ) : (
                        <div className="div-analise status">
                          <ReactSVG src={statusAguardando} />
                          <span className="tipo">Aguardando Orçamento</span>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </>
      )}
    </ItemMaterialBriefingStyle>
  );
};
