import { CardMaterialViaBriefingStyle } from "./style";
import Editar from "../../../assets/editar-material.svg";
import Excluir from "../../../assets/excluir-material.svg";
import Engrenagem from "../../../assets/fornecedor.svg";
import Concorrencia from "../../../assets/concorrencia.svg";
import Cadastro from "../../../assets/nota.svg";
import Lupa from "../../../assets/lupa.svg";
import { ReactSVG } from "react-svg";
import { useNavigate } from "react-router-dom";
import { DetalhesDeCampanhaMaterialBriefing } from "../../Modal/DetalhesDeCampanhaMaterialBriefing";
import React, { useState } from "react";
import { PedirRevisaoModal } from "../../Modal/PedirRevisaoModal";
import AguardandoOrcamento from "../../../assets/status-aguardando.svg";
import { ModalAdicionarProdutor } from "../../Modal/AddProdutor";
import { useAuth } from "../../../providers/Auth";
import { AddBriefingVitrine } from "../../Modal/AddBriefingVitrine";
import Vitrine from "../../../assets/vitrine.svg";
import Aprovacao from "../../../assets/aprovacao.svg";

export const CardMaterialViaBriefing = ({
  element,
  state,
  setAbrirModalDeletarMaterialBriefing,
  categorias,
  perfil,
  ncm,
  itens,
  campanha,
  buscarMaterialBriefing,
}) => {
  const [abrirModalDetalhes, setAbrirModalDetalhes] = useState(false);
  const [abrirModalPedirRevisao, setAbrirModalPedirRevisao] = useState(false);
  const [abrirModalAdicionarProdutores, setAbrirModalAdicionarProdutores] =
    useState(false);
  const [abrirModalAdicionarVitrine, setAbrirModalAdicionarVitrine] =
    useState(false);
  const navigate = useNavigate();
  const { user } = useAuth();

  const item = itens ? itens.find((eleme) => eleme.id == element.item_id) : "";
  const ncmItem = ncm ? ncm.find((eleme) => eleme.id == item.ncm_id) : "";
  return (
    <CardMaterialViaBriefingStyle id={element.id}>
      <td className="id">{element.id}</td>
      <td className="categoria">
        {categorias.length > 0 &&
          categorias.filter((elem) => elem.id == Number(element.categoria))
            .length > 0 &&
          categorias.filter((elem) => elem.id == element.categoria)[0].nome}
      </td>
      <td className="material">
        {element.nome} {element.subnome}
      </td>
      <td className="quantidade">
        <div className="quatro">
          {element.quantidades.map((quantidade, index) => {
            return index != 0 ? " | " + quantidade : quantidade;
          })}
        </div>
      </td>
      <td className="acoes cursor">
        {perfil.nome === "compras" ||
        perfil.nome === "produtor" ||
        perfil.nome === "atendimento" ||
        perfil.nome === "administrador" ? (
          <div className="flex center">
            {/* AQUI */}
            <div className="selecao margin25">
              <ReactSVG
                className={`${
                  user.permissoes.includes("admin") ||
                  user.permissoes.includes("atendimento")
                    ? "botao-adicionar-produtor"
                    : "botao-adicionar-produtor botao-disabled"
                } `}
                onClick={() => {
                  if (
                    user.permissoes.includes("admin") ||
                    user.permissoes.includes("atendimento")
                  ) {
                    setAbrirModalAdicionarProdutores(true);
                  }
                }}
                src={Engrenagem}
              />
              <span
                onClick={() => {
                  if (
                    user.permissoes.includes("admin") ||
                    user.permissoes.includes("atendimento")
                  ) {
                    setAbrirModalAdicionarProdutores(true);
                  }
                }}
                className="esconder produtor-position"
              >
                Produtor
              </span>
            </div>
            <div className="selecao">
              <ReactSVG
                src={Aprovacao}
                className={`aprovacaoBriefing ${
                  element.produtor_nome === "Pendente" ? "botao-disabled" : ""
                }`}
                onClick={() => {
                  if (element.produtor_nome === "Pendente") {
                    return;
                  }
                  navigate(
                    "/campanha/detalhes/" +
                      element.id +
                      "/editar/" +
                      categorias.find((elem) => elem.id == element.categoria)
                        .nome
                  );
                }}
              />
              <span
                className="esconder aprovar"
                onClick={() => {
                  if (element.produtor_nome === "Pendente") {
                    return;
                  }
                  navigate(
                    "/campanha/detalhes/" +
                      element.id +
                      "/editar/" +
                      categorias.find((elem) => elem.id == element.categoria)
                        .nome
                  );
                }}
              >
                Aprovação
              </span>
            </div>
            <div className="flex selecao">
              <div
                href={`/disputa/${element.id}`}
                className={`botao-concorrencia ${
                  element.aprovacao_produtor == 1 &&
                  element.produtor_nome != "Pendente"
                    ? ""
                    : "botao-disabled"
                }`}
                onClick={() => {
                  if (
                    element.aprovacao_produtor === 1 &&
                    element.produtor_nome !== "Pendente"
                  ) {
                    navigate(`/disputa/${element.id}`);
                  }
                }}
              >
                <ReactSVG src={Concorrencia} />
              </div>
              <span
                onClick={() => {
                  if (
                    element.aprovacao_produtor === 1 &&
                    element.produtor_nome !== "Pendente"
                  ) {
                    navigate(`/disputa/${element.id}`);
                  }
                }}
                className={`esconder concorrencia-position`}
              >
                Concorrência
              </span>
            </div>
          </div>
        ) : (
          <></>
        )}
      </td>
      <td className="acoes">
        <div className="flex center">
          <div className="selecao flex">
            <ReactSVG
              src={Lupa}
              className="lupa"
              onClick={() => {
                setAbrirModalDetalhes(true);
              }}
            />
            <span
              onClick={() => {
                setAbrirModalDetalhes(true);
              }}
              className="esconder especificacoes-position"
            >
              Especificações
            </span>
          </div>
          <div className="selecao">
            <ReactSVG
              onClick={() => setAbrirModalPedirRevisao(true)}
              className="revisao"
              src={Cadastro}
            />
            <span
              onClick={() => setAbrirModalPedirRevisao(true)}
              className="esconder revisao-position"
            >
              Revisão
            </span>
          </div>
          <div className="selecao">
            <ReactSVG
              onClick={() => {
                if (element.produtor_nome === "Pendente") {
                  return;
                }
                navigate(
                  "/campanha/detalhes/" +
                    element.id +
                    "/editar/" +
                    categorias.find((elem) => elem.id == element.categoria).nome
                );
              }}
              hidden={
                perfil.nome === "produtor" || perfil.nome === "compras"
                  ? true
                  : false
              }
              src={Editar}
              className="editar"
            />
            <span
              onClick={() => {
                if (element.produtor_nome === "Pendente") {
                  return;
                }
                navigate(
                  "/campanha/detalhes/" +
                    element.id +
                    "/editar/" +
                    categorias.find((elem) => elem.id == element.categoria).nome
                );
              }}
              className="esconder editar-position"
            >
              Editar
            </span>
          </div>
          <div className="selecao">
            {perfil.nome == "administrador" ? (
              <>
                <ReactSVG
                  className="vitrine"
                  src={Vitrine}
                  onClick={() => setAbrirModalAdicionarVitrine(true)}
                />
                <span
                  onClick={() => {
                    setAbrirModalDeletarMaterialBriefing(true);
                    state(element);
                  }}
                  className="esconder excluir-position"
                >
                  Vitrine
                </span>
              </>
            ) : (
              <></>
            )}
          </div>
          <div className="selecao">
            <ReactSVG
              src={Excluir}
              className="excluir"
              onClick={() => {
                setAbrirModalDeletarMaterialBriefing(true);
                state(element);
              }}
            />
            <span
              onClick={() => {
                setAbrirModalDeletarMaterialBriefing(true);
                state(element);
              }}
              className="esconder excluir-position"
            >
              Excluir
            </span>
          </div>
        </div>
      </td>
      <td>
        {element.status === "enviado pra orçamento" ||
        element.status === "pendente" ? (
          <div>
            <div className="aguardando-orcamento flex center">
              <ReactSVG className="margin" src={AguardandoOrcamento} />
              <h6>Aguardando Revisão</h6>
            </div>
            <h5 className="produtor">
              Produtor:{" "}
              <span className={element.produtor_nome}>
                {element.produtor_nome}
              </span>
            </h5>
          </div>
        ) : element.status === "enviado para os fornecedores" ? (
          <div>
            <div className="aguardando-disputa flex center">
              <ReactSVG className="margin" src={AguardandoOrcamento} />
              <h6>Aguardando Análise</h6>
            </div>
            <h5 className="produtor">
              Produtor:{" "}
              <span className={element.produtor_nome}>
                {element.produtor_nome}
              </span>
            </h5>
          </div>
        ) : element.status === "em produção" ? (
          <div className="column">
            <div className="producao flex center">
              <h6>Em Produção</h6>
            </div>
            <h5 className="produtor">
              Produtor:{" "}
              <span className={element.produtor_nome}>
                {element.produtor_nome}
              </span>
            </h5>
          </div>
        ) : element.status === "concluido" ? (
          <div className="column">
            <div className="concluido flex center">
              <h6>Concluído</h6>
            </div>
            <h5 className="produtor">
              Produtor:{" "}
              <span className={element.produtor_nome}>
                {element.produtor_nome}
              </span>
            </h5>
          </div>
        ) : element.status === "aguardando análise" ? (
          <div className="column">
            <div className="aguardando-disputa flex center">
              <ReactSVG className="margin" src={AguardandoOrcamento} />
              <h6>Aguardando Análise</h6>
            </div>
            <h5 className="produtor">
              Produtor:{" "}
              <span className={element.produtor_nome}>
                {element.produtor_nome}
              </span>
            </h5>
          </div>
        ) : (
          <></>
        )}
      </td>
      {abrirModalDetalhes && (
        <DetalhesDeCampanhaMaterialBriefing
          dados={element}
          state={setAbrirModalDetalhes}
          ncm={ncmItem}
          item={item}
          categoria={
            categorias.filter((elem) => elem.id == element.categoria)[0].nome
          }
        />
      )}
      {abrirModalPedirRevisao && (
        <PedirRevisaoModal
          state={setAbrirModalPedirRevisao}
          campanha={campanha}
          item={item}
          perfil={perfil.nome}
          briefing={element}
        />
      )}
      {abrirModalAdicionarProdutores && (
        <ModalAdicionarProdutor
          campanha={campanha}
          briefing={element}
          state={setAbrirModalAdicionarProdutores}
          buscarMaterialBriefing={buscarMaterialBriefing}
        />
      )}
      {abrirModalAdicionarVitrine && (
        <AddBriefingVitrine
          state={setAbrirModalAdicionarVitrine}
          id={element.id}
        />
      )}
    </CardMaterialViaBriefingStyle>
  );
};

{
  /* 
        <td className="acoes">
        {perfil.nome === "cliente" ? (
          element.status === "enviado pra orçamento" ||
          element.status === "pendente" ? (
            <div className="flex center">
              <div className="selecao">
                <div className="selecao flex">
                  <ReactSVG
                    src={Lupa}
                    className="lupa"
                    onClick={() => {
                      setAbrirModalDetalhes(true);
                    }}
                  />
                  <span className="esconder especificacoes-position">
                    Especificações
                  </span>
                </div>
                <ReactSVG
                  hidden={
                    perfil.nome === "produtor" || perfil.nome === "compras"
                      ? true
                      : false
                  }
                  src={Editar}
                  className="editar"
                  onClick={() => {
                    navigate(
                      "/campanha/detalhes/" +
                        element.id +
                        "/editar/" +
                        categorias.find((elem) => elem.id == element.categoria)
                          .nome
                    );
                  }}
                />
                <span className="esconder">Editar</span>
              </div>
              <ReactSVG
                hidden={
                  perfil.nome === "produtor" || perfil.nome === "compras"
                    ? true
                    : false
                }
                src={Excluir}
                className="excluir"
                onClick={() => {
                  setAbrirModalDeletarMaterialBriefing(true);
                  state(element);
                }}
              />
              {perfil.nome == "administrador" ? (
                <ReactSVG
                  src={Vitrine}
                  onClick={() => setAbrirModalAdicionarVitrine(true)}
                />
              ) : (
                <></>
              )}
            </div>
          ) : (
            <div className="flex center">
              <div className="selecao">
                <ReactSVG
                  hidden={
                    perfil.nome === "produtor" || perfil.nome === "compras"
                      ? true
                      : false
                  }
                  src={Editar}
                  className="editar"
                />
                <span className="esconder">Editar</span>
              </div>

              <ReactSVG
                hidden={
                  perfil.nome === "produtor" || perfil.nome === "compras"
                    ? true
                    : false
                }
                src={Excluir}
                className="excluir"
              />
              {perfil.nome == "administrador" ? (
                <ReactSVG
                  src={Vitrine}
                  onClick={() => setAbrirModalAdicionarVitrine(true)}
                />
              ) : (
                <></>
              )}
              <div className="selecao flex">
                <ReactSVG
                  src={Lupa}
                  className="lupa"
                  onClick={() => {
                    setAbrirModalDetalhes(true);
                  }}
                />
                <span className="esconder especificacoes-position">
                  Especificações
                </span>
              </div>
            </div>
          )
        ) : (element.aprovacao_produtor &&
            element.status === "enviado pra orçamento") ||
          element.status === "pendente" ? (
          <div className="flex center">
            <div className="selecao">
              <ReactSVG
                onClick={() => setAbrirModalPedirRevisao(true)}
                className="revisao"
                src={Cadastro}
              />
              <span
                onClick={() => setAbrirModalPedirRevisao(true)}
                className="esconder revisao-position"
              >
                Revisão
              </span>
            </div>
            <div className="selecao">
              <ReactSVG
                hidden={
                  perfil.nome === "produtor" || perfil.nome === "compras"
                    ? true
                    : false
                }
                src={Editar}
                className="editar"
                onClick={() => {
                  navigate(
                    "/campanha/detalhes/" +
                      element.id +
                      "/editar/" +
                      categorias.find((elem) => elem.id == element.categoria)
                        .nome
                  );
                }}
              />
              <span
                onClick={() => {
                  navigate(
                    "/campanha/detalhes/" +
                      element.id +
                      "/editar/" +
                      categorias.find((elem) => elem.id == element.categoria)
                        .nome
                  );
                }}
                className="esconder editar-position"
              >
                Editar
              </span>
            </div>
            <div className="selecao">
              <ReactSVG
                hidden={
                  perfil.nome === "produtor" || perfil.nome === "compras"
                    ? true
                    : false
                }
                src={Excluir}
                className="excluir"
                onClick={() => {
                  setAbrirModalDeletarMaterialBriefing(true);
                  state(element);
                }}
              />
              <span
                onClick={() => {
                  setAbrirModalDeletarMaterialBriefing(true);
                  state(element);
                }}
                className="esconder excluir-position"
              >
                excluir
              </span>
            </div>
            <div className="selecao">
              {perfil.nome == "administrador" ? (
                <>
                  <ReactSVG
                    className="vitrine"
                    src={Vitrine}
                    onClick={() => setAbrirModalAdicionarVitrine(true)}
                  />
                  <span
                    onClick={() => {
                      setAbrirModalDeletarMaterialBriefing(true);
                      state(element);
                    }}
                    className="esconder excluir-position"
                  >
                    Vitrine
                  </span>
                </>
              ) : (
                <></>
              )}
            </div>
            <div className="selecao flex">
              <ReactSVG
                src={Lupa}
                className="lupa"
                onClick={() => {
                  navigate(
                    "/campanha/detalhes/" +
                      element.id +
                      "/editar/" +
                      categorias.find((elem) => elem.id == element.categoria)
                        .nome
                  );
                }}
              />
              <span className="esconder especificacoes-position">
                Especificações
              </span>
            </div>
          </div>
        ) : element.aprovacao_produtor ? (
          <>
            <div className="flex center">
              <div className="selecao flex">
                <ReactSVG
                  src={Lupa}
                  className="lupa"
                  onClick={() => {
                    setAbrirModalDetalhes(true);
                  }}
                />
                <span className="esconder especificacoes-position">
                  Especificações
                </span>
              </div>
              <div className="selecao">
                <ReactSVG
                  onClick={() => setAbrirModalPedirRevisao(true)}
                  className="revisao"
                  src={Cadastro}
                />
                <span
                  onClick={() => setAbrirModalPedirRevisao(true)}
                  className="esconder revisao-position"
                >
                  Revisão
                </span>
              </div>
              <div className="flex selecao">
                <ReactSVG
                  hidden={
                    perfil.nome === "produtor" || perfil.nome === "compras"
                      ? true
                      : false
                  }
                  src={Editar}
                  className="editar"
                  onClick={() => {
                    navigate(
                      "/campanha/detalhes/" +
                        element.id +
                        "/editar/" +
                        categorias.find((elem) => elem.id == element.categoria)
                          .nome
                    );
                  }}
                />
                <span
                  onClick={() => {
                    navigate(
                      "/campanha/detalhes/" +
                        element.id +
                        "/editar/" +
                        categorias.find((elem) => elem.id == element.categoria)
                          .nome
                    );
                  }}
                  className="esconder editar-position"
                >
                  Editar
                </span>
              </div>
              <div className="selecao">
                {perfil.nome == "administrador" ? (
                  <>
                    <ReactSVG
                      className="vitrine"
                      src={Vitrine}
                      onClick={() => setAbrirModalAdicionarVitrine(true)}
                    />
                    <span
                      onClick={() => {
                        setAbrirModalDeletarMaterialBriefing(true);
                        state(element);
                      }}
                      className="esconder excluir-position"
                    >
                      Vitrine
                    </span>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </>
        ) : (
          <div
            hidden={
              perfil.nome === "produtor" || perfil.nome === "compras"
                ? true
                : false
            }
            className="flex center"
          >
            <div className="selecao flex">
              <ReactSVG
                src={Lupa}
                className="lupa"
                onClick={() => {
                  navigate(
                    "/campanha/detalhes/" +
                      element.id +
                      "/editar/" +
                      categorias.find((elem) => elem.id == element.categoria)
                        .nome
                  );
                }}
              />
              <span className="esconder especificacoes-position">
                Especificações
              </span>
            </div>
            <div className="selecao flex">
              <ReactSVG
                hidden={
                  perfil.nome === "produtor" || perfil.nome === "compras"
                    ? true
                    : false
                }
                src={Editar}
                className="editar"
                onClick={() => {
                  navigate(
                    "/campanha/detalhes/" +
                      element.id +
                      "/editar/" +
                      categorias.find((elem) => elem.id == element.categoria)
                        .nome
                  );
                }}
              />
              <span className="esconder editar-position">Editar</span>
            </div>

            {user.permissoes.includes("admin") ||
            user.permissoes.includes("atendimento") ? (
              <div className="selecao">
                <ReactSVG
                  className="botao-adicionar-produtor margin-right"
                  onClick={() => setAbrirModalAdicionarProdutores(true)}
                  src={Engrenagem}
                />
                <span
                  onClick={() => setAbrirModalAdicionarProdutores(true)}
                  className="esconder produtor-position"
                >
                  Produtor
                </span>
              </div>
            ) : (
              <></>
            )}
            <div className="selecao">
              <ReactSVG
                onClick={() => setAbrirModalPedirRevisao(true)}
                className="revisao"
                src={Cadastro}
              />
              <span
                onClick={() => setAbrirModalPedirRevisao(true)}
                className="esconder revisao-position"
              >
                Revisão
              </span>
            </div>
            <div className="selecao">
              <ReactSVG
                hidden={
                  perfil.nome === "produtor" || perfil.nome === "compras"
                    ? true
                    : false
                }
                src={Excluir}
                className="excluir"
                onClick={() => {
                  setAbrirModalDeletarMaterialBriefing(true);
                  state(element);
                }}
              />
              <span
                onClick={() => {
                  setAbrirModalDeletarMaterialBriefing(true);
                  state(element);
                }}
                className="esconder excluir-position"
              >
                excluir
              </span>
            </div>
            <div className="selecao">
              {perfil.nome == "administrador" ? (
                <>
                  <ReactSVG
                    className="vitrine"
                    src={Vitrine}
                    onClick={() => setAbrirModalAdicionarVitrine(true)}
                  />
                  <span
                    onClick={() => {
                      setAbrirModalDeletarMaterialBriefing(true);
                      state(element);
                    }}
                    className="esconder excluir-position"
                  >
                    Vitrine
                  </span>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        )}
      </td>
  */
}
