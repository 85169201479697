import { CardVitrineStyle } from "./style";
import { useNavigate } from "react-router-dom";
import { moneyMask } from "../../utils/masks";

export const CardVitrine = ({ material }) => {
  const navigate = useNavigate();

  return (
    <CardVitrineStyle
      onClick={() => {
        navigate(`/vitrine/${material.id}`);
      }}
    >
      {material.vizualizar_vitrine == 0 && (
        <div className="pendente">
          <span className="span-pendente">pendente</span>
        </div>
      )}
      <figure>
        <img src={material.imagem_principal} />
      </figure>
      <h3>{material.nome}</h3>
      <h4>
        a partir de{" "}
        <b>
          R$ {moneyMask(String(Number(material.preco_unitario).toFixed(2)))}
        </b>
      </h4>
    </CardVitrineStyle>
  );
};
