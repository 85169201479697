import { Link, useNavigate } from "react-router-dom";
import { Body, Inputs, NovaPermissaoContainer } from "./styles";
import { toast } from "react-toastify";
import api from "../../../../services/api";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { CampoObrigatorio } from "../../../../schemas/novoCadastro";
import { ReactSVG } from "react-svg";
import voltar from "../../../../assets/voltar.svg";

const NovaPermissao = () => {
  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm({
    resolver: yupResolver(CampoObrigatorio),
  });

  const navigate = useNavigate();

  const onSubmitFunction = (data) => {
    const permissoes = [data];
    api
      .post("/permissoes", { permissoes })
      .then((response) => {
        navigate("/permissoes");
        toast.success("Permissão cadastrada com sucesso!");
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  return (
    <NovaPermissaoContainer onSubmit={handleSubmit(onSubmitFunction)}>
      <div className="box">
        <div className="flex">
          <ReactSVG
            onClick={() => navigate("/permissoes")}
            className="voltar"
            src={voltar}
          />
          <div>
            <h1>Permissão</h1>
            <h2>Nova permissão</h2>
          </div>
        </div>
        <Body>
          <div className="checkbox">
            <input
              type="checkbox"
              id="checkbox1"
              name="status"
              {...register("status")}
              checked={true}
            />

            <label htmlFor="checkbox1">Ativo</label>
          </div>
          <Inputs>
            <div>
              <label>Nome da permissao</label>
              <input
                type="text"
                name="nome"
                {...register("nome")}
                placeholder="Nome da permissão"
              />
              {errors.nome && (
                <span className="input-error-cadastro">
                  {errors.nome.message}
                </span>
              )}
            </div>
          </Inputs>
        </Body>
        <div className="buttons">
          <button type="submit">Salvar</button>
        </div>
      </div>
    </NovaPermissaoContainer>
  );
};

export default NovaPermissao;
