import CategoriaFornecedor from "../../components/CategoriaFornecedor";
import Header from "../../components/Menu";


const CategoriaFornecedorPage = () => {
  return (
    <div>
       <Header/>
      <CategoriaFornecedor />
    </div>
  );
};

export default CategoriaFornecedorPage;