import { ReactSVG } from "react-svg";
import { MateriaisViaBriefingStyle } from "./style";
import { useEffect, useState } from "react";
import Lampada from "../../assets/materiais-via-briefing.svg";
import { CardMaterialViaBriefing } from "./CardMaterialViaBriefing";
import { ConfirmacaoDeOrcamento } from "../Modal/ConfirmacaoDeOrcamento";
import api from "../../services/api";
import { toast } from "react-toastify";

export const MateriaisViaBriefing = ({
  id,
  state,
  setAbrirModalDeletarMaterialBriefing,
  materialBriefing,
  categorias,
  perfil,
  campanha,
  item_id,
  buscarMaterialBriefing,
}) => {
  const [verMaisMaterialBriefing, setVerMaisMaterialBriefing] = useState(
    item_id ? true : false
  );
  const [modalConfirmarOrcamento, setModalConfirmarOrçamento] = useState(false);
  const [idMateriaisSelecionados, setIdMateriaisSelecionados] = useState([]);
  const [ncm, setNcm] = useState();
  const [itens, setItens] = useState();

  const filter = materialBriefing.filter(
    (element) => element.campanhas_id == Number(id) && element.status !== "0"
  );

  const verMaisBriefing = () => {
    if (verMaisMaterialBriefing === true) {
      setVerMaisMaterialBriefing(false);
    } else if (verMaisMaterialBriefing === false) {
      setVerMaisMaterialBriefing(true);
    }
  };

  const buscarNCM = () => {
    api
      .get(`/ncm`)
      .then((response) => {
        setNcm(response.data.data.ncms);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  };

  const verificarExistencia = (id) => {
    if (!idMateriaisSelecionados.includes(id)) {
      setIdMateriaisSelecionados([...idMateriaisSelecionados, id]);
    } else {
      const filter = idMateriaisSelecionados.filter(
        (element) => Number(element) !== Number(id)
      );
      setIdMateriaisSelecionados(filter);
    }
  };

  function buscarItens() {
    api
      .get(`/itens`)
      .then((response) => {
        setItens(response.data.data.itens);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  const colunas = [
    "ID",
    "Categoria",
    "Material",
    "Quantidade",
    `${perfil.nome !== "cliente" ? "Processos" : ""}`,
    "Ações",
    "Status",
  ];

  useEffect(() => {
    buscarNCM();
    buscarItens();
  }, []);

  return (
    <MateriaisViaBriefingStyle>
      <div className="header">
        <ReactSVG src={Lampada} />
        <h2>Material via Briefing</h2>
        <div>
          {verMaisMaterialBriefing === false && (
            <span onClick={() => verMaisBriefing()}>ver mais</span>
          )}
          {verMaisMaterialBriefing === true && (
            <span onClick={() => verMaisBriefing()}>ver menos</span>
          )}
        </div>
      </div>
      {filter.length > 0 &&
        verMaisMaterialBriefing === true &&
        materialBriefing && (
          <table>
            <thead>
              <tr>
                {colunas.map((coluna) => (
                  <th key={coluna}>{coluna}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {filter.map((element) => (
                <CardMaterialViaBriefing
                  element={element}
                  state={state}
                  setAbrirModalDeletarMaterialBriefing={
                    setAbrirModalDeletarMaterialBriefing
                  }
                  categorias={categorias}
                  perfil={perfil}
                  verificarExistencia={verificarExistencia}
                  ncm={ncm}
                  itens={itens}
                  campanha={campanha}
                  buscarMaterialBriefing={buscarMaterialBriefing}
                />
              ))}
            </tbody>
          </table>
        )}

      {filter.length === 0 && verMaisMaterialBriefing === true && (
        <h3 className="vazio">Nenhum material adicionado</h3>
      )}
      {modalConfirmarOrcamento && (
        <ConfirmacaoDeOrcamento state={setModalConfirmarOrçamento} />
      )}
    </MateriaisViaBriefingStyle>
  );
};
