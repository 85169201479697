import styled from "styled-components";

export const PedidoComprasCampanhaContainer = styled.div`
  background: #ffffff;
  width: 90%;
  margin: 40px auto;
  padding: 30px;
  border-radius: 20px;

  > h1 {
    display: flex;
    column-gap: 10px;
    color: #640000;
    font-weight: 600;
    font-size: 1em;

    > div {
      height: 20px;
      svg {
        height: 20px;
        path,
        polygon,
        rect,
        circle,
        ellipse {
          fill: #640000;
        }
      }
    }
  }

  h2 {
    display: flex;
    column-gap: 6px;
    color: #640000;
    font-weight: 600;
    font-size: 1em;

    div {
      height: 20px;
      svg {
        height: 20px;
        path,
        polygon,
        rect,
        circle,
        ellipse {
          fill: #640000;
        }
      }
    }
  }
`;
