import styled from "styled-components";

export const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 20px;
  width: 100%;
  height: 100vh;
  background-color: var(--base);
  padding: 65px;
  align-items: center;
  justify-content: center;
  border-radius: 0px 55px 55px 0px;

  .logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  >p{
    width: 333px;
    font-size: 0.9rem;
    font-weight: 500;
    text-align: center;
    color:var(--gray33);
    margin-top: 43px;
    }

    a{
       
        font-size: 0.8rem;
        font-weight: 600;
        
    }

`;

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 35px;
  max-width: 378px;
  margin-top: 10px;
  padding: 0 10px;
  border-bottom: 1px solid #707070;
  position: relative;

  >label {
    width: 20px;
  }

  >input {
    width: 100%;
    border: none;
    background: transparent;
    padding: 0 5px;
    font-size: 0.8rem;
    outline: none;
    

    ::placeholder {
      color: var(--gray0);
   
    }

    :focus {
      outline: none;
    }
  }

  > span {
    top: 30px;
    left: 20px;
  }

  > .show-password {
    svg {
      cursor: pointer;

      path {
        ${({ showPassword }) => (showPassword ? "fill: #fff" : "")};
      }
    }
  }
`;

export const LoginButton = styled.button`
  min-width: 200px;
  width: 80%;
  height: 38px;
  max-width: 305px;
  background: var(--principal);
  font-weight: 600;
  color: var(--base);
  border: none;
  border-radius: 20px;
  margin-top: 18px;

  @keyframes grow {
    from {
      transform: scale(1, 1);
    }
    to {
      transform: scale(1.3, 1.3);
    }
  }

  > .loading-container {
    margin: auto;
    width: min-content;
    display: flex;
    position: relative;
    .circle {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: var(--base);
      margin-right: 10px;

      &:first-child {
        animation: grow 700ms linear 100ms infinite;
      }

      &:nth-child(2) {
        animation: grow 700ms linear 200ms infinite;
      }

      &:nth-child(3) {
        animation: grow 700ms linear 300ms infinite;
      }

      &:last-child {
        animation: grow 700ms linear 400ms infinite;
      }
    }
  }
`;
export const VoltarButton = styled.button`
  min-width: 200px;
  width: 80%;
  height: 38px;
  max-width: 305px;
  color: var(--principal);
  font-weight: 600;
  background: var(--base);
  border-radius: 20px;
  border: 1px solid var(--principal);
`