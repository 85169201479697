import styled from "styled-components";

export const AprovarPropostaModalContainer = styled.div`
  display: flex;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  align-items: center;
  z-index: 2;
`;

export const ContentContainer = styled.div`
  background-color: var(--base01);
  min-width: 15%;
  border-radius: 18px;
  opacity: 1;
  padding: 30px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 10;
  width: 80%;
  max-width: 530px;

  > p {
    font-weight: 600;
    margin: 20px 0;
  }

  > .buttons-container {
    display: flex;
    justify-content: center;
    > button {
      border: none;
      border-radius: 20px;
      width: 100px;
      height: 35px;
      color: #fff;
      font-weight: 600;
    }

    :first-child {
      background: #fa5252;
    }

    :last-child {
      margin-top: 20px;
      background: var(--amarelo);
      color: var(--principal);
    }
  }

  input {
    padding: 16px 8px;
    border-radius: 12px;
    border: none;
  }

  h4 {
    font-size: 16px;
    font-weight: 600;
    margin-left: 5px;
  }

  h5 {
    font-size: 16px;
    font-weight: 600;
    color: #9f0010;
    margin-left: 3px;
  }

  .flex {
    margin-top: 10px;
  }
`;
