import styled from "styled-components";

export const ConfirmarAddBrieifingVitrineStyle = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;

  .div-body {
    width: 90%;
    max-width: 520px;
    padding: 50px;
    background-color: var(--base01);
    margin: 0 auto;
    border-radius: 8px;
    position: relative;
  }

  .close {
    position: absolute;
    top: -15px;
    right: -15px;
    color: var(--base01);
    background-color: #fa5252;
    padding: 11px 13px;
    font-size: 30px;
    font-weight: 300;
    border-radius: 30px;
  }

  .flex {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin-top: 30px;
  }

  button {
    width: 100px;
    height: 40px;
    border-radius: 16px;
    border: none;
    font-weight: 600;
    cursor: pointer;
  }

  .select {
    h3 {
      font-weight: 600;
      font-size: 13px;
      margin-top: 20px;
    }
  }

  .cancelar {
    background-color: var(--principal);
    color: var(--base);
  }

  .confirmar {
    background-color: var(--amarelo);
    color: var(--principal);
  }
`;
