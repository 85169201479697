import NovoItem from "../../../components/Cadastros/Novo/NovoItem";
import Header from "../../../components/Menu";

const NovoItemPage = () => {
  return (
    <div className="flex">
      <Header />
      <NovoItem />
    </div>
  );
};

export default NovoItemPage;
