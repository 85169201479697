import { Link } from "react-router-dom";
import { useAuth } from "../../../providers/Auth";
import { TableContainer } from "./styles";
import { ReactSVG } from "react-svg";
import ativo from "../../../assets/ativo.svg";
import inativo from "../../../assets/inativo.svg";
import { useEffect } from "react";

const Table = ({ perfis, buscarPerfis, perfilSelecionado, textoPesquisa }) => {
  useEffect(() => {
    buscarPerfis();
  }, []);

  const colunas = ["Id", "Nome", "Status"];
  const filtrarPermissao = (perfis) => {
    const perfilFiltrado =
      !perfilSelecionado || perfis.status === parseInt(perfilSelecionado);
    const pesquisaFiltrada =
      !textoPesquisa ||
      perfis.nome.toLowerCase().includes(textoPesquisa.toLowerCase());
    const idPerfis = parseInt(textoPesquisa);
    const idFiltrado = !isNaN(idPerfis) && perfis.id === idPerfis;
    return perfilFiltrado && (pesquisaFiltrada || idFiltrado);
  };
  const permissoesFiltradas = perfis.filter(filtrarPermissao);

  return (
    <TableContainer>
      <table>
        <thead>
          <tr>
            {colunas.map((coluna) => (
              <th key={coluna}>{coluna}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {permissoesFiltradas.map((lista) => (
            <tr className="map" key={lista.id}>
              <td>#{lista.id}</td>
              <td>{lista.nome}</td>

              {lista.status === 1 ? (
                <td>
                  <div className="ativo">
                    <ReactSVG src={ativo} />
                    <p>ativo</p>
                  </div>
                </td>
              ) : (
                <td>
                  <div className="ativo">
                    <ReactSVG src={inativo} />
                    <p>inativo</p>
                  </div>
                </td>
              )}
              <td className="td-editar">
                <Link
                  to={`/perfil/editar/${lista.id}`}
                  className="editar"
                  htmlFor="editar"
                >
                  Abrir
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </TableContainer>
  );
};

export default Table;
