import { ReactSVG } from "react-svg";
import Menu from "../../components/Menu";
import Som from "../../assets/campanha.svg";
import Filtro from "../../assets/fitro.svg";
import { Input } from "../../components/Input";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { FornecedorCampanhaPageStyle } from "./style";
import api from "../../services/api";
import { ListaCampanha } from "../../components/ListaCampanha";
import { useAuth } from "../../providers/Auth";
import Loading from "../../components/Loading";

export const FornecedorCampanhaPage = () => {
  const { register } = useForm();
  const { user } = useAuth();
  const [campanhas, setCampanhas] = useState(false);
  const [listaBriefingEVitrine, setListaBriefingEVitrine] = useState([]);
  const [orcamentos, setOrcamentos] = useState([]);
  const [fornecedor, setFornecedor] = useState();

  function buscarCampanhas() {
    api
      .get(`/campanhas`)
      .then((response) => {
        setCampanhas(response.data.data.campanhas);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  function buscarMaterialVitrine(fornecedor_id, dados) {
    api
      .get(`/campanha-material-vitrine/fornecedor/${fornecedor_id}`)
      .then((response) => {
        const arrayVitrine = response.data.data.materiais;
        setListaBriefingEVitrine([...dados, ...arrayVitrine]);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  function buscarOrcamentoBriefing(id) {
    api
      .get(`/orcamento-materiais-briefing/fornecedor/${id}`)
      .then((response) => {
        const data = response.data.data.orcamentos;
        setOrcamentos(data);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  function buscarFornecedor() {
    api
      .get(`/usuario-fornecedor/${user.id}`)
      .then((response) => {
        setFornecedor(response.data.data.usuario);
        buscarOrcamentoBriefing(response.data.data.usuario.fornecedor_id);
        buscarCampanhasFornecedor(response.data.data.usuario.fornecedor_id);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  function buscarCampanhasFornecedor(id) {
    api
      .get(`/campanha-material-briefing/fornecedores/fornecedor/${id}`)
      .then((response) => {
        buscarMaterialVitrine(id, response.data.data.fornecedores);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  useEffect(() => {
    buscarCampanhas();
  }, []);

  useEffect(() => {
    if (user.id !== undefined) {
      buscarFornecedor();
    }
  }, [user]);

  return (
    <div className="flex">
      <Menu />
      <FornecedorCampanhaPageStyle>
        <div className="flex">
          <ReactSVG src={Som} alt="" />
          <h1>Campanhas</h1>
        </div>
        <div className="header">
          <form>
            <Input
              type="text"
              placeholder="Pesquisar por ID, nome da campanha ou material"
              register={register("nome")}
              className="nome"
            />
            <div className="div-filter">
              <div className="flex">
                <ReactSVG src={Filtro} alt="" className="image-filter" />
                <span>Filtrar por status de Material</span>
              </div>
              <select {...register("marca")}>
                <option>Ver Todos</option>
              </select>
            </div>
          </form>
        </div>
        {campanhas ? (
          campanhas.map((element) =>
            ListaCampanha(
              element,
              listaBriefingEVitrine,
              orcamentos,
              fornecedor
            )
          )
        ) : (
          <Loading />
        )}
      </FornecedorCampanhaPageStyle>
    </div>
  );
};
