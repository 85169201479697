import EditarForncedor from "../../../components/Cadastros/Editar/EditarFornecedor";
import Header from "../../../components/Menu";

const EditarFornecedorPage = () => {
  return (
    <div className="flex">
      <Header />
      <EditarForncedor />
    </div>
  );
};

export default EditarFornecedorPage;
