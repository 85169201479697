import styled from "styled-components";

export const AdicionarMaterialCampanhaModalContainer = styled.div`
  display: flex;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  align-items: center;

  .flex {
    width: 100%;
    justify-content: space-evenly;
  }

  .span-button {
    width: 100px;
    font-size: 12px;
    font-weight: 600;
    color: var(--principal);
  }
`;

export const ContentContainer = styled.div`
  background-color: #f6f6fc;
  min-width: 15%;
  border-radius: 18px;
  opacity: 1;
  padding: 30px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 10;

  > .fechar {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 10px;
    border-radius: 100%;
    border: none;
    background-color: #fa5252;
    width: 50px;
    height: 50px;
    position: absolute;
    top: -25px;
    right: -25px;
  }

  > .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 20px;
    > p {
      font-weight: 600;
      font-size: 0.9em;
    }

    > .select-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 300px;

      > select {
        width: 100%;
        height: 42px;
        border-radius: 6px;
        border: none;
        padding: 0 10px;
      }

      > span {
        display: flex;
        column-gap: 2px;
        font-size: 0.9em;

        > a {
          color: #609ae5;
          font-size: 1em;
        }
      }
    }

    > div > .salvar-button {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #640000;
      font-weight: 600;
      font-size: 12px;
      background: #ffbe00;
      width: 160px;
      height: 42px;
      border: none;
      border-radius: 24px;

      > div {
        width: 20px;
        svg {
          height: 20px;
          path,
          polygon,
          rect,
          circle,
          ellipse {
            fill: #640000;
          }
        }
      }

      &:disabled {
        background: #cccccc;
        color: #666666;
        cursor: not-allowed;

        svg {
          path,
          polygon,
          rect,
          circle,
          ellipse {
            fill: #666666;
          }
        }
      }
    }
  }
`;
