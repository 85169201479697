import styled from "styled-components";

export const ModalCriarOrcamentoStyle = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;

  .body {
    width: 100% !important;
  }

  .flex {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }

  .flex_start {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 18px;
    margin-top: 12px;
  }
  .flex_center {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 18px;
    margin-top: 12px;
  }

  .background {
    position: relative;
    display: flex;
    flex-direction: column;
    text-align: center;
    border-radius: 30px;
    background-color: var(--base);
    padding: 25px 20px;
    width: 90%;
    height: 70%;
  }

  .close {
    position: absolute;
    top: -20px;
    right: -15px;
    padding: 15px 15px;
    background-color: #fa5252;
    border-radius: 300px;
    font-size: 32px;
    font-weight: 400;
    color: var(--base);
    text-decoration: none;
  }

  .overflow {
    overflow-y: auto;
    max-height: 70vh;
  }
`;
