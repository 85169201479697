import EditarItem from "../../../components/Cadastros/Editar/EditarItem";
import EditarNcm from "../../../components/Cadastros/Editar/EditarNcm";
import Header from "../../../components/Menu";

const EditarItemPage = () => {
  return (
    <div className="flex">
      <Header />
      <EditarItem />
    </div>
  );
};

export default EditarItemPage;
