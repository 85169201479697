import CadastroDeUsuario from "../../../components/CadastroDeUsuario";
import Header from "../../../components/Menu";

const CadastroDeUsuarioPage = () => {
  return (
    <div className="flex">
      <Header />
      <CadastroDeUsuario />
    </div>
  );
};

export default CadastroDeUsuarioPage;
