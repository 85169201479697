import styled from "styled-components";

export const ModalAdicionarProdutorStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;

  > div {
    position: relative;
    display: flex;
    flex-direction: column;
    text-align: center;
    border-radius: 30px;
    background-color: var(--base);
    padding: 40px 50px;
    width: 60%;
    height: 80%;
  }

  .close {
    position: absolute;
    top: -20px;
    right: -15px;
    padding: 15px 15px;
    background-color: #fa5252;
    border-radius: 300px;
    font-size: 32px;
    font-weight: 400;
    color: var(--base);
    text-decoration: none;
    cursor: pointer;
  }

  .adicionar {
    background-color: forestgreen;
    color: var(--base);
    padding: 6px 20px;
    border-radius: 8px;
    font-weight: 500;
    margin-top: 10px;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  select {
    border: none;
    border-radius: 8px;
    padding: 4px 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    margin-right: 10px;
  }

  .loading {
    max-width: 300px;
  }

  .coluna {
    display: flex;
    flex-direction: column;
    min-width: 350px;
  }

  .flex > .flex {
    overflow-y: auto;
  }

  .card {
    padding: 15px 0px;
    background: var(--base01);
    border-radius: 12px;
    margin-bottom: 20px;
    margin-right: 20px;
  }

  .table {
    height: 100%;
    overflow-y: auto;
  }

  .margin {
    display: flex;
    margin-top: 20px;
  }

  h2 {
    font-size: 20px;
    font-weight: 700;
  }

  h5 {
    color: white;
    font-weight: 600;
    width: 80%;
    margin: 0 auto;
    margin-top: 10px;
  }

  h4 {
    justify-content: center;
  }

  h4 > h4 {
    margin-right: 8px;
  }

  .aguardando-orcamento {
    background-color: #ef5713;
    padding: 5px 0px 2px;
    border-radius: 30px;
  }

  .aguardando-disputa {
    background-color: #ffbe00;
    padding: 5px 0px 2px;
    border-radius: 30px;
  }

  .producao {
    background-color: #609ae5;
    padding: 5px 0px 5px;
    border-radius: 30px;
    width: 80% !important;
  }

  .concluido {
    background-color: black;
    padding: 5px 0px 5px;
    border-radius: 30px;
  }

  .position {
    position: absolute;
    top: 39px;
    right: 80px;
  }

  .bold {
    font-weight: 600;
  }

  .red {
    color: red;
  }

  .flex {
    display: flex;
    justify-content: center;

    h4 {
      margin-right: 10px;
    }
  }
`;
