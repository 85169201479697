import styled from "styled-components";

export const PedidoComprasMaterialContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  row-gap: 10px;

  > .header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(112, 112, 112, 0.7);
    padding: 10px;

    > b {
      font-weight: 600;
      font-size: 0.9em;
    }

    > :last-child {
      width: 20%;
      text-align: center;
    }
  }

  > .body {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    padding: 0 10px;

    a {
      color: #609ae5;
      text-decoration: underline;
      font-size: 0.8em;
    }

    > .content-1 {
      display: flex;
      justify-content: space-between;
      > p {
        font-size: 0.9em;
        > a {
          font-size: 0.9em;
        }
      }

      > .status {
        width: 20%;

        > div {
          border-radius: 24px;
          font-size: 0.7em;
          padding: 6px 8px;
          text-align: center;
          line-height: 15px;
          font-weight: 500;
          color: #fff;
        }

        > .pendente {
          background: #ffbe00;
        }

        > .prova {
          background: #ef5713;
        }

        > .producao {
          background: #609ae5;
        }

        > .concluido {
          background: #000000;
        }
      }
    }

    > .content-2 {
      display: flex;
      justify-content: space-between;

      > div {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        > b {
          font-weight: 600;
          font-size: 0.9em;
        }

        > p {
          font-size: 0.9em;
        }
      }

      > .arquivo {
        > a {
          color: #609ae5;
          text-decoration: underline;
          font-size: 0.8em;
        }

        > .input-container {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 280px;
          height: 40px;
          background: rgba(165, 165, 165, 0.1);
          border-radius: 6px;
          cursor: pointer;

          > input {
            display: none;
          }

          > label {
            display: flex;
            align-items: center;
            color: #979696;
            padding-left: 15px;
            font-size: 0.9em;
            width: 100%;
            height: 100%;
            cursor: pointer;
          }

          > .pesquisar {
            padding: 10px;
            width: 35%;
            height: 100%;
            font-size: 0.9em;
            background: rgba(128, 131, 132, 0.39);
            border-radius: 6px;
            border: none;
            color: #707070;
            font-weight: 600;
          }
        }
      }

      > .pedido-compras {
        text-align: center;
        width: 20%;

        > .pdf {
          color: #609ae5;
          text-decoration: underline;
          font-size: 0.8em;
          background: none;
          border: none;
        }

        > .fornecedor {
          color: #7d0000;
          font-weight: 600;
          text-decoration: underline;
          font-size: 0.8em;
          background: none;
          border: none;
        }
      }
    }

    > .prova-container {
      > p {
        margin-bottom: 10px;
        font-size: 0.9em;
        > b {
          font-weight: 600;
          font-size: 1em;
        }
      }

      > .historico {
        display: flex;
        flex-direction: column;
        row-gap: 5px;
        margin-bottom: 10px;

        > .prova-historico {
          font-size: 0.9em;
        }
      }

      > .status {
        display: flex;
        column-gap: 2%;
        > .comentarios {
          width: 100%;
          height: 40px;
          background: rgba(165, 165, 165, 0.1);
          border-radius: 6px;
          font-size: 0.9em;
          padding: 10px;
          border: none;
        }

        > .reprovar {
          width: 240px;
          min-width: 240px;
          height: 35px;
          background: #9f0010;
          border-radius: 20px;
          border: none;
          font-size: 0.8em;
          color: #fff;
        }

        > .aprovar {
          width: 240px;
          min-width: 240px;
          height: 35px;
          background: #40ad48;
          border-radius: 20px;
          border: none;
          font-size: 0.8em;
          color: #fff;
        }
      }
    }

    > .producao-container {
      > .historico {
        display: flex;
        flex-direction: column;
        row-gap: 5px;
        margin-bottom: 10px;

        > .prova-historico {
          font-size: 0.9em;
        }
      }

      > .producao {
        display: flex;
        justify-content: space-between;
        margin: 20px 0;

        > .distribuicao {
          > b {
            font-weight: 600;
            font-size: 0.9em;
          }

          > button {
            font-size: 0.9em;
            border: none;
            background: none;
            text-decoration: underline;
            color: #609ae5;
          }
        }

        > p {
          font-size: 0.9em;

          > b {
            font-size: 1em;
            font-weight: 600;
          }
        }

        > .finalizar {
          background: none;
          border: 1px solid #7d0000;
          border-radius: 20px;
          color: #7d0000;
          font-weight: 600;
          font-size: 0.9em;
          height: 30px;
          width: 200px;

          &:disabled {
            cursor: not-allowed;
            border: 1px solid #a5a5a5;
            color: #a5a5a5;
          }
        }
      }
    }
  }
`;

export const ModalContainer = styled.div`
  display: flex;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  align-items: center;
  z-index: 10;

  > .content {
    background: #f6f6fc;
    min-width: 15%;
    border-radius: 18px;
    opacity: 1;
    padding: 30px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    position: relative;
    z-index: 10;

    > .fechar {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      border: none;
      background-color: #fa5252;
      width: 50px;
      height: 50px;
      position: absolute;
      top: -25px;
      right: -25px;
      > div {
        height: 25px;
      }
    }

    > h1 {
      font-size: 1em;
      color: #000;
    }

    > p {
      color: #fa5252;
      font-size: 0.8em;
      font-weight: 600;
    }

    > .input-container {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      column-gap: 4px;
      font-size: 0.9em;

      > input {
        background: #ffffff;
        border-radius: 6px;
        padding: 5px;
        height: 40px;
        font-size: 0.9em;
        border: none;
      }
    }

    > .enviar {
      width: 150px;
      height: 35px;
      background: #ffbe00;
      border-radius: 20px;
      font-size: 0.9em;
      font-weight: 600;
      border: none;
      color: #7d0000;
      margin: 0 auto;

      &:disabled {
        color: #fff;
        background: #ccc;
        cursor: not-allowed;
      }
    }

    > .buttons-container {
      display: flex;
      justify-content: center;
      column-gap: 10px;
      > button {
        border: none;
        border-radius: 20px;
        width: 100px;
        height: 35px;
        color: #fff;
        font-weight: 600;
      }

      :first-child {
        background: #fa5252;
      }

      :last-child {
        background: #40ad48;
      }
    }
  }
`;
