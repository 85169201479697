import { useForm } from "react-hook-form";
import { PedirRevisaoStyle } from "./style";
import api from "../../../services/api";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import Loading from "../../Loading";

export const PedirRevisaoModal = ({
  state,
  campanha,
  item,
  perfil,
  briefing,
}) => {
  const { register, handleSubmit, getValues } = useForm();
  const [mensagens, setMensagens] = useState([]);
  const [loading, setLoading] = useState(false);

  const enviarRevisao = () => {
    const data = {
      campanha: campanha,
      texto: getValues("revisao"),
      item: item,
    };

    api
      .post(`/mensagem-revisao-briefing`, {
        mensagensRevisao: [
          {
            perfil: perfil,
            texto: getValues("revisao"),
            campanha_materiais_briefing_id: briefing.id,
          },
        ],
      })
      .then((response) => {})
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });

    api
      .post(`/email/pedir-revisao`, data)
      .then((response) => {
        state(false);
        toast.success("Revisão Enviada 🎉");
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  };

  const buscarMensagens = () => {
    setLoading(false);

    api
      .get(`/mensagem-revisao-briefing/${briefing.id}`)
      .then((response) => {
        setMensagens(response.data.data);
        setLoading(true);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  };

  useEffect(() => {
    buscarMensagens();
  }, []);

  return (
    <PedirRevisaoStyle>
      {loading ? (
        <form className="background" onSubmit={handleSubmit(enviarRevisao)}>
          <div className="chat">
            {mensagens.map((mensagem) => (
              <div
                key={mensagem.id}
                className={`mensagem ${
                  perfil == "cliente" && mensagem.perfil == "cliente"
                    ? "direita"
                    : perfil !== "cliente" && mensagem.perfil !== "cliente"
                    ? "direita"
                    : "esquerda"
                }`}
              >
                <div>
                  <h3>
                    {mensagem.nome}{" "}
                    {mensagem.sobrenome ? mensagem.sobrenome : ""}
                  </h3>
                  <p>{mensagem.mensagem}</p>
                </div>
              </div>
            ))}
          </div>
          <h3>Descreva os itens e quais informações são necessárias</h3>
          <textarea placeholder="Revisão" {...register("revisao")} />
          <h3>
            *O responsável pela campanha irá receber um email com o pedido de
            revisão
          </h3>
          <button>Enviar</button>
          <span className="close" onClick={() => state(false)}>
            X
          </span>
        </form>
      ) : (
        <form className="background" onSubmit={handleSubmit(enviarRevisao)}>
          <Loading />
        </form>
      )}
    </PedirRevisaoStyle>
  );
};
