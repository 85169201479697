import { useNavigate } from "react-router-dom";
import { BoxFiltros, NovaCategoriaContainer } from "./styles";
import Table from "./Table";
import { useState } from "react";
import perfil from "../../assets/perfil.svg";
import { ReactSVG } from "react-svg";

const Permissoes = () => {
  const navigate = useNavigate();
  const [perfilSelecionado, setPerfilSelecionado] = useState("");
  const [textoPesquisa, setTextoPesquisa] = useState("");

  function NovaPermissao() {
    navigate("/permissao/nova");
  }
  return (
    <NovaCategoriaContainer>
      <div className="box">
        <div className="flex">
          <ReactSVG className="perfil" src={perfil} />
          <h1>Permissões</h1>
        </div>
        <BoxFiltros>
          <div className="filtro">
            <input
              placeholder="Pesquisar por ID ou nome"
              value={textoPesquisa}
              onChange={(e) => setTextoPesquisa(e.target.value)}
            />
            <div>
              <p>Filtrar por status</p>
              <select
                value={perfilSelecionado}
                onChange={(e) => setPerfilSelecionado(e.target.value)}
              >
                <option value="">Ver todos</option>
                <option value={1}>ativo</option>
                <option value={0}>inativo</option>
              </select>
            </div>
          </div>
          <button onClick={NovaPermissao}>Nova permissão</button>
        </BoxFiltros>
        <Table
          perfilSelecionado={perfilSelecionado}
          textoPesquisa={textoPesquisa}
        />
      </div>
    </NovaCategoriaContainer>
  );
};

export default Permissoes;
