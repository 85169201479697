import styled from 'styled-components';

export const GerarPedidoComprasModalContainer = styled.div`
    display: flex;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.7);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    align-items: center;
`;

export const ContentContainer = styled.div`
  background-color: #fff;
  min-width: 15%;
  border-radius: 18px;
  opacity: 1;
  padding: 30px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 10;
`;