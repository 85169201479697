import styled from "styled-components";

export const NotFoundPageContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  .logo {
    margin-top: 20px;
  }

  .notFound {
    display: flex;
    justify-content: center;
    position: relative;
    bottom: 64px;
    div {
      width: 70%;
      height: 50%;
    }
    svg {
      width: 100%;
    }
  }

  h3 {
    position: relative;
    bottom: 100px;
    font-size: 20px;
    font-weight: 800;
  }

  span {
    position: relative;
    bottom: 90px;
  }

  .flex {
    position: relative;
    bottom: 80px;
  }

  button {
    border: none;
    margin-right: 20px;
    background-color: var(--principal);
    padding: 8px 20px;
    color: white;
    border-radius: 100px;
    font-weight: 600;
  }

  button + button {
    border: 1px solid var(--principal);
    background-color: white;
    color: var(--principal);
  }
`;
