import { useNavigate } from "react-router-dom";
import { BoxFiltros, NovaCategoriaContainer } from "./styles";
import Table from "./Table";
import { useEffect, useState } from "react";
import api from "../../services/api";
import { toast } from "react-toastify";
import { ReactSVG } from "react-svg";
import voltar from "../../assets/voltar.svg";
import perfil from "../../assets/perfil.svg";

const Perfis = () => {
  const navigate = useNavigate();
  const [perfilSelecionado, setPerfilSelecionado] = useState("");
  const [textoPesquisa, setTextoPesquisa] = useState("");
  const [perfis, setPerfis] = useState([]);

  function buscarPerfis() {
    api
      .get("/perfis")
      .then((response) => {
        setPerfis(response.data.data.perfis);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  useEffect(() => {
    buscarPerfis();
  }, []);

  function NovoPerfil() {
    navigate("/Perfil/novo");
  }
  return (
    <NovaCategoriaContainer>
      <div className="box">
        <div className="flex">
          <ReactSVG className="image" src={perfil} />
          <h1>Perfil</h1>
        </div>
        <BoxFiltros>
          <div className="filtro">
            <input
              placeholder="Pesquisar por ID ou nome"
              value={textoPesquisa}
              onChange={(e) => setTextoPesquisa(e.target.value)}
            />
            <div>
              <p>Filtrar por status</p>
              <select
                value={perfilSelecionado}
                onChange={(e) => setPerfilSelecionado(e.target.value)}
              >
                <option value="">Ver todos</option>
                <option value={1}>ativo</option>
                <option value={0}>inativo</option>
              </select>
            </div>
          </div>
          <button onClick={NovoPerfil}>Novo perfil</button>
        </BoxFiltros>
        <Table
          perfilSelecionado={perfilSelecionado}
          textoPesquisa={textoPesquisa}
          perfis={perfis}
          buscarPerfis={buscarPerfis}
        />
      </div>
    </NovaCategoriaContainer>
  );
};

export default Perfis;
