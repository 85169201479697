import { useNavigate } from "react-router-dom";
import { BoxFiltros, ItensCategoriaContainer } from "./styles";
import Table from "./Table";
import { useAuth } from "../../providers/Auth";
import { useState } from "react";

const ItemCategoria = () => {
  const navigate = useNavigate();
  const [perfilSelecionado, setPerfilSelecionado] = useState("");
  const [textoPesquisa, setTextoPesquisa] = useState("");
  const {user} = useAuth();

  function NovaCategoria() {
    navigate("/item/categoria/nova");
  }
  return (
    <ItensCategoriaContainer>
      <div className="box">
        <h1>Lista categoria itens </h1>
        <BoxFiltros>
          <div className="filtro">
            <input
              placeholder="Pesquisar por ID ou nome da categoria"
              value={textoPesquisa}
              onChange={(e) => setTextoPesquisa(e.target.value)}
            />
            <div>
              <p>Filtrar por status</p>
              <select
                value={perfilSelecionado}
                onChange={(e) => setPerfilSelecionado(e.target.value)}
              >
                <option value="">Ver todos</option>
                <option value={1}>ativo</option>
                <option value={0}>inativo</option>
              </select>
            </div>
          </div>
          {user.permissoes.includes("categoria_item_criar") && <button onClick={NovaCategoria}>Nova Categoria</button>}
        </BoxFiltros>
        <Table
          perfilSelecionado={perfilSelecionado}
          textoPesquisa={textoPesquisa}
        />
      </div>
    </ItensCategoriaContainer>
  );
};

export default ItemCategoria ;
