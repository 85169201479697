import {
  ContentContainer,
  DistribuicaoTr,
  ModalAcompanharMaterialDistribuicoesContainer,
  TableContainer,
} from "./styles";
import { ReactSVG } from "react-svg";
import UploadSvg from "../../../assets/upload.svg";
import FecharSvg from "../../../assets/fechar.svg";
import SalvarSvg from "../../../assets/salvar.svg";
import CustomTable from "../../CustomTable";
import { useEffect, useState } from "react";
import { axios } from "axios";
import { toast } from "react-toastify";
import api from "../../../services/api";
import Loading from "../../Loading";

const ModalAcompanharMaterialDistribuicoes = ({
  setModalOpened,
  material,
  materialDistribuicoes,
  buscarMaterialDistribuicoes,
}) => {
  const [arrayMaterialDistribuicoes, setArrayMaterialDistribuicoes] = useState(
    []
  );
  const [arrayAdicionarDistribuicao, setArrayAdicionarDistribuicao] = useState(
    []
  );
  const [quantidadeRestante, setQuantidadeRestante] = useState(
    Number(material.quantidade)
  );

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!materialDistribuicoes.length) {
      setArrayAdicionarDistribuicao([
        {
          quantidade: 0,
          destino: "",
          nf_fornecedor: "",
          nf_vesta: "",
          comprovante_entrega: "",
        },
      ]);
    } else {
      setArrayMaterialDistribuicoes(materialDistribuicoes);
    }
  }, [materialDistribuicoes]);

  useEffect(() => {
    const distribuicaoQuantidade = arrayMaterialDistribuicoes.reduce(
      (acc, cur) => acc + cur.quantidade,
      0
    );
    const adicionarDistribuicaoQuantidade = arrayAdicionarDistribuicao.reduce(
      (acc, cur) => acc + cur.quantidade,
      0
    );
    setQuantidadeRestante(
      material.quantidade -
        distribuicaoQuantidade -
        adicionarDistribuicaoQuantidade
    );
  }, [arrayMaterialDistribuicoes, arrayAdicionarDistribuicao]);

  const handleUpdateMaterialDistribuicoes = (distribuicaoId, field, value) => {
    let newArrayMaterialDistribuicoes = [...arrayMaterialDistribuicoes];
    let materialDistribuicaoItem = arrayMaterialDistribuicoes.find(
      (item) => item.id === distribuicaoId
    );
    materialDistribuicaoItem[field] = value;
    newArrayMaterialDistribuicoes = newArrayMaterialDistribuicoes.map((item) =>
      item.id === distribuicaoId ? materialDistribuicaoItem : item
    );
    setArrayMaterialDistribuicoes([...newArrayMaterialDistribuicoes]);
  };

  const handleUpdateAdicionarDistribuicao = (index, field, value) => {
    let newArrayAdicionarDistribuicao = arrayAdicionarDistribuicao;
    let adicionarDistribuicaoItem = arrayAdicionarDistribuicao[index];
    adicionarDistribuicaoItem[field] = value;
    newArrayAdicionarDistribuicao[index] = adicionarDistribuicaoItem;
    setArrayAdicionarDistribuicao([...newArrayAdicionarDistribuicao]);
  };

  const handleSalvarDistribuicoes = async () => {
    setLoading(true);

    let editarDistribuicoes = [];
    for (const distribuicao of arrayMaterialDistribuicoes) {
      const dadosDistribuicao = {
        id: distribuicao.id,
        quantidade: distribuicao.quantidade,
        destino: distribuicao.destino,
      };
      if (
        distribuicao.nf_fornecedor !== null &&
        typeof distribuicao.nf_fornecedor === "object"
      ) {
        dadosDistribuicao.nf_fornecedor = distribuicao.nf_fornecedor;
      }
      if (
        distribuicao.nf_vesta !== null &&
        typeof distribuicao.nf_vesta === "object"
      ) {
        dadosDistribuicao.nf_vesta = distribuicao.nf_vesta;
      }
      if (
        distribuicao.comprovante_entrega !== null &&
        typeof distribuicao.comprovante_entrega === "object"
      ) {
        dadosDistribuicao.comprovante_entrega =
          distribuicao.comprovante_entrega;
      }
      editarDistribuicoes.push(dadosDistribuicao);
    }

    let criarDistribuicoes = [];
    for (const distribuicao of arrayAdicionarDistribuicao) {
      if (distribuicao.quantidade > 0) {
        criarDistribuicoes.push({
          quantidade: distribuicao.quantidade,
          destino: distribuicao.destino,
          material_proposta_id: material.id,
          nf_fornecedor: distribuicao.nf_fornecedor,
          nf_vesta: distribuicao.nf_vesta,
          comprovante_entrega: distribuicao.comprovante_entrega,
        });
      }
    }
    try {
      if (editarDistribuicoes.length) {
        for (const distribuicao of editarDistribuicoes) {
          await api.patch(
            `/proposta-material-distribuicao/${distribuicao.id}`,
            distribuicao
          );
          if (distribuicao.nf_fornecedor) {
            const formData = new FormData();
            formData.append("file", distribuicao.nf_fornecedor);
            await api.post(
              `/proposta-material-distribuicao/${distribuicao.id}/nf-fornecedor`,
              formData
            );
          }
          if (distribuicao.nf_vesta) {
            const formData = new FormData();
            formData.append("file", distribuicao.nf_vesta);
            await api.post(
              `/proposta-material-distribuicao/${distribuicao.id}/nf-vesta`,
              formData
            );
          }
          if (distribuicao.comprovante_entrega) {
            const formData = new FormData();
            formData.append("file", distribuicao.comprovante_entrega);
            await api.post(
              `/proposta-material-distribuicao/${distribuicao.id}/comprovante-entrega`,
              formData
            );
          }
        }
      }
      if (criarDistribuicoes.length) {
        for (const distribuicao of criarDistribuicoes) {
          const response = await api.post("/proposta-material-distribuicao", {
            distribuicoes: [distribuicao],
          });
          const insertId = response.data.data.insertId;
          if (distribuicao.nf_fornecedor) {
            const formData = new FormData();
            formData.append("file", distribuicao.nf_fornecedor);
            await api.post(
              `/proposta-material-distribuicao/${insertId}/nf-fornecedor`,
              formData
            );
          }
          if (distribuicao.nf_vesta) {
            const formData = new FormData();
            formData.append("file", distribuicao.nf_vesta);
            await api.post(
              `/proposta-material-distribuicao/${insertId}/nf-vesta`,
              formData
            );
          }
          if (distribuicao.comprovante_entrega) {
            const formData = new FormData();
            formData.append("file", distribuicao.comprovante_entrega);
            await api.post(
              `/proposta-material-distribuicao/${insertId}/comprovante-entrega`,
              formData
            );
          }
        }
      }
      toast.success("Distribuições salvas com sucesso.");
      buscarMaterialDistribuicoes();
      setModalOpened(false);
    } catch (error) {
      toast.error("Erro ao salvar distribuições.");
    }
  };

  const removerAnexoFornecedor = (imagem) => {
    api
      .patch(`/proposta-material-distribuicao/${imagem.id}/nf-fornecedor`, {
        dados: "",
      })
      .then((response) => {
        toast.success("Imagem Removida");
        buscarMaterialDistribuicoes();
      })
      .catch((error) => {
        toast.error("Erro ao buscar dados");
      });
  };

  const removerAnexoVesta = (imagem) => {
    api
      .patch(`/proposta-material-distribuicao/${imagem.id}/nf-vesta`, {
        dados: "",
      })
      .then((response) => {
        toast.success("Imagem Removida");
        buscarMaterialDistribuicoes();
      })
      .catch((error) => {
        toast.error("Erro ao buscar dados");
      });
  };

  const removerComprovanteEntrega = (imagem) => {
    api
      .patch(
        `/proposta-material-distribuicao/${imagem.id}/comprovante-entrega`,
        {
          dados: "",
        }
      )
      .then((response) => {
        toast.success("Imagem Removida");
        buscarMaterialDistribuicoes();
      })
      .catch((error) => {
        toast.error("Erro ao buscar dados");
      });
  };

  const deletarDistribuicao = (imagem) => {
    api
      .delete(`/proposta-material-distribuicao/${imagem.id}`)
      .then((response) => {
        toast.success("Imagem Removida");
        buscarMaterialDistribuicoes();
        setModalOpened(false);
      })
      .catch((error) => {
        toast.error("Erro ao buscar dados");
      });
  };

  return (
    <ModalAcompanharMaterialDistribuicoesContainer>
      <ContentContainer onClick={(e) => e.stopPropagation()}>
        <button className="fechar" onClick={() => setModalOpened(false)}>
          <ReactSVG src={FecharSvg} />
        </button>
        {loading ? (
          <Loading />
        ) : (
          <>
            <h1>Distribuição / Entrega</h1>
            <div className="header">
              <p>
                <b>Item:</b> {material.nome}
              </p>
              <p>
                <b>Quantidade total:</b> {material.quantidade}
                {material.status !== "concluido" && (
                  <span>
                    {quantidadeRestante > 0
                      ? `(Você ainda tem ${quantidadeRestante} ${
                          quantidadeRestante === 1 ? "unidade" : "unidades"
                        } para distribuir)`
                      : "(Você não tem unidades restantes para distribuir)"}
                  </span>
                )}
              </p>
            </div>
            <TableContainer>
              <CustomTable
                header={[
                  "Quantidade",
                  "Destino",
                  "NF fornecedor",
                  "NF Vesta",
                  "Comprovante de entrega",
                  "Remover",
                ]}
              >
                {arrayMaterialDistribuicoes.map((distribuicao) => (
                  <DistribuicaoTr key={distribuicao.id}>
                    <td>
                      <div className="quantidade">
                        <button
                          onClick={() =>
                            handleUpdateMaterialDistribuicoes(
                              distribuicao.id,
                              "quantidade",
                              Number(distribuicao.quantidade) - 1
                            )
                          }
                          disabled={
                            distribuicao.quantidade === 0 ||
                            material.status === "concluido"
                          }
                        >
                          -
                        </button>
                        <input
                          type="number"
                          min="0"
                          max={quantidadeRestante}
                          value={distribuicao.quantidade}
                          onChange={(e) =>
                            handleUpdateMaterialDistribuicoes(
                              distribuicao.id,
                              "quantidade",
                              e.target.value
                            )
                          }
                          disabled={material.status === "concluido"}
                        />
                        <button
                          onClick={() =>
                            handleUpdateMaterialDistribuicoes(
                              distribuicao.id,
                              "quantidade",
                              Number(distribuicao.quantidade) + 1
                            )
                          }
                          disabled={
                            quantidadeRestante === 0 ||
                            material.status === "concluido"
                          }
                        >
                          +
                        </button>
                      </div>
                    </td>
                    <td>
                      <div className="destino">
                        <input
                          value={distribuicao.destino}
                          onChange={(e) =>
                            handleUpdateMaterialDistribuicoes(
                              distribuicao.id,
                              "destino",
                              e.target.value
                            )
                          }
                          placeholder="Rua, Cep, Cidade"
                          disabled={material.status === "concluido"}
                        />
                      </div>
                    </td>
                    <td>
                      <div className="arquivo">
                        {distribuicao.nf_fornecedor ? (
                          <div className="relative">
                            <span
                              className="close"
                              onClick={() =>
                                removerAnexoFornecedor(distribuicao)
                              }
                            >
                              X
                            </span>
                            <a
                              href={
                                typeof distribuicao.nf_fornecedor === "string"
                                  ? distribuicao.nf_fornecedor
                                  : URL.createObjectURL(
                                      distribuicao.nf_fornecedor
                                    )
                              }
                              target="_blank"
                              rel="noreferrer"
                            >
                              {typeof distribuicao.nf_fornecedor === "string"
                                ? `nf_fornecedor_${
                                    distribuicao.id
                                  }.${distribuicao.nf_fornecedor
                                    .split(".")
                                    .at(-1)}`
                                : distribuicao.nf_fornecedor.name}
                            </a>
                          </div>
                        ) : (
                          <div className="input-container">
                            <input
                              type="file"
                              id={`nf-fornecedor-${distribuicao.id}`}
                              onChange={(e) =>
                                handleUpdateMaterialDistribuicoes(
                                  distribuicao.id,
                                  "nf_fornecedor",
                                  e.target.files[0]
                                )
                              }
                              disabled={material.status === "concluido"}
                            />
                            <label htmlFor={`nf-fornecedor-${distribuicao.id}`}>
                              <ReactSVG src={UploadSvg} />
                              upload
                            </label>
                          </div>
                        )}
                      </div>
                    </td>
                    <td>
                      <div className="arquivo">
                        {distribuicao.nf_vesta ? (
                          <div className="relative">
                            <span
                              className="close"
                              onClick={() => removerAnexoVesta(distribuicao)}
                            >
                              x
                            </span>
                            <a
                              href={
                                typeof distribuicao.nf_vesta === "string"
                                  ? distribuicao.nf_vesta
                                  : URL.createObjectURL(distribuicao.nf_vesta)
                              }
                              target="_blank"
                              rel="noreferrer"
                            >
                              {typeof distribuicao.nf_vesta === "string"
                                ? `nf_vesta_${
                                    distribuicao.id
                                  }.${distribuicao.nf_vesta.split(".").at(-1)}`
                                : distribuicao.nf_vesta.name}
                            </a>
                          </div>
                        ) : (
                          <div className="input-container">
                            <input
                              type="file"
                              id={`nf-vesta-${distribuicao.id}`}
                              onChange={(e) =>
                                handleUpdateMaterialDistribuicoes(
                                  distribuicao.id,
                                  "nf_vesta",
                                  e.target.files[0]
                                )
                              }
                              disabled={material.status === "concluido"}
                            />
                            <label htmlFor={`nf-vesta-${distribuicao.id}`}>
                              <ReactSVG src={UploadSvg} />
                              upload
                            </label>
                          </div>
                        )}
                      </div>
                    </td>
                    <td>
                      <div className="arquivo">
                        {distribuicao.comprovante_entrega ? (
                          <div className="relative">
                            <span
                              className="close"
                              onClick={() =>
                                removerComprovanteEntrega(distribuicao)
                              }
                            >
                              x
                            </span>
                            <a
                              href={
                                typeof distribuicao.comprovante_entrega ===
                                "string"
                                  ? distribuicao.comprovante_entrega
                                  : URL.createObjectURL(
                                      distribuicao.comprovante_entrega
                                    )
                              }
                              target="_blank"
                              rel="noreferrer"
                            >
                              {typeof distribuicao.comprovante_entrega ===
                              "string"
                                ? `comprovante_entrega_${
                                    distribuicao.id
                                  }.${distribuicao.comprovante_entrega
                                    .split(".")
                                    .at(-1)}`
                                : distribuicao.comprovante_entrega.name}
                            </a>
                          </div>
                        ) : (
                          <div className="input-container">
                            <input
                              type="file"
                              id={`comprovante-entrega-${distribuicao.id}`}
                              onChange={(e) =>
                                handleUpdateMaterialDistribuicoes(
                                  distribuicao.id,
                                  "comprovante_entrega",
                                  e.target.files[0]
                                )
                              }
                              disabled={material.status === "concluido"}
                            />
                            <label
                              htmlFor={`comprovante-entrega-${distribuicao.id}`}
                            >
                              <ReactSVG src={UploadSvg} />
                              upload
                            </label>
                          </div>
                        )}
                      </div>
                    </td>
                    <td>
                      <h3
                        className="remover"
                        onClick={() => deletarDistribuicao(distribuicao)}
                      >
                        remover
                      </h3>
                    </td>
                  </DistribuicaoTr>
                ))}
                {arrayAdicionarDistribuicao.map((distribuicao, index) => (
                  <DistribuicaoTr key={`adicionar_${index}`}>
                    <td>
                      <div className="quantidade">
                        <button
                          onClick={() =>
                            handleUpdateAdicionarDistribuicao(
                              index,
                              "quantidade",
                              Number(distribuicao.quantidade) - 1
                            )
                          }
                          disabled={distribuicao.quantidade === 0}
                        >
                          -
                        </button>
                        <input
                          type="number"
                          value={distribuicao.quantidade}
                          onChange={(e) =>
                            handleUpdateAdicionarDistribuicao(
                              index,
                              "quantidade",
                              e.target.value
                            )
                          }
                        />
                        <button
                          onClick={() =>
                            handleUpdateAdicionarDistribuicao(
                              index,
                              "quantidade",
                              Number(distribuicao.quantidade) + 1
                            )
                          }
                          disabled={quantidadeRestante === 0}
                        >
                          +
                        </button>
                      </div>
                    </td>
                    <td>
                      <div className="destino">
                        <input
                          value={distribuicao.destino}
                          onChange={(e) =>
                            handleUpdateAdicionarDistribuicao(
                              index,
                              "destino",
                              e.target.value
                            )
                          }
                          placeholder="Rua, Cep, Cidade"
                        />
                      </div>
                    </td>
                    <td>
                      <div className="arquivo">
                        {distribuicao.nf_fornecedor ? (
                          <a
                            href={URL.createObjectURL(
                              distribuicao.nf_fornecedor
                            )}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {distribuicao.nf_fornecedor.name}
                          </a>
                        ) : (
                          <div className="input-container">
                            <input
                              type="file"
                              id={`adicionar-nf-fornecedor-${index}`}
                              onChange={(e) =>
                                handleUpdateAdicionarDistribuicao(
                                  index,
                                  "nf_fornecedor",
                                  e.target.files[0]
                                )
                              }
                            />
                            <label htmlFor={`adicionar-nf-fornecedor-${index}`}>
                              <ReactSVG src={UploadSvg} />
                              upload
                            </label>
                          </div>
                        )}
                      </div>
                    </td>
                    <td>
                      <div className="arquivo">
                        {distribuicao.nf_vesta ? (
                          <a
                            href={URL.createObjectURL(distribuicao.nf_vesta)}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {distribuicao.nf_vesta.name}
                          </a>
                        ) : (
                          <div className="input-container">
                            <input
                              type="file"
                              id={`adicionar-nf-vesta-${index}`}
                              onChange={(e) =>
                                handleUpdateAdicionarDistribuicao(
                                  index,
                                  "nf_vesta",
                                  e.target.files[0]
                                )
                              }
                            />
                            <label htmlFor={`adicionar-nf-vesta-${index}`}>
                              <ReactSVG src={UploadSvg} />
                              upload
                            </label>
                          </div>
                        )}
                      </div>
                    </td>
                    <td>
                      <div className="arquivo">
                        {distribuicao.comprovante_entrega ? (
                          <a
                            href={URL.createObjectURL(
                              distribuicao.comprovante_entrega
                            )}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {distribuicao.comprovante_entrega.name}
                          </a>
                        ) : (
                          <div className="input-container">
                            <input
                              type="file"
                              id={`adicionar-comprovante-entrega-${index}`}
                              onChange={(e) =>
                                handleUpdateAdicionarDistribuicao(
                                  index,
                                  "comprovante_entrega",
                                  e.target.files[0]
                                )
                              }
                            />
                            <label
                              htmlFor={`adicionar-comprovante-entrega-${index}`}
                            >
                              <ReactSVG src={UploadSvg} />
                              upload
                            </label>
                          </div>
                        )}
                      </div>
                    </td>
                  </DistribuicaoTr>
                ))}
              </CustomTable>
            </TableContainer>
            {material.status !== "concluido" && (
              <>
                <div className="adicionar-container">
                  <button
                    className="adicionar-linha"
                    onClick={() => {
                      setArrayAdicionarDistribuicao((prev) => [
                        ...prev,
                        {
                          quantidade: 0,
                          destino: "",
                          nf_fornecedor: null,
                          nf_vesta: null,
                          comprovante_entrega: null,
                        },
                      ]);
                    }}
                  >
                    + adicionar linha de distribuição
                  </button>
                  <p>
                    Todos os campos tem que estar preenchidos e com uploads
                    feitos para concluir a produção do material
                  </p>
                </div>
                <button
                  className="salvar"
                  onClick={async () => await handleSalvarDistribuicoes()}
                >
                  <ReactSVG src={SalvarSvg} /> salvar
                </button>
              </>
            )}
          </>
        )}
      </ContentContainer>
    </ModalAcompanharMaterialDistribuicoesContainer>
  );
};

export default ModalAcompanharMaterialDistribuicoes;
