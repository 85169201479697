import EditarCategoria from "../../../components/Cadastros/Editar/EditarCategoria";
import Header from "../../../components/Menu";

const EditarCategoriaPage = () => {
  return (
    <div>
      <Header />
      <EditarCategoria/>
    </div>
  );
};

export default EditarCategoriaPage;
