import { useEffect, useState } from "react";
import api from "../../services/api";
import { RelatorioContasPagarStyle } from "./style";
import { useForm } from "react-hook-form";
import moment from "moment";
import { toast } from "react-toastify";
import GerarContasModal from "../Modal/GerarContasPagar";

export const RelatorioContasPagar = () => {
  const [relatorios, setRelatorios] = useState([]);
  const [abrirPagar, setAbrirPagar] = useState(false);
  const { register, getValues, handleSubmit } = useForm();

  function getRelatorio(data_inicial, data_final) {
    api
      .get(
        `/relatorios/contas_pagar?data_inicial=${encodeURI(
          data_inicial
        )}&data_final=${encodeURI(data_final)}`
      )
      .then((response) => {
        setRelatorios(response.data.data.materiais);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function buscarContasPagar() {
    const data_inicial = getValues("data_inicial");
    const data_final = getValues("data_final");

    const inicio = moment(data_inicial, "YYYY-MM-DD");
    const finalizacao = moment(data_final, "YYYY-MM-DD");

    const duracao = finalizacao.diff(inicio, "days");

    if (!getValues("data_inicial") || !getValues("data_final")) {
      toast.error("Preencha os campos de data");
    }

    if (duracao < 0) {
      toast.error("Data final não pode ser menor que a data inicial");
    }

    getRelatorio(data_inicial, data_final);
  }

  useEffect(() => {
    if (relatorios.length > 0) {
      setAbrirPagar(true);
    }
  }, [relatorios]);

  return (
    <RelatorioContasPagarStyle onSubmit={handleSubmit(buscarContasPagar)}>
      <div>
        <h2>Relatório: A Pagar</h2>
        <label htmlFor="data_inicial">Data Inicial: </label>
        <input
          {...register("data_inicial")}
          type="date"
          name="data_inicial"
          id="data_inicial"
        />
      </div>
      <div>
        <label htmlFor="data_final">Data Final: </label>
        <input
          {...register("data_final")}
          type="date"
          name="data_final"
          id="data_final"
        />
      </div>
      <button>Gerar Relatório</button>
      {abrirPagar && (
        <GerarContasModal
          relatorios={relatorios}
          setModalOpened={setAbrirPagar}
          data={getValues("data_inicial")}
          tipo="pagar"
        />
      )}
    </RelatorioContasPagarStyle>
  );
};
