import Header from "../../../components/Menu";
import NovoUsuario from "../../../components/Cadastros/Novo/NovoUsuario";

const NovoUsuarioPage = () => {
  return (
    <div className="flex">
      <Header />
      <NovoUsuario />
    </div>
  );
};

export default NovoUsuarioPage;
