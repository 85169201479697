import React from "react";
import Header from "../../../components/Menu";
import EditarPerfil from "../../../components/Cadastros/Editar/EditarPerfil";

const EditarPerfilPage = () => {
  return (
    <div className="flex">
      <Header />
      <EditarPerfil />
    </div>
  );
};

export default EditarPerfilPage;
