import { Link, useNavigate } from "react-router-dom";
import { Body, Inputs, NovaCategoriaContainer } from "./styles";
import { toast } from "react-toastify";
import api from "../../../../services/api";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAuth } from "../../../../providers/Auth/index";
import { CampoObrigatorio } from "../../../../schemas/novoCadastro";
import { ReactSVG } from "react-svg";
import voltar from "../../../../assets/voltar.svg";

const NovoItemVitrine = () => {
  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm({
    resolver: yupResolver(CampoObrigatorio),
  });

  const [itens, setItens] = useState([]);
  const [itemId, setItemId] = useState();
  const navigate = useNavigate();
  const { user } = useAuth();

  const onSubmitFunction = (data) => {
    api
      .post("/item-vitrine", data)
      .then((response) => {
        navigate("/itemVitrine");
        toast.success("Item cadastrado com sucesso!");
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  function listarItens() {
    api
      .get("/itens")
      .then((response) => {
        setItens(response.data.data.itens);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  useEffect(() => {
    listarItens();
  }, []);

  return (
    <NovaCategoriaContainer onSubmit={handleSubmit(onSubmitFunction)}>
      <div className="box">
        <div className="flex">
          <ReactSVG
            onClick={() => navigate("/itens")}
            className="voltar"
            src={voltar}
          />
          <div>
            <h1>Item Vitrine</h1>
            <span className="underline">Novo Item Vitrine</span>
          </div>
        </div>

        <Body>
          <div className="checkbox">
            <input
              type="checkbox"
              id="checkbox1"
              name="status"
              {...register("status")}
              defaultChecked={true}
            />
            <label htmlFor="checkbox1">Ativo</label>
          </div>
          <Inputs>
            <div>
              <label>Item *</label>
              <input
                type="text"
                name="nome"
                {...register("nome")}
                placeholder="Nome do produto/serviço"
              />
              {errors.nome && (
                <span className="input-error-cadastro">
                  {errors.nome.message}
                </span>
              )}
            </div>
            <div>
              <label>Item *</label>
              <select
                onClick={(e) => setItemId(e.target.value)}
                name="tipo"
                {...register("item_id")}
              >
                <option value={""}>Selecione uma opção</option>
                {itens.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.nome}
                  </option>
                ))}
              </select>
              {errors.nome && (
                <span className="input-error-cadastro">
                  {errors.nome.message}
                </span>
              )}
            </div>
          </Inputs>
        </Body>
        <div className="buttons">
          {user && user.permissoes.includes("itens_criar") && (
            <button type="submit">Salvar</button>
          )}
        </div>
      </div>
    </NovaCategoriaContainer>
  );
};

export default NovoItemVitrine;
