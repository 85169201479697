import { HeaderContainer } from "./styles";
import home from "../../assets/menu-home.svg";
import dashboard from "../../assets/menu-dash.svg";
import vitrine from "../../assets/menu-vitrine.svg";
import profile from "../../assets/perfilDefault.png";
import campanhas from "../../assets/menu-campanha.svg";
import cadastro from "../../assets/menu-cadastros.svg";
import arteFinal from "../../assets/menu-arte-final.svg";
import sair from "../../assets/menu-sair.svg";
import logo from "../../assets/logo-vesta.svg";
import { Link, useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { useState } from "react";
import { useAuth } from "../../providers/Auth";
import cliente from "../../assets/cliente.svg";
import forncedor from "../../assets/fornecedor.svg";
import itemServico from "../../assets/item-serviço.svg";
import ncm from "../../assets/ncm.svg";
import permissao from "../../assets/permissao.svg";
import usuario from "../../assets/usuario.svg";
import perfil from "../../assets/perfil.svg";
import marca from "../../assets/marca.svg";
import concorrencia from "../../assets/menu-concorrencia.svg";
import imposto from "../../assets/imposto-menu.svg";
import itemVitrine from "../../assets/menu-cadastro.svg";

const Menu = () => {
  const { user } = useAuth();
  const navigate = useNavigate();

  const [loggoutOpened, setLoggoutOpened] = useState(false);
  const [abrirCadastros, setAbrirCadastros] = useState(false);

  function Dashboard() {
    navigate("/dashboard");
  }

  function Campaing() {
    navigate("/campanhas");
  }

  function Vitrine() {
    navigate("/vitrine");
  }

  function Usuario() {
    navigate("/usuarios");
  }

  function Permissoes() {
    navigate("/permissoes");
  }

  function Perfil() {
    navigate("/perfis");
  }

  function Clientes() {
    navigate("/clientes");
  }

  function Fornecedores() {
    navigate("/fornecedores");
  }

  function Loggout() {
    localStorage.removeItem("@erp:token");
    window.location.reload();
  }

  function ItensServicos() {
    navigate("/itens");
  }

  function Marcas() {
    navigate("/marcas");
  }

  function Ncm() {
    navigate("/ncms");
  }

  function Imposto() {
    navigate("/imposto");
  }

  function ItensVitrine() {
    navigate("/itemVitrine");
  }

  function Concorrencia() {
    navigate("/concorrencia");
  }

  function extenderCadastros() {
    if (abrirCadastros) {
      setAbrirCadastros(false);
    } else {
      setAbrirCadastros(true);
    }
  }

  return (
    <HeaderContainer>
      <div className="boxHeader">
        <img src={logo} alt="logo" className="logo" />
        <div className="categories">
          <button className="category" onClick={Dashboard}>
            <ReactSVG src={dashboard} />
            <p>Dashboard</p>
          </button>
          {user && user.permissoes.includes("cliente") && (
            <button onClick={Campaing} className="category">
              <ReactSVG src={campanhas} />
              <p>Campanhas</p>
            </button>
          )}
          {user && user.permissoes.includes("cliente") && (
            <button onClick={Vitrine} className="category">
              <ReactSVG src={vitrine} />
              <p>Vitrine</p>
            </button>
          )}
          {user && user.permissoes.includes("admin") && (
            <button onClick={Concorrencia} className="category">
              <ReactSVG src={concorrencia} />
              <p>Concorrência</p>
            </button>
          )}
          {user && user.permissoes.includes("cliente") && (
            <button className="category">
              <ReactSVG src={arteFinal} />
              <p>Arte Final</p>
            </button>
          )}
          {user && user.permissoes.includes("admin") && (
            <button onClick={() => extenderCadastros()} className="category">
              <ReactSVG src={cadastro} />
              <p>Cadastros</p>
            </button>
          )}
          {abrirCadastros && user && user.permissoes.includes("admin") && (
            <div className="extends">
              <button onClick={Usuario} className="category">
                <ReactSVG src={usuario} />
                <p>Usuário</p>
              </button>{" "}
              <button onClick={Perfil} className="category">
                <ReactSVG src={perfil} />
                <p>Perfil</p>
              </button>
              <button onClick={Permissoes} className="category">
                <ReactSVG src={permissao} />
                <p>Permissão</p>
              </button>
              <button onClick={Clientes} className="category">
                <ReactSVG src={cliente} />
                <p>Cliente</p>
              </button>
              <button onClick={() => Fornecedores()} className="category">
                <ReactSVG src={forncedor} />
                <p>Fornecedor</p>
              </button>
              <button onClick={() => Ncm()} className="category">
                <ReactSVG src={ncm} />
                <p>NCM</p>
              </button>
              <button onClick={() => ItensServicos()} className="category">
                <ReactSVG src={itemServico} />
                <p>Item / Serviço</p>
              </button>
              <button onClick={() => Marcas()} className="category">
                <ReactSVG src={marca} />
                <p>Marcas</p>
              </button>
              <button onClick={() => Imposto()} className="category">
                <ReactSVG src={imposto} />
                <p>Imposto</p>
              </button>
              <button onClick={() => ItensVitrine()} className="category">
                <ReactSVG src={itemVitrine} className="itemVitrine" />
                <p>Item Vitrine</p>
              </button>
            </div>
          )}

          {user &&
            user.permissoes.includes("fornecedor") &&
            !user.permissoes.includes("admin") && (
              <button
                onClick={() => navigate("/fornecedor/campanhas")}
                className="category"
              >
                <ReactSVG src={campanhas} />
                <p>Campanhas</p>
              </button>
            )}
        </div>
      </div>
      <div
        className="profile"
        onMouseOver={() => setLoggoutOpened(true)}
        onMouseLeave={() => setLoggoutOpened(false)}
      >
        <img
          src={
            user.imagem != null
              ? user.imagem
              : "https://3.bp.blogspot.com/_MqYZDpoO9ks/TI5FjMy-ZVI/AAAAAAAADBM/-LW9YEp8yeM/s400/anonimo2.jpg"
          }
          alt="profile"
          onMouseOver={() => setLoggoutOpened(true)}
          onMouseLeave={() => setLoggoutOpened(false)}
          className="imagem-perfil"
        />
        <div className="flex-column">
          <p>
            {user.nome} {user.sobrenome}
          </p>
          <div className="flex" onClick={() => Loggout()}>
            <ReactSVG src={sair} />
            <p>Sair</p>
          </div>
        </div>
      </div>
    </HeaderContainer>
  );
};

export default Menu;
