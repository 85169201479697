import { useForm } from "react-hook-form";
import Menu from "../../components/Menu";
import { ValorMaximoPropostaStyle } from "./style";
import api from "../../services/api";
import { toast } from "react-toastify";
import { useEffect } from "react";

export const ValorMaximoProposta = () => {
  const { register, getValues, reset, handleSubmit } = useForm();

  function buscarValorMaximo() {
    // Buscar valor máximo de proposta
    api
      .get("/valor-maximo-proposta")
      .then((response) => {
        reset(response.data.data.valorMaximo);
      })
      .catch((error) => {
        console.error("Erro ao buscar valor máximo de proposta", error);
        toast("Erro ao buscar valor máximo de proposta");
      });
  }

  function atualizarValorMaximo() {
    const valor = getValues("valor");

    // Atualizar valor máximo de proposta
    api
      .patch("/valor-maximo-proposta", { valor: valor })
      .then(() => {
        toast.success("Valor máximo de proposta atualizado com sucesso");
        buscarValorMaximo();
      })
      .catch((error) => {
        console.error("Erro ao atualizar valor máximo de proposta", error);
        toast.error("Erro ao atualizar valor máximo de proposta");
      });
  }

  useEffect(() => {
    buscarValorMaximo();
  }, []);

  return (
    <div className="flex">
      <Menu />
      <ValorMaximoPropostaStyle>
        <h1>Valor Máximo de Proposta</h1>
        <form onSubmit={handleSubmit(atualizarValorMaximo)}>
          <div>
            <input {...register("valor")} type="number" />
            <span>R$</span>
            <button>Editar</button>
          </div>
        </form>
      </ValorMaximoPropostaStyle>
    </div>
  );
};
