import React from 'react'
import Header from '../../../components/Menu'
import EditarCategoriaItem from '../../../components/Cadastros/Editar/EditarCategoriaItem'

const EditarCategoriaItemPage = () => {
  return (
    <div>
      <Header/>
      <EditarCategoriaItem/>
    </div>
  )
}

export default EditarCategoriaItemPage
