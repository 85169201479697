import { ReactSVG } from "react-svg";
import { ListaVitrineStyle } from "./style";
import Vitrine from "../../assets/materiais-via-vitrine.svg";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ListaCategoria } from "./ListaCategoria";
import api from "../../services/api";
import { toast } from "react-toastify";
import Loading from "../Loading";
import { useAuth } from "../../providers/Auth";

export const ListaVitrine = () => {
  const [materiaisVitrine, setMateriaisVitrine] = useState([]);

  const { user } = useAuth();
  const navigate = useNavigate();

  const [materiaisVitrineLoaded, setMateriaisVitrineLoaded] = useState(false);
  const [materiaisPendentes, setMateriaisPendentes] = useState([]);
  const [categorias, setCategorias] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (materiaisVitrineLoaded) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [materiaisVitrineLoaded]);

  const buscarMateriaisVitrine = () => {
    setMateriaisVitrineLoaded(false);
    api
      .get("material-vitrine")
      .then((response) => {
        if (
          user.permissoes.includes("admin") ||
          user.permissoes.includes("produtor")
        ) {
          setMateriaisVitrine(response.data.data.materiais);
          setMateriaisPendentes(
            response.data.data.materiais.filter(
              (element) => element.vizualizar_vitrine == 0
            ).length
          );
        } else {
          const filtro = response.data.data.materiais.filter(
            (elem) => elem.vizualizar_vitrine && elem.status
          );
          setMateriaisVitrine(filtro);
        }
        setMateriaisVitrineLoaded(true);
      })
      .catch((error) => {
        toast.error("Erro ao buscar materiais da vitrine");
      });
  };

  function buscarCategoriaItem() {
    api
      .get(`/categoria-item`)
      .then((response) => {
        setCategorias(response.data.data.categorias);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  useEffect(() => {
    buscarMateriaisVitrine();
    buscarCategoriaItem();
  }, []);

  return (
    <ListaVitrineStyle>
      <div className="flex">
        <ReactSVG src={Vitrine} />
        <h1>Vitrine</h1>
      </div>

      {loading ? (
        <Loading />
      ) : (
        categorias && (
          <>
            {materiaisPendentes > 0 && (
              <h3 className="h3-aviso">
                ⚠️Você tem {materiaisPendentes} materias para aprovar!
              </h3>
            )}
            <h3></h3>
            {categorias.map((elem) => (
              <ListaCategoria
                categoria={elem.nome}
                materiais={materiaisVitrine.filter(
                  (material, index) => Number(material.categoria) === elem.id
                )}
                materiaisPendentes={materiaisPendentes}
              />
            ))}
          </>
        )
      )}
    </ListaVitrineStyle>
  );
};
