import LoginForm from "../Forms/LoginForm";
import { BoxLeft, BoxRight, LoginContainer } from "./styles";
import imagemLogin from "../../assets/imagem-login.svg";

import { motion } from "framer-motion";

const Login = () => {
  return (
    <LoginContainer>
      <BoxLeft>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 1, ease: "easeInOut" }}
        >
          <LoginForm />
        </motion.div>
      </BoxLeft>
      <BoxRight>
        <img src={imagemLogin} alt="imagem do Login" />
      </BoxRight>
    </LoginContainer>
  );
};

export default Login;
