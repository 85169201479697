import { Link, useNavigate } from "react-router-dom";
import { Body, Inputs, NovaCategoriaContainer } from "./styles";
import { toast } from "react-toastify";
import api from "../../../../services/api";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAuth } from "../../../../providers/Auth/index";
import { CampoObrigatorio } from "../../../../schemas/novoCadastro";
import { ReactSVG } from "react-svg";
import voltar from "../../../../assets/voltar.svg";

const NovoNcm = () => {
  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm({
    resolver: yupResolver(CampoObrigatorio),
  });

  const [ncms, setNcms] = useState([]);
  const [categorias, setCategorias] = useState([]);
  const navigate = useNavigate();
  const { user } = useAuth();

  const onSubmitFunction = (data) => {
    const ncms = [data];

    api
      .post("/ncm", { ncms })
      .then((response) => {
        navigate("/ncms");
        toast.success("Item cadastrado com sucesso!");
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  //BUSCA TODAS AS CATEGORIA
  function buscarCategoriaItem() {
    api
      .get("/categoria-item")
      .then((response) => {
        setCategorias(response.data.data.categorias);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  //BUSCA TODAS AS CATEGORIA
  function listarNcm() {
    api
      .get("/ncm")
      .then((response) => {
        setNcms(response.data.data.ncms);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  useEffect(() => {
    buscarCategoriaItem();
    listarNcm();
  }, []);

  return (
    <NovaCategoriaContainer onSubmit={handleSubmit(onSubmitFunction)}>
      <div className="box">
        <div className="flex">
          <ReactSVG
            onClick={() => navigate("/ncms")}
            className="voltar"
            src={voltar}
          />
          <div>
            <h1>NCM</h1>
            <span className="underline">Novo NCM</span>
          </div>
        </div>

        <Body>
          <div className="checkbox">
            <input
              type="checkbox"
              id="checkbox1"
              name="status"
              {...register("status")}
              defaultChecked={true}
            />

            <label htmlFor="checkbox1">Ativo</label>
          </div>
          <Inputs>
            <div className="flex basic">
              <div>
                <label>Código NCM *</label>
                <input
                  type="text"
                  name="codigo"
                  {...register("codigo")}
                  placeholder="0000.00.00"
                />
              </div>
              <div>
                <label>Nome *</label>
                <input
                  type="text"
                  name="nome"
                  {...register("nome")}
                  placeholder="Nome"
                />
                {errors.nome && (
                  <span className="input-error-cadastro">
                    {errors.nome.message}
                  </span>
                )}
              </div>
            </div>
            <div className="flex basic">
              <div>
                <label>Descrição Receita Federal *</label>
                <input
                  type="text"
                  name="descricao"
                  {...register("descricao")}
                  placeholder="Descrição receita federal"
                />
              </div>
            </div>
            <div className="flex basic">
              <div>
                <label>IPI *</label>
                <input
                  type="text"
                  name="ipi"
                  {...register("ipi")}
                  placeholder="0%"
                />
              </div>
              <div>
                <label>ICMS SP *</label>
                <input
                  type="number"
                  name="icms_sp"
                  {...register("icms_sp")}
                  placeholder="0%"
                />
              </div>
              <div>
                <label>ICMS-ST *</label>
                <input
                  type="text"
                  name="icms_st"
                  {...register("icms_st")}
                  placeholder="0%"
                />
              </div>
            </div>
            <div className="wrap">
              <div>
                <label>ICMS-AC *</label>
                <input
                  type="text"
                  name="icms_ac"
                  {...register("icms_ac")}
                  placeholder="0%"
                />
              </div>
              <div>
                <label>ICMS-AL *</label>
                <input
                  type="text"
                  name="icms_al"
                  {...register("icms_al")}
                  placeholder="0%"
                />
              </div>
              <div>
                <label>ICMS-AP *</label>
                <input
                  type="text"
                  name="icms_ap"
                  {...register("icms_ap")}
                  placeholder="0%"
                />
              </div>
              <div>
                <label>ICMS-AM *</label>
                <input
                  type="text"
                  name="icms_am"
                  {...register("icms_am")}
                  placeholder="0%"
                />
              </div>
              <div>
                <label>ICMS-BA *</label>
                <input
                  type="text"
                  name="icms_ba"
                  {...register("icms_ba")}
                  placeholder="0%"
                />
              </div>
              <div>
                <label>ICMS-CE *</label>
                <input
                  type="text"
                  name="icms_ce"
                  {...register("icms_ce")}
                  placeholder="0%"
                />
              </div>
              <div>
                <label>ICMS-ES *</label>
                <input
                  type="text"
                  name="icms_es"
                  {...register("icms_es")}
                  placeholder="0%"
                />
              </div>
              <div>
                <label>ICMS-GO *</label>
                <input
                  type="text"
                  name="icms_go"
                  {...register("icms_go")}
                  placeholder="0%"
                />
              </div>
              <div>
                <label>ICMS-MA *</label>
                <input
                  type="text"
                  name="icms_ma"
                  {...register("icms_ma")}
                  placeholder="0%"
                />
              </div>
              <div>
                <label>ICMS-MT *</label>
                <input
                  type="text"
                  name="icms_mt"
                  {...register("icms_mt")}
                  placeholder="0%"
                />
              </div>
              <div>
                <label>ICMS-MS *</label>
                <input
                  type="text"
                  name="icms_ms"
                  {...register("icms_ms")}
                  placeholder="0%"
                />
              </div>
              <div>
                <label>ICMS-MG *</label>
                <input
                  type="text"
                  name="icms_mg"
                  {...register("icms_mg")}
                  placeholder="0%"
                />
              </div>
              <div>
                <label>ICMS-PA *</label>
                <input
                  type="text"
                  name="icms_pa"
                  {...register("icms_pa")}
                  placeholder="0%"
                />
              </div>
              <div>
                <label>ICMS-PB *</label>
                <input
                  type="text"
                  name="icms_pb"
                  {...register("icms_pb")}
                  placeholder="0%"
                />
              </div>
              <div>
                <label>ICMS-PR *</label>
                <input
                  type="text"
                  name="icms_pr"
                  {...register("icms_pr")}
                  placeholder="0%"
                />
              </div>
              <div>
                <label>ICMS-PE *</label>
                <input
                  type="text"
                  name="icms_pe"
                  {...register("icms_pe")}
                  placeholder="0%"
                />
              </div>
              <div>
                <label>ICMS-PI *</label>
                <input
                  type="text"
                  name="icms_pi"
                  {...register("icms_pi")}
                  placeholder="0%"
                />
              </div>
              <div>
                <label>ICMS-RJ *</label>
                <input
                  type="text"
                  name="icms_rj"
                  {...register("icms_rj")}
                  placeholder="0%"
                />
              </div>{" "}
              <div>
                <label>ICMS-RN *</label>
                <input
                  type="text"
                  name="icms_rn"
                  {...register("icms_rn")}
                  placeholder="0%"
                />
              </div>{" "}
              <div>
                <label>ICMS-RS *</label>
                <input
                  type="text"
                  name="icms_rs"
                  {...register("icms_rs")}
                  placeholder="0%"
                />
              </div>{" "}
              <div>
                <label>ICMS-RO *</label>
                <input
                  type="text"
                  name="icms_ro"
                  {...register("icms_ro")}
                  placeholder="0%"
                />
              </div>{" "}
              <div>
                <label>ICMS-RR *</label>
                <input
                  type="text"
                  name="icms_rr"
                  {...register("icms_rr")}
                  placeholder="0%"
                />
              </div>{" "}
              <div>
                <label>ICMS-SC *</label>
                <input
                  type="text"
                  name="icms_sc"
                  {...register("icms_sc")}
                  placeholder="0%"
                />
              </div>
              <div>
                <label>ICMS-SE *</label>
                <input
                  type="text"
                  name="icms_se"
                  {...register("icms_se")}
                  placeholder="0%"
                />
              </div>
              <div>
                <label>ICMS-TO *</label>
                <input
                  type="text"
                  name="icms_to"
                  {...register("icms_to")}
                  placeholder="0%"
                />
              </div>
            </div>
            <div className="flex basic">
              <div>
                <label>Obversações *</label>
                <input
                  type="text"
                  name="observacoes"
                  {...register("observacoes")}
                  placeholder="Observação"
                />
                {errors.nome && (
                  <span className="input-error-cadastro">
                    {errors.nome.message}
                  </span>
                )}
              </div>
            </div>
          </Inputs>
        </Body>
        <div className="buttons">
          {user && user.permissoes.includes("itens_criar") && (
            <button type="submit">Salvar</button>
          )}
        </div>
      </div>
    </NovaCategoriaContainer>
  );
};

export default NovoNcm;
