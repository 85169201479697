import Header from "../../components/Menu";
import ItemVitrine from "../../components/ItemVitrine";

const ItemVitrinePage = () => {
  return (
    <div className="flex">
      <Header />
      <ItemVitrine />
    </div>
  );
};

export default ItemVitrinePage;
