import NovoCliente from "../../../components/Cadastros/Novo/NovoClienteForm";
import Header from "../../../components/Menu";

const NovoClientePage = () => {
  return (
    <div className="flex">
      <Header />
      <NovoCliente />
    </div>
  );
};

export default NovoClientePage;
