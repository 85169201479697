import { styled } from "styled-components";

export const CardMaterialViaVitrineStyle = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px 0px 20px 0px;

  span {
    text-decoration: underline;
    color: #609ae5;
    font-size: 0.9em;
  }

  .escala {
    display: flex;
    padding: 30px 0px 10px 10px;
    border-bottom: 1px solid var(--gray70);
    .categoria {
      font-weight: 600;
      width: 17%;
      font-size: 0.9em;
    }

    .material {
      font-weight: 600;
      width: 23%;
      font-size: 0.9em;
    }

    .quantidade {
      font-weight: 600;
      width: 23%;
      font-size: 0.9em;
    }

    .unidade {
      font-weight: 600;
      width: 15%;
      font-size: 0.9em;
    }

    .total {
      font-weight: 600;
      width: 15%;
      font-size: 0.9em;
    }

    .acoes {
      font-weight: 600;
      font-size: 0.9em;
    }
  }

  .categoria {
    width: 17%;
    font-size: 0.9em;
    margin-left: 10px;
  }

  .material {
    width: 23%;
    font-size: 0.9em;
    margin-left: 10px;
    > h4 {
      font-size: 1em;
      line-height: 1.2;
      margin-bottom: 4px;
    }

    .descricao {
      font-size: 0.8em;
      line-height: 1.2;
    }
  }

  .quantidade {
    width: 23%;
    font-size: 0.9em;
    margin-left: 10px;
  }

  .unidade {
    width: 15%;
    font-size: 0.9em;
    margin-left: 10px;
  }

  .total {
    width: 14%;
    font-size: 0.9em;
    margin-left: 10px;
  }

  .editar {
    display: flex;
    justify-content: center;
    align-items: center;  
    width: 30px;
    height: 30px;
    border-radius: 80px;
    background-color: var(--amarelo);
    margin-right: 10px;
    font-size: 0.9em;
  }

  .excluir {
    display: flex;
    justify-content: center;
    align-items: center;  
    width: 30px;
    height: 30px;
    border-radius: 800px;
    background-color: var(--principal);
    > div {
      height: 16px;
      svg {
        height: 100%;
      }
    }
  }

  .acoes {
    display: flex;
    flex-direction: row;
    height: max-content;
  }

  > .infos-adicionais-container {
    margin-top: 15px;
    justify-content: space-between;
    
    > .personalizacao-container {
      display: flex;
      flex-direction: column;
  
      h3 {
        margin-bottom: 10px;
        > b {
          font-weight: bold;
          font-size: 0.9em;
        }
  
        > button {
          margin-left: 4px;
          font-size: 0.8em;
          color: #609ae5;
          text-decoration: underline;
          background: none;
          border: none;
        }
      }

      > .arquivo-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 280px;
        height: 40px;
        background: rgba(165, 165, 165, 0.1);
        border-radius: 6px;
        cursor: pointer;

        > input {
            display: none;
        }

        > label {
            display: flex;
            align-items: center;
            color: #979696;
            padding-left: 15px;
            font-size: 0.9em;
            width: 100%;
            height: 100%;
            cursor: pointer;
        }

        > .pesquisar {
            padding: 10px;
            width: 35%;
            height: 100%;
            font-size: 0.9em;
            background: rgba(128, 131, 132, 0.39);
            border-radius: 6px;
            border: none;
            color: #707070;
            font-weight: 600;
        }
      }

      > a {
        margin: 4px 0 0 4px;
        font-size: 0.8em;
        color: #609ae5;
      }
    }
  
    > .endereco-container {
      display: flex;
      flex-direction: column;

      > h3 {
        margin-bottom: 10px;
        > b {
          font-weight: bold;
          font-size: 0.9em;
        }
  
        > button {
          margin-left: 4px;
          font-size: 0.8em;
          color: #609ae5;
          text-decoration: underline;
          background: none;
          border: none;
        }
      }

      > .endereco {
        > p {
          font-size: 0.9em;
        }
      }
    } 
  }
`;

export const ArteMaterialContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 2px;
  margin-top: 2px;

  > a {
    font-size: 0.9em;
    color: #609ae5;
  }
`;