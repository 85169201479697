import React from "react";
import Header from "../../components/Menu";
import Permissoes from "../../components/Permissoes";

const PermissoesPage = () => {
  return (
    <div className="flex">
      <Header />
      <Permissoes />
    </div>
  );
};

export default PermissoesPage;
