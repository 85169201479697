import { useNavigate } from "react-router-dom";
import { BoxFiltros, NovoUsuarioContainer } from "./styles";
import Table from "./Table";
import Usuario from "../../assets/menu-usuario.svg";
import { useState, useEffect } from "react";
import api from "../../services/api";
import { toast } from "react-toastify";
import { ReactSVG } from "react-svg";

const CadastroDeUsuario = () => {
  const navigate = useNavigate();
  const [perfilSelecionado, setPerfilSelecionado] = useState("");
  const [textoPesquisa, setTextoPesquisa] = useState("");
  const [usuarios, setUsuarios] = useState([]);

  useEffect(() => {
    buscarUsuario();
  }, []);

  //BUSCA TODOS OS USUARIOS
  function buscarUsuario() {
    api
      .get("/usuarios")
      .then((response) => {
        setUsuarios(response.data.data.usuarios);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  return (
    <NovoUsuarioContainer>
      <div className="box">
        <div className="flex">
          <ReactSVG src={Usuario} />
          <h1>Usuário</h1>
        </div>
        <BoxFiltros>
          <div className="filtro">
            <input
              placeholder="Pesquisar por ID ou Nome do usuário"
              value={textoPesquisa}
              onChange={(e) => setTextoPesquisa(e.target.value)}
            />
            <div>
              <p>Filtrar por Perfil</p>
              <select
                value={perfilSelecionado}
                onChange={(e) => setPerfilSelecionado(e.target.value)}
              >
                <option value="">Ver todos</option>;
                {usuarios.map((data) => (
                  <option key={data.perfil_id} value={data.perfil_id}>
                    {data.nome_perfil}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <button onClick={() => navigate("/usuario/novo")}>
            Novo usuário
          </button>
        </BoxFiltros>
        <Table
          perfilSelecionado={perfilSelecionado}
          textoPesquisa={textoPesquisa}
          usuarios={usuarios}
        />
      </div>
    </NovoUsuarioContainer>
  );
};

export default CadastroDeUsuario;
