import { ReactSVG } from "react-svg";
import Menu from "../../components/Menu";
import { useAuth } from "../../providers/Auth";
import { DisputaPageStyle } from "./style";
import Botao from "../../assets/voltar.svg";
import { TabelaDisputa } from "../../components/TabelaDisputa";
import api from "../../services/api";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Loading from "../../components/Loading";
import { useParams } from "react-router-dom";
import { EditarPrazoConcorrencia } from "../../components/Modal/EditarPrazoConcorrencia";

export const DisputaPage = () => {
  const { id } = useParams();
  const { token, user } = useAuth();
  const [campanha, setCampanha] = useState(false);
  const [materialBriefing, setMaterialBriefing] = useState(false);
  const [orcamentos, setOrcamentos] = useState([]);
  const [marcas, setMarcas] = useState([]);
  const [ncm, setNcm] = useState([]);
  const [item, setItem] = useState({});
  const [categoriasFornecedor, setCategoriasFornecedor] = useState();
  const [bloquear, setBloquear] = useState();
  const [abrirModalEditarPrazo, setAbrirModalEditarPrazo] = useState(false);
  const [quantidade, setQuantidade] = useState();

  const { register, getValues, reset } = useForm();

  function buscarCampanhas(material_id) {
    api
      .get(`/campanhas`)
      .then((response) => {
        const dataCampanhas = response.data.data.campanhas;
        const filterCampanhas = dataCampanhas.filter(
          (element) => element.id == material_id
        )[0];
        buscarMarcas(filterCampanhas.id);
        setCampanha(filterCampanhas);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  function buscarMaterialBriefing() {
    api
      .get("/campanha-material-briefing")
      .then((response) => {
        const dataMateriais = response.data.data.materiais;
        const filterMaterial = dataMateriais.filter(
          (element) => element.id == id
        )[0];
        setMaterialBriefing(filterMaterial);
        reset({
          quantidade: filterMaterial.quantidades[0],
          fee: filterMaterial.fee
            ? filterMaterial.fee
            : filterMaterial.cliente_fee,
        });
        setQuantidade(filterMaterial.quantidades[0]);
        buscarItens(filterMaterial.item_id);
        buscarOrcamentoBriefing(filterMaterial.id);
        buscarCampanhas(filterMaterial.campanhas_id);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  function buscarMarcas(id) {
    api
      .get(`/campanha-marca/${id}`)
      .then((response) => {
        const dataMarcas = response.data.data.vinculos;
        const filterMarcas = dataMarcas.filter(
          (element) => Number(element.campanha_id) === Number(id)
        );
        setMarcas(filterMarcas);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  function buscarOrcamentoBriefing(material_id) {
    if (!user) {
      return;
    }
    api
      .get(`/orcamento-materiais-briefing`)
      .then((response) => {
        const dataOrcamentos = response.data.data.orcamentos;
        const filterOrcamentos = dataOrcamentos.filter(
          (element) => element.campanha_material_briefing_id == material_id
        );
        const verificarConcluao = filterOrcamentos.filter(
          (element) =>
            element.status === "aprovado" ||
            element.status === "proposta aprovada"
        );

        if (verificarConcluao.length > 0) {
          setBloquear(true);
        }
        setOrcamentos(filterOrcamentos);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  function buscarNCM() {
    api
      .get(`/ncm`)
      .then((response) => {
        setNcm(response.data.data.ncms);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  function buscarItens(id) {
    api
      .get(`/itens/${id}`)
      .then((response) => {
        setItem(response.data.data.item);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  function buscarCategoriaFornecedor() {
    api
      .get(`/categoria-fornecedor`)
      .then((response) => {
        setCategoriasFornecedor(response.data.data.categorias);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  const adicionarHistoricoAtualizar = (prazo) => {
    const parte1 = prazo.split("T")[0];
    const parte2 = prazo.split("T")[1];
    const partes = parte1.split("-");
    const conversao = `${partes[2]}/${partes[1]}/${partes[0]} ${parte2}`;
    const dados = materialBriefing;
    const data = {
      tipo: "Campanha",
      texto: `Prazo Máximo de Propostas Atualizado: #${dados.id} ${dados.nome} de ${materialBriefing.prazo_propostas} para ${conversao}`,
      campanha_id: dados.campanhas_id,
    };

    api
      .post("/historico-campanha", [data])
      .then((response) => {})
      .catch((error) => {
        toast.error("Erro ao salvar no historico");
      });
  };

  useEffect(() => {
    if (!user) {
      return;
    }
    buscarMaterialBriefing();
    buscarNCM();
    buscarCategoriaFornecedor();
  }, [user]);

  //  useEffect(() => {
  //    if (materialBriefing) {
  //      buscarUsuarioCliente();
  //    }
  //  }, [materialBriefing]);

  function quantidadeDeCotacao(quantidade) {
    setQuantidade(quantidade);
  }

  const getDataUTC = (utc) => {
    const dataAtual = new Date();
    const dataNaoFormatada = new Date(dataAtual.valueOf() - utc * 60 * 60000);

    const { dia, mes, anoCompleto, hora, minuto } = {
      dia: dataNaoFormatada.getUTCDate().toString().padStart(2, "0"),
      mes: (dataNaoFormatada.getUTCMonth() + 1).toString().padStart(2, "0"),
      anoCompleto: dataNaoFormatada.getFullYear(),
      hora: dataNaoFormatada.getUTCHours().toString().padStart(2, "0"),
      minuto: dataNaoFormatada.getUTCMinutes().toString().padStart(2, "0"),
    };

    const dataFormatada = [dia, mes, anoCompleto].join("/");
    return dataFormatada;
  };

  function converterFormatoData(dataOriginal) {
    const partesData = dataOriginal.split("/");
    const dataObj = new Date(
      `${partesData[2]}-${partesData[1]}-${partesData[0]}`
    );
    const dataConvertida = dataObj.toISOString().split("T")[0];
    return dataConvertida;
  }

  const dataAtual = converterFormatoData(getDataUTC(3));

  function adicionarPrazoProposta(prazo) {
    const parte1 = prazo.split("T")[0];
    const parte2 = prazo.split("T")[1];
    const partes = parte1.split("-");
    const data = `${partes[0]}-${partes[1]}-${partes[2]}`;
    const conversao = `${partes[2]}/${partes[1]}/${partes[0]} ${parte2}`;

    const parteBriefing1 = materialBriefing.prazo_propostas
      ? materialBriefing.prazo_propostas.split(" ")[0]
      : 0;
    const parteBriefing2 = parteBriefing1 ? parteBriefing1.split("/") : 0;
    const dataBriefing = `${parteBriefing2[2]}-${parteBriefing2[1]}-${parteBriefing2[0]}`;

    if (
      materialBriefing.prazo_propostas ? data < dataBriefing : prazo < dataAtual
    ) {
      return toast.error("Precisa ser uma data maior que a atual!");
    }

    api
      .patch(
        `/campanha-material-briefing/${materialBriefing.id}`,
        { prazo_propostas: conversao, status: "enviado para os fornecedores" },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        toast.success(
          materialBriefing.prazo_propostas
            ? "Prazo máximo Atualizado! 🎉"
            : "Prazo máximo Adicionado! 🎉"
        );
        adicionarHistoricoAtualizar(prazo);
        buscarMaterialBriefing();
      })
      .catch((error) => {
        console.log(error);
        toast.error("Algo deu Errado. Tente novamente.");
      });
  }

  function atualizandoFee() {
    const fee = getValues("fee");
    api
      .patch(
        `/campanha-material-briefing/${materialBriefing.id}`,
        { fee: fee },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        toast.success("Fee atualizado com sucesso! 🎉");
        buscarMaterialBriefing();
      })
      .catch((error) => {
        toast.error("Algo deu Errado. Tente novamente.");
      });
  }

  return (
    <DisputaPageStyle>
      <Menu />
      {materialBriefing && campanha ? (
        <div className="body">
          <div className="div-detalhes">
            <div className="flex">
              <ReactSVG
                className="back"
                onClick={() => window.history.back()}
                src={Botao}
              />
              <div className="detalhes">
                <h1># {campanha && campanha.id}</h1>
                <h2 className="nome-campanha">
                  {Object.keys(campanha).length != 0 && campanha.nome}
                </h2>
              </div>
            </div>
            <div className="flex">
              <div className="div-dados">
                <div className="infos flex">
                  <div className="flex margin-right">
                    <h3>Cliente: </h3>
                    <h4>{materialBriefing.cliente_nome}</h4>
                  </div>
                  <div className="flex margin-right">
                    <h3>Marca: </h3>
                    {marcas &&
                      marcas.map((element, index) => (
                        <h4 className="marcas">
                          {index > 0 ? "| " + element.nome : element.nome}{" "}
                        </h4>
                      ))}
                  </div>
                </div>
                <div className="flex solicitante">
                  <h3>Solicitante:</h3>
                  <h2>
                    {materialBriefing.solicitante_nome +
                      " " +
                      (materialBriefing.solicitante_sobrenome
                        ? materialBriefing.solicitante_sobrenome
                        : "")}
                  </h2>
                </div>
              </div>
              {!materialBriefing.prazo_propostas ? (
                <div className="prazo">
                  <h2>Prazo final de envio das propostas:</h2>
                  <div>
                    <input
                      type="datetime-local"
                      {...register("prazo_propostas")}
                    />
                    {user.permissoes.includes("produtor") ||
                    user.permissoes.includes("compras") ? (
                      <button
                        onClick={() =>
                          adicionarPrazoProposta(getValues("prazo_propostas"))
                        }
                      >
                        Confirmar
                      </button>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              ) : (
                <div className="prazo_proposta">
                  <div>
                    <div className="aling-itens">
                      <h2>Prazo final de envio das propostas:</h2>
                    </div>
                    <div className="div-prazo">
                      <h3>{materialBriefing.prazo_propostas}</h3>
                      <button
                        className="editar"
                        onClick={() => setAbrirModalEditarPrazo(true)}
                      >
                        Editar
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="faixa">
            <div className="flex">
              <h4>Material :</h4>
              <h2>
                {" "}
                {Object.keys(materialBriefing).length != 0 &&
                  "#" + materialBriefing.id + " " + materialBriefing.nome}{" "}
                {materialBriefing.subnome && materialBriefing.subnome}
              </h2>
            </div>
          </div>
          <div className="flex div-detalhes-proposta">
            <div className="column div-especificao">
              <h3>Especificação:</h3>
              {item && item.tipo === "Serviço" ? (
                materialBriefing.descricao
              ) : (
                <h4>
                  {materialBriefing.categoria_item &&
                  materialBriefing.categoria_item === "Brindes"
                    ? ` Público Alvo: ${
                        materialBriefing.publico_alvo
                      } | Item de Marca: ${
                        materialBriefing.item_de_marca ? "Sim" : "Não"
                      } | Quantidades: [${materialBriefing.quantidades.map(
                        (element) => element
                      )}] | Observações: ${materialBriefing.descricao} `
                    : materialBriefing.categoria_item === "Material Impresso"
                    ? ` Quantidade de páginas: ${
                        materialBriefing.quantidade_de_paginas
                      } | Formato aberto: ${
                        materialBriefing.formato_aberto
                      }cm | Formato fechado: ${
                        materialBriefing.formato_fechado
                      }cm | Tipo de Papel: ${
                        materialBriefing.substrato
                      } | Gramatura: ${materialBriefing.gramatura} | Cores: ${
                        materialBriefing.cores
                      } | Acabamentos: ${
                        materialBriefing.acabamentos
                      } | Quantidades:  [${materialBriefing.quantidades.map(
                        (element) => element
                      )}] | Observações: ${materialBriefing.descricao} `
                    : materialBriefing.categoria_item == "Ponto de Venda"
                    ? `Tamanho Final: ${
                        materialBriefing.tamanho
                      } | Substrato: ${
                        materialBriefing.substrato
                      } | Espessura: ${materialBriefing.espessura} | Cores: ${
                        materialBriefing.cores
                      } | Acabamentos: ${
                        materialBriefing.acabamentos
                      } | Necessário Positivação: ${
                        materialBriefing.positivacao ? "Sim" : "Não"
                      } | Quantidades:  [${materialBriefing.quantidades.map(
                        (element) => element
                      )}] | Observações: ${materialBriefing.descricao} `
                    : materialBriefing.categoria_item === "Comunicação Visual"
                    ? `Tamanho Final: ${
                        materialBriefing.tamanho
                      } | Formato aberto: ${
                        materialBriefing.formato_aberto
                      }cm | Substrato: ${
                        materialBriefing.substrato
                      } | Gramatura: ${materialBriefing.gramatura} | Cores: ${
                        materialBriefing.cores
                      } | Acabamentos: ${
                        materialBriefing.acabamentos
                      } | Necessário Positivação: ${
                        materialBriefing.positivacao ? "Sim" : "Não"
                      } | Observações: ${materialBriefing.descricao} `
                    : ""}
                </h4>
              )}
            </div>
            <div className="column div-tipo">
              <h3>Tipo</h3>
              <h4>
                {Object.keys(materialBriefing).length != 0 &&
                  materialBriefing.categoria_item}
              </h4>
            </div>
            <div className="column div-ncm">
              {item && item.tipo === "Serviço" ? (
                <>
                  <h3>ISS</h3>
                  <h4>{item.iss}</h4>
                </>
              ) : (
                <>
                  <h3>NCM</h3>
                  <h4>
                    {ncm.length > 0 &&
                      Object.keys(item).length != 0 &&
                      ncm.find((element) => element.id == item.ncm_id).codigo}
                  </h4>
                </>
              )}
            </div>

            <div className="div-quantidade">
              <h3>Quantidade para cotação:</h3>

              <div className="flex">
                <select
                  {...register("quantidade")}
                  className="select"
                  onChange={(e) => quantidadeDeCotacao(e.target.value)}
                >
                  {materialBriefing.quantidades &&
                    materialBriefing.quantidades.map((quantidade) => (
                      <option>{quantidade}</option>
                    ))}
                </select>
              </div>
            </div>
            <div>
              {materialBriefing.fee ? (
                <div className="div-fee">
                  <h5>Fee Específico para o JOB:</h5>
                  <div className="flex percentage-input">
                    <input
                      type="number"
                      {...register("fee")}
                      disabled={
                        orcamentos
                          .filter((element) => element.quantidade == quantidade)
                          .filter(
                            (el) =>
                              el.status === "aprovado" ||
                              el.status === "proposta aprovada"
                          ).length > 0
                          ? true
                          : false
                      }
                    />
                    {user.permissoes.includes("produtor") ||
                    user.permissoes.includes("compras") ? (
                      <button onClick={() => atualizandoFee()}>
                        Atualizar
                      </button>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              ) : (
                <div className="div-fee">
                  <h5>Fee Específico para o JOB:</h5>
                  <div className="flex percentage-input">
                    <input
                      type="number"
                      {...register("fee")}
                      disabled={
                        orcamentos
                          .filter((element) => element.quantidade == quantidade)
                          .filter(
                            (el) =>
                              el.status === "aprovado" ||
                              el.status === "proposta aprovada" ||
                              el.status === "concluido"
                          ).length > 0
                          ? true
                          : false
                      }
                    />
                    {user.permissoes.includes("produtor") ||
                    user.permissoes.includes("compras") ? (
                      <button onClick={() => atualizandoFee()}>
                        Atualizar
                      </button>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
          {ncm.length > 0 && materialBriefing ? (
            <TabelaDisputa
              orcamentos={orcamentos.filter(
                (element) => element.quantidade == quantidade
              )}
              buscarMaterialBriefing={buscarMaterialBriefing}
              materialBriefing={materialBriefing}
              ncm={ncm.find((element) => element.id == item.ncm_id)}
              fee={materialBriefing.cliente_fee}
              categoriasFornecedor={categoriasFornecedor}
              item={item}
              bloquear={bloquear}
              quantidade={quantidade}
              setAbrirModalEditarPrazo={setAbrirModalEditarPrazo}
            />
          ) : (
            <Loading />
          )}
        </div>
      ) : (
        <Loading />
      )}
      {abrirModalEditarPrazo && (
        <EditarPrazoConcorrencia
          materialBriefing={materialBriefing}
          state={setAbrirModalEditarPrazo}
          editarPrazo={adicionarPrazoProposta}
        />
      )}
    </DisputaPageStyle>
  );
};
