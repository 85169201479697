import { useNavigate } from "react-router-dom";
import { BoxFiltros, ItensContainer } from "./style";
import Table from "./Table";
import som from "../../../assets/campanha.svg";
import { useEffect, useState } from "react";
import { ReactSVG } from "react-svg";
import api from "../../../services/api";
import { toast } from "react-toastify";
import { useAuth } from "../../../providers/Auth";

const CampanhasCriadas = () => {
  const navigate = useNavigate();
  const { user } = useAuth();

  const [status, setStatus] = useState("");
  const [textoPesquisa, setTextoPesquisa] = useState("");
  const [marcas, setMarcas] = useState([]);
  const [produtores, setProdutores] = useState([]);
  const [atendimentos, setAtendimentos] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [clienteSelecionado, setClienteSelecionado] = useState("");
  const [produtorSelecionado, setProdutorSelecionado] = useState("");
  const [atendimentoSelecionado, setAtendimentoSelecionado] = useState("");

  function NovaCampanha() {
    navigate("/campanha/nova");
  }

  function buscarMarcas() {
    api
      .get("/marcas")
      .then((response) => {
        setMarcas(response.data.data.marcas);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  function listarProdutores() {
    api
      .get(`/clientes/${0}/produtores`)
      .then((response) => {
        setProdutores(response.data.data.produtores.produtores);
      })
      .catch((error) => {
        console.error("Listar Produtores Erro:", error);
        toast.error("Erro ao listar produtores. Tente novamente.");
      });
  }

  function listarAtendimento() {
    api
      .get("/usuarios?perfil=atendimento")
      .then((response) => {
        setAtendimentos(response.data.data.usuarios);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  function listarClientes() {
    api
      .get("/clientes/cliente/responsaveis")
      .then((response) => {
        setClientes(response.data.data.clientes);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  useEffect(() => {
    if (
      user.permissoes.includes("admin") ||
      user.permissoes.includes("atendimento") ||
      user.permissoes.includes("produtor")
    ) {
      listarProdutores();
      listarAtendimento();
      listarClientes();
    }
  }, []);

  useEffect(() => {
    buscarMarcas();
  }, []);

  return (
    <ItensContainer>
      <div className="box">
        <div className="flex">
          <ReactSVG src={som} />
          <h1>Campanhas</h1>
        </div>
        <BoxFiltros>
          <div className="filtro">
            <input
              placeholder="Pesquisar por ID ou nome"
              value={textoPesquisa}
              onChange={(e) => setTextoPesquisa(e.target.value)}
            />
            {(user.permissoes.includes("admin") ||
              user.permissoes.includes("atendimento") ||
              user.permissoes.includes("produtor")) && (
              <div>
                <p>Filtrar por cliente</p>
                <select
                  value={clienteSelecionado}
                  onChange={(e) => setClienteSelecionado(e.target.value)}
                >
                  <option value="">Ver todos</option>
                  {clientes.map((cliente) => (
                    <option value={cliente.id}>{cliente.nome_fantasia}</option>
                  ))}
                </select>
              </div>
            )}
            {user.permissoes.includes("admin") && produtores && (
              <div>
                <p>Filtrar por produtor</p>
                <select
                  value={produtorSelecionado}
                  onChange={(e) => setProdutorSelecionado(e.target.value)}
                >
                  <option value="">Ver todos</option>
                  {produtores.map((produtor) => (
                    <option value={produtor.id}>{produtor.nome}</option>
                  ))}
                </select>
              </div>
            )}
            {user.permissoes.includes("admin") && produtores && (
              <div>
                <p>Filtrar por atendimento</p>
                <select
                  value={atendimentoSelecionado}
                  onChange={(e) => setAtendimentoSelecionado(e.target.value)}
                >
                  <option value="">Ver todos</option>
                  {atendimentos.map((atendimento) => (
                    <option value={atendimento.id}>{atendimento.nome}</option>
                  ))}
                </select>
              </div>
            )}
            <div>
              <p>Filtrar por status</p>
              <select
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              >
                <option value="">Ver todos</option>
                <option value="aguardando">Aguardando análise</option>
                <option value="em produção">Em Produção</option>
                <option value="concluido">Concluído</option>
              </select>
            </div>
          </div>
          {!user.permissoes.includes("produtor") && (
            <button onClick={NovaCampanha}>Nova Campanha</button>
          )}
        </BoxFiltros>
        <Table
          status={status}
          clienteSelecionado={clienteSelecionado}
          produtorSelecionado={produtorSelecionado}
          atendimentoSelecionado={atendimentoSelecionado}
          textoPesquisa={textoPesquisa}
        />
      </div>
    </ItensContainer>
  );
};

export default CampanhasCriadas;
