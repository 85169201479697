import { Link, useNavigate } from "react-router-dom";
import { Body, Inputs, NovaCategoriaContainer } from "./styles";
import { toast } from "react-toastify";
import api from "../../../../services/api";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAuth } from "../../../../providers/Auth/index";
import { CampoObrigatorio } from "../../../../schemas/novoCadastro";
import { ReactSVG } from "react-svg";
import voltar from "../../../../assets/voltar.svg";

const NovoImposto = () => {
  const {
    // formState: { errors },
    handleSubmit,
    register,
  } = useForm();
  //{ resolver: yupResolver(CampoObrigatorio),}

  const navigate = useNavigate();
  const { user } = useAuth();

  const onSubmitFunction = (data) => {
    data.status = 1;
    const imposto = [data];

    api
      .post("/regimes-empresa", data)
      .then((response) => {
        navigate("/imposto");
        toast.success("Item cadastrado com sucesso!");
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  return (
    <NovaCategoriaContainer onSubmit={handleSubmit(onSubmitFunction)}>
      <div className="box">
        <div className="flex">
          <ReactSVG
            onClick={() => navigate("/imposto")}
            className="voltar"
            src={voltar}
          />
          <div>
            <h1>Imposto</h1>
            <span className="underline">Novo Imposto</span>
          </div>
        </div>
        <Body>
          <div className="checkbox">
            <input
              type="checkbox"
              id="checkbox1"
              name="status"
              {...register("status")}
              defaultChecked={true}
            />

            <label htmlFor="checkbox1">Ativo</label>
          </div>
          <Inputs>
            <div>
              <label>Regime da Empresa *</label>
              <input
                type="text"
                name="nome"
                {...register("regime_empresa")}
                placeholder="Simples, pressumido ou real..."
              />
            </div>
            <div>
              <label>PIS *</label>
              <input
                {...register("pis")}
                type="number"
                min={0}
                placeholder="0%"
              />
            </div>
            <div>
              <label>COFINS *</label>
              <input
                {...register("cofins")}
                type="number"
                min={0}
                placeholder="0%"
              />
            </div>
          </Inputs>
        </Body>
        <div className="buttons">
          {user && user.permissoes.includes("itens_criar") && (
            <button type="submit">Salvar</button>
          )}
        </div>
      </div>
    </NovaCategoriaContainer>
  );
};

export default NovoImposto;
