import { Link } from "react-router-dom";
import { TableContainer } from "./styles";
import { ReactSVG } from "react-svg";
import ativo from "../../../assets/ativo.svg";
import inativo from "../../../assets/inativo.svg";
import { useEffect, useState } from "react";

const Table = ({ perfilSelecionado, textoPesquisa, usuarios }) => {
  const colunas = ["Id", "Nome", "Perfil", "Status"];
  const [usuariosFiltrados, setusuariosFiltrados] = useState([]);

  useEffect(() => {
    setusuariosFiltrados(usuarios.filter(filtrarUsuarios));
  }, [usuarios]);

  //FILTRO DE INPUT
  const filtrarUsuarios = (usuario) => {
    const perfilFiltrado =
      !perfilSelecionado || usuario.perfil_id === parseInt(perfilSelecionado);
    const pesquisaFiltrada =
      !textoPesquisa ||
      usuario.nome.toLowerCase().includes(textoPesquisa.toLowerCase());
    const pesquisaFiltradaSobrenome =
      !textoPesquisa ||
      usuario.sobrenome.toLowerCase().includes(textoPesquisa.toLowerCase());
    const idUsuario = parseInt(textoPesquisa);
    const idFiltrado = !isNaN(idUsuario) && usuario.id === idUsuario;

    return (
      perfilFiltrado &&
      (pesquisaFiltrada || idFiltrado || pesquisaFiltradaSobrenome)
    );
  };

  return (
    <TableContainer>
      <table>
        <thead>
          <tr>
            {colunas.map((coluna) => (
              <th key={coluna}>{coluna}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {usuariosFiltrados.map((lista) => (
            <tr className="map" key={lista.id}>
              <td>#{lista.id}</td>
              <td>{lista.nome}</td>
              <td>{lista.perfil}</td>

              {lista.status === 1 ? (
                <td>
                  <div className="ativo">
                    <ReactSVG src={ativo} />
                    <p>ativo</p>
                  </div>
                </td>
              ) : (
                <td>
                  <div className="ativo">
                    <ReactSVG src={inativo} />
                    <p>inativo</p>
                  </div>
                </td>
              )}
              <td className="td-editar">
                <Link
                  to={`/usuario/editar/${lista.id}`}
                  className="editar"
                  htmlFor="editar"
                >
                  Abrir
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </TableContainer>
  );
};

export default Table;
