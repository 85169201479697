import { styled } from "styled-components";

export const ItemMaterialBriefingStyle = styled.div`
  padding: 30px;
  background-color: var(--base);
  border-radius: 16px;
  margin-right: 30px;
  margin-top: 20px;
  min-width: 500px;

  .status {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    width: 230px;

    svg {
      margin-right: 6px;
      width: 16px;
      height: 16px;
    }
  }

  .h2 {
    font-size: 18px;
    font-weight: 600;
    margin-right: 10px;
  }

  .margin-bottom {
    margin-bottom: 20px;
  }

  .column {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .div-unidades {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px 0px;
    border-bottom: 1px solid var(--gray70);
  }

  .tipo {
    font-size: 14px;
    text-align: center;
    font-weight: 600;
    color: var(--base);
    border-radius: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .div-analise {
    background-color: #ef5713;
    border-radius: 30px;
  }

  .div-orcamento {
    background-color: #9f0010;
    border-radius: 30px;
  }

  .pendente {
    background-color: orange;
  }

  .prazo-expirado {
  }

  .div-recusado {
    background-color: #ee4859;
    border-radius: 30px;
  }

  .div-aprovado {
    background-color: #609ae5;
    border-radius: 30px;
  }

  .status-producao {
    margin: 0px 0px 2px;
  }
  .div-prazo-expirado {
    background-color: #aaaaaa;
    width: 230px;
    border-radius: 30px;
  }

  .div-esperando {
    background-color: #ef5713;
    border-radius: 30px;
  }

  .justify {
    display: flex;
    justify-content: space-between;
  }

  .concluido {
    background-color: black;
  }
`;
