import Header from "../../components/Menu";
import Imposto from "../../components/Imposto";

const ImpostoPage = () => {
  return (
    <div className="flex">
      <Header />
      <Imposto />
    </div>
  );
};

export default ImpostoPage;
