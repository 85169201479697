import styled from "styled-components";

export const AddBriefingVitrineStyle = styled.div`
  display: flex;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  align-items: center;
  z-index: 3;

  td {
    border-bottom: none !important;
    border-top: none !important;
  }

  .white {
    background-color: var(--grayF3);
    width: 90%;
    max-height: 90%;
    border-radius: 18px;
    opacity: 1;
    padding: 30px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
    flex-direction: column;
    position: relative;
    z-index: 1;
  }

  .overflow {
    overflow: auto;
  }

  .close {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--base);
    font-size: 1.8rem;
    background-color: #fa5252;
    border-radius: 35px;
    width: 50px;
    height: 50px;
    position: absolute;
    top: -20px;
    right: -25px;
    cursor: pointer;
    text-decoration: none;
    z-index: 9999;
  }

  .disputa {
    display: flex;
    background-color: var(--base01);
    border-radius: 60px 0px 0px 60px;

    .body {
      width: 100%;
      padding: 35px;
      border-radius: 60px 0px 0px 60px;
    }

    .select {
      width: 100%;
      border-radius: 6px;
      border: none;
      padding: 4px;
    }

    .margin-right {
      margin-right: 15px;
    }

    .div-detalhes {
      display: flex;
      justify-content: space-between;
    }

    .back {
      cursor: pointer;
      svg {
        width: 35px;
        height: 35px;
      }
    }

    .div-detalhes {
      display: flex;
      width: 80%;
    }

    .detalhes {
      margin-top: 6px;
      margin-left: 12px;
    }

    h1 {
      font-size: 30px;
      font-weight: 600;
      color: #640000;
    }

    .nome-campanha {
      font-size: 22px;
      font-weight: 600;
      margin-bottom: 24px;
      margin-top: 6px;
      color: #640000;
    }

    .column {
      display: flex;
      flex-direction: column;
      width: 70%;
      width: max-content;

      .prazo_proposta {
        display: flex;
        justify-content: center;
        align-items: baseline;
        h2 {
          padding-top: 30px;
          font-size: 14px;
          font-weight: 600;
        }

        h3 {
          font-size: 13px;
          margin-left: 5px;
        }

        span {
          color: #609ae5;
          font-size: 10px;
          text-decoration: underline;
          margin-left: 5px;
        }
      }
      .prazo {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: baseline;
        h2 {
          padding-top: 30px;
          font-size: 14px;
          font-weight: 600;
        }

        h3 {
          font-size: 13px;
          margin-left: 5px;
        }

        span {
          color: #609ae5;
          font-size: 10px;
          text-decoration: underline;
          margin-left: 5px;
        }
      }

      input {
        border-radius: 6px;
        border: none;
        padding: 6px;
        margin-right: 13px;
      }

      button {
        border: none;
        border-radius: 8px;
        background-color: var(--amarelo);
        color: var(--principal);
        font-size: 12px;
        font-weight: 600;
        padding: 8px;
      }
    }

    .div-dados {
      display: flex;
      justify-content: space-between;

      h3 {
        font-size: 14px;
      }

      h4 {
        font-size: 14px;
      }
    }

    .faixa {
      display: flex;
      width: 100%;
      padding: 12px 10px;
      background-color: var(--principal);
      align-items: center;
      border-radius: 5px;
      margin-top: 30px;
      justify-content: space-between;

      span {
        color: var(--amarelo);
        font-size: 20px;
        margin-right: 6px;
      }

      h2 {
        font-size: 16px;
        color: var(--base);
        margin-left: 10px;
      }

      h4 {
        font-size: 16px;
        color: var(--amarelo);
      }
    }

    .div-especificao {
      width: 300px;
      margin-top: 20px;

      h3 {
        font-weight: 600;
        margin-bottom: 6px;
        font-size: 14px;
      }

      h4 {
        font-size: 12px;
      }
    }

    .div-tipo {
      margin-top: 20px;
      width: max-content;

      h3 {
        font-weight: 600;
        margin-bottom: 6px;
        font-size: 14px;
      }

      h4 {
        font-size: 12px;
      }
    }

    .div-ncm {
      margin-top: 20px;
      width: max-content;

      h3 {
        font-weight: 600;
        margin-bottom: 6px;
        font-size: 14px;
      }

      h4 {
        font-size: 12px;
      }
    }

    .div-quantidade {
      display: flex;
      flex-direction: column;
      margin-top: 20px;

      h3 {
        font-size: 14px;
        font-weight: 600;
      }

      label {
        margin-left: 6px;
        font-size: 14px;
      }
    }

    .div-detalhes-proposta {
      width: 90%;
      justify-content: space-around;
    }

    .marcas {
      font-size: 14px;
      margin: 0px 3px;
    }

    .aling-itens {
      display: flex;
      align-items: flex-end;
    }

    .div-fee {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      h5 {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 10px;
      }

      input {
        border-radius: 4px;
        border: none;
        height: 30px;
        width: 100px;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      }

      button {
        border: none;
        background-color: var(--amarelo);
        color: var(--principal);
        border-radius: 8px;
        padding: 4px;
        font-size: 12px;
        font-weight: 600;
        margin-left: 10px;
      }
    }

    input[type="radio"] {
      cursor: pointer;
    }
  }
`;
