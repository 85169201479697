import { styled } from "styled-components";

export const PropostaFornecedorPageStyle = styled.div`
  display: flex;

  .width {
    width: calc(100% - 260px);
    padding: 30px;
    background-color: var(--base01);
    border-radius: 60px 0px 0px 60px;
  }

  .back {
    cursor: pointer;
    width: 50px;
    height: 50px;
    svg {
      width: 40px;
    }
  }

  .div-detalhes {
    display: flex;
    justify-content: space-between;
  }

  .div-detalhes {
    display: flex;
    width: 100%;
    align-items: center;
  }

  h1 {
    margin-bottom: 10px;
    font-size: 28px;
    font-weight: 600;
    color: #640000;
    padding: 18px 0px 0px 0px;
    margin-bottom: 35px;
  }

  .nome {
    margin-left: 6px;
  }

  .font-weight {
    font-weight: 600;
  }

  .nome-campanha {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 24px;
    margin-top: 14px;
    color: #640000;
  }

  .column {
    display: flex;
    flex-direction: column;
    width: 400px;

    .prazo {
      display: flex;
      justify-content: center;
      align-items: baseline;
      h2 {
        padding-top: 40px;
        font-size: 17px;
        font-weight: 600;
      }

      h3 {
        font-size: 15px;
        margin-left: 5px;
      }

      span {
        color: #609ae5;
        font-size: 14px;
        text-decoration: underline;
        margin-left: 5px;
      }
    }
  }

  .div-dados {
    display: flex;
    justify-content: space-between;
  }

  .h4-0 {
    padding: 0px 5px 0px 3px;
  }

  .h4-1 {
    padding-left: 5px;
    border-left: solid 1px var(--gray70);
  }
`;
