// import { useNavigate } from "react-router-dom";
import { ItemMaterialBriefing } from "../ItemMaterialBriefing";
import { ListaCampanhaStyle } from "./style";

export const ListaCampanha = (element, array, orcamentos, fornecedor) => {
  const filter = array.filter(
    (elem) =>
      (elem.campanhas_id == element.id && Number(elem.status) !== 0) ||
      elem.campanha_id == element.id
  );

  return (
    <ListaCampanhaStyle>
      {filter.length > 0 ? (
        <>
          <div className="flex">
            <h2 className="title">
              #{element.id} - {element.nome}
            </h2>
            <a href={`/fornecedor/campanha/${element.id}`}>Ver tudo &gt;</a>
          </div>
          <div className="filter">
            {filter &&
              filter.map((ele) =>
                ItemMaterialBriefing(ele, orcamentos, fornecedor)
              )}
          </div>
        </>
      ) : (
        <></>
      )}
    </ListaCampanhaStyle>
  );
};
