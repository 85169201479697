import styled from "styled-components";

export const ModalPreencherEnderecoContainer = styled.div`
  display: flex;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  align-items: center;
`;

export const ContentContainer = styled.div`
  background: #f6f6fc;
  min-width: 15%;
  border-radius: 18px;
  opacity: 1;
  padding: 30px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  position: relative;
  z-index: 10;
  width: 80%;

  > .fechar {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    border: none;
    background-color: #fa5252;
    width: 50px;
    height: 50px;
    position: absolute;
    top: -25px;
    right: -25px;
    > div {
      height: 25px;
    }
  }

  > h1 {
    font-size: 0.9em;
    color: #000;
  }

  .error-span {
    color: red;
    font-size: 14px;
    text-shadow: 1px 1px 1px #00000015;
    padding-left: 5px;

    position: absolute;
    max-width: 100%;
    left: 0;

    z-index: 1;
    background-color: #ffffff;
    padding: 0.5em;
    border-radius: 6px;
    box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.3),
      0 0.0625rem 0.125rem rgba(0, 0, 0, 0.2);
  }

  .error-span::before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;

    bottom: 100%;
    left: 1em;
    border: 0.5rem solid transparent;
    border-top: none;

    border-bottom-color: #fff;
    filter: drop-shadow(0 -0.0625rem 0.0625rem rgba(0, 0, 0, 0.1));
  }

  > .endereco-container {
    display: flex;
    flex-wrap: wrap;
    column-gap: 2%;
    row-gap: 20px;

    > .cep {
      width: 40%;
    }

    > .email,
    > .logradouro {
      width: 58%;
    }

    > .numero {
      width: 35%;
    }

    > .complemento {
      width: 63%;
    }

    > .bairro,
    > .cidade,
    > .estado {
      width: 32%;
    }

    > .button-container {
      display: flex;
      justify-content: center;
      width: 100%;

      > button {
        background: #ffbe00;
        border-radius: 24px;
        border: none;
        color: #7d0000;
        font-weight: bold;
        width: 140px;
        height: 35px;
      }
    }
  }
`;

export const CepInputDiv = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: start;
  box-sizing: border-box;
  background-color: #ffffff;

  label {
    display: flex;
    align-items: center;
    justify-content: center;
    width: min-content;
    height: 100%;
    padding: 0 20px;
    border-radius: 5px;
    background-color: #707070;
    cursor: ${({ disabled }) => (disabled ? "" : "pointer")};
    color: #ffffff;
  }

  input {
    border: 1px solid #fafafd;
    border-radius: 5px;
    padding: 0 13px;
    max-width: 100%;
    width: 100%;
    height: 47px;
    :disabled {
      background-color: #fafafa;
      border: 1px solid #fafafa;
      color: #c2c5c7;
    }

    .hidden {
      display: none;
    }

    :focus {
      outline: none;
    }

    ::placeholder {
      color: #babdbf;
    }
  }
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  position: relative;
  height: 72px;

  > label {
    color: #292828;
  }

  > input {
    background-color: #ffffff;
    border: 1px solid #fafafd;
    border-radius: 5px;
    height: 47px;
    padding: 0 13px;
  }

  > span {
    top: 75px;
  }
`;

export const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  a {
    color: #4061bc;
    margin-left: 5px;
    font-size: 0.8em;
  }

  > select {
    background-color: #ffffff;
    border: 1px solid #fafafd;
    border-radius: 5px;
    height: 47px;
    padding: 0 13px;
    max-width: 100%;
    width: 100%;
  }

  > span {
    top: 75px;
  }
`;
