import { toast } from "react-toastify";
import { AprovarPropostaModalContainer, ContentContainer } from "./styles";
import api from "../../../services/api";
import { useForm } from "react-hook-form";
import { ReactSVG } from "react-svg";
import salvar from "../../../assets/salvar.svg";

const AprovarPropostaModal = ({
  setModalOpened,
  campanha,
  materiais,
  buscarDadosCampanha,
}) => {
  const { register, getValues } = useForm();
  const handleCriaProposta = async () => {
    const urgente = getValues("urgente");
    const po = getValues("po");
    const idsVistos = new Set();
    const duplicados = new Set();
    const materiaisDuplicados = materiais.filter((material) => {
      if (idsVistos.has(material.material_id)) {
        duplicados.add(material.material_id);
        return true;
      }
      idsVistos.add(material.material_id);
      return false;
    });
    if (materiaisDuplicados.length) {
      toast.error(
        "O mesmo material não pode ter duas ou mais quantidades selecionadas."
      );
      return;
    }
    let subtotal = 0;
    materiais.forEach((material) => {
      if (material.origem === "Vitrine") {
        subtotal += Number(material.valor_total);
      } else {
        subtotal += Number(material.valor_de_venda);
      }
    });
    try {
      const dados = {
        nome: campanha.nome,
        subtotal: subtotal,
        campanha_id: campanha.id,
        po: urgente ? "Urgente Job" : po,
      };
      const response = await api.post("/propostas", dados);
      const propostaId = response.data.data.insertId;
      const materiaisBriefing = [];
      const materiaisVitrine = [];
      const dadosMateriais = materiais.map((material) => {
        if (material.origem === "Briefing") {
          materiaisBriefing.push({
            id: material.orcamento_id,
            status: "proposta aprovada",
          });
        }
        if (material.origem === "Vitrine") {
          materiaisVitrine.push({
            id: material.material_id,
            status: "proposta aprovada",
          });
        }
        return {
          proposta_id: propostaId,
          fornecedor_id: material.fornecedor_id,
          prazo_entrega: material.prazo_entrega,
          origem: material.origem,
          nome: material.nome_material,
          descricao: material.descricao_material,
          material_id: material.material_id,
          quantidade: material.quantidade,
          icms: material.icms
            ? Number(material.icms)
            : Number(material.imposto),
          iss: Number(material.iss),
          ipi: material.ipi,
          valor_unitario: material.valor_unidade,
          valor_final: material.valor_de_venda,
          orcamento_id: material.orcamento_id ? material.orcamento_id : "",
        };
      });
      for (const material of materiaisBriefing) {
        await api.patch(
          `/orcamento-materiais-briefing/${material.id}`,
          material
        );
      }
      for (const material of materiaisVitrine) {
        await api.patch(`/campanha-material-vitrine/${material.id}`, material);
      }
      await api.post("/proposta-materiais", { materiais: dadosMateriais });
      toast.success("Proposta criada com sucesso.");
      setModalOpened(false);
      buscarDadosCampanha();
    } catch (error) {
      console.log(error);
      toast.error("Erro ao criar proposta.");
    }
  };

  return (
    <AprovarPropostaModalContainer onClick={() => setModalOpened(false)}>
      <ContentContainer onClick={(e) => e.stopPropagation()}>
        <p>Informe o número da PO</p>
        <input {...register("po")} placeholder="000000" />
        <div className="flex">
          <input type="checkbox" {...register("urgente")} />
          <h4>Não tenho número de PO, esse é um</h4>
          <h5>Urgent JOB</h5>
        </div>
        <div className="buttons-container">
          <button onClick={async () => await handleCriaProposta()}>
            Salvar
          </button>
        </div>
      </ContentContainer>
    </AprovarPropostaModalContainer>
  );
};

export default AprovarPropostaModal;
