import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font: normal normal normal 16px/20px Poppins;
        color: #363636;
    }

    .rounded-icon{
        border-radius: 1000px;
    }

    :root{
        --principal:#640000;
        --base:#FFFFFF;
        --base01:#F6F6FC;
        --black:#000000;
        --gray33: #333333;
        --gray36:#363636;
        --gray66:#666666;
        --gray70:#707070;
        --grayA5:#A5A5A5;
        --grayDA:#DADADA;
        --grayEF:#EFEFEF;
        --grayD2:#D2D2D2;
        --status:#EF923E;
        --grayF3:#F3F3F3;
        --azul:#609AE5;
        --amarelo:#FFBE00;
        --urgenciaMedia:#F37020;
        --urgenciaAlta:#E20817;
        --urgenciaBaixa:#35C047;


    }

    body {
        min-height: 100vh;
        background-color: #FFFFFF;
    }

    button {
        cursor: pointer;
    }

    select{
        cursor: pointer;
    }

    option{
        cursor: pointer;
    }

    a{
        cursor: pointer;
    }

    input{
        outline: none;
    }

  
    
    .input-error {
        color: red;
        font-size: 14px;
        text-shadow: 1px 1px 1px #00000015;
        margin-top: 3px;
        padding-left: 5px;

        position: absolute;
        max-width: 100%;
        left: 0;
        
        z-index: 1;
        background-color: #FFFFFF;
        padding: 0.5em 0.7em;
        border-radius: 6px;
        box-shadow:	0 0.125rem 0.5rem rgba(0, 0, 0, .3), 0 0.0625rem 0.125rem rgba(0, 0, 0, .2);
    };

    .input-error::before{
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        
        bottom: 100%;
        left: 1em;
        border: .5rem solid transparent;
        border-top: none;

        border-bottom-color: #fff;
        filter: drop-shadow(0 -0.0625rem 0.0625rem rgba(0, 0, 0, .1));
    };

    .input-error-cadastro {
        color: red;
        font-size: 14px;
        text-shadow: 1px 1px 1px #00000015;
        margin-top: 3px;
        padding-left: 5px;

        position: relative;
    
    
        
        z-index: 1;
        background-color: #FFFFFF;
        padding: 0.5em 0.7em;
        border-radius: 6px;
        box-shadow:	0 0.125rem 0.5rem rgba(0, 0, 0, .3), 0 0.0625rem 0.125rem rgba(0, 0, 0, .2);
    };

    .input-error-cadastro::before{
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        
        bottom: 100%;
        left: 1em;
        border: .5rem solid transparent;
        border-top: none;

        border-bottom-color: #fff;
        filter: drop-shadow(0 -0.0625rem 0.0625rem rgba(0, 0, 0, .1));
    };

    body{
        background-color :#FFFFFF;
    }
    
    .flex{
        display: flex;
    }
`;

export default GlobalStyle;
