import NovoImposto from "../../../components/Cadastros/Novo/NovoImposto";
import Header from "../../../components/Menu";

const NovoImpostoPage = () => {
  return (
    <div className="flex">
      <Header />
      <NovoImposto />
    </div>
  );
};

export default NovoImpostoPage;
