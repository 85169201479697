import { LoadingTr, Table } from "./styles";

const CustomTable = ({ header, children, message, isLoading = false }) => {
  return (
    <Table>
      <thead>
        <tr>
          {header.map((item, index) => {
            return <th key={index}>{item}</th>;
          })}
        </tr>
      </thead>
      <tbody>
        {isLoading ? (
          <LoadingTr>
            {header.map((item, index) => (
              <td key={item}>
                {index === 0 && (
                  <div>
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                )}
              </td>
            ))}
          </LoadingTr>
        ) : children.length ? (
          children
        ) : (
          <tr className="message">
            {header.map((item, index) => {
              return <td key={item}>{index === 0 && message}</td>;
            })}
          </tr>
        )}
      </tbody>
    </Table>
  );
};

export default CustomTable;
