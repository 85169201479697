import { AuthProvider } from "./Auth";
import { DataProvider } from "./Data";

const Providers = ({ children }) => {
  return (
    <AuthProvider>
      <DataProvider>{children}</DataProvider>
    </AuthProvider>
  );
};

export default Providers;
