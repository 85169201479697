import styled from "styled-components";

export const LoginContainer = styled.div`
  background-size: cover;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: var(--amarelo);
`;

export const BoxLeft = styled.div`
  opacity: 1;
  display: flex;
  justify-content: start;
  align-items: center;
  width: 50%;
  height: 100%;
  border-radius: 0px 55px 55px 0px;

  > div {
    width: 100%;
    display: flex;
    justify-content: center;
  }
`;

export const BoxRight = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 100%;

  img {
    width: 80%;
    background-size: cover;
    min-width: 380px;
  }
`;
