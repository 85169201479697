import styled from "styled-components";

export const FormBrindeStyle = styled.form`
  .flex {
    margin-bottom: 18px;
  }

  .tres {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0px;

    .input-completo {
      width: 94%;
    }
  }

  .quatro {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 0px;

    .input-completo {
      width: 94%;
    }
  }

  label {
    font-size: 14px;
    font-weight: 500;
  }

  .item {
    margin-bottom: 18px;
  }
  .column {
    display: flex;
    flex-direction: column;
  }

  .width-90 {
    width: 90%;

    div {
      max-width: 92%;
    }
  }

  .true-false {
    width: 300px;
  }
`;

export const BriefingStyle = styled.div`
  background-color: var(--base01);
  border-radius: 40px;

  display: flex;
  width: 100%;
  border-radius: 50px;

  .back {
    cursor: pointer;
    svg {
      width: 45px;
      height: 45px;
    }
  }

  .div-right {
    padding: 40px 40px;
    width: 90%;
  }

  .title {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    h1 {
      font-size: 36px;
      font-weight: 600;
      margin-left: 14px;
    }
  }

  .div-category {
    display: flex;
    flex-direction: column;

    select {
      width: 220px;
      border-radius: 6px;
      border: none;
      padding: 6px 5px;
      margin-top: 7px;
    }
  }

  .div-category-name {
    display: flex;
    align-items: center;
  }

  .input-name {
    width: 100%;
    padding: 6px 0px;
    max-width: none;
    margin-top: 7px;
    border: none;
    border-radius: 6px;

    .select {
      width: 220px;
      border: none;
      padding: 6px 5px;
      border-radius: 6px;
      padding: 6px 5px;
    }

    input {
      border-radius: 6px;
      border: none;
      padding: 8px 6px;
      margin-top: 7px;
    }
  }

  .input-description {
    width: 100%;
    max-width: none;
    margin-top: 26px;
    input {
      border-radius: 6px;
      border: none;
      padding: 8px 6px;
      margin-top: 7px;
    }
  }

  .div-prazo-endereco {
    display: flex;
    align-items: flex-end;
  }

  .flex {
    display: flex;
    align-items: center;

    span {
      margin-left: 7px;
    }
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    margin-top: 20px;
    width: 100%;

    div {
      width: 100%;

      input {
        border-radius: 6px;
        border: none;
        padding: 8px 6px;
        margin-top: 7px;
      }
    }

    .preco {
      input {
        padding: 8px 30px;
      }
    }

    .aling {
      width: 225px;
    }

    .true-false {
      width: 300px;
    }
  }

  .input-data {
    margin-top: 20px;
    display: flex;

    margin-right: 30px;
    width: 220px;

    input {
      width: 100%;
      border-radius: 6px;
      border: none;
      padding: 8px 7px;
    }
  }

  .input-endereco {
    width: 100%;
    margin-left: 0px;
  }

  .div-file {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
  }

  #file-upload-button {
    border: none;
  }

  .div-medidas {
    display: flex;
    flex-direction: column;

    div {
      input {
        width: 60px;
        display: flex;
        text-align: center;
      }
    }
  }

  .inputs {
    width: 200px;
  }

  .inputs {
    width: 100%;
    display: flex;
    align-items: center;
    background-color: var(--base);
    text-align: center;
  }

  .button {
    border: none;
    border-radius: 60px;

    padding: 8px 16px;
    background-color: var(--amarelo);
    color: var(--principal);

    font-size: 16px;
    font-weight: 700;

    margin-top: 20px;
  }

  h5 {
    background-color: var(--base);
    color: var(--grayA5);
  }

  .input-error {
    position: relative;
  }

  .input-completo {
    width: 100%;
  }

  .data {
    width: max-content;
    margin-right: 30px;

    input {
      border-radius: 6px;
      border: none;
      padding: 8px 6px;
      margin-top: 9px;
    }
  }

  input[type="file"] {
    display: none;
  }

  .div-file {
    border: 1px dashed #707070;
    width: 100%;
  }

  .label-file {
    color: var(--gray70);
    text-align: center;
    padding: 25px 0px;
  }

  .red {
    text-decoration: underline;
    color: var(--principal);
  }

  .label-div-file {
    display: flex;
    margin-top: 30px;
  }

  @media (min-width: 1070px) {
    .grid {
      flex-direction: column;
      display: flex;
    }
  }

  @media (min-width: 1270px) {
    .grid {
      flex-direction: column;
      display: flex;
    }
  }

  .input_categoria {
    width: 100%;
    display: flex;
    margin: 10px auto 20px;
    height: 40px;
    border-radius: 6px;
    border: none;
  }
`;
