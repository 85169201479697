import { useNavigate } from "react-router-dom";
import { BoxFiltros, NovaCategoriaContainer } from "./styles";
import Table from "./Table";
import { useAuth } from "../../providers/Auth";
import { useEffect, useState } from "react";
import api from "../../services/api";
import { toast } from "react-toastify";

const CategoriaFornecedor = () => {
  const navigate = useNavigate();
  const [empresas, setEmpresas] = useState([]);
  const [perfilSelecionado, setPerfilSelecionado] = useState("");
  const [textoPesquisa, setTextoPesquisa] = useState("");

  function buscarEmpresa() {
    api
      .get("/empresas")
      .then((response) => {
        setEmpresas(response.data.data.empresas);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  useEffect(() => {
    buscarEmpresa();
  }, []);
  function NovaCategoria() {
    navigate("/categoria/nova");
  }
  return (
    <NovaCategoriaContainer>
      <div className="box">
        <h1> Categoria Fornecedor</h1>
        <BoxFiltros>
          <div className="filtro">
            <input
              placeholder="Pesquisar por ID ou nome da categoria"
              value={textoPesquisa}
              onChange={(e) => setTextoPesquisa(e.target.value)}
            />
            <div>
              <p>Filtrar por empresa</p>
              <select
                value={perfilSelecionado}
                onChange={(e) => setPerfilSelecionado(e.target.value)}
              >
                <option value="">Ver todos</option>;
                {empresas.map((data) => (
                  <option key={data.id} value={data.id}>
                    {data.nome}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <button onClick={NovaCategoria}>Nova Categoria</button>
        </BoxFiltros>
        <Table
          perfilSelecionado={perfilSelecionado}
          textoPesquisa={textoPesquisa}
        />
      </div>
    </NovaCategoriaContainer>
  );
};

export default CategoriaFornecedor;
