import NovaMarca from "../../../components/Cadastros/Novo/NovaMarca";
import Header from "../../../components/Menu";

const NovaMarcaPage = () => {
  return (
    <div className="flex">
      <Header />
      <NovaMarca />
    </div>
  );
};

export default NovaMarcaPage;
