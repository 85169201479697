import React from "react";
import Header from "../../../components/Menu";
import NovoPerfil from "../../../components/Cadastros/Novo/NovoPerfil";

const NovoPerfilPage = () => {
  return (
    <div className="flex">
      <Header />
      <NovoPerfil />
    </div>
  );
};

export default NovoPerfilPage;
