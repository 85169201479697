import styled from "styled-components";

export const ConfirmacaoDeOrcamentoStyle = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  div {
    position: relative;
    display: flex;
    flex-direction: column;
    text-align: center;
    border-radius: 30px;
    background-color: var(--base);
    padding: 20px 15px;
  }

  button {
    padding: 15px 30px;
    border: none;
    background-color: var(--amarelo);
    color: var(--principal);
    border-radius: 24px;
    font-size: 16px;
    font-weight: 700;
    width: max-content;
    margin: 10px auto 0px;
  }

  input {
    border-radius: 6px;
    border: none;
    padding: 6px;
    margin-right: 13px;
    width: 220px;
    margin: 8px auto 0px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  }

  h3 {
    font-weight: 500;
    font-size: 18px;
    width: 350px;
    margin: 0 auto 10px;
  }

  .close {
    position: absolute;
    top: -20px;
    right: -15px;
    padding: 15px 15px;
    background-color: #fa5252;
    border-radius: 300px;
    font-size: 32px;
    font-weight: 400;
    color: var(--base);
    cursor: pointer;
  }

  .div-buttons {
    display: flex;
    width: 400px;
    flex-direction: row;
  }

  .cancelar {
    background-color: var(--principal);
    color: white;
  }
`;
